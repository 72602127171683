import { handleActions } from 'redux-actions';

const ProfileTabReducer = {
  selectedTab: 0,
};

const reducer = handleActions(
  {
    PROFILE_TAB_SUCCESS: (state, selectedTab) => {
      return {
        ...state,
        selectedTab: selectedTab.payload,
      };
    },
  },
  ProfileTabReducer
);
export default reducer;
