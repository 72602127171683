import { Typography } from '@mui/material';
import React from 'react';
import { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import * as chestAction from '../redux/action/chestAction';
const Timer = (props) => {
  const [minutes, setMinutes] = useState(props.min);
  const [seconds, setSeconds] = useState(props.sec % 60);
  useEffect(() => {
    let myInterval = setInterval(() => {
      if (seconds > 0) {
        setSeconds(seconds - 1);
      }
      if (seconds === 0) {
        if (minutes === 0) {
          clearInterval(myInterval);
        } else {
          setMinutes(minutes - 1);
          setSeconds(59);
        }
      }
    }, 1000);
    return () => {
      clearInterval(myInterval);
    };
  });

  useEffect(() => {
    if(window.location.pathname==='/my-profile'&&seconds===0&&minutes===0){
        props.getChestData(localStorage.getItem('userToken'));
    }  
  }, [seconds||minutes])
  
  return (
    <>
      {props.typeTimer === 'footer' ? `${minutes < 10 ? `0${minutes}`: minutes}:${seconds < 10 ? `0${seconds}`  : seconds}`:
      (
        minutes === 0 && seconds === 0 ? null : (
          <Typography
            variant="body1"
            component="p"
            sx={{ marginBottom: '40px', ml: 1.5 }}
          >
            {minutes < 10 ? `0${minutes}` : minutes}:
            {seconds < 10 ? `0${seconds}` : seconds}
          </Typography>
        )
      ) }
    </>
  );
};

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {
    getChestData: (requestData) =>
      dispatch(chestAction.getChestData(requestData)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Timer);
