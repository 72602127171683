import React from 'react';

export default function ChatStickers(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width={props.width}
      height={props.height}
      viewBox={`0 0 ${props.width} ${props.height}`}
      style={{marginRight:`${props.margin}px`,marginLeft:`${props.margin}px`}}
    >
      {props.id!==''?<image
        xlinkHref={props.source}
        width={props.width}
        height={props.height}
        onClick={props.havingCallbackFunction&&props.uniCode!==null?()=>props.sendMessage(props.uniCode):props.havingCallbackFunction&&props.uniCode===null?()=>props.sendMessage(props.id):''}
      />:<image
      xlinkHref={props.source}
      width={props.width}
      height={props.height}
    />}
    </svg>
  );
}
