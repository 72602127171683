import React from 'react'

export default function TermsAndConditions() {
    return (
        <div>	<p>Die Webseite und alle Services, welche unter <a href="www.iseeju.com">www.iseeju.com</a> angeboten werden, sind von diesen
            Geschäftsbedingungen umfasst. Dies betrifft ausdrücklich, aber nicht ausschließlich den Zugang zu den
            Diensten & Leistungen dieser Internetpräsenz und aller angeschlossenen Dienste & Services. Dies
            schließt ausdrücklich die Nutzung von Mobil-Apps mit ein, welche dem Nutzer gesondert zu Verfügung
            gestellt werden. Im Folgenden werden alle Dienste & Services mit dem Label „iseeju“ gekennzeichnet
            und sind damit auch vollumfänglich gemeint.</p>

            <p>Die einzelnen Bestandteile dieser Allgemeinen Geschäftsbedingungen sind im Folgenden im Einzelnen
                aufgeführt.</p>

            <p>Der Zugang zu allen Diensten allen Services, die mit dem Label „iseeju“ in Zusammenhang stehen ist nur
                möglich, wenn der Nutzer die Allgemeinen Geschäftsbedingungen akzeptiert. Gleiches gilt für die
                Datenschutzerklärung. Des Weiteren verwendet diese Seite so genannte "Cookies" und der Nutzer
                stimmt der Verwendung dieser ausdrücklich zu. Wenn Sie weder der Verwendung von Cookies noch den
                Allgemeinen Geschäftsbedingungen sowie unseren Datenschutzbestimmungen zustimmen möchten,
                dann brechen Sie bitte jeglichen Anmeldevorgang ab und verlassen Sie diese Internetpräsenz und
                deinstallieren Sie jegliche mit dem Label „iseeju“ in Verbindung stehende Zusatz-Software.</p>

            <p>Diese Internetpräsenz ist ein erotischer Unterhaltungsdienst. Das Angebot umfasst sowohl die
                Kommunikation über das Onlineportal selbst als auch über angebundene Messenger-Dienste. Diese
                Dienste sind registrierten Usern eingeschränkt zugänglich. Nutzer, die über die angeschlossenen
                Zahlmethoden individuelle Gegenwerte für Ihre Einzahlungen (so genannte "Coins") erhalten haben,
                können diese Coins wiederum nutzen, um erweiterte Leistungen & Funktionen in Anspruch zu nehmen.
                Diese Leistungen sind im Einzelnen auf der jeweiligen Seite in unmittelbarer Nähe zum jeweiligen
                Angebot benannt, ebenso wie der zu entrichtende Gegenwert in Coins.</p>

            <p>Diese Allgemeinen Geschäftsbedingungen betreffen somit insbesondere die Erstellung von eigenen
                Accounts durch den sich registrierenden Nutzer sowie den Zugang, die Nutzungsmöglichkeiten &
                angebotenen Dienste dieser Internetpräsenz und aller angeschlossenen Dienste, welche für Besucher,
                registrierte Nutzer und jene Nutzern zugänglich sind, die für die einzelnen Dienstleistungen Zahlungen
                erbringen.</p>

            <h3 style={{paddingLeft:"4rem"}}>1. Definitionen</h3>

            <p style={{paddingLeft:"6rem"}}>a. "Account" umfasst den Zugang zum geschlossenen Nutzerbereich nach erfolgreich
                abgeschlossener Registrierung durch den Nutzer.</p>

            <p style={{paddingLeft:"6rem"}}>b. "Erotischer Kontakt, Flirt- und Chat-Dienstleistungen umfassen den Kontakt zu anderen Personen über die Messenger-Funktionen des Portals im geschlossenen Nutzerbereich
                sowie den Kontakt über angeschlossene Dienste zu anderen Mitgliedern des Portals und
                virtuellen Personen. Dieser Service setzt zusätzlich zu den real existierenden Personen
                mit eigens hinterlegten Profilen so genannte "Agenten" ein, welche dem Nutzer als
                Gesprächspartner für den virtuellen Gedankenaustausch zur Verfügung stehen. Mit
                diesen Personen sind lediglich Rollenspiele möglich und es wird über einen reinen
                virtuellen Kontakt hinaus zu keinen "Realkontakten" kommen, Treffen mit diesen
                Agenten sind also ausgeschlossen. Diese Agenten sind gewerblich agierende Mitarbeiter
                oder Freiberufler eines oder auch diverser Unternehmen und werden für den virtuellen
                Gedankenaustausch von ihren Arbeitgebern/Geschäftspartnern für die erbrachten
                Chatleistungen (Rollenspiele) bezahlt. Um dem Endnutzer für diese Rollenspiele ein
                möglichst ansprechendes Umfeld zu bieten und eine möglichst angenehme virtuelle
                Erfahrung zu bieten, verwenden diese Agenten Nutzerprofile innerhalb der
                Internetpräsenz, die keine reale Person zum Vorbild haben, folglich somit auch nicht real
                sind.</p>

            <p style={{paddingLeft:"6rem"}}>c. "Erotischer Nutzer-Kontakt, Flirt- und Chat-Dienstleistungen umfassen nur den Kontakt zu anderen Personen über die Messenger-Funktionen des Portals im geschlossenen
                Nutzerbereich sowie den Kontakt über SMS zu anderen Mitgliedern des Portals.</p>

            <p style={{paddingLeft:"6rem"}}>d. "Cookies" ist der verwendete und weithin geläufige Begriff für eigene Elemente dieser Internetpräsenz. Genaueres entnehmen Sie bitte unserer Datenschutzerklärung.</p>

            <p style={{paddingLeft:"6rem"}}>e. "Firma", "iseeju", "wir" oder "unser" sowie "Seitenbetreiber" steht innerhalb dieser Allgemeinen Geschäftsbedingungen grundsätzlich für den verantwortlichen Seitenbetreiber. Der hier verantwortliche Seitenbetreiber ist dem Impressum zuentnehmen.</p>

            <p style={{paddingLeft:"6rem"}}>f. "Zustimmen" oder "Zustimmung" bedeutet, der Nutzer muss der Verwendung von
                Cookies auf dieser Internetpräsenz zustimmen und/oder auch weiterführenden
                Berechtigungen, wie sie beispielsweise durch den jeweiligen App-Markt für die Nutzung
                der entsprechenden App-Services erforderlich sind, indem an der entsprechenden Stelle
                während der Registrierung der erforderliche Haken im angezeigten Feld (Checkbox)
                gesetzt wird. Diese Zustimmung ist Voraussetzung für die Nutzung der Dienste von iseeju
                und ist somit eine Pflichtangabe. Der Nutzer willigt an dieser Stelle ebenfalls ein, dass er
                die Datenschutzerklärung sowie diese Allgemeinen Geschäftsbedingungen in vollem
                Umfang anerkennt und ihm diese bekannt sind, er sie also auch vollständig zur Kenntnis
                genommen hat.</p>

            <p style={{paddingLeft:"6rem"}}>g. "Inhalte" oder "Content" als Sammelbegriff umfasst alle Texte, Scripte, Informationen, Dokumente und Dokumentationen sowie Grafiken, digitale Dateien, Fotografien, mobile
                Inhalte, Töne, Musik, sonstige Audiodateien, Audio-visuelle Dateien, Videos und
                interaktive Elemente, welche auf der gesamten Internetpräsenz enthalten sind.</p>

            <p style={{paddingLeft:"6rem"}}>h. "Rechte am geistigen Eigentum" oder "Urheberrechte" umfasst alle
                Copyrightbestimmungen und Rechte von gleicher oder ähnlicher Gestalt, unregistrierte
                und registrierte Markenschutzrechte, unregistrierte Designs wie registrierte Designs,
                Bildrechte, Rechte an der Unterhaltung und deren Inhalten und den Rechten an selbst
                erstelltem Tonmaterial. Dies schließt das Recht auf eine spätere Registrierung der
                einzelnen Positionen ausdrücklich mit ein.</p>

            <p style={{paddingLeft:"6rem"}}>i. "Medien" umfasst als Begriff alle multimedialen Kanäle, Videos, Fotografien,
                Animationen, Töne und Musikdateien, welche auf dieser Internetpräsenz enthalten sind.</p>

            <p style={{paddingLeft:"6rem"}}>j. "Member" oder „Mitglieder“ umfasst alle Endnutzer, welche den Registrierungsprozess bei iseeju erfolgreich abgeschlossen haben und somit kostenfreien Zugang zum
                geschlossenen Nutzerbereich erhalten haben.</p>


            <p style={{paddingLeft:"6rem"}}>k. "Beteiligte" bedeutet die Nutzer/Member und/oder VIP-Member</p>


            <p style={{paddingLeft:"6rem"}}>l. "Payment" oder "Zahlung" bedeutet die Zahlung durch das Mitglied bzw. den Endnutzer in Übereinstimmung mit Ziffer 6 um ein VIP Mitglied zu werden und Coins zu erwerben
                um individuelle Leistungen sowie Dienste in Anspruch nehmen zu können. </p>

            <p style={{paddingLeft:"6rem"}}>m. "Datenschutzerklärung" bedeutet & beinhaltet eben jene Datenschutzerklärung, die
                jedem Endnutzer jederzeit unter der folgenden URL zugänglich ist: LINK</p>

            <p style={{paddingLeft:"6rem"}}>n. "Profil" bedeutet alle Videos, Fotografien, e-Books, Texte, Scripte, Informationen, mobile Inhalte, Töne, Musik, sonstige Audio-Dateien sowie multimediale Inhalte und interaktive
                Funktionen, welche von den Mitgliedern / Nutzern dieser Internetseite erstellt oder
                bereitgestellt werden /worden sind.</p>


            <p style={{paddingLeft:"6rem"}}>o. "Registrierung" oder "Registrieren" bedeutet die Registrierung des Nutzers als ein Mitglied mit Zugang zum geschlossenen Nutzerbereich dieser Webseite um individuelle
                Inhalte zu erstellen wie in Ziffer 4 beschrieben.</p>


            <p style={{paddingLeft:"6rem"}}>p. "Dienste" bedeutet die Erleichterung des Adult Kontaktes (Sexkontaktes,
                Erwachsenenkontaktes), der virtuellen Flirts, der Chatdienste während der Nutzung
                dieser Webseite oder der damit in Verbindung bzw. angebundenen Dienste für die
                Anzahl an Coins in Zusammenhang mit den Coinkosten für die Nutzung der einzelnen
                Dienste als registrierter Nutzer dieser Internetseite wie unter Ziffer 7 beschrieben.</p>


            <p style={{paddingLeft:"6rem"}}>q. "Coins" ist die auf dieser Seite erwerbbare Online-Währung zur Nutzung der einzelnen kostenpflichtigen Leistungen & Dienste.</p>

            <p style={{paddingLeft:"6rem"}}>r. "Nutzer" umfasst als Begriff jede Person, die Dienste von iseeju durch Nutzung des Mediums Internet aufrufen bzw. betreten kann.</p>

            <p style={{paddingLeft:"6rem"}}>s. "Member" oder "Mitglied" als Begriffsdefinition umfasst alle Personen, die Zugriff auf diese Internetseite oder die damit in Verbindung stehenden oder angebundenen Dienste
                haben, gleich welches Gerät dafür genutzt wird. Der Zugang des Nutzers zu Leistungen &
                Diensten des gesamten Angebotes von iseeju wird limitiert und somit stehen dem
                Nutzer auch nur bestimmte Inhalte zur Verfügung. Um vollen Zugriff auf den
                geschlossenen Nutzerbereich dieser Leistungen & Dienste zu haben ist es erforderlich,
                dass der Nutzer den Registrierungsprozess abschließt und somit zu einem Mitglied wird. Der Nutzer mag ein Vertreter einer Firma, Gesellschaft, eines Vereines oder einer
                Behörde sein und ist daher vollumfänglich selbst verantwortlich für die Inhalte und die
                Form der gewählten Selbstdarstellung.</p>

            <p style={{paddingLeft:"6rem"}}> t. "VIP", "VIP-Member" oder "VIP Mitglied" als Begriffe beschreiben jeden einzelnen
                Nutzer der Dienstleistungen von iseeju, welcher den Registrierungsprozess
                abgeschlossen und Coins gekauft hat um den vollen Umfang der hier angebotenen
                Leistungen & Dienste zu nutzen / nutzen zu können.</p>


            <p style={{paddingLeft:"6rem"}}>u. "VIP Mitgliedschaft" umfasst den Status aller Mitglieder der Dienstleistungen von iseeju, welche sowohl den Registrierungsprozess abgeschlossen als auch Coins erworben haben
                um den vollen Umfang der hier angebotenen Leistungen & Dienste zu nutzen / nutzen zu
                können.</p>


            <p style={{paddingLeft:"6rem"}}>v. "Virtuelle Person" als Begriff beschreibt alle künstlich erschaffenen Personen &
                Personenprofile, welche stellvertretend durch in irgend einer Geschäftsbeziehung zu
                dem/den Anbieter(n) dieser Dienste & Leistungen stehenden Personen (seien es
                Angestellte, freie Mitarbeiter oder sonstige Geschäftspartner) gegen Entgelt mit den
                Endnutzern dieser Internetseite im Rahmen einer rein virtueller
                Rollenspiel-Kommunikation in Kontakt treten.</p>


            <h3 style={{paddingLeft:"4rem"}}>2. Zustimmungen</h3>

            <p style={{paddingLeft:"6rem"}}>a. Diese AGB`s sind die rechtsverbindlichen Bedingungen für den Benutzer, das Mitglied und/oder das VIP-Mitglied, um die Dienstleistungen zu nutzen, auf diese zuzugreifen, die
                Dienste & Leistungen auf dieser Website zu nutzen und/oder um ein eigenes
                Nutzerprofil auf dieser Internetseite zu erstellen. Der Nutzer, das Mitglied und/oder das
                VIP-Mitglied welches im Rahmen des Angebotes innerhalb der Dienste von iseeju
                browsen, zugreifen, nutzen oder Daten hochladen bzw. bereitstellen möchte, wird an
                das Folgende gebunden sein:</p>

            <p style={{paddingLeft:"8rem"}}>i. Diese Allgemeinen Geschäftsbedingungen, welche jederzeit einsehbar sind
                unter: LINK</p>

            <p style={{paddingLeft:"8rem"}}>ii. Die Nutzung von Cookies durch den diese Webseite vertretenden
                Seitenbetreiber sowie</p>

            <p style={{paddingLeft:"8rem"}}>iii. Die Datenschutzerklärung, welche jederzeit unter LINK eingesehen werden kann.</p>

            <p style={{paddingLeft:"6rem"}}>b. Das Mitglied und/oder VIP-Mitglied wird diese Allgemeinen Geschäftsbedingungen
                bestätigen müssen um Zugang zum geschlossenen Nutzerbereich und allen damit in
                Verbindung stehenden Diensten & Leistungen zu haben.</p>

            <p style={{paddingLeft:"6rem"}}>c. Der Seitenbetreiber behält sich das Recht vor, diese Allgemeinen Geschäftsbedingungen und/oder die Datenschutzerklärung jederzeit nach eigenem Ermessen abzuändern oder
                an neu eingetretene Umstände & Rechtsgrundlagen anzupassen. Alle Änderungen an
                diesen genannten Dokumenten treten in Kraft, sobald sie innerhalb der Dienste von
                iseeju jedermann zugänglich gemacht, respektive veröffentlicht wurden. In beiden
                Dokumenten wird am Ende der einzelnen Bestimmungen das Datum der letzten
                Aktualisierung/Änderung dargestellt. Mit erneutem Zugriff und/oder Nutzung aller
                Dienste von iseeju nachdem Datenschutzerklärung und/oder Allgemeine
                Geschäftsbedingungen aktualisiert wurden, erneuert der Nutzer das Einverständnis der
                Nutzer/Mitglieder/VIP-Mitglieder zu eben diesen und den damit ggf. in Verbindung
                stehenden Änderungen und/oder Aktualisierungen. Iseeju wird seine Nutzer über
                Änderungen innerhalb aller Bestandteile dieser Nutzerbedingungen unterrichten, bevor
                diese in Kraft treten. Diese Unterrichtung findet in geeigneter Form via
                Online-Mitteilungen statt.</p>

            <p style={{paddingLeft:"6rem"}}>d. Der Nutzer versichert, mindestens 18 Jahre alt zu sein (oder das gesetzliche Mindestalter erreicht zu haben, um diese AGB`s in der Gerichtsbarkeit, in der sich der Nutzer befindet,
                anzunehmen). Die Nutzung der Angebote, Dienste & Leistungen auf dieser Webseite ist
                für Benutzer über 18 Jahre beschränkt. Entsprechend ist es Nutzern unter 18 Jahren
                nicht gestattet (oder unterhalb des gesetzlichen Mindestalters innerhalb der
                Gerichtsbarkeit, in der sich der Nutzer befindet, um diese AGB`s anzunehmen), sich für
                die Dienste von iseeju zu registrieren. Jeder Nutzer verpflichtet sich, bei der
                Registrierung ehrliche Angaben bezüglich seines Alters zu hinterlegen. Sofern ein Nutzer
                zum Zeitpunkt der Registrierung nachweislich falsche Angaben über sein tatsächliches
                Alter (Geburtsdatum) gemacht hat, liegt es allein im Ermessen des Seitenbetreibers, ob
                und wie dieser Nutzer die Angebote, Dienste & Leistungen dieser Internetseite weiterhin
                nutzen kann. Der Seitenbetreiber ist zudem berechtigt, auch eine lebenslange Sperre
                dieses Nutzers für die Angebote, Dienste & Leistungen von iseeju zu veranlassen.</p>

            <p style={{paddingLeft:"6rem"}}>e. Der Zugriff auf die Angebote, Dienste & Leistungen dieser Internetseite ist nur Nutzern gestattet, die im Rahmen der Gesetze Ihres Landes derartige Leistungen auch in
                Anspruch nehmen dürfen und diese AGB`s akzeptieren dürfen.</p>

            <p style={{paddingLeft:"6rem"}}>f. Um Zweifel zu vermeiden wird an dieser Stelle noch einmal ausdrücklich darauf
                hingewiesen, dass diese Webseite den Zugang zu Angeboten, Inhalten, Diensten &
                Leistungen ermöglicht bzw. erleichtert, die von sexueller Natur sind/sein können.
                Minderjährigen ist der Zugang zu diesen Angeboten, Inhalten, Diensten & Leistungen
                ausdrücklich und unter allen Umständen untersagt/zu untersagen.</p>

            <p style={{paddingLeft:"6rem"}}>g. Diese Webseite stellt Angebote, Inhalte, Dienste & Leistungen für Erwachsene bereit, die von sexueller Natur sind oder sein können. Dies beinhaltet vor Allem Adult Kontakte,
                Sexkontakte, Flirt- und Chatdienste.</p>

            <h3 style={{paddingLeft:"4rem"}}>3. Zustimmung zur Nutzung von Cookies</h3>


            <p style={{paddingLeft:"6rem"}}>a. Der Nutzer/Member/VIP-Member stimmt der Nutzung von Cookies auf dieser und durch
                diese Internetseite ausdrücklich zu, wie in den Datenschutzbestimmungen näher
                beschrieben. Einsehbar sind diese jederzeit unter: LINK</p>

            <p style={{paddingLeft:"6rem"}}>b. Der Seitenbetreiber verwendet so genannte "Session Cookies" auf dieser Internetseite.</p>

            <p style={{paddingLeft:"6rem"}}>c. Der Seitenbetreiber benutzt Google Analytics für seine Dienste. Wenn der Benutzer
                und/oder das Mitglied und/oder die VIP Member mittels eines so genannten
                Internetbrowsers oder unter Nutzung einer sonstigen Anwendung auf diese
                Internetseite zugreifen, werden automatisch bestimmte Informationen an Server von
                Google übermittelt.d. Der Dienstebetreiber ist bezüglich der angebotenen und angeschlossenen Apps (iOS
                sowie Android) an die Bedingungen des jeweiligen App-Stores gebunden. Um diese Apps
                umfassend nutzen zu können, sind unter Umständen weitere Berechtigungsfreigaben auf
                dem jeweiligen Endgerät des Nutzers erforderlich. Jenseits davon gelten für die Nutzung
                der Apps die gleichen Bedingungen wie für die Nutzung der Internetseite.
            </p>

            <h3 style={{paddingLeft:"4rem"}}>4. Registrierung</h3>

            <p style={{paddingLeft:"6rem"}}>a. Der Nutzer kann die Dienste von iseeju auch ohne jegliche Vorab-Registrierung
                besuchen, erhält jedoch nur eingeschränkten Zugang zu den hier angebotenen Diensten
                & Leistungen bzw. Inhalten. Für den vollen Zugang zu all diesen Optionen ist eine
                Registrierung des Nutzers als Member unumgänglich. Bestimmte Funktionen, Angebote
                & Dienste stehen dem registrierten Nutzer zudem nur nach erfolgreichem Kauf der auf
                dieser Webseite verwendeten Online-Währung, den so genannten Coins, zur Verfügung.</p>

            <p style={{paddingLeft:"6rem"}}>b. Im Rahmen der Registrierung werden von dem sich Registrierenden diverse
                Informationen als Pflichtangaben abgefragt werden. Zum Umgang des Dienstebetreibers
                mit diesen Angaben finden sich Detailinformationen in der Datenschutzerklärung. Der
                Nutzer wird nach erfolgreichem Abschluss seiner Registrierung mittels eines Passwortes
                und eines Benutzernamens Zugriff auf den geschlossenen Nutzerbereich haben.</p>

            <p style={{paddingLeft:"6rem"}}>c. Der Nutzer wird vor Abschluss der Registrierung den Allgemeinen
                Geschäftsbedingungen, der Datenschutzerklärung sowie der Nutzung von Cookies
                zustimmen müssen, indem er in den jeweiligen Feldern einen Haken setzt. Durch die
                Betätigung der Schaltfläche "Registrierung abschließen" oder "Senden" erklärt der
                Nutzer sein ausdrückliches Einverständnis zu diesen Bestimmungen.</p>

            <p style={{paddingLeft:"6rem"}}>d. Im Rahmen der Registrierung via der Internetseite wird der Nutzer eine Email anseine angegebene Email-Adresse erhalten, in welcher ein 6-stelliger Anmeldecode enthalten
                ist. Dieser so genannte OTP-Code muss vom User im Rahmen der Anmeldung
                eingegeben werden um den Registrierungsprozess fortzusetzen.</p>

            <p style={{paddingLeft:"6rem"}}>e. Im Falle der Registrierung via App wird der Nutzer im Rahmen seiner Registrierung eine SMS an seine angegebene Mobilfunkrufnummer mit einem dort enthaltenen Code
                erhalten, welchen er innerhalb der App in einem speziell gekennzeichneten Feld
                eingeben und durch Betätigung einer Schaltfläche bestätigen muss. Sobald dieser
                Vorgang abgeschlossen ist, wird der Nutzer automatisch in den geschlossenen
                Nutzerbereich weitergeleitet und ist als Member registriert. Ab diesem Zeitpunkt ist der
                Nutzer (das Member) in der Lage, sich mit seinem Nutzernamen und seinem Passwort
                jederzeit in dem geschlossenen Nutzerbereich anzumelden. Um Zweifel zu vermeiden sei
                erwähnt, dass jedes Member in der Lage sein wird, durch den Verbrauch zuvor
                erworbener Coins weitere Leistungen & Dienste der Internetseite zu nutzen.</p>

            <p style={{paddingLeft:"6rem"}}>f. Nachdem der Nutzer seine Registrierung erfolgreich abgeschlossen hat, wird er in der Lage sein, innerhalb des geschlossenen Nutzerbereiches jegliche kostenfreien Inhalte
                einzusehen und seine eigenen Profildaten zu erweitern/korrigieren/anzupassen. </p>

            <p style={{paddingLeft:"6rem"}}>g. Nur Nutzer im Alter von 18 Jahren oder älter dürfen die Registrierung abschließen.</p>

            <p style={{paddingLeft:"6rem"}}>h. Der Nutzer ist verpflichtet, während des Registrierungsprozesses wahre & vollständige Angaben zu machen.</p>

            <p style={{paddingLeft:"6rem"}}>i. Es ist dringend erforderlich, dass jedes Mitglied seine Zugangsdaten, insbesondere das Passwort, sicher und geschützt vor dem Zugriff durch Dritte aufbewahrt.</p>

            <p style={{paddingLeft:"6rem"}}>j. Der Dienstebetreiber wird die Verwendung von vulgären, unangemessenen und/oder
                beleidigenden Nutzernamen (Nicknames) einschränken und ggf. unterbinden.</p>

            <h3 style={{paddingLeft:"4rem"}}>5. Verbindlichkeiten & Pflichten der Member & VIP-Member (Pflichten des Nutzers)
            </h3>

            <p style={{paddingLeft:"6rem"}}>a. Die Member & VIP-Member (Mitglieder) verpflichten sich, die seitens iseeju
                angebotenen Dienste & Leistungen nur in Übereinstimmung mit diesen AGB`s & der
                Datenschutzerklärung zu nutzen bzw. in Anspruch zu nehmen.</p>

            <p style={{paddingLeft:"6rem"}}>b. Jeder Member und/oder VIP-Member garantiert dem Dienstebetreiber, dass die
                während der Registrierung angegebenen Informationen, in Übereinstimmung mit Ziffer
                4 weiter oben, wahr sind und nach bestem Wissen und Gewissen getätigt wurden.</p>

            <p style={{paddingLeft:"6rem"}}>c. Jeder Member & VIP-Member garantiert dem Dienstebetreiber, dass die angegebenen
                Informationen nach erfolgter Registrierung, insbesondere die angegebenen
                Profilinformationen, wahr sind und nach bestem Wissen und Gewissen getätigt wurden.</p>

            <p style={{paddingLeft:"6rem"}}>d. Durch die Nutzung dieser Internetseite erklären die Member & VIP-Member sich,
                übereinstimmend mit dem Data Protection Act 1998 ("the Act") damit einverstanden,
                dass Angestellte von iseeju genauso Zugriff auf Ihre persönlichen Informationen haben
                wie vertraglich mit iseeju verbundene Unternehmen & Einzelpersonen sowie deren
                Angestellte & Mitarbeiter. Dies geschieht in Übereinstimmung mit der
                Datenschutzerklärung.</p>

            <p style={{paddingLeft:"6rem"}}>e. Die Member & VIP-Member sind damit einverstanden, verpflichten sich und garantieren dem Dienstebetreiber, dass:</p>

            <p style={{paddingLeft:"8rem"}}>i. Das eigene Profil des Members/VIP-Members auch von ihm selbst erstellt wurde
                und</p>

            <p style={{paddingLeft:"8rem"}}>ii. Das Member oder VIP-Member nicht Informationen über sich selbst im Profil
                hinterlegen wird, die die Urheberrechte Dritter verletzen.</p>

            <p style={{paddingLeft:"8rem"}}>iii. Die Nutzer, die Member & VIP-Member sind damit einverstanden, verpflichten
                sich und garantieren dem Dienstebetreiber, dass:</p>

            <p style={{paddingLeft:"8rem"}}>iv. Jegliche Urheberrechtsverletzung in jedem Profil des Nutzers, des Members
                und/oder des VIP-Members unverzüglich dem Dienstebetreiber zu melden und
                den Dienstebetreiber bei der Beseitigung derartiger Inhalte zu unterstützen
                durch eine Informations-Email an: LINK</p>

            <p style={{paddingLeft:"8rem"}}>v. Regelmäßig die Internetseite zu besuchen und sich auf dem Laufenden zu halten
                bezüglich Änderungen und/oder Aktualisierungen, welche durch den Dienstebetreiber an den Allgemeinen Geschäftsbedingungen vorgenommen werden.</p>

            <p style={{paddingLeft:"8rem"}}>vi. Keinesfalls im Rahmen der Registrierung oder danach falsche, irreführende oder
                ungenaue Informationen auf dieser Internetseite zu verwenden.</p>

            <h3 style={{paddingLeft:"4rem"}}>6. Zahlmethoden</h3>

            <p style={{paddingLeft:"6rem"}}>a. Um innerhalb der Dienstleistungen von iseeju Zahlungen leisten zu können, muss jeder Nutzer Folgendem zustimmen:</p>

            <p style={{paddingLeft:"8rem"}}>i. Den Allgemeinen Geschäftsbedingungen.</p>

            <p style={{paddingLeft:"8rem"}}>ii. Das Member muss akzeptieren, dass alle alle Preise in EUR dargestellt werden.</p>

            <p style={{paddingLeft:"8rem"}}>iii. Je nach vom Nutzer ausgewählter Zahlmethode werden u.U. externe Gebühren
                des jeweiligen Zahlungsanbieters erhoben, auf die iseeju keinen Einfluss hat.</p>

            <p style={{paddingLeft:"8rem"}}>iv. Folgende Zahlmethoden werden von iseeju akzeptiert:</p>

            <p style={{paddingLeft:"8rem"}}>AUFLISTUNG ZAHLMETHODEN</p>

            <p style={{paddingLeft:"8rem"}}>v. Das Member muss über 18 Jahre alt sein und dieses auch versichern (oder in
                dem legalen Alter im Rahmen der Gesetze seines Landes, um diese Allgemeinen
                Geschäftsbedingungen zu bestätigen).</p>

            <p style={{paddingLeft:"8rem"}}>vi. Um jeden Zweifel auszuräumen: jede Nachricht, die von Membern empfangen
                wird – gleich ob per Email oder auf anderen Wegen – ist frei von jeglichen Kosten. Lediglich das Schreiben von Nachrichten sowie weitere gesondert gekennzeichnete Leistungen unterliegt den jeweils individuell ausgewiesenen
                Kosten.</p>

            <p style={{paddingLeft:"6rem"}}>b. Um ein Member im Rahmen der kostenfreien Nutzung des geschlossenen
                Nutzerbereiches zu werden, muss der Nutzer diesen Allgemeinen Geschäftsbedingungen
                zustimmen/zugestimmt haben. Diese kostenfreie Registrierung, verbunden mit der
                kostenfreien Nutzung einzelner und gesondert ausgewiesener Angebote, beinhaltet eine
                zeitlich ungebundene Mitgliedschaft.</p>

            <h3 style={{paddingLeft:"4rem"}}>7. Member Zugänge</h3>

            <p style={{paddingLeft:"6rem"}}>a. Um ein Profil innerhalb der Dienste von iseeju erstellen zu können, muss der jeweilige Nutzer sich zunächst registrieren, also den Status eines Members erreichen.</p>

            <p style={{paddingLeft:"6rem"}}>b. Für den Fall, dass das Member und/oder VIP-Member Kenntnis davon erlangt, dass sich Unbefugte auf den eigenen Zugang Zugriff verschaffen, ist der Dienstebetreiber
                unverzüglich zu benachrichtigen durch eine Email an: LINK</p>

            <p style={{paddingLeft:"6rem"}}>c. Das Member/VIP-Member ist für den Dienstebetreiber haftbar für alle Aktivitäten,
                welche in Zusammenhang mit dem jeweiligen persönlichen Zugang stehen.</p>

            <p style={{paddingLeft:"6rem"}}>d. Das Member/VIP-Member ist nicht berechtigt, den Zugang eines anderen
                Members/VIP-Members zu nutzen, sei es für eigene oder fremde Zwecke.</p>

            <p style={{paddingLeft:"6rem"}}>e. Für den Fall, dass ein Member und/oder VIP-Member gegen eine Bestimmung innerhalb
                dieser Allgemeinen Geschäftsbedingungen und/oder der Datenschutzbestimmungen
                verstößt, stehen dem Dienstebetreiber alle Möglichkeiten des "Hausrechtes" sowie alle
                juristischen Möglichkeiten offen. In jedem Fall aber ist der Dienstebetreiber unverzüglich
                und ohne Vorankündigung berechtigt, den betreffenden Nutzerzugang auf unbestimmte
                Zeit zu sperren.</p>

            <p style={{paddingLeft:"6rem"}}>f. Der Dienstebetreiber ist nicht verantwortlich für jegliche entstandenen Schäden oder Verluste, die Member und/oder VIP-Member durch unauthorisierte
                Nutzung/Handlungen innerhalb der jeweiligen Nutzerzugänge erleiden. Im Gegenzug ist
                jedoch das Member/VIP-Member dem Dienstebetreiber gegenüber für entstandene &
                zukünftig entstehende Schäden verantwortlich, die durch derartige unauthorisierte
                Nutzungshandlungen entstanden sind/entstehen oder entstehen können.</p>

            <p style={{paddingLeft:"6rem"}}>g. Im Falle, dass das Member einen kostenfreien Zugang besitzt hat das Member Zugang zu folgenden Diensten & Leistungen:</p>

            <p style={{paddingLeft:"8rem"}}>i. Im Falle einer zeitlich unbegrenzten kostenfreien Mitgliedschaft ist das Member
                berechtigt, die folgenden Handlungen durchzuführen:</p>

            <p style={{paddingLeft:"10rem"}}>1. Die einmalige Versendung von Online-Nachrichten zu den angegebenen
                Coinkosten an andere Member/VIP-Member/virtuelle Personen;</p>

            <p style={{paddingLeft:"10rem"}}>2. Der in der Menge unlimitierte Empfang von Online-Nachrichten von
                anderen Member/VIP-Member/virtuellen Personen; </p>

            <p style={{paddingLeft:"10rem"}}>3. Die einmalige Versendung von Nachrichten zu den angegebenen
                Coinkosten an andere Member/VIP-Member/virtuelle Personen;</p>

            <p style={{paddingLeft:"6rem"}}>h. Im Falle, dass ein Member die Online-Währung Coins innerhalb der Dienste von iseeju erwirbt, erhält das Member automatisch den so genannten VIP-Status, welcher Zugang
                zu folgenden Leistungen gewährt:</p>

            <p style={{paddingLeft:"8rem"}}>i. Im Falle einer zeitlich unbegrenzten Mitgliedschaft ist das VIP-Member
                berechtigt, die folgenden Handlungen durchzuführen:</p>

            <p style={{paddingLeft:"10rem"}}>1. Der in der Menge unlimitierte Versand von Online-Nachrichten an
                andere Member/VIP-Member/virtuelle Personen, abhängig vom noch
                verfügbaren Coin-Guthaben;</p>

            <p style={{paddingLeft:"10rem"}}>2. Der in der Menge unlimitierte Empfang von Online-Nachrichten von
                anderen Member/VIP-Member/virtuellen Personen, abhängig vom noch
                verfügbaren Coin-Guthaben;</p>

            <p style={{paddingLeft:"10rem"}}>3. Der in der Menge unlimitierte Versand von SMS Nachrichten an andere
                Member/VIP-Member/virtuelle Personen, abhängig vom noch
                verfügbaren Coin-Guthaben;</p>

            <p style={{paddingLeft:"10rem"}}>4. Der in der Menge unlimitierte Empfang von SMS-Nachrichten von
                anderen Member/VIP-Member/virtuellen Personen, abhängig vom noch
                verfügbaren Coin-Guthaben.</p>

            <h3 style={{paddingLeft:"4rem"}}>8. Widerrufsfrist</h3>

            <p style={{paddingLeft:"6rem"}}>a. Da es sich bei diesen Produkten um so bezeichnete "Verbrauchsgüter" handelt, ist ein Widerruf bzw. eine Erstattung der bereits erhaltenen Leistungen und eine Rückzahlung
                der bereits erfolgten Ausgaben auf Seiten des Nutzers nicht möglich.</p>

            <h3 style={{paddingLeft:"4rem"}}>9. Die Pflichten des Dienstebetreibers</h3>

            <p style={{paddingLeft:"6rem"}}>a. Der Dienstebetreiber ist verpflichtet, die von ihm bereit gestellten Leistungen auf dieser Webseite für seine Member und VIP-Member mit der gebotenen Umsicht und
                Professionalität auszuführen.</p>

            <p style={{paddingLeft:"6rem"}}>b. Der Dienstebetreiber wird im Rahmen seines Verantwortungsbereiches Sorge dafür
                tragen, dass seine angebotenen Leistungen & Dienste ohne unnötige Verzögerungen
                jederzeit erreichbar ist. Für extern verursachte Ausfälle ist der Dienstebetreiber
                hingegen nicht verantwortlich und/oder verantwortlich zu machen.</p>

            <p style={{paddingLeft:"6rem"}}>c. Der Dienstebetreiber wird sicherstellen, dass entsprechende, marktübliche
                Sicherheitsvorkehrungen gegen die Zerstörung, den Verlust oder den unauthorisierten
                Zugriff auf diese Webseite getroffen werden. Das schließt Sicherungen des vorhandenen
                Datenmaterials ausdrücklich mit ein.</p>

            <p style={{paddingLeft:"6rem"}}>d. Um jeden Zweifel auszuräumen: Der Dienstebetreiber gibt keine Garantien betreffend
                die Qualität, die Erreichbarkeit oder das persönliche Nutzer-Wohlbefinden, die nicht in
                seiner Hand und/oder seinem Verantwortungsbereich liegen. Ausdrücklich ist der
                Dienstebetreiber auch nicht dafür verantwortlich und/oder haftbar zu machen, wenn
                der Nutzer/das Member/das VIP-Member nach Nutzung der auf dieser Webseite
                angebotenen Dienstleistungen eine andersartige Dienstleistung vorgestellt hat und/oder
                diese Leistungen für ein kostenfreies Angebot gehalten hat. Auch ist der
                Dienstebetreiber in keiner Weise verantwortlich für einen wie auch immer gearteten
                Erwartungshorizont des Nutzers/Members/VIP-Members, insofern dafür auch nicht
                haftbar zu machen.</p>

            <p style={{paddingLeft:"6rem"}}>e. Der Dienstebetreiber ist ausdrücklich nicht verantwortlich für dieNicht-Erreichbarkeit der Dienste & Leistungen sowie der Software selbst, sofern es nicht seinem
                Verantwortungsbereich unterliegt. Dies schließt technische Probleme außerhalb der
                Sphäre des Dienstebetreibers sowie außerplanmäßige Wartungsarbeiten mit ein.</p>

            <p style={{paddingLeft:"6rem"}}>f. Weder der Dienstebetreiber noch irgendein Dritter hat Kontrolle über das Internet,
                welches ein dezentrales, globales Netzwerk darstellt, bestehend aus einer
                unüberschaubaren Zahl unterschiedlichster Computersysteme. Probleme bezüglich der
                Erreichbarkeit dieser Webseite mögen weit außerhalb der vom Dienstebetreiber
                verantwortbaren und/oder leistbaren und/oder kontrollierbaren Grenzen auftreten, wie
                beispielsweise Systemfehlfunktionen oder Fehler von Seiten Dritter. Unter diesen
                Umständen wird der Dienstebetreiber alles tun, was in seiner Macht steht und/oder in
                seinem Verantwortungsbereich liegt um die Dienste & Leistungen dieser Webseite so
                schnell wie möglich erneut zur Verfügung zu stellen.</p>

            <p style={{paddingLeft:"6rem"}}>g. Der Dienstebetreiber wird ernsthafte Anstrengungen unternehmen, um jeglichen
                denkbaren Fehler so schnell als irgend möglich und innerhalb realistischer Zeit- sowie
                Kostenrahmen wieder zu beheben. Es ist mittlerweile Bestandteil der Allgemeinbildung,
                dass die Nutzung des Internets generell Risiken in sich birgt, die weit über die reine
                Stabilität der Kommunikation hinausgehen. Daher ist es ausdrücklich nicht möglich,
                jeglichen Umstand innerhalb oder außerhalb des Verantwortungsbereiches des
                Dienstebetreibers für jeglichen denkbaren in der Zukunft liegenden Zeitpunkt zu
                berücksichtigen und somit eine in die Zukunft schauende Garantie abzugeben, was die
                Dienste & Leistungen angeht.</p>

            <p style={{paddingLeft:"6rem"}}>h. Der Dienstebetreiber hat das Recht jegliches Nutzerprofil von seiner Webseite zu
                entfernen, welches Urheberrechte Dritter verletzt, bis der Ursprung der tatsächlichen
                Urheberschaft nachgewiesen ist.</p>

            <p style={{paddingLeft:"6rem"}}>i. Der Dienstebetreiber hat das Recht, jegliches Nutzerprofil von seiner Webseite zu
                entfernen, welches erwiesenermaßen Urheberrechte Dritter verletzt.</p>

            <h3 style={{paddingLeft:"4rem"}}>10. Disclaimers</h3>

            <p style={{paddingLeft:"6rem"}}>a. Der Dienstebetreiber macht keine Zusicherungen, dass:</p>

            <p style={{paddingLeft:"8rem"}}>i. Profile, welche von den Membern/VIP-Membern innerhalb der Dienste von
                iseeju hochgeladen werden/worden sind, ganz oder in Teilen frei sind von
                Material(ien), welche einen Verstoß gegen jegliches Schutzrecht darstellen;</p>

            <p style={{paddingLeft:"8rem"}}>ii. Profile, welche von den Membern/VIP-Membern innerhalb der Dienste von
                iseeju hochgeladen werden/worden sind, ganz oder in Teilen frei sind von
                lizenzpflichtigen Inhalten, die der Zustimmung durch einen anderen
                Rechteinhaber bedürfen;</p>

            <p style={{paddingLeft:"8rem"}}>iii. das Gegenüber, mit welchem das Member/VIP-Member in Kontakt tritt um zu
                flirten und zu chatten ein gleichartiges Ziel oder eine gleichartige Interessenlage
                verfolgt wie das Member/VIP-Member selbst. Um jeden Zweifel auszuräumen:
                der Dienstebetreiber hat keinerlei Einfluss auf die Inhalte, welche zwischen den
                einzelnen Membern/VIP-Membern ausgetauscht werden, ebenso wenig hat der
                Dienstebetreiber Einfluss auf die im Einzelnen innerhalb dieser Kommunikation
                verfolgten Ziele & Vorstellungen;</p>

            <p style={{paddingLeft:"8rem"}}>iv. das Member und/oder VIP-Member jemals ein reales Treffen mit seinem
                Gegenüber wird vereinbaren können oder dass es jemals dazu kommen wird. Ob
                und wie es zu Treffen bzw. Verabredungen derselben kommt liegt allein im
                Ermessen der beiden Kommunikationsparteien und unterliegt ausdrücklich nicht
                dem Verantwortungsbereich des Dienstebetreibers.</p>

            <p style={{paddingLeft:"6rem"}}>b. Der Dienstebetreiber wird im Rahmen seiner Möglichkeiten den Kontakt, die
                Kommunikation, den Flirt zwischen den Mitgliedern/VIP-Mitgliedern/virtuellen Personen
                erleichtern. Um jeden Zweifel auszuräumen: es ist ein Teil der Dienste & Leistungen auf
                dieser Webseite, dass die Member/VIP-Member mit virtuellen Personen flirten bzw.
                plaudern können!</p>

            <h3 style={{paddingLeft:"4rem"}}>11. Garantien</h3>

            <p style={{paddingLeft:"6rem"}}>Der Member und/oder VIP-Member sowie der Dienstebetreiber gewährleisten gegenseitig, dass jede Vertragspartei über ausreichende Rechte am Profil verfügt um ihre vertraglichen Pflichten
                zu erfüllen, sobald die für ein Profil erforderlichen Inhalte einmal auf die Webseite hochgeladen wurden.</p>

            <p style={{paddingLeft:"8rem"}}>a. Obwohl der Dienstebetreiber bestrebt ist sicher zu stellen, dass innerhalb der Dienste von iseeju mit all ihren Angeboten, Leistungen & Diensten jederzeit erreichbar ist,
                können dafür keine Garantien übernommen werden. Der Dienstebetreiber behält sich
                das Recht vor, ohne die Nennung von Gründen jederzeit den Zugang zu der Webseite
                und/oder einzelnen Leistungen & Diensten auf dieser Webseite einzuschränken
                und/oder ganz zu verhindern.</p>

            <p style={{paddingLeft:"8rem"}}>b. Obwohl der Dienstebetreiber bestrebt ist sicher zu stellen, dass die innerhalb der
                Dienste von iseeju dargestellten Inhalte vollständig angezeigt werden, kann und wird er
                dafür keine Garantien übernehmen. Eine Gewährleistung beispielweise aber nicht
                ausschließlich bezüglich der Rechtmäßigkeit, Vollständigkeit, Eignung für einen
                bestimmten Zweck, Funktionalität Zuverlässigkeit, Verfügbarkeit, Geschwindigkeit des
                Zugriffes oder Aktualität durch den Dienstebetreiber erfolgt ausdrücklich nicht.</p>

            <h3 style={{paddingLeft:"4rem"}}>12. Verbotene Inhalte</h3>

            <p style={{paddingLeft:"8rem"}}>a. Es ist den Membern und/oder VIP-Membern nicht gestattet, an irgendeiner Stelle dieser Webseite Inhalte zu veröffentlichen, die verletzend, beleidigend, diffamierend,
                belästigend, abfällig oder anderweitig anstößig sind oder die Rechte Dritter verletzen.
                Die Member/VIP-Member werden auf dieser Webseite keine Software- oder
                Datenbankinhalte lagern und auch sonst keine Inhalte hier ablegen, die mit den
                Gesetzen des jeweiligen Landes nicht in Einklang stehen.</p>

            <p style={{paddingLeft:"8rem"}}>b. Es ist strengstens verboten, öffentlich seine persönlichen Daten bekannt zu geben (z.B. im öffentlichen Flirttext). Dazu zählen Telefonnummern, E-Mail Adressen, Skype, MSN,
                ICQ, Web Links und sonstige Kontaktdaten jeder Art von Messenger-Dienst. Da es genug
                Suchroboter gibt, welche unsere Seiten nach solchen öffentlichen Daten scannen, wollen
                wir dies im Vorfeld schon vermeiden. Denn Person bezogene Daten gehören nicht
                öffentlich auf die Webseite. Wer dagegen verstößt, wird mit sofortiger Wirkung gesperrt.</p>

            <p style={{paddingLeft:"8rem"}}>c. Wir warnen eindringlich davor, Emailadressen, Handynummern, Skype Adressen oder
                sonstige Kontaktdaten weiterzugeben, die andere Personen für illegale zwecke benutzen
                könnten.</p>

            <p style={{paddingLeft:"8rem"}}>d. Wir warnen ausdrücklich davor, an andere Mitglieder & Gesprächspartner innerhalb der Dienste von iseeju direkt Geld zu versenden – sei es auf Nachfrage oder aus eigenem
                Antrieb. Jegliche Zahlungsabwicklung muss innerhalb der Dienste von iseeju ueber die
                Zahlmethoden, die iseeju zur Verfügung stellt. Nutzer, die andere Nutzer nach direkten
                Geldüberweisungen fragen koennen unter der Email Adresse LINK direkt gemeldet
                werden und werden vom Dienstebetreiber umgehend innerhalb verantwortbarer Frist
                gesperrt, sofern dieser Umstand bekannt geworden ist.</p>

            <h3 style={{paddingLeft:"4rem"}}>13. Lizenz, Zusicherungen und Gewährleistungen.</h3>

            <p style={{paddingLeft:"8rem"}}>a. Durch die Veröffentlichung ihrer Daten sichern die Member/VIP-Member dem
                Dienstebetreiber eine unwiderrufliche, unbefristete, nicht-exklusive, unentgeltliche,
                weltweite, übertragbare Sub-Lizenz und übertragbare Lizenz zu, diese Inhalte zu
                veröffentlichen, zu verbreiten und derivative Kopien davon zu erstellen. Dies gilt auch für
                eventuelle zukünftige Rechts- oder Geschäftsnachfolger des gegenwärtigen
                Dienstebetreibers, einschließlich und ohne Einschränkungen in Bezug auf die
                Umverteilung von Teilen oder den Inhalten in Ihrer Gesamtheit (und Derivaten davon) in
                einem beliebigen Media-Format und über Medien jeder Art ohne Erfordernis einer
                vorherigen Genehmigung durch das Member/VIP-Member.</p>

            <h3 style={{paddingLeft:"4rem"}}>14. Recht auf Entfernung einzelner Inhalte</h3>

            <p style={{paddingLeft:"8rem"}}>a. Alle Profile, welche durch Member/VIP-Member vorgelegt werden unterliegen dem
                Vorbehalt der Genehmigung durch den Dienstebetreiber. Profile werden durch den
                Dienstebetreiber nach dem Zufallsprinzip in Übereinstimmung mit Ziffer 26 kontrolliert.
                Die Member/VIP-Member erklären sich ausdrücklich damit einverstanden, dass der
                Dienstebetreiber berechtigt ist ihre Profile ganz oder in Teilen zu entfernen oder zu
                deaktivieren respektive den Zugriff auf ganze Profile oder einzelne Teile dieser
                einzuschränken (einschließlich, aber nicht beschränkt auf jene Profile, die die
                Member/VIP-Member entsprechend ihrer jeweiligen Mitgliedsstaaten auf diese
                Webseite hochgeladen haben). Der Dienstebetreiber nimmt die Angaben der jeweiligen
                Member/VIP-Member in gutem Glauben als wahr an und es liegt im Ermessen des
                Dienstebetreibers, wann er einen Verstoß gegen die hier vorliegenden Allgemeinen
                Geschäftsbedingungen annimmt. Verbotene Profile und/oder Profilbestandteile sind
                beispielsweise gekennzeichnet durch:</p>

            <p style={{paddingLeft:"10rem"}}>i. Offenkundig den Rassismus, Fanatismus, Hass oder die körperliche Gewalt
                jeglicher Art gegen Einzelpersonen oder Personengruppen fördernde oder dazu
                aufrufende Aussagen & Begrifflichkeiten;</p>

            <p style={{paddingLeft:"10rem"}}>ii. Aussagen & Begriffe die geeignet sind, andere Personen oder Personengruppen
                zu belästigen oder dazu aufzurufen;</p>

            <p style={{paddingLeft:"10rem"}}>iii. Aussagen & Begriffe die geeignet sind, andere Personen oder Personengruppen
                in sexueller oder gewaltsamer Weise zu verletzen;</p>

            <p style={{paddingLeft:"10rem"}}>iv. Aussagen & Begriffe die geeignet sind, ein Übermaß an Gewalt oder
                andersartige anstößige Inhalte auszudrücken;</p>

            <p style={{paddingLeft:"10rem"}}>v. Vetrauliche oder persönliche Informationen von Personen unter 18 Jahren;</p>

            <p style={{paddingLeft:"10rem"}}>vi. Veröffentlichte Beiträge oder Informationen, die ein Datenschutz- oder
                Sicherheitsrisiko für einzelne Personen darstellen;</p>

            <p style={{paddingLeft:"10rem"}}>vii. Dargestellte Informationen oder das Fördern derselben, sofern diese
                offenkundig falsch oder irreführend, illegale Handlungen oder Verhaltensweisen
                darstellend, beleidigend, bedrohend, obszön, diffamierend oder verleumderisch
                sind;</p>

            <p style={{paddingLeft:"10rem"}}>viii. Sich auf die Übertragung von "Junk Mail", "Kettenbriefen" oder
                unaufgeforderten Massenmails, Sofortnachrichten, "Fishing" oder "Spamming
                beziehende oder Derartiges fördernde Inhalte;</p>

            <p style={{paddingLeft:"10rem"}}>ix. Enthaltende Pseudonyme, die geeignet sind die Rechte Dritter zu verletzen wie
                beispielsweise Verstöße gegen das Urheberrecht, Patent- & Markenrechte,
                Eigentumsrechte sowie andere persönliche Informationen ohne die vorherige
                Zustimmung durch den betroffenen Dritten.</p>

            <p style={{paddingLeft:"10rem"}}>x. Enthaltene Bilder oder (versteckte) Seiten, die nur eingeschränkt oder durch
                Nutzung eines Passwortes zugänglich sind;</p>

            <p style={{paddingLeft:"10rem"}}>xi. Aussagen & Begriffe die geeignet sind, kriminelle Aktivitäten zu fördern oder
                derartige Vorhaben zu unterstützen bzw. als Anweisungen verstanden werden
                können;</p>


            <p style={{paddingLeft:"10rem"}}>xii. Passworte oder personenbezogene Daten von anderen Nutzern für
                kommerzielle oder ungesetzliche Zwecke;</p>


            <p style={{paddingLeft:"10rem"}}>xiii. Sich auf kommerzielle Aktivitäten und/oder Verkäufe beziehende Inhalte ohne
                vorherige schriftliche Genehmigung durch den Dienstebetreiber.</p>


            <p style={{paddingLeft:"8rem"}}>b. Der Dienstebetreiber ist nicht verpflichtet, jedem User, jedem Member und/oder
                VIP-Member den Zugang zu den Diensten & Leistungen innerhalb von iseeju zu
                ermöglichen und kann dieses nach eigenem Ermessen auch ablehnen.
            </p>

            <h3 style={{paddingLeft:"4rem"}}>15. Ausschluß der Haftung für Profilinhalte</h3>

            <p style={{paddingLeft:"8rem"}}>a. Der Dienstebetreiber hat nicht die Inhalte für jedes Profil, nicht jede Meinung,
                Empfehlung oder jeden Ratschlag der innerhalb der Dienste von iseeju dargestellt wird
                erstellt. Ausdrücklich ist der Dienstebetreiber nicht haftbar zu machen für Inhalte, die
                durch seine Nutzer/Member/VIP-Member hochgeladen bzw. durch diese innerhalb der
                Dienste von iseeju bereitgestellt wurden.</p>

            <h3 style={{paddingLeft:"4rem"}}>16. Limitierung der Haftung</h3>

            <p style={{paddingLeft:"8rem"}}>a. Es ist ein Grundsatz des Dienstebetreibers, keinen Akt der Verletzung gewerblicher
                Schutzrechte oder anderen anwendbaren Gesetzen zu tolerieren. Der Dienstebetreiber
                wird alle angemessenen Anstrengungen unternehmen Profile zu entfernen, deaktivieren
                oder nur noch beschränkten Zugriff auf diese zu ermöglichen bis hin zu vorübergehender
                Nicht-Verfügbarkeit, sofern diese aus der subjektiven Sicht des Dienstebetreibers gegen
                diesen Grundsatz verstoßen. Die Bestimmungen des Abs. 2 sollen diese Allgemeinen
                Geschäftsbedingungen ergänzen, sind aber nicht dazu gedacht, den Dienstebetreiber an
                anderer Stelle zu limitieren oder haftbar zu machen.</p>

            <p style={{paddingLeft:"8rem"}}>b. Der Dienstebetreiber ist für unauthorisiert und/oder unbefugt hochgeladene Profile
                nicht haftbar zu machen.</p>

            <p style={{paddingLeft:"8rem"}}>c. Nichts in diesen Allgemeinen Geschäftsbedingungen soll in irgendeiner Weise die
                Verantwortung und/oder Haftung irgendeiner Partei limitieren in Fällen von Betrug,
                Mord oder Körperverletzung, sofern direkt durch die Fahrlässigkeit der betroffenen
                Parteien verursacht. Dies gilt für jegliche Form der Haftung, sofern diese nicht
                ausgeschlossen werden kann oder als eine Angelegenheit von begrenzten Rechts oder
                verursacht wurde durch Bedienstete, Mitarbeiter oder Vertreter. Dies gilt unter diesen
                Vorbehalten:</p>

            <p style={{paddingLeft:"10rem"}}>i. Die maximale Haftung der Gesellschaft bezüglich unerlaubter Handlung (ohne
                Diffamierung), Fahrlässigkeit, vorvertraglichen oder anderen Zusicherungen oder
                anderweitig aus oder im Zusammenhang mit diesen Allgemeinen
                Geschäftsbedingungen oder die Leistung oder die Einhaltung seiner
                Verpflichtungen aus diesen Allgemeinen Geschäftsbedingungen, und jeder
                anwendbaren Teil davon wird in ihrer Gesamtheit auf den Wert der Zahlung für
                den im Individualfall in Rede stehenden Gegenwert der betroffenen Coinsumme
                im Rahmen dieser Allgemeinen Geschäftsbedingungen beschränkt.</p>

            <p style={{paddingLeft:"8rem"}}>d. Der Dienstebetreiber ist nicht haftbar zu machen für die Erwartungen seiner
                Member/VIP-Member/Nutzer, seien diese geschäftlicher oder auch rein privater Natur,
                seien sie ein tatsächlicher oder erwarteter Verlust, sei dieser Verlust materiell oder
                immateriell. Auch haftet der Dienstebetreiber nicht für daraus möglicherweise
                entstehende Folgeschäden irgendwelcher Art. Der Dienstebetreiber haftet ausdrücklich
                nur für Dinge, die er innerhalb dieser Allgemeinen Geschäftsbedingungen aufgrund der
                geltenden Gesetzeslage nicht ausschließen kann.</p>

            <p style={{paddingLeft:"8rem"}}>e. Unter keinen Umständen wird das Unternehmen seine leitenden Angestellten,
                Direktoren, Mitarbeiter oder Vertreter haftbar machen für irgendwelche Fehler,
                Trojanische Pferde, Viren, oder dergleichen, sofern diese von Dritten innerhalb der
                Dienste von iseeju übertragen wurden oder werden.</p>

            <p style={{paddingLeft:"8rem"}}>f. Die Basis, auf der der Dienstebetreiber die Dienste von iseeju den
                Membern/Nutzern/VIP-Membern zugänglich macht lautet "so wie sie ist" und "wenn
                verfügbar" ohne jeglichen weiteren Garantien bezüglich der Natur oder Richtigkeit
                irgendwelcher Inhalte innerhalb der Dienste von iseeju. Um Zweifel zu vermeiden sei
                erwähnt, dass der Dienstebetreiber (im vollen Umfang und gesetzlich zulässig) Garantien
                hinsichtlich der Qualität und/oder Eignung der Profile innerhalb der Dienste von iseeju
                und darüber, ob diese sich für einen bestimmten Zweck eignen, ausschließt. Der
                Dienstebetreiber ist unter keinen Umständen und in keinster Weise haftbar für
                Handlungen seiner Member/VIP-Member, seien diese privater oder gewerblicher Natur,
                sofern diese für eine eigene vorteilhaft erscheinende Darstellung innerhalb der
                jeweiligen Profile gegen diese Allgemeinen Geschäftsbedingungen verstoßen.</p>

            <h3 style={{paddingLeft:"4rem"}}>17. Politik</h3>

            <p style={{paddingLeft:"8rem"}}>a. Unter keinen Umständen haftet der Dienstebetreiber, seine leitenden Angestellten,
                Direktoren, Mitarbeiter oder Vertreter gegenüber dem Member/VIP-Member in dem
                Falle, dass irgendein Profil innerhalb der Dienste von iseeju bereitgestellt wird/wurde,
                um die Rechte Dritter zu verletzen.</p>

            <p style={{paddingLeft:"8rem"}}>b. Weder der Dienstebetreiber, noch seine leitenden Angestellten, Direktoren, Mitarbeiter oder Agenten geben eine Zusicherung oder Gewährleistung in Bezug auf die Qualität,
                Eignung oder Echtheit der Profile ab, die von jedem einzelnen Member und/oder
                VIP-Member innerhalb der Dienste von iseeju hochgeladen bzw. bereitgestellt wurden</p>

            <p style={{paddingLeft:"8rem"}}>c. Die Member/VIP-Member erklären sich bereit, jeglichen dem Dienstebetreiber
                entstehenden Schaden zu erstatten, der durch ihre Handlungen im Umgang mit dem
                Profil entsteht, insbesondere betrifft dies die Verletzung von Rechten Dritter.</p>

            <p style={{paddingLeft:"8rem"}}>d. Die Member/VIP-Member erklären sich damit einverstanden und dazu bereit, sofern
                durch ihre Handlungen dem Dienstebetreiber Schäden entstehen, die entstandenen
                Schäden und Auslagen zu erstatten.</p>

            <h3 style={{paddingLeft:"4rem"}}>18. Datenschutz und Vertraulichkeit</h3>

            <p style={{paddingLeft:"8rem"}}>a. Alle personenbezogenen Daten, die (ggf. von Zeit zu Zeit) durch den Dienstebetreiber über die Nutzer/Member/VIP-Member gesammelt werden, werden streng vertraulich
                behandelt und nicht an Dritte weitergegeben, außer dies ist gesetzlich vorgeschrieben.
                Der Dienstebetreiber wird nur personenbezogene Daten erheben, die für den Betrieb
                innerhalb der Dienste von iseeju erforderlich sind und/oder von den Nutzern freiwillig
                hinterlegt wurden und/oder über deren Erhebung der Nutzer vorab informiert wurde
                und diesen Erhebungen zugestimmt hat.</p>

            <p style={{paddingLeft:"8rem"}}>b. Die Daten der Nutzer/Member/VIP-Member werden in einer Datenbank im Einklang mit
                geltendem Recht gespeichert werden.</p>

            <p style={{paddingLeft:"8rem"}}>c. Der Dienstebetreiber kann die Nutzer/Member/VIP-Member über Angebote und/oder
                Neuigkeiten bzw. Änderungen innerhalb der Dienste von iseeju informieren. Für den Fall,
                dass die Nutzer/Member/VIP-Member dieses nicht wünschen, können diese im Einklang
                mit den Bestimmungen des Datenschutzes davon Abstand nehmen durch Zusendung
                einer Email an: LINK</p>

            <p style={{paddingLeft:"8rem"}}>Dies gilt ausdrücklich aber nicht um Falle von Änderungen bezüglich der Allgemeinen
                Geschäftsbedingungen und/oder der Datenschutzerklärung. In diesem Falle wird der
                Dienstebetreiber immer seine Informationspflicht wahrnehmen und ist dazu auch
                verpflichtet. </p>

            <h3 style={{paddingLeft:"4rem"}}>19. Vertraulichkeit</h3>

            <p style={{paddingLeft:"6rem"}}>Während der Laufzeit dieser Allgemeinen Geschätsbedingungen und für einen Zeitraum von 2 Jahren danach stimmen die Member/VIP-Member zu, alle Informationen, die die
                Member/VIP-Member in dieser Zeit über den Dienstebetreiber, sein Geschäft, seine Finanzen
                sowie innere Abläufe, mit Arbeiten betraute Personen & Technologien erhalten haben
                ("Vertrauliche Informationen") streng vertraulich zu behandeln.</p>

            <p style={{paddingLeft:"8rem"}}>a. Die Bedingungen von Ziffer 19 gelten nicht bei:</p>

            <p style={{paddingLeft:"10rem"}}>i. Informationen, die auf anderem Wege und unabhängig von dieser Klausel
                bereits Verbreitung in der Öffentlichkeit gefunden haben und/oder</p>

            <p style={{paddingLeft:"10rem"}}>ii. Informationen, die durch einen Dritten ohne Verletzung dieser Klausel oder
                einer anderen Vertrauenspflicht mitgeteilt wurden. und/oder</p>

            <p style={{paddingLeft:"10rem"}}>iii. Informationen, die im Rahmen von Berateraufträgen offen gelegt wurden unter
                der Voraussetzung, dass die Verpflichtung zur Geheimhaltung aus solchen
                Beratungsverhältnissen nicht weniger Vertraulichkeit bietet als die Ziffer 19
                weiter oben und/oder</p>

            <p style={{paddingLeft:"10rem"}}>iv. Informationen, die trivial oder offensichtlich sind.</p>

            <h3 style={{paddingLeft:"4rem"}}>20. Webseiten Disclaimer</h3>

            <p style={{paddingLeft:"8rem"}}>a. Der Dienstebetreiber wird keinesfalls persönliche Daten oder Profile seiner
                Nutzer/Member/VIP-Member an Dritte gleich welcher Art weiter geben, sofern dies
                nicht für den Betrieb und die Bereitstellung seiner Dienste erforderlich ist.. Auch ist der
                Dienstebetreiber nicht dafür verantwortlich zu machen, dass einzelne bereit gestellte
                Inhalte in anderen Territorien, die einer anderen Rechtshoheit unterliegen, als illegal
                gelten oder verboten sind. Nutzer/Member und/oder VIP-Member, die eigenständig die
                Entscheidung fällen, die Dienste und/oder Leistungen dieser Internetpräsenz zu nutzen,
                obwohl sie einer anderen Gerichtsbarkeit unterliegen, handeln allein aus eigener
                Initiative und auf eigenes Risiko!</p>

            <p style={{paddingLeft:"8rem"}}>b. Der Dienstebetreiber verwehrt sich gegen jede Verantwortlichkeit, ausgedrückt oder
                angenommen, für von Nutzern/Membern/VIP-Membern bereit gestellte Inhalte. Diese
                Webseite wird Nutzern/Membern/VIP-Membern bereit gestellt "wie sie ist" ohne
                jegliche wie auch immer gearteten Garantien bezüglich der Natur, der Vollständigkeit,
                (gleich ob gerade erst bereit gestellt oder im Laufe der Zeit aufgefallen) in Bezug auf
                jeglichen bereitgestellten Inhalt und/oder Profile auf dieser Webseite.</p>

            <p style={{paddingLeft:"8rem"}}>c. Der Dienstebetreiber ist nicht verantwortlich zu machen für eventuelle Anweisungen
                und/oder Ratschläge von dritter Seite auf dieser Webseite oder bezüglich dieser
                Webseite, gleichgültig ob grundsätzlich und/oder oberflächlich oder speziell und der
                Nutzer/Member und/oder VIP-Member hat den Dienstebetreiber unverzüglich zu
                informieren, sofern er von derartigen Aktionen Kenntnis erlangt und diese gegen die hier
                benannten Allgemeinen Geschäftsbedingungen verstoßen.</p>

            <h3 style={{paddingLeft:"4rem"}}>21. Höhere Gewalt</h3>

            <p style={{paddingLeft:"8rem"}}>a. Der Dienstebetreiber ist nicht verantwortlich für nichterfüllte Pflichten, wenn sie außerhalb der Kontrolle liegen, einschließlich der folgenden Ursachen:</p>

            <p style={{paddingLeft:"10rem"}}>i. Höhere Gewalt, wie beispielsweise Feuer, Fluten, Erdbeben, Stürme, Hurricanes
                oder andere Naturkatastrophen;</p>

            <p style={{paddingLeft:"10rem"}}>ii. Krieg, Invasionen, Gewalt von außen, Feindseligkeiten (gleich, ob Krieg erklärt
                wurde oder nicht), Bürgerkrieg, Rebellion, Revolution, Aufstände, militärische
                oder widerrechtliche Machtübernahme sowie Beschlagnahme oder
                terroristische Aktivitäten.</p>

            <p style={{paddingLeft:"10rem"}}>iii. Sanktionen von und durch Regierungen, Verstaatlichung, Blockaden,
                Arbeitskämpfe, Blockaden oder Ausfälle von Versorgungsleistungen.</p>

            <p style={{paddingLeft:"10rem"}}>iv. Die Übernahme der Weltherrschaft durch Amazon, Google, Facebook oder
                ähnliche Internet-Konzerne mit annähernd monopolistischer Machtstellung.</p>

            <h3 style={{paddingLeft:"4rem"}}>22. Rechte Dritter</h3>

            <p style={{paddingLeft:"8rem"}}>a. Ungeachtet anderer Bestimmungen innerhalb dieser Allgemeinen
                Geschäftsbedingungen haben Dritte, die nicht Vertragspartei des Dienstebetreibers sind
                keine Ansprüche auf die Erfüllung von Leistungen das Recht auf die Durchsetzung
                einzelner Bestandteile dieser Allgemeinen Geschäftsbedingungen zu bestehen.</p>

            <h3 style={{paddingLeft:"4rem"}}>23. Abtretung</h3>

            <p style={{paddingLeft:"6rem"}}>Das Member und/oder VIP-Member ist nicht berechtigt, Ansprüche oder Rechte aus diesen
                Allgemeinen Geschäftsbedingungen ganz oder in Teilen an Dritte abzutreten.
            </p>

            <h3 style={{paddingLeft:"4rem"}}>23. Abtretung</h3>

            <p style={{paddingLeft:"6rem"}}>Das Versäumnis der Ausübung eines Rechts oder Rechtsmittels durch den Dienstebetreiber – gleich in welchem Stadium der Ausübung – gilt nicht als Verzicht auf das Recht oder
                Rechtsmittel, weder ganz noch in Teilen. Die Rechte und Rechtsmittel wie in diesen Allgemeinen
                Geschäftsbedingungen als Basis für die Geschäftsbeziehung dargelegt sind kumulativ und
                schließen die spätere Einforderung von Rechten & Rechtsmitteln nicht aus.</p>

            <p style={{paddingLeft:"6rem"}}>Die Nichtigkeit, Rechtswidrigkeit oder Undurchführbarkeit einer Bestimmung dieser AGB berührt nicht und/oder beeinflusst nicht die weitere Gültigkeit der übrigen Bestimmungen dieser
                Allgemeinen Geschäftsbedingungen. Nichts in diesen Allgemeinen Geschäftsbedingungen soll als
                eine Partnerschaft oder ein Joint Venture jeglicher Art zwischen den Vertragsparteien verstanden
                werden.</p>

            <p style={{paddingLeft:"6rem"}}>Jede der beiden Vertragsparteien verpflichtet sich, alle gegenseitig zugesprochenen Rechte & Pflichten nach besten Kräften und in angemessener Weise zu erfüllen und alle Schritte zu
                unternehmen die notwendig oder wünschenswert sind um die Erfüllung der
                Vertragsbestandteile im Sinne dieser Allgemeinen Geschäftsbedingungen zu gewährleisten. Die
                Erfüllung jedes Vertragsbestandteiles hat in angemessener, zumutbarer Weise zu erfolgen und es
                sind alle zumutbaren Anstrengungen zu unternehmen um dieses auch zu gewährleisten, soweit
                die Vertragsparteien dazu in der Lage sind. Dies schließt auch die Erstellung weiterer Urkunden &
                Dokumente, Versicherungen, Erfüllungshandlungen und alle anderen Dinge mit ein, welche ein
                Vertragspartner, wie in jedem Vertrag üblich, vernünftigerweise verlangen kann um die
                einzelnen Bestimmungen & Vertragsbestandteile zu erfüllen.</p>

            <h3 style={{paddingLeft:"4rem"}}>25. Gerichtsbarkeit</h3>

            <p style={{paddingLeft:"4rem"}}>Diese Allgemeinen Geschäftsbedingungen unterliegen der Gerichtsbarkeit von England & Wales und der Nutzer/das Member und/oder VIP-Member stimmt dem Standort der Gerichtsbarkeit
                sowie der Zuständigkeit der Gerichte & Gesetzgebungen ausdrücklich zu! Der Nutzer/das
                Member und/oder VIP-Member akzeptiert, dass keine fremdartige oder andersartige
                Gesetzgebung auf diese Bestimmungen angewendet werden kann, gleich, aus welchem Land
                diese Internetseite aufrufbar sein sollte, gleich, ob das Angebot dieser Internetseite in anderen
                Ländern dieser Welt nicht dem Rechts- und/oder Geschäftsgebahren des jeweiligen Landes
                entsprechen sollte.
            </p>

            <h3 style={{paddingLeft:"4rem"}}>26. Gesamte Vereinbarung und salvatorische Klausel</h3>

            <p style={{paddingLeft:"8rem"}}>a. Diese Allgemeinen Geschäftsbedingungen und auch die Datenschutzerklärung mögen
                für den Einzelnen zeitlweilig schwer verständlich sein, dennoch ist dies eine
                Individualabrede zwischen zwei Parteien und regelt alle wesentlichen Bestandteile der
                Geschäftsbeziehung der Beteiligten.</p>

            <p style={{paddingLeft:"8rem"}}>b. Sollte(n) eine oder mehrere Bestimmungen dieser AGB ungültig sein, so bleibt die
                Wirksamkeit der übrigen Bestimmungen unberührt.</p>

            <h3 style={{paddingLeft:"4rem"}}>27. Akzeptanz</h3>

            <p style={{paddingLeft:"6rem"}}>Das Member und/oder VIP-Member bestätigt, diese Allgemeinen Geschäftsbedingungen gelesen und akzeptiert zu haben.</p>

            <p style={{paddingLeft:"6rem"}}>Diese Allgemeinen Geschäftsbedingungen wurde zuletzt aktualisiert am: 28.02.2022</p>
        </div>
    )
}
