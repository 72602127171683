import axios from 'axios';
import { createAction } from 'redux-actions';

export const spinWheelEligibleCheckInitiate = createAction('CHECK_SPIN_WHEEL_ELIGIBILITY_INITIATE');
export const spinWheelEligibleCheckSuccess = createAction('CHECK_SPIN_WHEEL_ELIGIBILITY_SUCCESS');
export const spinWheelEligibleCheckFailure = createAction('CHECK_SPIN_WHEEL_ELIGIBILITY_FAILURE');
export const spinWheelEligibleCheckNotUnlocked = createAction('CHECK_SPIN_WHEEL_NOT_UNLOCKED');

export const getSpinWheelDataInitiate = createAction('GET_SPIN_WHEEL_DATA_INITIATE');
export const getSpinWheelDataSuccess = createAction('GET_SPIN_WHEEL_DATA_SUCCESS');
export const getSpinWheelDataFailure = createAction('GET_SPIN_WHEEL_DATA_FAILURE');

export const postSpinWheelGiftInitiate = createAction('POST_SPIN_WHEEL_GIFT_INITIATE');
export const postSpinWheelGiftSuccess = createAction('POST_SPIN_WHEEL_GIFT_SUCCESS');
export const postSpinWheelGiftFailure = createAction('POST_SPIN_WHEEL_GIFT_FAILURE');

export const spinWheelEligible = (requestData) => {
    const config = {
      headers: {
        Authorization: `Bearer ${requestData}`,
      },
    };
    return (dispatch) => {
      dispatch(spinWheelEligibleCheckInitiate());
      axios
        .get(`${process.env.REACT_APP_BACKEND_URL }spin_elig_check`,config)
        .then((response) => {
          if (response.data.count>0) {                                                                                 
            dispatch(spinWheelEligibleCheckSuccess(response.data));
          } else if(response.data.count==="-1"){
            dispatch(spinWheelEligibleCheckNotUnlocked(response.data.message));
          } else if (response.data.count===0) {                                                                                 
            dispatch(spinWheelEligibleCheckSuccess(response.data));
          }else{
            dispatch(spinWheelEligibleCheckFailure());
          }
        })
        .catch((error) => {
          if (error.response.status === 401) {
            localStorage.clear();
            window.location.replace('/session-expired');
          }
          else {
          dispatch(spinWheelEligibleCheckFailure());
          }
        });
    };
  };
  
  export const spinWheelData = (requestData) => {
    const config = {
      headers: {
        Authorization: `Bearer ${requestData}`,
      },
    };
    return (dispatch) => {
      dispatch(getSpinWheelDataInitiate());
      axios
        .get(`${process.env.REACT_APP_BACKEND_URL }spin_wheel_data`,config)
        .then((response) => {
          if (response.data.STATUS) {                                                                                 
            dispatch(getSpinWheelDataSuccess(response.data.message));
          } else {
            dispatch(getSpinWheelDataFailure());
          }
        })
        .catch((error) => {
          if (error.response.status === 401) {
            localStorage.clear();
            window.location.replace('/session-expired');
          }
          else {
          dispatch(getSpinWheelDataFailure());
          }
        });
    };
  };


  export const postSpinWheelGiftData = (requestData,token) => {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    return (dispatch) => {
      dispatch(postSpinWheelGiftInitiate());
      axios
        .post(`${process.env.REACT_APP_BACKEND_URL }spin_and_insert_chest`,requestData,config)
        .then((response) => {
          if (response.data.STATUS) {                                                                                 
            dispatch(postSpinWheelGiftSuccess(response.data));
          } else {
            dispatch(postSpinWheelGiftFailure());
          }
        })
        .catch((error) => {
          if (error.response.status === 401) {
            localStorage.clear();
            window.location.replace('/session-expired');
          }
          else {
          dispatch(postSpinWheelGiftFailure());
          }
        });
    };
  };

  
  export const setspinWheelDataNull=createAction("SPIN_WHEEL_DATA_NULL");
  
   export const spinWheelDataNull = ()=>{ 
     return (dispatch)=>{
       dispatch(setspinWheelDataNull());
   }
   }