import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import UserLayout from '../layouts/userLayout';
import MobileLayout from '../layouts/mobileLayout';
import { Grid, Card, CardContent, Divider, Typography } from '@mui/material';
import BasicInfoCard from '../components/profilePages/basicInfoCard';
import useStyles from '../styles/myProfile';
import { useHistory } from 'react-router';
import * as stickersAction from '../redux/action/stickersAction';
import ChatStickers from '../utils/chatStickers';
import Alert from '../components/common/alert';
import ConfirmationAlert from '../components/common/confirmationAlert';
import { getIsMobile } from '../utils';
import * as userDetailsAction from '../redux/action/userDetailsAction';
import { useTranslation } from 'react-i18next';
const MyProfile = (props) => {
  const {t}=useTranslation()
  const classes = useStyles();
  const history = useHistory();
  const isMobile = getIsMobile();
  const Layout=isMobile?MobileLayout:UserLayout;
  const [stickerData, setStickerData] = useState([]);
  const [commonAlertMessage, setCommonAlertMessage] = useState('');
  const [commonAlertButtonText, setCommonAlertButtonText] = useState('');
  const [IconName, setIconName] = useState('');
  const [commonTitle, setCommonAlertTitle] = useState('');
  const [containLink, setContainLink] = useState(false);
  const [navigationLink, setNavigateLink] = useState('');
  const [closeIcon, setCloseIcon] = useState(false);
  const [spinWheelAlert, setSpinWheelAlert] = useState(false);
  const [alertConfirmButton, setAlertConfirmButton] = useState(false);
  const [alertCloseButton, setAlertCloseButton] = useState(false);
  const [alertConfirmButtonText, setAlertConfirmButtonText] = useState('');
  const [alertCloseButtonText, setAlertCloseButtonText] = useState('');
  const [alertText, setAlertText] = useState('');
  const [commonAlertModal, setCommonAlertModal] = useState(false);
  const [alertTitle, setAlertTitle] = useState('');
  const [reqBonusPoints, setRequiredBonusPoint] = useState(0);
  const [stickerId, setStickerId] = useState(0);
  const [buttonDisabled,setButtonDisabled]=useState(false)
  useEffect(() => {
    if (localStorage.getItem('userToken') === null) {
      history.push('/');
    }
  }, [localStorage.getItem('userToken')]);

  useEffect(() => {
    if (localStorage.getItem('userToken') !== null&&props.stickersData.length===0) {
      props.getStickersService(localStorage.getItem('userToken'));
    }
  }, []);

  useEffect(() => {
    if (props.stickersData.length > 0) {
      setStickerData(props.stickersData);
    }
  }, [props.stickersData]);

  const purchaseSticker = (item) => {
    if(props.stickerUnlockedStatus){
      setRequiredBonusPoint(item.required_bonuspoints);
      setStickerId(item.sticker_id);
      setSpinWheelAlert(true);
      setAlertConfirmButton(item.is_owned === 1 ? false : true);
      setAlertCloseButton(true);
      setAlertConfirmButtonText(item.is_owned === 1 ? '' : t("update"));
      setAlertCloseButtonText(t("Close"));
      setAlertTitle(t("purchaseStickers"));
      setAlertText(
        item.is_owned === 1
          ?t("alreadyPurchased")
          : t("sureWantToBuySticker")
      );
    }else{
      setSpinWheelAlert(true);
      setAlertConfirmButton(false);
      setAlertCloseButton(true);
      setAlertConfirmButtonText( '');
      setAlertCloseButtonText('CLOSE');
      setAlertTitle('Purchase Sticker');
      setAlertText(
           'You need to unlock stickers from skill tree'
      );
    }
 
  };


  const CloseAlert = () => {
    setSpinWheelAlert(false);
    setAlertConfirmButton(false);
    setAlertCloseButton(false);
    setAlertConfirmButtonText('');
    setAlertCloseButtonText('');
    setAlertTitle('');
    setAlertText('');
  };

  const spinWithBonuspoints = () => {
    if (props.userGeneralData.bonuspoints >= reqBonusPoints) {
      CloseAlert();
      let data = new FormData();
      data.append('sticker_id', stickerId);
      props.buyStickersService(data, localStorage.getItem('userToken'));
    } else {
      setCloseIcon(false);
      setCommonAlertModal(true);
      setCommonAlertMessage(
        t("dontHaveBonusForSticker")
      );
      setCommonAlertButtonText(t("Close"));
      setIconName('BonusPointsNotEnough');
      setCommonAlertTitle(t("dontHaveBonusPoint"));
      setContainLink(false);
      setNavigateLink('');
      CloseAlert();
    }
  };
  const setCommonModalFalse = () => {
    setCommonAlertModal(false);
    setCommonAlertMessage('');
    setCommonAlertButtonText('');
    setIconName('');
    setCommonAlertTitle('');
    setContainLink(false);
    setNavigateLink('');
    setCloseIcon(false);
  };
  useEffect(() => {
    if (props.stickerPurchasedStatus==='Stickers Purchased') {
      props.setPurchasedStatusNull()
      setSpinWheelAlert(true);
      setAlertConfirmButton(false);
      setAlertCloseButton(true);
      setAlertConfirmButtonText( '');
      setAlertCloseButtonText(t("Close"));
      setAlertTitle(t("purchaseStickers"));
      setAlertText(
          t("stickerPurchaseSuccess")
      );
      props.getStickersService(localStorage.getItem('userToken'));
      props.userGeneralDetailsService(localStorage.getItem('userToken'));
    }else if(props.stickerPurchasedStatus!=null){
      props.setPurchasedStatusNull()
      setSpinWheelAlert(true);
      setAlertConfirmButton(false);
      setAlertCloseButton(true);
      setAlertConfirmButtonText( '');
      setAlertCloseButtonText(t("Close"));
      setAlertTitle(t("purchaseStickers"));
      setAlertText(
           t("somethingWentWrongTryLater")
      );
    }
  }, [props.stickerPurchasedStatus]);

  return (
    <Layout subHeader={!isMobile? true:false} subFooter={!isMobile? true:false} header={!isMobile? true:false}>
      {commonAlertModal ? (
        <Alert
          isContainLink={containLink}
          navigateLink={navigationLink}
          title={commonTitle}
          modal={true}
          message={commonAlertMessage}
          buttonText={commonAlertButtonText}
          icon={IconName}
          removeFunc={setCommonModalFalse}
          closeIcon={closeIcon}
        />
      ) : (
        ''
      )}
      <ConfirmationAlert
        alert={spinWheelAlert}
        confirmButton={alertConfirmButton}
        closeButton={alertCloseButton}
        confirmButtonText={alertConfirmButtonText}
        closeButtonText={alertCloseButtonText}
        alertTitle={alertTitle}
        alertText={alertText}
        closeFunc={CloseAlert}
        confirmFunc={spinWithBonuspoints}
        buttonDisabled={buttonDisabled}
      />
      <Grid
        container
        direction="row"
        justifyContent="space-around"
        spacing={4}
        sx={{ padding: '20px 0px' }}
      >
        {!isMobile?<Grid item xs={12} md={5} lg={5}>
          <BasicInfoCard myProfile={true} />
        </Grid>:''}

        <Grid item xs={12} md={7} lg={7}>
          <Card
            sx={{
              borderRadius: '10px',
              minHeight: '823px',
              maxHeight: '823px',
              overflow: 'auto',
            }}
          >
            <CardContent>
              <Grid
                container
                justifyContent="space-between"
                sx={{ margin: '20px 0px' }}
              >
                <Grid item xs={7} sx={{ cursor: 'pointer' }}>
                  {stickerData.map((item) =>
                    item.type === 2 ? (
                      <ChatStickers
                      key={item}
                        source={
                          item.is_owned === 1
                            ? item.unlocked_img
                            : item.locked_img
                        }
                        height={150}
                        width={150}
                        margin={10}
                        id={item}
                        havingCallbackFunction={true}
                        sendMessage={purchaseSticker}
                        uniCode={null}
                      />
                    ) : (
                      ''
                    )
                  )}
                </Grid>
                <Divider orientation="vertical" flexItem={true} />
                <Grid item xs={4} sx={{ cursor: 'pointer' }}>
                  {stickerData.map((item) =>
                    item.type === 1 ? (
                      <ChatStickers
                      key={item}
                        source={
                          item.is_owned === 1
                            ? item.unlocked_img
                            : item.locked_img
                        }
                        height={70}
                        width={70}
                        margin={10}
                        id={item}
                        havingCallbackFunction={true}
                        sendMessage={purchaseSticker}
                        uniCode={null}
                      />
                    ) : (
                      ''
                    )
                  )}
                </Grid>
              </Grid>
            </CardContent>
            <CardContent
              sx={{
                marginTop: '5px',
                display: 'flex',
                justifyContent: 'center',
                marginLeft: '2rem',
              }}
            >
              <Typography variant="body1" component="h6">
              {t("bonusPoints")}:{' '}
                <span className={classes.currentMsg}>
                  {props.userGeneralData.bonuspoints}
                </span>
              </Typography>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Layout>
  );
};

const mapStateToProps = (state) => {
  return {
    selectedTab: state.ProfileTabReducer.selectedTab,
    stickersData: state.StickersReducer.stickersData,
    userGeneralData: state.UserDetailsReducer.userGeneralData,
    stickerPurchasedStatus: state.StickersReducer.stickerPurchasedStatus,
    stickerUnlockedStatus:state.StickersReducer.stickerUnlockedStatus
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getStickersService: (requestData) =>
      dispatch(stickersAction.getStickersService(requestData)),
    buyStickersService: (requestData, token) =>
      dispatch(stickersAction.buyStickersService(requestData, token)),
      setPurchasedStatusNull: () =>
      dispatch(stickersAction.setPurchasedStatusNull()),
      userGeneralDetailsService: (requestData) =>
      dispatch(userDetailsAction.userGeneralDetailsService(requestData)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(MyProfile);
