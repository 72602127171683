import { handleActions } from 'redux-actions';

const SecurityReducer = {
    securityData: [],
    securityPostResponse:null,
};

const reducer = handleActions(
    {
        GET_SECURITY_INITIATE: (state) => {
            return {
                ...state,
            };
        },
        GET_SECURITY_SUCCESS: (state, action) => {
            return {
                ...state,
                securityData: action.payload,
            };
        },
        GET_SECURITY_FAILURE: (state) => {
            return {
                ...state,
                securityData: [],
            };
        },
        POST_SECURITY_DATA_INITIATE: (state) => {
            return {
                ...state,
            };
        },
        POST_SECURITY_DATA_SUCCESS: (state, action) => {
            return {
                ...state,
                securityPostResponse: action.payload,
            };
        },
        POST_SECURITY_DATA_FAILURE: (state) => {
            return {
                ...state,
                securityPostResponse: null,
            };
        },
    },
    SecurityReducer
);
export default reducer;
