import { handleActions } from 'redux-actions';
import { API_STATUS } from '../../../constant';

const ResetPasswordReducer = {
  tokenStatus: null,
  passwordChangeStatus: null,
};

const reducer = handleActions(
  {
    VALIDATE_TOKEN_INITIATE: (state) => {
      return {
        ...state,
      };
    },
    VALIDATE_TOKEN_SUCCESS: (state, action) => {
      return {
        ...state,
        tokenStatus: API_STATUS.success,
      };
    },
    VALIDATE_TOKEN_FAILURE: (state) => {
      return {
        ...state,
        tokenStatus: API_STATUS.failed,
      };
    },
    RESET_PASSWORD_INITIATE: (state) => {
      return {
        ...state,
      };
    },
    RESET_PASSWORD_SUCCESS: (state, action) => {
      return {
        ...state,
        passwordChangeStatus: API_STATUS.success,
      };
    },
    RESET_PASSWORD_FAILURE: (state) => {
      return {
        ...state,
        passwordChangeStatus: API_STATUS.failed,
      };
    },
  },
  ResetPasswordReducer
);
export default reducer;
