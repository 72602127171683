import axios from "axios";
import {createAction} from "redux-actions";

export const forgotPasswordInitiate=createAction("FORGOT_PASSWORD_INITIATE");
export const forgotPasswordSuccess=createAction("FORGOT_PASSWORD_SUCCESS");
export const forgotPasswordFailure=createAction("FORGOT_PASSWORD_FAILURE");
export const forgotPasswordStatusNull=createAction("FORGOT_PASSWORD_NULL");

export const forgotPasswordService = (requestData)=>{
    return (dispatch)=>{
        dispatch(forgotPasswordInitiate());
        axios(
            {
                method: "post",
                url: `${process.env.REACT_APP_BACKEND_URL }forget_password`,
                data: requestData,
                headers: { "Content-Type": "multipart/form-data"},
              } )
          .then((response)=>{
              // if(response.data.STATUS===true||response.data.STATUS==="true") {
                dispatch(forgotPasswordSuccess(response.data));
            //  }else{
            //     dispatch(forgotPasswordFailure(response.data));
            //  }
          })
          .catch((error)=>{
            dispatch(forgotPasswordFailure());
          })
    }
}
export const forgotPasswordServiceNull = ()=>{
  return (dispatch)=>{
      dispatch(forgotPasswordStatusNull())
  }
}