import React from 'react';
import useStyles from '../../styles/alertCard';
import { ReactComponent as CloseIcon } from '../icons/ico-close.svg';
import { ReactComponent as PaymentSuccess } from '../icons/purchaseSuccess.svg';
import { ReactComponent as PaymentFailed } from '../icons/purchaseFailed.svg';
import {
  Typography,
  Button,
  Card,
  Backdrop,
  CardHeader,
  CardContent,
  IconButton,
} from '@mui/material';
import { Link } from 'react-router-dom';
export default function PaymentAlert(props) {
  const classes = useStyles();
  const getIcon = (activityType) => {
    let icon;
     if (activityType === 'paymentSuccess') {
      icon = <PaymentSuccess />;
    } else if (activityType === 'paymentFailure') {
      icon = <PaymentFailed />;
    }
    return icon;
  };
  return (
    <>
      <Backdrop
        sx={{  zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={props.modal}
        onClick={() => props.removeFunc()}
      >
        <Card variant="outlined" class={classes.containerClass}>
          <div class={classes.alertAccordianClass}>{getIcon(props.icon)}</div>
         
          <CardHeader
          sx={{pt:'30px !important',pb:'0px !important'}}
            //  avatar={
            //   // <Avatar sx={{ bgcolor: red[500] }} aria-label="recipe">
            //   //   R
            //   // </Avatar>
            // }
           classes={{
            title: classes.title,
          }}
          action={
            props.closeIcon? <IconButton aria-label="settings">
                <CloseIcon onClick={() => props.removeFunc()}/>
              </IconButton>:''
            }
            title= {props.title}
          />
          <CardContent sx={{pb:'0px !important',pt:'15px !important'}}>
            <Typography variant="body2" color="black" sx={{textAlign:'center'}}>
            {props.message}
            </Typography>
          </CardContent>
          <div class={classes.buttonText}>
            {props.isContainLink ? (
              <Link
                to={props.navigateLink}
                style={{ textDecoration: 'none' }}
                onClick={() => props.removeFunc()}
              >
                <Button
                  variant="contained"
                  color="primary"
                  sx={{
                    borderRadius: '10px',
                    width: '200px'
                  }}
                >
                  {props.buttonText}
                </Button>
              </Link>
            ) : (
              <Button
                variant="contained"
                color="primary"
                sx={{
                  borderRadius: '10px',
                  minWidth: '200px',
                  maxWidth:'300px',
                }}
                onClick={() => props.removeFunc()}
              >
                {props.buttonText}
              </Button>
            )}
          </div>
        </Card>
      </Backdrop>
    </>
  );
}
