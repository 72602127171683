import { handleActions } from 'redux-actions';
import { API_STATUS } from '../../../constant';

const UserDetailsReducer = {
  userGeneralData: {},
  userPersonalData: {},
  apiStatus: null,
  userLikedata:null,
  mobileOtpSendStatus:null,
  mobileOtpVerifyStatus:null,
};

const reducer = handleActions(
  {
    GENERAL_DETAILS_INITIATE: (state) => {
      return {
        ...state,
      };
    },
    GENERAL_DETAILS_SUCCESS: (state, action) => {
      return {
        ...state,
        userGeneralData: action.payload.result,
        userLikedata:action.payload.user_like_level
      };
    },
    GENERAL_DETAILS_FAILURE: (state) => {
      return {
        ...state,
      };
    },

    PERSONAL_DETAILS_INITIATE: (state) => {
      return {
        ...state,
      };
    },
    PERSONAL_DETAILS_SUCCESS: (state, action) => {
      return {
        ...state,
        userPersonalData: action.payload,
      };
    },
    PERSONAL_DETAILS_FAILURE: (state) => {
      return {
        ...state,
      };
    },
    UPDATE_PERSONAL_DETAILS_INITIATE: (state) => {
      return {
        ...state,
        apiStatus: API_STATUS.initiated,
      };
    },
    UPDATE_PERSONAL_DETAILS_SUCCESS: (state, action) => {
      return {
        ...state,
        apiStatus: API_STATUS.success,
      };
    },
    UPDATE_PERSONAL_DETAILS_FAILURE: (state) => {
      return {
        ...state,
        apiStatus: API_STATUS.failed,
      };
    },
    UPDATE_MOBILE_INITIATE: (state) => {
      return {
        ...state,
        apiStatus: API_STATUS.initiated,
      };
    },
    UPDATE_MOBILE_SUCCESS: (state, action) => {
      return {
        ...state,
        mobileOtpSendStatus:action.payload
      };
    },
    UPDATE_MOBILE_FAILURE: (state,action) => {
      return {
        ...state,
        mobileOtpSendStatus:action.payload
      };
    },
    VERIFY_MOBILE_OTP_INITIATE: (state) => {
      return {
        ...state,
        apiStatus: API_STATUS.initiated,
      };
    },
    VERIFY_MOBILE_OTP_SUCCESS: (state, action) => {
      return {
        ...state,
        mobileOtpVerifyStatus: action.payload,
      };
    },
    VERIFY_MOBILE_OTP_FAILURE: (state,action) => {
      return {
        ...state,
        mobileOtpVerifyStatus:action.payload
      };
    },
    STATE_DATA_NULL: (state,action) => {
      return {
        ...state,
        mobileOtpVerifyStatus:null,
        mobileOtpSendStatus:null
      };
    },
  },
  UserDetailsReducer
);
export default reducer;
