import React, { useState, useEffect } from 'react';
import { Grid, Card, CardContent, Typography } from '@mui/material';
import { ReactComponent as ChestLockedIcon } from '../icons/ico_chest_locked.svg';
import * as skillTreeActon from '../../redux/action/skillTreeAction';
import * as chestAction from '../../redux/action/chestAction';
import * as commonAction from '../../redux/action/commonAction';
import useStyles from '../../styles/myProfile';
import { connect } from 'react-redux';
import { ReactComponent as GreenChestIcon } from '../../components/icons/ico-green-chest (1).svg';
import SkillTree from './skillTree';
import Alert from '../common/alert';
import ConfirmationAlert from '../common/confirmationAlert';
import Timer from '../../utils/timer';
import * as userDetailsAction from '../../redux/action/userDetailsAction';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
const ProfileCard = (props) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [chestDatas, setChestDatas] = useState([]);
  const [unlockAlert, setUnlockAlert] = useState(false);
  const [commonAlertMessage, setCommonAlertMessage] = useState('');
  const [commonAlertButtonText, setCommonAlertButtonText] = useState('');
  const [IconName, setIconName] = useState('');
  const [commonTitle, setCommonAlertTitle] = useState('');
  const [containLink, setContainLink] = useState(false);
  const [navigationLink, setNavigateLink] = useState('');
  const [closeIcon, setCloseIcon] = useState('');
  const [chestUnlockAlert, setChestUnlockAlert] = useState(false);
  const [alertConfirmButton, setAlertConfirmButton] = useState(false);
  const [alertCloseButton, setAlertCloseButton] = useState(false);
  const [alertConfirmButtonText, setAlertConfirmButtonText] = useState('');
  const [alertCloseButtonText, setAlertCloseButtonText] = useState('');
  const [alertText, setAlertText] = useState('');
  const [alertTitle, setAlertTitle] = useState('');
  const [chestID, setChestID] = useState(0);
  const [buttonDisabled,setButtonDisabled]=useState(false)
  useEffect(() => {
    props.getSkillTreeData(localStorage.getItem('userToken'));
  }, []);

  useEffect(() => {
    props.getChestData(localStorage.getItem('userToken'));
  }, []);

  useEffect(() => {
    if (props.chestData.length > 0) {
      setChestDatas(props.chestData);
    } else if (props.chestData.length === 0) {
      setChestDatas([]);
    }
  }, [props.chestData]);

  const handleTime = (dataD) => {
    let data = new Date(dataD);
    let hrs = data.getHours();
    let mins = data.getMinutes();
    if (hrs <= 9) hrs = '0' + hrs;
    if (mins < 10) mins = '0' + mins;

    const postTime = hrs + ':' + mins;
    return postTime;
  };

  const unlockPrize = (item) => {
    var startTime = new Date(`${item.start_time} UTC`);

    var endTime = new Date(`${item.end_time} UTC`);
    var fStartTime = startTime.toString();
    var fEndtTime = endTime.toString();
    const timeStart = moment(fStartTime);
    const timeEnd = moment(fEndtTime);
    const current = moment(new Date());
   let diff1 = timeEnd.diff(timeStart, "minutes");
   let diff2 = current.diff(timeStart, "minutes");
    if (diff2 >= diff1) {
      let data = new FormData();
      data.append('chest_log_id', item.id);
      data.append('Unlock_with_coins', 0);
      props.unlockChestData(data, localStorage.getItem('userToken'));
    }else if(item.status===3){
      setChestID(item.id);
      setChestUnlockAlert(true);
      setAlertTitle(t("unableToUnlockChest"));
      setAlertText(
        t("tryAfterSometime")
      );
      setAlertConfirmButton(false);
      setAlertCloseButton(true);
      setAlertConfirmButtonText('');
      setAlertCloseButtonText(t("Close"));
    
    } else {
      setChestID(item.id);
      setChestUnlockAlert(true);
      setAlertTitle(t("unlockTimingIs")+ handleTime(moment(fEndtTime)));
      setAlertText(t("chestWithCoinMessage"));
      setAlertConfirmButton(true);
      setAlertCloseButton(true);
      setAlertConfirmButtonText(t("timerConfirmButton"));
      setAlertCloseButtonText(t("timerCancelButton"));
    }
  };

  useEffect(() => {
    if (props.unlockStatus && props.prize != '') {
      props.getFooterData(localStorage.getItem('userToken'))
      props.userGeneralDetailsService(localStorage.getItem('userToken'));
      setChestUnlockAlert(false);
      setAlertConfirmButton(false);
      setAlertCloseButton(false);
      setAlertConfirmButtonText('');
      setAlertCloseButtonText('');
      setAlertTitle(t("congratulations"));
      setAlertText('');
      setUnlockAlert(true);
      setCommonAlertMessage(props.prize);
      setCommonAlertButtonText(t("claim"));
      setIconName('ChestUnlockIcon');
      setCommonAlertTitle(t("chestUnlocked"));
      setContainLink(false);
      setNavigateLink('');
      setCloseIcon(false);
      setButtonDisabled(false)  
    }else if(props.unlockStatus===false&&props.prize !== ''){
      props.UnlockStatusNull();
      setChestUnlockAlert(true);
      setAlertTitle(t("somethingWentWrong"));
      setAlertText(
        props.prize
      );
      setAlertConfirmButton(false);
      setAlertCloseButton(true);
      setAlertConfirmButtonText('');
      setAlertCloseButtonText(t("Close"));
      setButtonDisabled(false)
    }
  }, [props.unlockStatus]);

  const closeRewardAlert = () => {
    setUnlockAlert(false);
    setCommonAlertMessage('');
    setCommonAlertButtonText('');
    setIconName('');
    setCommonAlertTitle('');
    setContainLink(false);
    setNavigateLink('');
    setCloseIcon(false);
    props.UnlockStatusNull();
    props.getChestData(localStorage.getItem('userToken'));
  };

  const CloseAlert = () => {
    setChestUnlockAlert(false);
    setAlertConfirmButton(false);
    setAlertCloseButton(false);
    setAlertConfirmButtonText('');
    setAlertCloseButtonText('');
    setAlertTitle('');
    setAlertText('');
    setChestID(0);
    props.getChestData(localStorage.getItem('userToken'));
  };

  const ChestUnlockWithCoins = () => {
    setButtonDisabled(true)
    let data = new FormData();
    data.append('chest_log_id', chestID);
    data.append('Unlock_with_coins', props.chestUnlockReqCoins);
    props.unlockChestData(data, localStorage.getItem('userToken'));
  };

  return (
    <>
      {unlockAlert ? (
        <Alert
          isContainLink={containLink}
          navigateLink={navigationLink}
          title={commonTitle}
          modal={true}
          message={commonAlertMessage}
          buttonText={commonAlertButtonText}
          icon={IconName}
          removeFunc={closeRewardAlert}
          closeIcon={closeIcon}
        
        />
      ) : (
        ''
      )}
      <ConfirmationAlert
        alert={chestUnlockAlert}
        confirmButton={alertConfirmButton}
        closeButton={alertCloseButton}
        confirmButtonText={alertConfirmButtonText}
        closeButtonText={alertCloseButtonText}
        alertTitle={alertTitle}
        alertText={alertText}
        closeFunc={CloseAlert}
        confirmFunc={ChestUnlockWithCoins}
        buttonDisabled={buttonDisabled}
      />

      <Card
        sx={{
          borderRadius: '10px',
          minHeight: '823px',
          maxHeight: '823px',
          overflow: 'auto',
        }}
      >
        <CardContent>
          <Grid container sx={{ margin: '20px 0px' }}>
            <Grid item xs={1} />
            <Grid item xs={4}>
              <Typography
                variant="body1"
                component="h6"
                sx={{ marginBottom: '40px', ml: 1 }}
              >
               {t("chest")}
              </Typography>
              {chestDatas.length > 0 && chestDatas.map((item) =>
                item.status===1&&chestID!==item.id ? (
                  <Grid item xs={12} sx={{ mt: 2.5 }} key={item}>
                    <ChestLockedIcon
                      style={{ cursor: 'pointer' }}
                      onClick={() => unlockPrize(item)}
                    />
                    <Timer min={item.remaining_min} sec={item.remaining_sec} typeTimer={'profile'} />
                  </Grid>
                ) :  item.status===2?
                  <Grid item xs={12} sx={{ mt: 1 }}>
                    <GreenChestIcon
                      style={{ cursor: 'pointer' }}
                      sx={{ marginBottom: '40px', width: '100%' }}
                      onClick={() => unlockPrize(item)}
                    />
                  </Grid>:   <Grid item xs={12} sx={{ mt: 2.5 }}>
                    <ChestLockedIcon
                      style={{ cursor: 'pointer' }}
                      onClick={() => unlockPrize(item)}
                    />
                  </Grid>
                
              )}
            </Grid>
            <Grid item xs={7} sx={{ position: 'relative' }}>
              <Typography
                variant="body1"
                component="h6"
                sx={{ marginBottom: '20px', textAlign: 'start' }}
              >
                Skill Tree
              </Typography>
              <SkillTree sx={{ width: '100%' }} />

              <Typography
                variant="body1"
                component="h6"
                sx={{
                  textAlign: 'center',
                  position: 'absolute',
                  left: '7.5rem',
                  marginTop: '50px',
                }}
              >
                {t("bonusPoints")}: <span className={classes.currentMsg}>{props.userGeneralData.bonuspoints}</span>
              </Typography>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    skillTreeData: state.SkillTreeReducer.skillTreeData,
    chestData: state.ChestReducer.chestData,
    unlockStatus: state.ChestReducer.unlockStatus,
    prize: state.ChestReducer.prize,
    userGeneralData: state.UserDetailsReducer.userGeneralData,
    chestUnlockReqCoins: state.ChestReducer.chestUnlockReqCoins,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getSkillTreeData: (requestData) =>
      dispatch(skillTreeActon.getSkillTreeService(requestData)),
    getChestData: (requestData) =>
      dispatch(chestAction.getChestData(requestData)),
    unlockChestData: (requestData, token) =>
      dispatch(chestAction.unlockChestData(requestData, token)),
    UnlockStatusNull: () => dispatch(chestAction.UnlockStatusNull()),
    getFooterData: (token) =>
      dispatch(commonAction.getFooterData(token)),
    userGeneralDetailsService: (requestData) =>
      dispatch(userDetailsAction.userGeneralDetailsService(requestData)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ProfileCard);
