import LoginReducer from "../loginReducer"
import ProfileTabReducer from "../profileTabReducer"
import RegisterReducer from "../registerReducer"
import ForgotPasswordReducer from "../forgotReducer"
import StaticPagesReducer from "../staticPagesReducer"
import UserDetailsReducer from "../userDetailsReducer"
import CommonReducer from "../commonReducer"
import MyFavoritesReducer from "../myFavoriteReducer"
import ResetPasswordReducer from '../resetPasswordReducer'
import SkillTreeReducer from '../skillTreeReducer'
import LastVisitorReducer from '../lastVisitorReducer'
import MemberHistoryReducer from '../memberHistoryReducer'
import GalleryReducer from '../galleryReducer'
import SpinWheelReducer from '../spinwheelReducer'
import ChestReducer from '../chestReducer'
import HeaderReducer from '../headerReducer'
import PackageReducer from '../packageReducer'
import SearchReducer from '../searchReducer'
import HappyClientsReducer from '../happyclientsReducer'
import StickersReducer from '../stickersReducer'
import SecurityReducer from '../securityReducer'
import CookiePolicyReducer from '../cookiepolicyReducer'
import FAQReducer from '../faqReducer'
import ChatReducer from '../chatReducer'
import DeleteAccountReducer from '../deleteAccountReducer'
const combinedReducers ={
    LoginReducer:LoginReducer,
    ProfileTabReducer:ProfileTabReducer,
    RegisterReducer:RegisterReducer,
    ForgotPasswordReducer:ForgotPasswordReducer,
    StaticPagesReducer:StaticPagesReducer,
    UserDetailsReducer:UserDetailsReducer,
    CommonReducer:CommonReducer,
    MyFavoritesReducer:MyFavoritesReducer,
    ResetPasswordReducer:ResetPasswordReducer,
    SkillTreeReducer:SkillTreeReducer,
    LastVisitorReducer:LastVisitorReducer,
    MemberHistoryReducer:MemberHistoryReducer,
    GalleryReducer:GalleryReducer,
    SpinWheelReducer:SpinWheelReducer,
    ChestReducer:ChestReducer,
    HeaderReducer:HeaderReducer,
    PackageReducer:PackageReducer,
    SearchReducer:SearchReducer,
    HappyClientsReducer:HappyClientsReducer,
    StickersReducer:StickersReducer,
    SecurityReducer:SecurityReducer,
    CookiePolicyReducer:CookiePolicyReducer,
    FAQReducer:FAQReducer,
    ChatReducer:ChatReducer,
    DeleteAccountReducer:DeleteAccountReducer,
}


export default combinedReducers;