import axios from "axios";
import {createAction} from "redux-actions";

export const getSecurityDataInitiate=createAction("GET_SECURITY_INITIATE");
export const getSecurityDataSuccess=createAction("GET_SECURITY_SUCCESS");
export const getSecurityDataFailure=createAction("GET_SECURITY_FAILURE");


export const postSecurityDataInitiate=createAction("POST_SECURITY_DATA_INITIATE");
export const postSecurityDataSuccess=createAction("POST_SECURITY_DATA_SUCCESS");
export const postSecurityDataFailure=createAction("POST_SECURITY_DATA_FAILURE");


export const getSecurityDataService = (requestData)=>{
    const config = {
     headers: {
        Authorization: `Bearer ${requestData}`
     }
   };
     return (dispatch)=>{
       dispatch(getSecurityDataInitiate());
       axios.get( `${process.env.REACT_APP_BACKEND_URL }security_list`,config)
         .then((response)=>{
             if(response.data.STATUS) { 
               dispatch(getSecurityDataSuccess(response.data.result));
            }else{
               dispatch(getSecurityDataFailure());
            }
         })
         .catch((error)=>{
          if (error.response.status === 401) {
            localStorage.clear();
            window.location.replace('/session-expired');
          }
          else {
           dispatch(getSecurityDataFailure());
          }
         })
   }
   }


   export const postSecurityDataService = (requestData,token)=>{
    const config = {
     headers: {
        Authorization: `Bearer ${token}`
     }
   };
     return (dispatch)=>{
       dispatch(postSecurityDataInitiate());
       axios.post( `${process.env.REACT_APP_BACKEND_URL }unlock_security`,requestData,config)
         .then((response)=>{
             if(response.data.STATUS) { 
               dispatch(postSecurityDataSuccess(response.data.result));
            }else{
               dispatch(postSecurityDataFailure());
            }
         })
         .catch((error)=>{
          if (error.response.status === 401) {
            localStorage.clear();
            window.location.replace('/session-expired');
          }
          else {
           dispatch(postSecurityDataFailure());
          }
         })
   }
   }