import axios from "axios";
import {createAction} from "redux-actions";

export const checkCookiesInitiate=createAction("CHECK_COOKIES_INITIATE");
export const checkCookiesSuccess=createAction("CHECK_COOKIES__SUCCESS");
export const checkCookiesFailure=createAction("CHECK_COOKIES__FAILURE");


export const postCookiesInitiate=createAction("POST_COOKIES_INITIATE");
export const postCookiesSuccess=createAction("POST_COOKIES_SUCCESS");
export const postCookiesFailure=createAction("POST_COOKIES_FAILURE");

export const postCookieStatusService = ()=>{
     return (dispatch)=>{
       dispatch(checkCookiesInitiate());
       axios.post( `${process.env.REACT_APP_BACKEND_URL }cookies_checkup`)
         .then((response)=>{
             if(response.data.STATUS) { 
               dispatch(checkCookiesSuccess(response.data.result));
            }else{
               dispatch(checkCookiesFailure());
            }
         })
         .catch((error)=>{
           dispatch(checkCookiesFailure());
         })
   }
   }


   export const getCookieStatusService = ()=>{
     return (dispatch)=>{
       dispatch(postCookiesInitiate());
       axios.post( `${process.env.REACT_APP_BACKEND_URL }first_time_cookies`)
         .then((response)=>{
             if(response.data.STATUS) { 
               dispatch(postCookiesSuccess(response.data.result));
            }else{
               dispatch(postCookiesFailure());
            }
         })
         .catch((error)=>{
           dispatch(postCookiesFailure());
         })
   }
   }