import { handleActions } from 'redux-actions';

const StickersReducer = {
  stickersData:[],
  stickerUnlockedStatus:null,
  stickerPurchasedStatus:null
};

const reducer = handleActions(
  {
    GET_STICKERS_INITIATE: (state) => {
      return {
        ...state,
      };
    },
    GET_STICKERS_SUCCESS: (state, action) => {
      return {
        ...state,
        stickersData: action.payload.message,
        stickerUnlockedStatus:action.payload.sticker_unlock_checkup
      };
    },
    GET_STICKERS_FAILURE: (state, action) => {
      return {
        ...state,
        stickersData:[]
      };
    },
    BUY_STICKERS_INITIATE: (state) => {
      return {
        ...state,
      };
    },
    BUY_STICKERS_SUCCESS: (state, action) => {
      return {
        ...state,
        stickerPurchasedStatus: action.payload.message
      };
    },
    BUY_STICKERS_FAILURE: (state, action) => {
      return {
        ...state,
        stickerPurchasedStatus: action.payload.message
      };
    },
    SET_STICKERS_NULL: (state, action) => {
      return {
        ...state,
        stickerPurchasedStatus: null
      };
    },
  },
  StickersReducer
);
export default reducer;
