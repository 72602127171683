import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
	footerSec: {
		background: 'url(/assets/images/footer_bg.png) no-repeat',
		padding: '25vh 10vw 10vh 10vw',
		backgroundSize: 'cover',
		[theme.breakpoints.down('sm')]: {
			padding: '25vh 10vw 2vh 10vw',
		},
	},
	instagram: {
		marginRight: '10px',
		marginTop: '10px',
	},
	copyright: {
		backgroundColor: '#191919',
		padding: '20px',
	},
	links: {
		'&:hover': {
			textDecoration: 'underline',
		},
		margin: "0",
		fontFamily: "Montserrat,sans-serif",
		fontWeight: "400",
		fontSize: "10px",
		color: "#000000",
		lineHeight: "1.57",
		letterSpacing:" 0.00714em",
		textDecoration: "none",
		marginTop: "10px",
	},
	currentMsg: {
		color: '#fff',
		padding: '0px 5px',
		backgroundColor: '#FF5B5B',
		borderRadius: 4,
		fontWeight: 400,
	},
	coins: {
		color: '#fff',
		padding: '0px 5px',
		backgroundColor: '#F3B227',
		borderRadius: 4,
		fontWeight: 400,
	},
	profileProgress: {
		backgroundColor: '#cfdee9 !important',
		height: '6px',
		borderRadius: '3px',
	},
	progressBar: {
		height: '6px !important',
		borderRadius: '3px',
		width: '50%',
		marginLeft: '5px',
	},
}));

export default useStyles;
