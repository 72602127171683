import { Button, Checkbox, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Fab, FormControlLabel, FormGroup, Typography } from '@mui/material'
import React, { useState, useEffect } from 'react'
import CookiePolicy from './CookiePolicy'
import PrivacyPolicy from './PrivacyPolicy'
import TermsAndConditions from './TermsAndConditions'
import { useTranslation } from 'react-i18next';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { getIsMobile } from '.'
export default function RegisterConfirmation({ dialogStatus, closeConfirmation, registerFunc }) {
    const { t } = useTranslation()
    const isMobile = getIsMobile();
    const [openDialog, setOpenDialog] = useState(false)
    const [checkbox, setCheckBox] = useState({ checked1: false, checked2: false, checked3: false })
    const[showBottomIcon, setShowBottomIcon] = useState({ upArrow: false, downArrow: false })
    const handleClose = () => {
        setOpenDialog(false)
        setCheckBox({ checked1: false, checked2: false, checked3: false })
        closeConfirmation();
        setShowBottomIcon({ downArrow: false, upArrow: false })
    }
    useEffect(() => {
        setOpenDialog(dialogStatus)
    }, [dialogStatus])
    const handleChange = (e) => {
        let eventName = e.target.name;
        let newState = checkbox[eventName] === true ? false : true;
        setCheckBox({ ...checkbox, [e.target.name]: newState })
    }
    const movetoRegister = () => {
        sessionStorage.setItem('rulesAccepted', 'true')
        closeConfirmation();
        window.location.replace("/register")
    }
    const handleScroll = (e) => {
        let scrollPosition = e.target.scrollTop;
        let overAllHeight=e.target.scrollHeight;
        if (scrollPosition > 100 && scrollPosition < overAllHeight-500) {
            setShowBottomIcon({ downArrow: true, upArrow: false })
        } else if (scrollPosition+500 >= overAllHeight) {
            setShowBottomIcon({ downArrow: false, upArrow: true })
        }
    }

    const handleIconScroll=(toScroll)=>{
        let scrollContainer =document.getElementById("scrollContent");
        let overAllHeight=scrollContainer.scrollHeight;
        if(toScroll==="toUp"){
            scrollContainer.scrollTop=0;
            setShowBottomIcon({ downArrow: false, upArrow: false })
        }else if(toScroll==="toDown"){
            scrollContainer.scrollTop=overAllHeight;
            setShowBottomIcon({ downArrow: false, upArrow: true })
        }
    }
    return (
        <Dialog
            fullWidth
            maxWidth={'lg'}
            open={openDialog}
            onClose={handleClose}
        >
            <DialogTitle sx={{ fontWeight: 'bold' }}>{t("heyYouHalfAway")}</DialogTitle>
            <DialogContent
                id='scrollContent'
                onScroll={(e) => handleScroll(e)}
                sx={{
                    '&::-webkit-scrollbar': {
                        width: '10px'
                    },
                    '&::-webkit-scrollbar-track': {
                        // boxShadow: ' 0 0 5px grey',
                        borderRadius: '10px',
                    },
                    '&::-webkit-scrollbar-thumb': {
                        background: 'gray',
                        borderRadius: '10px',
                    }
                }}>
                <DialogContentText>
                    <Typography variant='h3' sx={{ fontWeight: 'bold' }}>{t("terms")}</Typography>
                    <TermsAndConditions />
                    <Typography variant='h3' sx={{ fontWeight: 'bold' }}>{t("cookiesPolicy")}</Typography>
                    <CookiePolicy />
                    <Typography variant='h3' sx={{ fontWeight: 'bold' }}>{t("privacyPolicy")}</Typography>
                    <PrivacyPolicy />
                </DialogContentText>
                <FormGroup>
                    <FormControlLabel control={<Checkbox checked={checkbox.checked1} name='checked1' onChange={(e) => handleChange(e)} />}
                        label={`${t("acceptanceTermsofService")}`}
                    />
                    <FormControlLabel control={<Checkbox checked={checkbox.checked2} name='checked2' onChange={(e) => handleChange(e)} />}
                        label={`${t("acceptancePrivacyPolicy")}`} />
                    <FormControlLabel control={<Checkbox checked={checkbox.checked3} name='checked3' onChange={(e) => handleChange(e)} />}
                        label={`${t("acceptancePCookiePolicy")}`} />
                </FormGroup>
                {showBottomIcon.upArrow||showBottomIcon.downArrow?
                <Fab color="primary" onClick={()=>handleIconScroll(!showBottomIcon.downArrow?"toUp":"toDown")} 
                aria-label="add" 
                sx={{zIndex:1,position:'absolute',top:'80%',left:isMobile?'75%':'88%'}}>
                   {showBottomIcon.upArrow?<ExpandLessIcon onClick={()=>handleIconScroll("toUp")}/>:""}
                   {showBottomIcon.downArrow?<ExpandMoreIcon onClick={()=>handleIconScroll("toDown")}/>:""}
                </Fab>:""}    
            </DialogContent>
            <DialogActions sx={{ justifyContent: 'center' }}>
                <Button onClick={() => handleClose()} color='secondary' variant='contained' sx={{ backgroundColor: '#20202047', borderRadius: '5px' }}>{t("agbCancel")}</Button>
                <Button color='primary' variant='contained' sx={{ borderRadius: '5px' }}
                    disabled={!checkbox.checked1 || !checkbox.checked2 || !checkbox.checked3} onClick={() => registerFunc()}> {t("accept")} </Button>
            </DialogActions>
        </Dialog>
    )
}
