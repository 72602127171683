import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
  text: {
    color: '#7a797a !important',
    fontWeight: '600 !important',
    marginBottom: '14px !important',
  },
  headings: {
    color: '#202020 !important',
    fontWeight: '700 !important',
    marginBottom: '4px !important',
  },
  currentLevel: {
    color: '#fff',
    padding: '0px 5px',
    backgroundColor: theme.palette.primary.main,
    borderRadius: 4,
    fontWeight: 400,
  },
  likePoints: {
    color: '#fff',
    padding: '0px 5px',
    backgroundColor: '#1FD76D',
    borderRadius: 4,
    fontWeight: 400,
  },
  leftArrow: {
    position: 'absolute',
    top: '50%',
    left: '0px',
    cursor: 'pointer',
    zIndex: 10,
    backgroundColor: theme.palette.primary.main,
    height: '25px',
    width: '25px',
    borderRadius: '20px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  rightArrow: {
    position: 'absolute',
    top: '50%',
    right: '0px',
    cursor: 'pointer',
    zIndex: 10,
    backgroundColor: theme.palette.primary.main,
    height: '25px',
    width: '25px',
    borderRadius: '20px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  arrowIcons: {
    color: '#fff',
    fontSize: '40px',
  },
  carouselSec: {
    position: 'relative',
    display:'flex',
    justifyContent:'space-around',
    marginLeft:'5px',
    marginRight:"5px",
  },
  likeSec: {
    position: 'absolute',
    top: '6px',
    right: '20px',
    backgroundColor: '#82a0b7',
    borderRadius: '4px',
    padding: '0px 4px',
  },
  likeCounts: {
    fontSize: '11px',
    color: '#fff',
    fontWeight: 600,
    marginRight: '4px',
  },
  images: {
    borderRadius: '20px',
    height: '120px',
    width: '135px',
    backgroundSize: '135px 120px !important',
    // backgroundPosition: 'center',
    margin: '0 auto',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    '&:hover': {
      cursor: 'pointer',
      '& $timerSmall': {
        display: 'block',
      },
    },
  },
  mypic: {
    // background: 'url(/assets/images/demo/demo_gallery1.png) no-repeat',
    borderRadius: '10px',
    height: '380px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundSize: "auto 100% !important",
    backgroundPosition: "center !important",
    '&:hover': {
      cursor: 'pointer',
      '& $timerBig': {
        display: 'block',
      },
    },
  },
  //  timerBig: {
  //   display: 'none',
  // },
  // timerSmall: {
  //   display: 'none',
  // },
  timerpink: {
    color: '#ff0000',
  },
  icons: {
    marginRight: '10px',
    cursor:'pointer'
  },
  counts: {
    fontSize: '11px',
    color: '#7a797a',
    fontWeight: 600,
    marginRight: '4px',
  },
  hideComments: {
    fontSize: ' 13px !important',
    textDecoration: 'underline !important',
    color: '#2887c9 !important',
    fontWeight: '600 !important',
    padding: '0px !important',
  },
  box1: {
    backgroundColor: '#c064db',
    borderRadius: '4px',
    position: 'absolute',
    top: '16px',
    left: 0,
    zIndex: 5,
    minWidth: '27px',
    height: '31px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'flex-end',
    paddingBottom: '5px',
  },
  box2: {
    backgroundColor: '#ff9935',
    borderRadius: '4px',
    position: 'absolute',
    top: '40px',
    left: 0,
    zIndex: 4,
    minWidth: '27px',
    height: '31px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'flex-end',
    paddingBottom: '5px',
  },
  box3: {
    backgroundColor: '#1fd76d',
    borderRadius: '4px',
    position: 'absolute',
    top: '66px',
    left: 0,
    zIndex: 3,
    minWidth: '27px',
    height: '31px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'flex-end',
    paddingBottom: '5px',
  },
  box4: {
    backgroundColor: '#2887c9',
    borderRadius: '4px',
    position: 'absolute',
    top: '90px',
    left: 0,
    zIndex: 2,
    minWidth: '27px',
    height: '31px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'flex-end',
    paddingBottom: '5px',
  },
  box5: {
    backgroundColor: '#0b5386',
    borderRadius: '4px',
    position: 'absolute',
    top: '114px',
    left: 0,
    zIndex: 1,
    minWidth: '27px',
    height: '31px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'flex-end',
    paddingBottom: '5px',
  },
  profileProgress: {
    backgroundColor: '#cfdee9 !important',
    height: '6px',
    borderRadius: '3px',
  },
  progressBar: {
    height: '9px !important',
    borderRadius: '3px',
    maxWidth: "150px",
    minWidth: "150px",
  },
  box6: {
    backgroundColor: '#82a0b7',
    borderRadius: '4px',
    position: 'absolute',
    top: '16px',
    right: 0,
    zIndex: 5,
    minWidth: '27px',
    height: '31px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'flex-end',
    paddingBottom: '5px',
  },
  viewProfile: {
    color: '#fff !important',
    fontWeight: `${600} !important`,
    fontSize: '13px !important',
    backgroundColor: '#2887c9 !important',
    borderRadius: '6px !important',
  },
  inputFields: {
    padding: '4px 6px !important',
    border: '1px solid #b4bcc0',
    borderRadius: '4px',
  },
  inputRoot: {
    // borderRadius: '4px !important',
    // borderTopLeftRadius: '4px !important',
    // borderTopRightRadius: '4px !important',
    fontSize: '13px !important',
    lineHeight: '21px !important',
    color: `#7a797a !important`,
    fontWeight: `${600} !important`,
    marginBottom: '10px !important',
  },
  inputSelectRoot: {
    fontSize: '13px !important',
    lineHeight: '21px !important',
    color: `#7a797a !important`,
    fontWeight: `${600} !important`,
    // marginBottom: '10px !important',
  },
  closeIcon: {
    position: 'absolute',
    right: ' 25px',
    top: '17px',
    color: '#9F9F9F',
    cursor: 'pointer',
  },
  uploadDragableUpload: {
    width: '125px',
    height: '150px',
    position: 'relative',
    margin: '20px auto',
  },
  uploadPlaceholderCt: {
    width: '125px',
    height: '150px',
    borderRadius: '10px',
    backgroundColor: '#DDE5E8',
    position: 'relative',
    overflow: 'hidden',
    '& img': {
      width: '100%',
      height: '150px',
      objectFit: 'cover',
    },
    '&:hover': {
      backgroundColor: '#000',
      '& img': {
        opacity: 0.5,
      },
      cursor: 'pointer',
      '& $makeProfilePicText': {
        display: 'block',
      },
    },
    '&:focus': {
      outline: 'none',
    },
  },
  addImage: {
    position: 'absolute',
    top: '45%',
    left: '40%',
    color: theme.palette.primary.main,
    cursor: 'pointer',
  },
  heartIcon: {
    color: '#E61D4A',
  },
  makeProfilePicText: {
    display: 'none',
    color: '#fff !important',
    fontWeight: '600 !important',
    textDecoration: 'underline',
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translateX(-50%) translateY(-50%)',
    width: '100%',
    textAlign: 'center',
  },
  skillIcon: {
    position: 'absolute',
    left: '0px',
    top: '73px',
    width: '100%',
  },
  awaitingCard: {
    position: 'relative',
    maxWidth: '250px',
    textAlign: 'center',
    padding: '20px',
    top: "120px",
    left: "150px",
  },
  commentInputFields: {
    padding: '14px 16px !important',
    border: '1px solid #b4bcc0',
    borderRadius: '4px',
  },
  updateButton: {
    borderRadius: '10px !important',
  },
  EditImage: {
    position: 'absolute',
    bottom: '-15px',
    right: '-25px',
    cursor: 'pointer',
  },
  TimerIcon:{
    position: 'absolute',
    bottom: '-5px',
    right: '0px',
   
  },
  profileUpload: {
    width: '60px',
    height: '60px',
    position: 'relative',
    marginTop: '20px',
    marginBottom: '20px',
  },
  currentMsg: {
    color: '#fff',
    padding: '0px 5px',
    backgroundColor: '#42ba96',
    borderRadius: 4,
    fontWeight: 400,
    '@media(max-width: 720px)': {
      padding: '0px',
    },
  },
  boxStyle: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 320,
    height: 'auto',
    backgroundColor: '#fff',
    borderRadius: '10px',
    boxShadow: 24,
    p: 4,
  },
  arrowIcon: {
    position: "relative",
    // top: '7px',
    // right:'5px'
},
arrowIconLock: {
  position: "relative",
  top: '18px',
  right:'5px'
}
,commentText: {
  color: '#7a797a !important',
  fontWeight: '600 !important'
},mobileInput: {
  // color: '#7a797a !important',
  // fontWeight: '600 !important',
  border:'1px solid gray',
  display:'flex',
  justifyContent:'center'
},
inputContainer: {
  height: '45px',
  width: '45px !important',
  borderRadius: '10px',
  margin: '50px 5px',
  backgroundColor: '#ffffff80',
  border: '2px solid gray',
  fontSize: '42px',
  lineHeight: '19px',
  color: '#1a1a1a',
  
  [theme.breakpoints.down('md')]: {
    height: '45px',
    width: '45px !important',
  },
  [theme.breakpoints.down('sm')]: {
    height: '45px',
    width: '40px !important',
  },
  '@media(max-width: 767px)': {
    width: '25px !important',fontSize:'13px'
  },
  '&:focus-visible': {
    outline: 'none',
  },
},
otpContainer: {
  justifyContent: 'center',
},
}));

export default useStyles;
