import { handleActions } from 'redux-actions';

const HappyClientsReducer = {
  happyClientsData:[],
};

const reducer = handleActions(
  {
    GET_HAPPY_CLIENTS_INITIATE: (state) => {
      return {
        ...state,
      };
    },
    GET_HAPPY_CLIENTS_SUCCESS: (state, action) => {
      return {
        ...state,
        happyClientsData: action.payload,
      };
    },
    GET_HAPPY_CLIENTS_FAILURE: (state, action) => {
      return {
        ...state,
        happyClientsData:[]
      };
    },
  },
  HappyClientsReducer
);
export default reducer;
