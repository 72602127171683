import React from 'react';
import { Grid, Typography, Button } from '@mui/material';
import useStyles from '../../styles/landingPage';
import { getIsMobile } from '../../utils';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
const WhyUs = () => {
	const classes = useStyles();
	const isMobile = getIsMobile();
	const { t } = useTranslation();
	return (
		<>
			<Grid
				container
				justifyContent="space-between"
				alignItems="center"
				className={classes.projectSec}
			>
				<Grid item xs={11} md={4} className={isMobile ? classes.order2 : null}>
					<Typography variant="h2" component="h2">
						{t("whyChooseiseeju")}
					</Typography>
					{/* <Typography variant="body1" component="p" sx={{ marginTop: '10px' }}>
						Consectetur adipiscing elit. In nec aliquet arcu.
					</Typography> */}
					<Typography
						variant="body2"
						component="p"
						sx={{ marginTop: '10px', color: '#0f0f0f' }}
					>
						{t("para4")}
					</Typography>
					<Button
						variant="contained"
						color="primary"
						sx={{ marginTop: '20px' }}
					>
						<Link to="/register" style={{textDecoration:"none",color:"#fff"}}>{t("joinNow")}</Link>
					</Button>
				</Grid>
				<Grid item xs={12} md={6} className={isMobile ? classes.order1 : null}>
					<img
						src="/assets/images/banner2.png"
						style={{ width: '100%' }}
						alt="banner"
					/>
				</Grid>
			</Grid>
		</>
	);
};
export default WhyUs;
