import axios from 'axios';
import { createAction } from 'redux-actions';

export const GetCountryInitiate = createAction('GET_COUNTRY_INITIATE');
export const GetCountrySuccess = createAction('GET_COUNTRY_SUCCESS');
export const GetCountryFailure = createAction('GET_COUNTRY_FAILURE');

export const getCountryService = () => {
  return (dispatch) => {
    dispatch(GetCountryInitiate());
    axios
      .get(`${process.env.REACT_APP_BACKEND_URL }country_list`)
      .then((response) => {
        if (response.data.STATUS) {
          dispatch(GetCountrySuccess(response.data.result));
        } else {
          dispatch(GetCountryFailure());
        }
      })
      .catch((error) => {
        dispatch(GetCountryFailure());
      });
  };
};
export const GetStateInitiate = createAction('GET_STATE_INITIATE');
export const GetStateSuccess = createAction('GET_STATE_SUCCESS');
export const GetStateFailure = createAction('GET_STATE_FAILURE');

export const getStateService = (requestData) => {
  return (dispatch) => {
    dispatch(GetStateInitiate());
    axios
      .get(
        `${process.env.REACT_APP_BACKEND_URL }get_states_by_country/${requestData}`,
      )
      .then((response) => {
        if (response.data.STATUS) {
          dispatch(GetStateSuccess(response.data.result));
        } else {
          dispatch(GetStateFailure());
        }
      })
      .catch((error) => {
        dispatch(GetStateFailure());
      });
  };
};

export const GetCityInitiate = createAction('GET_CITY_INITIATE');
export const GetCitySuccess = createAction('GET_CITY_SUCCESS');
export const GetCityFailure = createAction('GET_CITY_FAILURE');

export const getCityService = (requestData) => {
  return (dispatch) => {
    dispatch(GetCityInitiate());
    axios
      .get(
        `${process.env.REACT_APP_BACKEND_URL }get_cities_by_state/${requestData}`,
      )
      .then((response) => {
        if (response.data.STATUS) {
          dispatch(GetCitySuccess(response.data.result));
        } else {
          dispatch(GetCityFailure());
        }
      })
      .catch((error) => {
        dispatch(GetCityFailure());
      });
  };
};

export const GetAgeRangeInitiate = createAction('GET_AGERANGE_INITIATE');
export const GetAgeRangeSuccess = createAction('GET_AGERANGE_SUCCESS');
export const GetAgeRangeFailure = createAction('GET_AGERANGE_FAILURE');

export const getAgeRangeService = () => {
  return (dispatch) => {
    dispatch(GetAgeRangeInitiate());
    axios
      .get(`${process.env.REACT_APP_BACKEND_URL }age_range`,)
      .then((response) => {
        if (response.data.STATUS) {
          dispatch(GetAgeRangeSuccess(response.data.result));
        } else {
          dispatch(GetAgeRangeFailure());
        }
      })
      .catch((error) => {
        dispatch(GetAgeRangeFailure());
      });
  };
};

export const GetBodyTypeInitiate = createAction('GET_BODYTYPE_INITIATE');
export const GetBodyTypeSuccess = createAction('GET_BODYTYPE_SUCCESS');
export const GetBodyTypeFailure = createAction('GET_BODYTYPE_FAILURE');

export const getBodyTypeService = () => {
  return (dispatch) => {
    dispatch(GetBodyTypeInitiate());
    axios
      .get(`${process.env.REACT_APP_BACKEND_URL }body_types`, )
      .then((response) => {
        if (response.data.STATUS) {
          dispatch(GetBodyTypeSuccess(response.data.result));
        } else {
          dispatch(GetBodyTypeFailure());
        }
      })
      .catch((error) => {
        dispatch(GetBodyTypeFailure());
      });
  };
};

export const GetEthenicityInitiate = createAction('GET_ETHENICITY_INITIATE');
export const GetEthenicitySuccess = createAction('GET_ETHENICITY_SUCCESS');
export const GetEthenicityFailure = createAction('GET_ETHENICITY_FAILURE');

export const getEthenicityService = () => {
  return (dispatch) => {
    dispatch(GetEthenicityInitiate());
    axios
      .get(`${process.env.REACT_APP_BACKEND_URL }ethnicities`)
      .then((response) => {
        if (response.data.STATUS) {
          dispatch(GetEthenicitySuccess(response.data.result));
        } else {
          dispatch(GetEthenicityFailure());
        }
      })
      .catch((error) => {
        dispatch(GetEthenicityFailure());
      });
  };
};

export const GetHairColorInitiate = createAction('GET_HAIRCOLOR_INITIATE');
export const GetHairColorSuccess = createAction('GET_HAIRCOLOR_SUCCESS');
export const GetHairColorFailure = createAction('GET_HAIRCOLOR_FAILURE');

export const getHairColorService = () => {
  return (dispatch) => {
    dispatch(GetHairColorInitiate());
    axios
      .get(`${process.env.REACT_APP_BACKEND_URL }hair_color_list`)
      .then((response) => {
        if (response.data.STATUS) {
          dispatch(GetHairColorSuccess(response.data.result));
        } else {
          dispatch(GetHairColorFailure());
        }
      })
      .catch((error) => {
        dispatch(GetHairColorFailure());
      });
  };
};

export const GetOrientationInitiate = createAction('GET_ORIENTATION_INITIATE');
export const GetOrientationSuccess = createAction('GET_ORIENTATION_SUCCESS');
export const GetOrientationFailure = createAction('GET_ORIENTATION_FAILURE');

export const getOrientationService = () => {
 
  return (dispatch) => {
    dispatch(GetOrientationInitiate());
    axios
      .get(`${process.env.REACT_APP_BACKEND_URL }orientations_list`)
      .then((response) => {
        if (response.data.STATUS) {
          dispatch(GetOrientationSuccess(response.data.result));
        } else {
          dispatch(GetOrientationFailure());
        }
      })
      .catch((error) => {
        dispatch(GetOrientationFailure());
      });
  };
};

export const GetEyeListInitiate = createAction('GET_EYELIST_INITIATE');
export const GetEyeListSuccess = createAction('GET_EYELIST_SUCCESS');
export const GetEyeListFailure = createAction('GET_EYELIST_FAILURE');

export const getEyeListService = () => {
  return (dispatch) => {
    dispatch(GetEyeListInitiate());
    axios
      .get(`${process.env.REACT_APP_BACKEND_URL }eye_list`)
      .then((response) => {
        if (response.data.STATUS) {
          dispatch(GetEyeListSuccess(response.data.result));
        } else {
          dispatch(GetEyeListFailure());
        }
      })
      .catch((error) => {
        dispatch(GetEyeListFailure());
      });
  };
};

export const GetPiercingsInitiate = createAction('GET_PIERCINGS_INITIATE');
export const GetPiercingsSuccess = createAction('GET_PIERCINGS_SUCCESS');
export const GetPiercingsFailure = createAction('GET_PIERCINGS_FAILURE');

export const getPiercingsService = () => {
 
  return (dispatch) => {
    dispatch(GetPiercingsInitiate());
    axios
      .get(`${process.env.REACT_APP_BACKEND_URL }piercings_list`)
      .then((response) => {
        if (response.data.STATUS) {
          dispatch(GetPiercingsSuccess(response.data.result));
        } else {
          dispatch(GetPiercingsFailure());
        }
      })
      .catch((error) => {
        dispatch(GetPiercingsFailure());
      });
  };
};

export const GetTattoosListInitiate = createAction('GET_TATTOOS_LIST_INITIATE');
export const GetTattoosListSuccess = createAction('GET_TATTOOS_LIST_SUCCESS');
export const GetTattoosListFailure = createAction('GET_TATTOOS_LIST_FAILURE');

export const getTattoosListService = () => {
  return (dispatch) => {
    dispatch(GetTattoosListInitiate());
    axios
      .get(`${process.env.REACT_APP_BACKEND_URL }tatoos_list`)
      .then((response) => {
        if (response.data.STATUS) {
          dispatch(GetTattoosListSuccess(response.data.result));
        } else {
          dispatch(GetTattoosListFailure());
        }
      })
      .catch((error) => {
        dispatch(GetTattoosListFailure());
      });
  };
};


export const advancedSearchInitiate = createAction('ADVANCED_SEARCH_INITIATE');
export const advancedSearchSuccess = createAction('ADVANCED_SEARCH_SUCCESS');
export const advancedSearchFailure = createAction('ADVANCED_SEARCH_FAILURE');

export const advancedSearchService = (requestData,token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'multipart/form-data',
    },
  };
  return (dispatch) => {
    dispatch(advancedSearchInitiate());
    axios
      .post(`${process.env.REACT_APP_BACKEND_URL }advanced_search`,requestData,config)
      .then((response) => {
        if (response.data.STATUS) {
          dispatch(advancedSearchSuccess(response.data));
        } else {
          dispatch(advancedSearchFailure());
        }
      })
      .catch((error) => {
        dispatch(advancedSearchFailure());
      });
  };
};

export const generalSearchInitiate = createAction('GENERAL_SEARCH_INITIATE');
export const generalSearchSuccess = createAction('GENERAL_SEARCH_SUCCESS');
export const generalSearchFailure = createAction('GENERAL_SEARCH_FAILURE');

export const generalSearchService = (requestData,token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'multipart/form-data',
    },
  };
  return (dispatch) => {
    dispatch(generalSearchInitiate());
    axios
      .post(`${process.env.REACT_APP_BACKEND_URL }general_search`,requestData,config)
      .then((response) => {
        if (response.data.STATUS) {
          dispatch(generalSearchSuccess(response.data));
        } else {
          dispatch(generalSearchFailure());
        }
      })
      .catch((error) => {
        dispatch(generalSearchFailure());
      });
  };
};
export const luckySearchInitiate = createAction('LUCKY_SEARCH_INITIATE');
export const luckySearchSuccess = createAction('LUCKY_SEARCH_SUCCESS');
export const luckySearchFailure = createAction('LUCKY_SEARCH_FAILURE');

export const luckySearchService = (token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  return (dispatch) => {
    dispatch(luckySearchInitiate());
    axios
      .get(
        `${process.env.REACT_APP_BACKEND_URL}lucky_search`,
        config
      )
      .then((response) => {
        if (response.data.STATUS) {
          dispatch(luckySearchSuccess(response.data));
        } else {
          dispatch(luckySearchFailure());
        }
      })
      .catch((error) => {
        dispatch(luckySearchFailure());
      });
  };
};