import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
	bannerBgImage: {
		background: 'url(/assets/images/banner1.jpg) no-repeat',
		minHeight: '110vh',
		backgroundSize: 'cover',
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'center',
		alignItems: 'flex-start',
		paddingLeft: '10vw',
		backgroundPosition: 'bottom',
		[theme.breakpoints.down('lg')]: {
			minHeight: '85vh',
		},
		[theme.breakpoints.down('sm')]: {
			minHeight: '65vh',
		},
		'@media (max-width: 450px)': {
			minHeight: '50vh',
			padding: '13px',
		},
	},
	sections: {
		padding: '12vh 10vw',
		textAlign: 'center',
	},
	leftSections: {
		paddingLeft: '10vw',
	},
	downloadBg: {
		background: 'url(/assets/images/download_bg.png) no-repeat',
		minHeight: '143vh',
		backgroundSize: 'cover',
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'center',
		alignItems: 'center',
		paddingRight: '3vw',
		backgroundPosition: 'center',
		[theme.breakpoints.down('md')]: {
			minHeight: '80vh',
		},
		[theme.breakpoints.down('sm')]: {
			minHeight: '50vh',
			padding: '13px',
			position: 'relative',
		},
	},
	downloadText: {
		width: '30vw',
		'@media (max-width: 1068px)': {
			width: '35vw',
		},
		[theme.breakpoints.down('md')]: {
			width: '38vw',
		},
		[theme.breakpoints.down('sm')]: {
			position: 'absolute',
			top: '600px',
			left: '36px',
			textAlign: 'center',
			width: '80vw',
			display: 'flex',
			flexDirection: 'column',
			alignItems: 'center',
		},
	},
	happyUsersSec: {
		textAlign: 'center',
		padding: '10vh 5vw',
		[theme.breakpoints.down('sm')]: {
			// marginTop: '200px',
		},
	},
	userSec: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		marginTop: '10vh',
	},
	leftArrow: {
		position: 'absolute',
		top: '50%',
		left: '0px',
		cursor: 'pointer',
		zIndex: 10,
		backgroundColor: '#44DAFE',
		height: '30px',
		width: '30px',
		borderRadius: '20px',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
	},
	rightArrow: {
		position: 'absolute',
		top: '50%',
		right: '0px',
		cursor: 'pointer',
		zIndex: 10,
		backgroundColor: '#44DAFE',
		height: '30px',
		width: '30px',
		borderRadius: '20px',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
	},
	arrowIcons: {
		color: '#fff',
		fontSize: '40px',
	},
	locationIcon: {
		fontSize: '16px',
		marginBottom: '-4px',
	},
	headingSec: {
		width: '40vw',
		display: 'table',
		margin: '0 auto',
		[theme.breakpoints.down('sm')]: {
			width: '100vw',
		},
	},
	playstoreButton: {
		'&:hover': {
			boxShadow:
				'0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%);',
			borderRadius: '30px',
		},
	},
}));

export default useStyles;
