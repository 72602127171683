import React from "react";
class Paypal extends React.Component {
    constructor(props) {
        super(props);
        this.state = { date: new Date(), orderId: 0 };
    }
    componentDidMount = () => {
        let paymentData = this.props.paymentData;
        let paymentSource;
        let self = this;
        window.paypal
            .Buttons({
                // Set up the transaction
                createOrder(data, actions) {
                    paymentSource = data.paymentSource
                    return actions.order.create({
                        purchase_units: [
                            {
                                amount: {
                                    value: paymentData.price,
                                    currency_code: "EUR",
                                }
                            }
                        ]
                    }).then((orderID) => {
                        sessionStorage.setItem("OrderID", orderID)
                        let purchaseData = new FormData();
                        purchaseData.append("package_id", paymentData.id)
                        purchaseData.append("order_id", orderID)
                        purchaseData.append("type", parseInt(1))
                        purchaseData.append("method", paymentSource)
                        self.props.postOrderData(purchaseData);
                        return orderID;
                    });
                },

                // Finalize the transaction
                onApprove(data, actions) {
                    return actions.order.capture().then(function (details) {
                        // Show a success message to the buyer
                        let purchaseData = new FormData();
                        purchaseData.append("status", 1)
                        purchaseData.append("order_id", details.id)
                        purchaseData.append("response", JSON.stringify(details))
                        self.props.postTransactionSuccess(purchaseData)
                    });
                },

                onCancel(data) {
                    // Show a cancel page, or return to cart
                    let purchaseData = new FormData();
                    purchaseData.append("status", 2)
                    purchaseData.append("order_id", data.orderID)
                    purchaseData.append("response", JSON.stringify(data))
                    self.props.postTransactionCancel(purchaseData)
                },
                onError(err) {
                    // For example, redirect to a specific error page
                    console.log(err);
                }
            })
            .render("#paypal-button-container");
    }

    postOrderId = (purchaseData) => {
        console.log(purchaseData);
        //this.props.postPurchaseInitation(purchaseData, localStorage.getItem("userToken"))
    }

    render() {
        return (
            <div>
                <div id="paypal-button-container" />
            </div>
        );
    }
}

export default Paypal;