import { handleActions } from 'redux-actions';

const MemberHistoryReducer = {
    memberHistoryData:[]
};

const reducer = handleActions(
  {
    GET_MEMBER_HISTORY_INITIATE: (state) => {
      return {
        ...state,
   
      };
    },
    GET_MEMBER_HISTORY_SUCCESS: (state, action) => {
      return {
        ...state,
        memberHistoryData:action.payload
      };
    },
    GET_MEMBER_HISTORY_FAILURE: (state, action) => {
      return {
        ...state,
        data: {},
  
      };
    },
  },
  MemberHistoryReducer
);
export default reducer;
