import { handleActions } from 'redux-actions';

const DeleteAccountReducer = {
    AccountDeleteStatus: null,
};

const reducer = handleActions(
    {
        DELETE_USER_INITIATE: (state) => {
            return {
                ...state,
            };
        },
        DELETE_USER_SUCCESS: (state, action) => {
            return {
                ...state,
                AccountDeleteStatus: action.payload,
            };
        },
        DELETE_USER_FAILURE: (state, action) => {
            return {
                ...state,
                AccountDeleteStatus: action.payload,
            };
        },
    },
    DeleteAccountReducer
);
export default reducer;
