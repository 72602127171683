import { Dialog, DialogContent, DialogTitle } from '@mui/material';
import React from 'react'
import CancelIcon from '@mui/icons-material/Cancel';
import { FacebookShareButton, TwitterShareButton } from "react-share";
import { FacebookIcon, TwitterIcon } from "react-share";
import useStyles from '../styles/myProfile';
import { useTranslation } from 'react-i18next';
export default function SocialShare(props) {
    const classes = useStyles();
    const {t}=useTranslation();
  return (
    <Dialog
    open={props.shareDialouge}
    onClose={() => props.setSocialShareing(false)}
    aria-labelledby="alert-dialog-title"
    aria-describedby="alert-dialog-description"
  >
    <DialogTitle id="alert-dialog-title">{t("shareTo")}</DialogTitle>
    <DialogContent sx={{width:'300px'}}>
      <CancelIcon
        className={classes.closeIcon}
        onClick={() => props.setSocialShareing(false)}
      />
      <FacebookShareButton
       onClick={() => props.setSocialShareing(false)}
        url={props.url}
        quote={props.description}
        hashtag={'#GalleryPicture'}
        description={props.description}
        className="Demo__some-network__share-button"
      >
        <FacebookIcon size={32} round />
      </FacebookShareButton>
      <TwitterShareButton
      style={{marginLeft:'5px'}}
       onClick={() => props.setSocialShareing(false)}
        title={props.description}
        url={props.url}
        hashtag={'#GalleryPicture'}
      >
        <TwitterIcon size={32} round />
      
      </TwitterShareButton>
    </DialogContent>
  </Dialog>
  )
}
