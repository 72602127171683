const primaryColor = '#FE3D73';
const primaryDarkColor = '#FD456D';
const secondaryColor = '#202020';
const secondaryLightColor = '#4D4B4B';

// const infoColor = '#61C2FF';
const successColor = '#1fd76d';
// const dangerColor = '#FF0000';
// const warningColor = '#DD9900';
// const headings = '#07204D';
// const textPrimary = '#07204D';
// const textSecondary = '#707070';
// const buttonPrimary = '#3732BF';
// const buttonSecondary = '#E0532E';
// const cardPrimary = '#DAD9F6';
// const backgroundPrimary = '#E7E8EB';
// const backgroundSecondary = '#A7A0EC';
// const grayText = '#8B919B';
// const cardBackground = '#DAD9F6';
const linkColor = '#fffefe';
const white = '#FFFFFF';
const black = '#000000';
const theme = {
	primaryColor,
	secondaryColor,
	// infoColor,
	// successColor,
	// dangerColor,
	// warningColor,
	// headings,
	// textPrimary,
	// textSecondary,
	// buttonPrimary,
	// buttonSecondary,
	// cardPrimary,
	// backgroundPrimary,
	// backgroundSecondary,
	// grayText,
	// cardBackground,
	linkColor,
	palette: {
		primary: {
			main: primaryColor,
			dark: primaryDarkColor,
		},
		secondary: {
			light: secondaryLightColor,
			main: secondaryColor,
		},
		common: {
			white,
			black,
		},
		// grey: {},
		text: {
			primary: '#010101',
			// secondary: '',
			// disabled: '',
		},
		success: {
			main: successColor,
		},
		contrastThreshold: 3,
	},
	typography: {
		h1: {
			fontFamily: 'Montserrat, sans-serif',
			fontWeight: 700,
			fontSize: '33px',
			color: white,
			textTransform: 'capitalize',
			'@media (max-width: 1200px)': {
				fontSize: '30px',
			},
			'@media (max-width: 900px)': {
				fontSize: '27px',
			},
			'@media (max-width: 600px)': {
				fontSize: '25px',
			},
		},
		h2: {
			fontFamily: 'Montserrat, sans-serif',
			fontWeight: 500,
			fontSize: '25px',
			color: '#010101',
			textTransform: 'uppercase',
			'@media (max-width: 1200px)': {
				fontSize: '23px',
			},
			'@media (max-width: 900px)': {
				fontSize: '21px',
			},
			'@media (max-width: 600px)': {
				fontSize: '19px',
			},
		},
		h3: {
			fontFamily: 'Montserrat, sans-serif',
			fontWeight: 600,
			fontSize: '23px',
			color: '#202020',
		},
		h4: {
			fontFamily: 'Montserrat, sans-serif',
			fontWeight: 600,
			fontSize: '21px',
			color: white,
		},
		h5: {
			fontFamily: 'Montserrat, sans-serif',
			fontWeight: 400,
			fontSize: '17px',
			color: white,
			'@media (max-width: 1200px)': {
				fontSize: '15px',
			},
			'@media (max-width: 900px)': {
				fontSize: '14px',
			},
			'@media (max-width: 600px)': {
				fontSize: '12px',
			},
		},
		h6: {
			fontFamily: 'Montserrat, sans-serif',
			fontWeight: 500,
			fontSize: '15px',
			'@media (max-width: 1200px)': {
				fontSize: '14px',
			},
			'@media (max-width: 900px)': {
				fontSize: '13px',
			},
			'@media (max-width: 600px)': {
				fontSize: '11px',
			},
		},
		body1: {
			fontFamily: 'Montserrat, sans-serif',
			fontWeight: 700,
			fontSize: '15px',
			color: '#0f0f0f',
			'@media (max-width: 1200px)': {
				fontSize: '14px',
			},
			'@media (max-width: 900px)': {
				fontSize: '13px',
			},
			'@media (max-width: 600px)': {
				fontSize: '11px',
			},
		},
		body2: {
			fontFamily: 'Montserrat, sans-serif',
			fontWeight: 400,
			fontSize: '13px',
			color: '#010101',
			'@media (max-width: 1200px)': {
				fontSize: '12px',
			},
			'@media (max-width: 900px)': {
				fontSize: '11px',
			},
			'@media (max-width: 600px)': {
				fontSize: '10px',
			},
		},
		subtitle1: {
			fontFamily: 'Montserrat, sans-serif',
			fontWeight: 700,
			fontSize: '10px',
			color: '#000000',
		},
		subtitle2: {
			fontFamily: 'Montserrat, sans-serif',
			fontWeight: 400,
			fontSize: '10px',
			color: '#000000',
		},
		button: {
			fontFamily: 'Montserrat, sans-serif',
			fontWeight: 500,
			color: '#010101',
			fontSize: '15px',
			'@media (max-width: 1200px)': {
				fontSize: '13px',
			},
			'@media (max-width: 900px)': {
				fontSize: '11px',
			},
		},
	},
	components: {
		MuiButton: {
			styleOverrides: {
				root: {
					borderRadius: '25px',
					padding: '6px 25px',
					'@media (max-width: 1200px)': {
						padding: '5px 22px',
					},
					'@media (max-width: 900px)': {
						padding: '4px 20px',
					},
					'@media (max-width: 600px)': {
						padding: '3px 18px',
					},
				},
				text: {
					color: linkColor,
				},
			},
		},
		MuiCardContent: {
			styleOverrides: {
				root: {
					padding: '24px !important',
				},
			},
		},
		MuiPaper: {
			styleOverrides: {
				rounded: {
					borderRadius: 16,
				},
			},
		},
	},

	// 	MuiContainer: {
	// 		maxWidthLg: {
	// 			padding: 0,
	// 		},
	// 	},
	// 	MuiListItemIcon: {
	// 		root: {
	// 			minWidth: '30px',
	// 		},
	// 	},
};

export default theme;
