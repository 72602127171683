import React, {useEffect} from 'react';
import { connect } from 'react-redux';
import UserLayout from '../layouts/userLayout';
import { Grid } from '@mui/material';
import BasicInfoCard from '../components/profilePages/basicInfoCard';
import GalleryCard from '../components/profilePages/galleryCard';
import { useHistory } from 'react-router';

const UserProfile = (props) => {
	const history =useHistory()
	useEffect(() => {
		if( localStorage.getItem('userToken') === null){
		  history.push("/")
		  window.location.reload()
		}
		  }, [ localStorage.getItem('userToken')])
	return (
		<UserLayout subHeader={false} subFooter={true} myProfile={false} header={true}>
			<Grid
				container
				direction="row"
				justifyContent="space-around"
				spacing={4}
				sx={{ padding: '20px 0px' }}
			>
				<Grid item xs={12} md={5} lg={5}>
					<BasicInfoCard myProfile={false}/>
				</Grid>

				<Grid item xs={12} md={7} lg={7}>
					<GalleryCard myProfile={false} />
				</Grid>
			</Grid>
		</UserLayout>
	);
};

const mapStateToProps = (state) => {
	return {
		selectedTab:state.ProfileTabReducer.selectedTab,
		userToken: state.LoginReducer.userToken,
	};
  };
  
  const mapDispatchToProps = (dispatch) => {
	return {
   
	};
  };

export default connect(mapStateToProps, mapDispatchToProps)(UserProfile);
