import { handleActions } from 'redux-actions';

const SpinWheelReducer = {
  spinWheelStatus: null,
  spinWheelData:[], 
  userBonusPoints: null,
  requiredBonusPoints:null,
  freeSpinCount:0,
  spinWheelChestAdd:null,
  isCoinSpin:null,
};

const reducer = handleActions(
  {
    CHECK_SPIN_WHEEL_ELIGIBILITY_INITIATE: (state) => {
      return {
        ...state,
      };
    },
    CHECK_SPIN_WHEEL_ELIGIBILITY_SUCCESS: (state, action) => {
      return {
        ...state,
        spinWheelStatus:action.payload.count,
        userBonusPoints:action.payload.user_bonus_point,
        requiredBonusPoints:action.payload.required_point,
        isCoinSpin:action.payload.is_coins  
      };
    },
    CHECK_SPIN_WHEEL_ELIGIBILITY_FAILURE: (state) => {
      return {
        ...state,
        spinWheelStatus:false
      };
    },
    CHECK_SPIN_WHEEL_NOT_UNLOCKED: (state,action) => {
      return {
        ...state,
        spinWheelStatus:action.payload
      };
    },
    GET_SPIN_WHEEL_DATA_INITIATE: (state) => {
        return {
          ...state,
        };
      },
      GET_SPIN_WHEEL_DATA_SUCCESS: (state, action) => {
        return {
          ...state,
          spinWheelData:action.payload
        };
      },
      GET_SPIN_WHEEL_DATA_FAILURE: (state) => {
        return {
          ...state,
          spinWheelData:[]
        };
      },
      POST_SPIN_WHEEL_GIFT_INITIATE: (state) => {
        return {
          ...state,
        };
      },
      POST_SPIN_WHEEL_GIFT_SUCCESS: (state, action) => {
        return {
          ...state,
          spinWheelChestAdd:action.payload.STATUS
        };
      },
      POST_SPIN_WHEEL_GIFT_FAILURE: (state) => {
        return {
          ...state,
          spinWheelChestAdd:false
        };
      },
      SPIN_WHEEL_DATA_NULL: (state) => {
        return {
          ...state,
          spinWheelChestAdd:null
        };
      },
  },
  SpinWheelReducer
);
export default reducer;
