import { handleActions } from 'redux-actions';
import { API_STATUS } from '../../../constant';

const LoginReducer = {
  apiStatus: -1,
  data: {},
  userToken: '',
  loginMessage: '',
  logoutStatus:null
};

const reducer = handleActions(
  {
    LOGIN_INITIATE: (state) => {
      return {
        ...state,
        apiStatus: API_STATUS.initiated,
      };
    },
    LOGIN_SUCCESS: (state, action) => {
      return {
        ...state,
        data: action,
        userToken: action.payload.auth_token,
        apiStatus: API_STATUS.success,
        logoutStatus: false
      };
    },
    LOGIN_FAILURE: (state, action) => {
      return {
        ...state,
        data: {},
        apiStatus: API_STATUS.failed,
        loginMessage: action.payload,
      };
    },
    LOGOUT_INITIATE: (state) => {
      return {
        ...state,
        apiStatus: API_STATUS.initiated,
      };
    },
    LOGOUT_SUCCESS: (state) => {
      return {
        ...state,
        logoutStatus: true,
      };
    },
    LOGOUT_FAILURE: (state) => {
      return {
        ...state,
        logoutStatus: false
      };
    },
    LOGIN_NULL: (state) => {
      return {
        ...state,
        loginMessage: ''
      };
    },
  },
  LoginReducer
);
export default reducer;
