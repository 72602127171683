import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import UserLayout from '../layouts/userLayout';
import {
  Grid,
  Card,
  CardActionArea,
  CardMedia,
  Typography,
  styled,
  Badge,
  Pagination,
} from '@mui/material';
import { ReactComponent as LikeIcon } from '../components/icons/ico like_gray.svg';
import { ReactComponent as LikeIconInActive } from '../components/icons/DarkBlue Like.svg';
import { ReactComponent as HeartIcon } from '../components/icons/ico_heart_active.svg';
import { ReactComponent as HeartIconInActive } from '../components/icons/ico heart inactive.svg';
import { ReactComponent as LocationIcon } from '../components/icons/location_Pin.svg';
import useStyles from '../styles/newestMembers';
import { useHistory } from 'react-router';
import * as commonAction from '../redux/action/commonAction';
import * as lastVisitorAction from '../redux/action/lastVisitorAction';
import { ReactComponent as Level1 } from '../components/icons/DarkBlue Like.svg';
import { ReactComponent as Level2 } from '../components/icons/litebluelike.svg';
import { ReactComponent as Level3 } from '../components/icons/ico like green.svg';
import { ReactComponent as Level4 } from '../components/icons/orange_like.svg';
import { ReactComponent as Level5 } from '../components/icons/like_purple.svg';
import { ReactComponent as GrayLike } from '../components/icons/ico like_gray.svg';
import { ReactComponent as ChatIcon } from '../components/icons/ico_message.svg';
import { ReactComponent as HotIcon } from '../components/icons/hot.svg';
import { Link } from 'react-router-dom';
import * as chatAction from '../redux/action/chatAction';
import ConfirmationAlert from '../components/common/confirmationAlert';
import Loader from '../utils/loader';
import { ReactComponent as TimerSmallIcon } from '../components/icons/ico_timer_small.svg';
import { useTranslation } from 'react-i18next';
const StyledBadge = styled(Badge)(({ theme }) => ({
  '& .MuiBadge-badge': {
    backgroundColor: '#44b700',
    color: '#44b700',
    boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
    bottom: '50%',
    right: '-30%',
  },
}));
const InYourArea = (props) => {
  const classes = useStyles();
  const history = useHistory();
  const { t } = useTranslation();
  const [newestMemberAlert, setNeweastMemberAlert] = useState(false);
  const [alertConfirmButton, setAlertConfirmButton] = useState(false);
  const [alertCloseButton, setAlertCloseButton] = useState(false);
  const [alertConfirmButtonText, setAlertConfirmButtonText] = useState('');
  const [alertCloseButtonText, setAlertCloseButtonText] = useState('');
  const [alertText, setAlertText] = useState('');
  const [alertTitle, setAlertTitle] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [initialLoading, setInitialLoading] = useState(true);
  const [noNewMember, setNoNewMember] = useState('')
  const [page, setPage] = useState(1);
  const [pageDatalimit, setPageDatalimit] = useState(15);
  const [buttonDisabled, setButtonDisabled] = useState(false)
  const [inYourAreaData, setInyourAreaData] = useState([])
  useEffect(() => {
    if (localStorage.getItem('userToken') === null) {
      history.push('/');
    }
  }, [localStorage.getItem('userToken')]);

  useEffect(() => {
    let pageNo=sessionStorage.getItem('InPageNo')===null?1:parseInt(sessionStorage.getItem('InPageNo'));
   
    if(pageNo===1){
    setTimeout(() => {
      props.inYourArea(localStorage.getItem('userToken'), pageDatalimit, pageNo - 1);
    }, 1000);

    setTimeout(() => {
      setInitialLoading(false)
    }, 2000);
  }else{
    setPage(pageNo)
  }
  }, []);

  useEffect(() => {
    // if(page>1){
      props.inYourArea(localStorage.getItem('userToken'), pageDatalimit, page - 1);
      setTimeout(() => {
        setInitialLoading(false)
      }, 2000);
    // }
  }, [page]);
  useEffect(() => {
    if (props.userData.length > 0) {
      setInyourAreaData(props.userData)
    }
  }, [props.userData]);

  const getUserData = (e, item) => {
    e.stopPropagation();
    props.setCommonStatusNull();
    localStorage.setItem('currentID', item.user_id);
    props.getUserByID(localStorage.getItem('userToken'), item.user_id);
    props.addLastVisit(item.user_id, localStorage.getItem('userToken'));
    history.push('/user-profile');
  };

  const handleFavorite = (e, item) => {
    setIsLoading(true)
    e.stopPropagation();
    let data = new FormData();
    data.append('member_id', item.user_id);
    data.append('action', item.is_favourite === 1 ? 0 : 1);
    props.addorRemoveFavorite(data);
  };
  const handleLike = (e, item) => {

    e.stopPropagation();
    if (item.is_liked_level <= 4) {
      setIsLoading(true)
      let data = new FormData();
      data.append('member_id', item.user_id);
      data.append('action', 1);
      data.append(
        'level',
        item.is_liked_level <= 4 ? item.is_liked_level + 1 : ''
      );
      props.likeorDislike(data);
    } else {
      setNeweastMemberAlert(true);
      setAlertConfirmButton(false);
      setAlertCloseButton(true);
      setAlertConfirmButtonText('');
      setAlertCloseButtonText(t("Close"));
      setAlertTitle('');
      setAlertText(t("likeLevelReached"));
    }
  };
  useEffect(() => {
    if (props.likeStatus) {
      setIsLoading(false)
      props.setCommonStatusNull();
      props.inYourArea(localStorage.getItem('userToken'), pageDatalimit, page - 1);  
    } else if (props.likeStatus === false) {
      setIsLoading(false)
      props.setCommonStatusNull();
      setNeweastMemberAlert(true);
      setAlertConfirmButton(false);
      setAlertCloseButton(true);
      setAlertConfirmButtonText('');
      setAlertCloseButtonText(t("Close"));
      setAlertTitle(t("dontHaveLikePoint"));
      setAlertText(t("earnAndTryLater"));
    }
  }, [props.likeStatus]);

  useEffect(() => {
    if (props.favoriteStatus) {
      setIsLoading(false)
      props.inYourArea(localStorage.getItem('userToken'), pageDatalimit, page - 1);
    }
  }, [props.favoriteStatus]);

  const movetoChat = (e, item) => {
    e.stopPropagation();
    localStorage.setItem('currentChatId', item.user_id);
    let data = new FormData();
    data.append('to_user_id', item.user_id);
    props.InitiateNewChat(data, localStorage.getItem('userToken'));
  };

  useEffect(() => {
    if (props.newChatConnectionStatus) {
      props.newChatStatusNull();
      // setTimeout(() => {
      history.push('/chat');
      //  }, 000);

    }
  }, [props.newChatConnectionStatus]);

  const getIcon = (item) => {
    let icon;
    if (item.is_liked_level === 0) {
      icon = (
        <GrayLike
          onClick={(e) => handleLike(e, item)}
          className={classes.likeIcon}
        />
      );
    } else if (item.is_liked_level === 1) {
      icon = (
        <Level1
          onClick={(e) => handleLike(e, item)}
          className={classes.likeIcon}
        />
      );
    } else if (item.is_liked_level === 2) {
      icon = (
        <Level2
          onClick={(e) => handleLike(e, item)}
          className={classes.likeIcon}
        />
      );
    } else if (item.is_liked_level === 3) {
      icon = (
        <Level3
          onClick={(e) => handleLike(e, item)}
          className={classes.likeIcon}
        />
      );
    } else if (item.is_liked_level === 4) {
      icon = (
        <Level4
          onClick={(e) => handleLike(e, item)}
          className={classes.likeIcon}
        />
      );
    } else if (item.is_liked_level === 5) {
      icon = <Level5 className={classes.likeIcon}
      // onClick={(e) => handleLike(e, item)}
        />;
    }
    return icon;
  };
  const CloseAlert = () => {
    setNeweastMemberAlert(false);
    setAlertConfirmButton(false);
    setAlertCloseButton(false);
    setAlertConfirmButtonText('');
    setAlertCloseButtonText('');
    setAlertTitle('');
    setAlertText('');
  };
  const confirmFunc = () => { };

  const handleChange = (event, value) => {
    setPage(value);
    sessionStorage.setItem('InPageNo',value)
  };
  return (
    initialLoading ? <UserLayout subHeader={false} subFooter={false} header={true}><Loader /></UserLayout> :
      <UserLayout subHeader={false} subFooter={false} header={true}>
        {isLoading ? <Loader /> : ''}
        <ConfirmationAlert
          alert={newestMemberAlert}
          confirmButton={alertConfirmButton}
          closeButton={alertCloseButton}
          confirmButtonText={alertConfirmButtonText}
          closeButtonText={alertCloseButtonText}
          alertTitle={alertTitle}
          alertText={alertText}
          closeFunc={CloseAlert}
          confirmFunc={confirmFunc}
          buttonDisabled={buttonDisabled}
        />
        <Grid
          container
          direction="row"
          justify="space-between"
          alignItems="center"
          spacing={4}
        >
          {inYourAreaData.map((item) => {
            return (
              <Grid item xs={6} sm={4} md={3} lg={2.3} xl={2.3} key={item}>
                <Card className={classes.bannerCard}>
                  {/* <CardActionArea> */}
                  <CardMedia
                    onClick={(e) => getUserData(e, item)}
                    className={classes.hotbidImg}
                    image={`${item.pictures}`}

                    title=""
                  >
                    {item.is_approved === 0 ? (
                      <TimerSmallIcon
                        className={classes.TimerIcon}
                        onClick={(e) => handleFavorite(e, item)}
                      />
                    ) : ''}
                    {item.is_favourite === 1 ? (
                      <HeartIcon
                        className={classes.heartIcon}
                        onClick={(e) => handleFavorite(e, item)}
                      />
                    ) : (
                      <HeartIconInActive
                        className={classes.heartIcon}
                        onClick={(e) => handleFavorite(e, item)}
                      />
                    )}
                    {getIcon(item)}

                    <div>
                      {item.is_popular === 1 ? <HotIcon className={classes.box1} /> : ''}
                      <div className={classes.userDescription} style={{ minHeight: '90px', maxHeight: '100px' }}>
                        {item.is_online === 1 ? (
                          <Grid
                            sx={{
                              display: 'flex',
                              justifyContent: 'space-between',
                            }}
                          >
                            <Grid item xs={9}>

                              <Typography
                                variant="body1"
                                component="p"
                                className={classes.userName}
                              >
                                {item.username.length > 7 ? `${item.username.slice(0, 7)}...` : item.username},
                                &nbsp;{item.age === null ? '' : item.age}
                                &nbsp;
                                {
                                  item.gender === 'Male'
                                    ? 'M'
                                    : item.gender === 'Female'
                                      ? 'F'
                                      : ''}
                              </Typography>

                            </Grid>
                            <Grid item xs={1}>
                              <StyledBadge
                                overlap="circular"
                                anchorOrigin={{
                                  vertical: 'bottom',
                                  horizontal: 'right',
                                }}
                                variant="dot"
                              />
                            </Grid>
                            <Grid item xs={1}>
                              <ChatIcon onClick={(e) => movetoChat(e, item)} />
                            </Grid>
                          </Grid>
                        ) : (
                          <Grid
                            sx={{
                              display: 'flex',
                              justifyContent: 'space-between',
                            }}
                          >
                            <Grid item>
                              <Typography
                                variant="body1"
                                component="p"
                                className={classes.userName}
                              >
                                {item.username.length > 7 ? `${item.username.slice(0, 7)}...` : item.username},

                                &nbsp;{item.age === null ? '' : item.age}
                                &nbsp;
                                {item.gender === 'Male'
                                  ? 'M'
                                  : item.gender === 'Female'
                                    ? 'F'
                                    : ''}
                              </Typography>
                            </Grid>
                            <Grid item onClick={(e) => movetoChat(e, item)}>
                              <ChatIcon style={{ cursor: 'pointer' }} />
                            </Grid>
                          </Grid>
                        )}
                        <Grid sx={{ display: 'flex', justifyContent: 'start' }}>
                          <Grid item>
                            <LocationIcon />
                          </Grid>
                          <Grid item>
                            <Typography
                              variant="body2"
                              component="p"
                              className={classes.userName}
                              sx={{ ml: 1 }}
                            >
                              {item.distance} km away
                            </Typography>
                          </Grid>{' '}
                        </Grid>
                        <Grid container>
                        <Grid item xs={8.5} display="flex" justifyContent={'start'}>
                          <Typography
                            variant="body2"
                            component="p"
                            className={classes.userName}
                          >
                            Status:
                          </Typography>
                          </Grid>
                          <Grid item xs={3.5} display="flex" justifyContent={'start'}>
                          <Typography
                            variant="body2"
                            component="p"
                            className={classes.userName}
                          >
                            {item.martial_status === ''||item.martial_status === null ? '' : item.martial_status}
                          </Typography>
                          </Grid>
                        </Grid>
                        <Grid container>
                        <Grid item xs={8.5} display="flex" justifyContent={'start'}>
                          <Typography
                            variant="body2"
                            component="p"
                            className={classes.userName}
                          >
                             {t("lookingFor")}:
                          </Typography>
                          </Grid>
                          <Grid item xs={3.5} display="flex" justifyContent={'start'}>
                          <Typography
                            variant="body2"
                            component="p"
                            className={classes.userName}
                          >
                            {item.looking_for === null || item.looking_for === '' ? '' : t(`${item.looking_for}`)}
                          </Typography>
                          </Grid>
                        </Grid>
                      </div>
                    </div>
                  </CardMedia>
                  {/* <CardContent className={classes.hotbidCard}>
										

										<Favorite className={classes.favoriteIcon} />
									</CardContent> */}
                  {/* </CardActionArea> */}
                  {/* <CardActions>
        <Button size="small" color="primary">
          Share
        </Button>
        <Button size="small" color="primary">
          Learn More
        </Button>
      </CardActions> */}
                </Card>
              </Grid>
            );
          })}
          {props.inYourAreaCount > 15 ? <Grid item xs={12} display='flex' justifyContent='center'>
            <Pagination count={Math.floor(props.inYourAreaCount / 15)} page={page} onChange={handleChange} variant="outlined" shape="rounded" />
          </Grid> : ''}
        </Grid>
      </UserLayout>
  );
};

const mapStateToProps = (state) => {
  return {
    userData: state.CommonReducer.inYourAreaMemberList,
    favoriteStatus: state.CommonReducer.favoriteStatus,
    likeStatus: state.CommonReducer.likeStatus,
    newChatConnectionStatus: state.ChatReducer.newChatConnectionStatus,
    inYourAreaCount: state.CommonReducer.inYourAreaCount
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    inYourArea: (requestData, limit, offset) => dispatch(commonAction.inYourArea(requestData, limit, offset)),
    getUserByID: (token, requestData) =>
      dispatch(commonAction.getUserByID(token, requestData)),
    addorRemoveFavorite: (requestData) =>
      dispatch(commonAction.favoriteOtherMember(requestData)),
    likeorDislike: (requestData) =>
      dispatch(commonAction.likeOtherMember(requestData)),
    addLastVisit: (userId, Token) =>
      dispatch(lastVisitorAction.addUserVisitor(userId, Token)),
    InitiateNewChat: (requestData, token) =>
      dispatch(chatAction.InitiateNewChat(requestData, token)),
    getChatHistoryData: (requestData) =>
      dispatch(chatAction.getChatHistoryData(requestData)),
    newChatStatusNull: () => dispatch(chatAction.newChatStatusNull()),
    setCommonStatusNull: () => dispatch(commonAction.setCommonStatusNull()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(InYourArea);
