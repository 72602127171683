import React from 'react';
import { CircularProgress } from '@mui/material';
import UserHeader from '../components/layout/userHeader';
import UserSubheader from '../components/layout/userSubheader';
import UserSubfooter from '../components/layout/userSubfooter';
import Footer from '../components/layout/footer';
import LoadingOverlay from 'react-loading-overlay';
import { getIsMobile } from '../utils';

const UserLayout = ({ loading, children, subHeader, subFooter,myProfile,header}) => {
	const isMobile = getIsMobile();
	return (
		<LoadingOverlay
			active={loading}
			spinner={<CircularProgress />}
			text="Please wait..."
			styles={{
				content: (base) => ({
					...base,
					color: '#919191',
					marginTop: '50vh',
				}),
				overlay: (base) => ({
					...base,
					zIndex: 9999,
					color: '#919191',
					backgroundColor: 'rgba(255,255,255,.5)',
				}),
			}}
		>
			<div style={{ backgroundColor: '#F3F8FF' }}>
				{header?<UserHeader />:''}
				{subHeader || subFooter ? (
					<div style={isMobile?{padding: '5px '}:{ padding: '20px 10vw' }}>
						{subHeader ? <UserSubheader children={children} /> : children}
						{subFooter ? <UserSubfooter profile={myProfile}/> : null}
					</div>
				) : (
					<div style={isMobile?{padding: '20px '}:{ padding: '20px 10vw',minHeight:"200px" }}>{children}</div>
				)}
				<Footer />
			</div>
		</LoadingOverlay>
	);
};

export default UserLayout;
