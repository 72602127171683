import React from 'react'
import {Avatar,Button,Box} from '@mui/material';
import NoEncryptionOutlinedIcon from '@mui/icons-material/NoEncryptionOutlined';
import Typography from '@mui/material/Typography';
import { useHistory } from 'react-router';
import {useTranslation} from 'react-i18next'
export default function SessionExpired() {
    const history=useHistory()
    const {t}=useTranslation();
  return (
      <Box
        sx={{
          marginTop: 8,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
          <NoEncryptionOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h3">
          {t("sessionExpired")}
        </Typography>
        <Box component="form"  noValidate sx={{ mt: 1 }}>
          <Button 
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
            onClick={()=>history.push('/login')}
          >
          {t("goToLogin")}
          </Button>
        </Box>
      </Box>
  )
}
