import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
  bannerBgImage: {
    background: 'url(/assets/images/login_bg.png) no-repeat',
    minHeight: '100vh',
    backgroundSize: 'cover',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    backgroundPosition: 'bottom',
  },
  bannerBgProfileImage: {
    background: 'url(/assets/images/login_bg.png) no-repeat',
    minHeight: '100vh',
    backgroundSize: 'cover',
  },
  instagram: {
    margin: '40px 10px 20px 0px',
  },
  inputFields: {
    padding: '13px !important',
  },
  inputRoot: {
    backgroundColor: '#ffffff80 !important',
    border: 'none',
    borderRadius: '10px !important',
    borderTopLeftRadius: '10px !important',
    borderTopRightRadius: '10px !important',
    fontSize: '17px !important',
    lineHeight: '21px !important',
    color: `${theme.palette.common.white} !important`,
    fontWeight: `${400} !important`,
  },
  errorText: {
    fontSize: '13px !important',
    letterSpacing: '1px !important',
    lineHeight: '8px !important',
    color: '#d81100 !important',
    fontWeight: '400 !important',
  },
  inputContainer: {
    height: '72px',
    width: '79px !important',
    borderRadius: '10px',
    margin: '50px 2px',
    backgroundColor: '#ffffff80',
    border: '0px',
    fontSize: '42px',
    lineHeight: '19px',
    color: '#1a1a1a',
    [theme.breakpoints.down('md')]: {
      height: '62px',
      width: '69px !important',
    },
    [theme.breakpoints.down('sm')]: {
      height: '45px',
      width: '40px !important',
    },
    '@media(max-width: 767px)': {
      width: '35px !important',
      fontSize:'25px !important'
    },
    '&:focus-visible': {
      outline: 'none',
    },
  },
  otpContainer: {
    justifyContent: 'center',
    display:'flex',
  },
  asterik: {
    color: '#ff0000',
  },
  genderButton: {
    backgroundColor: '#ffffff80 !important',
    borderRadius: '10px !important',
    fontSize: '17px !important',
    lineHeight: '21px !important',
    color: `${theme.palette.common.white} !important`,
    fontWeight: `${400} !important`,
    textTransform: 'none !important',
    margin: '8px 4px !important',
    padding: '0px 20px !important',
    height: '50px',
    border: 'none !important',
  },
  selectedGenderButton: {
    backgroundColor: '#ffffff !important',
    borderRadius: '10px !important',
    fontSize: '17px !important',
    lineHeight: '21px !important',
    color: '#1e1e1e !important',
    fontWeight: `${400} !important`,
    textTransform: 'none !important',
    margin: '8px 4px !important',
    padding: '0px 20px !important',
    height: '50px',
    border: 'none !important',
  },
  buttonGrp: {
    boxShadow: 'none !important',
  },
  uploadDragableUpload: {
    width: '80%',
    height: '320px',
    position: 'relative',
    marginBottom: '35px',
    display:'flex',
    justifyContent:'center'
  },
  uploadPlaceholderCt: {
    width: '65%',
    // height: '250px',
    borderRadius: '10px',
    backgroundColor: '#ebe9e8',
    // display: 'table',
    // margin: '20px auto',
    '& img': {
      width: '100%',
      height: '250px',
      objectFit: 'cover',
    },
  },
  startAdorn: {
    marginTop: '0px !important',
    color: '#2191FF',
    backgroundColor: '#fff',
    padding: '25px 15px',
    marginLeft: '-12px',
    borderTopLeftRadius: '10px',
    borderBottomLeftRadius: '10px',
  },
  forgotstartAdorn: {
    marginTop: '0px !important',
    color: '#2191FF',
    backgroundColor: '#fff',
    padding: '0px',
    marginLeft: '0px',
    borderTopLeftRadius: '10px',
    borderBottomLeftRadius: '10px',
  },
  lockIcon: {
    color: '#FF481D',
    width: '30px',
    height: '40px',
  },
  mailIcon: {
    color: '#2191FF',
    width: '30px',
    height: '40px',
  },
  forgotmailIcon: {
    color: '#1b1c1c59',
    width: '30px',
    height: '40px',
    backgroundColor:'#cccccc'
  },
  closeIcon: {
    position: 'absolute',
    right: ' 25px',
    top: '17px',
    color: '#9F9F9F',
    cursor: 'pointer',
  },
  inputRootModal: {
    backgroundColor: '#ccc !important',
    border: 'none',
    borderRadius: '10px !important',
    borderTopLeftRadius: '10px !important',
    borderTopRightRadius: '10px !important',
    fontSize: '17px !important',
    lineHeight: '21px !important',
    color: `${theme.palette.common.black} !important`,
    fontWeight: `${400} !important`,
  },
  cameraImage: {
    position: 'absolute',
    // bottom: '100px',
    // right: '170px',
    color: theme.palette.primary.main,
    cursor: 'pointer',
    top:'125px'
  },
  addImage:{
    position: 'absolute',
    // bottom: '100px',
    // right: '170px',
    color: theme.palette.primary.main,
    cursor: 'pointer',
    top:'300px',
    left:'78%',
    background: "aliceblue",
    borderRadius:'50px'
  },
  twiterlogin:{
    display:' table-caption',
    marginBottom:' 17px'
  }
}));

export default useStyles;
