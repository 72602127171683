import axios from "axios";
import {createAction} from "redux-actions";

export const GetUserVisitorInitiate=createAction("GET_USER_VISITOR_INITIATE");
export const GetUserVisitorSuccess=createAction("GET_USER_VISITOR_SUCCESS");
export const GetUserVisitorFailure=createAction("GET_USER_VISITOR_FAILURE");

export const AddUserVisitorInitiate=createAction("ADD_USER_VISITOR_INITIATE");
export const AddUserVisitorSuccess=createAction("ADD_USER_VISITOR_SUCCESS");
export const AddUserVisitorFailure=createAction("ADD_USER_VISITOR_FAILURE");

export const getUserVisitorData = (requestData)=>{
    const config = {
     headers: {
        Authorization: `Bearer ${requestData}`
     }
   };
     return (dispatch)=>{
       dispatch(GetUserVisitorInitiate());
       axios.get( `${process.env.REACT_APP_BACKEND_URL }user_visitors`,config)
         .then((response)=>{
             if(response.data.STATUS) { 
               dispatch(GetUserVisitorSuccess(response.data.message));
            }else{
               dispatch(GetUserVisitorFailure());
            }
         })
         .catch((error)=>{
          if (error.response.status === 401) {
            localStorage.clear();
            window.location.replace('/session-expired');
          }
          else {
           dispatch(GetUserVisitorFailure());
          }
         })
   }
   }


   export const addUserVisitor = (userId,requestData)=>{
    const config = {
     headers: {
        Authorization: `Bearer ${requestData}`
     }
   };
   let data=new FormData()
   data.append('member_id',userId)
     return (dispatch)=>{
       dispatch(AddUserVisitorInitiate());
       axios.post( `${process.env.REACT_APP_BACKEND_URL }add_visit`,data,config)
         .then((response)=>{
             if(response.data.STATUS) { 
               dispatch(AddUserVisitorSuccess(response.data.result));
            }else{
               dispatch(AddUserVisitorFailure());
            }
         })
         .catch((error)=>{
          if (error.response.status === 401) {
            localStorage.clear();
            window.location.replace('/session-expired');
          }
          else {
           dispatch(AddUserVisitorFailure());
          }
         })
   }
   }