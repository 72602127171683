import React from 'react'

export default function CookiePolicy() {
    return (
        <div><p>Wir verwenden Cookies und ähnliche Tools (zusammenfassend "Cookies") für die hier unten
            beschriebenen Zwecke.</p>

            <p><strong>Betriebstechnische Cookies:</strong> Wir verwenden Cookies für die Bereitstellung unserer Dienste, zum
                Beispiel, um:</p>

            <ul style={{paddingLeft:"4rem"}}>
                <li>zu erkennen, wenn Sie sich zur Nutzung unserer Dienste anmelden;</li>
                <li>zu erkennen, ob Sie ein VIP-Member sind, und um weitere kundenspezifische Funktionen
                    und Dienste anzubieten;</li>
                <li>Funktionen, Produkte und Dienste anzubieten, die für Sie von Interesse sein könnten,
                    insbesondere Anzeigen für unsere Dienste, sofern es sich um auf iseeju verfügbare Produkte
                    und Dienste handelt;</li>
                <li>Ihre Accountdaten zu speichern, was die Bestellung von Diensten & Leistungen einschliesst,
                    welche Sie bei iseeju bestellt und/oder bereits erworben haben;</li>
                <li>betrügerische Aktivitäten zu verhindern;</li>
                <li>die Sicherheit zu verbessern;</li>
                <li>Ihre Präferenzen, z.B. für Währung und Sprache berücksichtigen.</li>
            </ul>

            <p>Wir verwenden Cookies auch, um zu verstehen wie Kunden unsere Dienste nutzen, damit wir
                Verbesserungen vornehmen können. Wir verwenden Cookies zum Beispiel zur Durchführung von
                Recherchen und Diagnosen, um die Inhalte, Produkte und Dienste von iseeju zu verbessern und um die
                Leistung unserer Dienste zu messen und zu analysieren.</p>

            <p><strong>Werbe-Cookies:</strong> Wir verwenden Cookies auch, um bestimmte Arten von Anzeigen zu schalten,
                insbesondere auch für Profil- sowie Kontaktvorschläge und Dienste und für bestimmte Anzeigen, die
                aufgrund Ihrer Interessen relevant für Sie sind.</p>

            <p>Auch zugelassene Drittanbieter können Cookies setzen, wenn Sie mit den Services von iseeju
                interagieren. Zu diesen Drittanbietern gehören Suchmaschinen, Anbieter von Mess- und
                Analysediensten, Social-Media-Netzwerke und Werbefirmen. Drittanbieter verwenden Cookies bei der
                Bereitstellung von Werbeinhalten, insbesondere Anzeigen, die aufgrund Ihrer Interessen relevant für Sie
                sind, um die Effektivität ihrer Anzeigen zu messen und um Dienstleistungen im Auftrag von iseeju zu
                erbringen.</p>

            <p>Um zu sehen, welche zugelassenen Drittparteien Cookies verwenden und um Cookie-Einstellungen
                vorzunehmen, besuchen Sie bitte die Seite Cookie Einstellungen.</p>

            <h3 style={{paddingLeft:"16px"}}>Weitere Informationen</h3>
            <p>Betriebstechnische Cookies verbleiben nach Ihrem letzten Besuch bei unseren Diensten 13 Monate in
                Ihrem Browser, ausgenommen davon sind Cookies die Ihre Einstellungen zu persönlichen Informationen
                festhalten (wie zum Beispiel, Einstellungen für Werbung), die bis zu 5 Jahre in Ihrem Browser verbleiben
                können. Andere Cookies verbleiben in Ihrem Browser für 13 Monate nachdem Sie uns Ihre Zustimmung
                zur Verwendung dieser Cookies erteilt haben.</p>

            <p>Sie können Cookies auf unserer Seite mit den Cookie-Einstellungen verwalten. Wir werden Ihre
                Cookie-Einstellungen auf Amazon-Dienste und den Browser, mit dem Sie Ihre Auswahl getroffen haben,
                sowie jeden weiteren Browser anwenden, bei dem Sie angemeldet sind. Wenn Sie nicht angemeldet
                sind, müssen wir Ihre Auswahl unter Umständen erneut abfragen.</p>

            <p>Alternativ dazu können Sie in den Einstellungen Ihres Browsers erfahren, wie Sie verhindern, dass Ihr
                Browser neue Cookies akzeptiert, wie Sie einstellen, dass Ihr Browser Sie benachrichtigt, wenn Sie ein
                neues Cookie empfangen, wie Sie Cookies deaktivieren und entfernen und wann Cookies ablaufen.</p>

            <p>Dank der betriebstechnischen Cookies können Sie einige der wesentlichen Funktionen von iseeju nutzen.
                Wenn Sie betriebstechnische Cookies über Ihre Browsereinstellungen blockieren oder auf andere Weise
                ablehnen, werden einige Funktionen und Dienste möglicherweise nicht mehr funktionieren.
                Möglicherweise müssen Sie auch einige Ihrer Einstellungen jedes Mal manuell anpassen, wenn Sie einen
                unserer Dienste besuchen.</p>

            <p>Weitere Informationen zu der Art der von uns gesammelten Informationen finden Sie in
                unserer Datenschutzerklärung.</p></div>
    )
}
