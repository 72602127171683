import React, { useEffect, useState } from 'react';
import {
    Accordion, AccordionDetails, AccordionSummary, Typography, Container, List, ListItem, ListItemAvatar, Avatar, styled,
    Badge,
    TextField, ListItemText, Checkbox, Button, Grid
} from '@mui/material';
import ArrowDropDownOutlinedIcon from '@mui/icons-material/ArrowDropDownOutlined';
import UserLayout from '../layouts/userLayout';
import useStyles from '../styles/faq';
import { connect } from 'react-redux';
import * as faqAction from '../redux/action/faqAction';
import * as headerAction from '../redux/action/headerAction';
import { useHistory } from 'react-router';
import { useTranslation } from 'react-i18next';
import ReCAPTCHA from 'react-google-recaptcha';
const FAQ = (props) => {
    const { t } = useTranslation()
    const [faqData, setFaqData] = useState([])
    const [newQuestion, setNewQuestion] = useState('')
    const [QStatus, setQStatus] = useState('')
    const classes = useStyles();
    const history = useHistory();
    const [submitButtonDisabled, setButtonDisabled] = useState(true)
    const StyledBadge = styled(Badge)(({ theme }) => ({
        '& .MuiBadge-badge': {
            backgroundColor: '#44b700',
            color: '#44b700',
            boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
            bottom: '18%',
            right: '18%',
        },
    }));

    useEffect(() => {
        if (localStorage.getItem('userToken') != null) {
            props.getHeaderData(localStorage.getItem('userToken'));
        }
    }, []);

    useEffect(() => {
        props.getFaqService()
    }, [])

    useEffect(() => {
        if (props.faqData.length > 0) {
            setFaqData(props.faqData)
        }
    }, [props.faqData])

    useEffect(() => {
        if (localStorage.getItem('userToken') === null) {
            history.push('/login')
        }
    }, [localStorage.getItem('userToken')])

    const postNewQuestions = () => {
        if (newQuestion != '') {
            let Data = new FormData();
            Data.append('question', newQuestion)
            props.postNewQuestionService(Data, localStorage.getItem('userToken'))
            setQStatus('')
        }else{
            setQStatus(t("typeYourQues"))
        }
    }

    useEffect(() => {
        if (props.newQuestionStatus === 'FAQ Created') {
            setNewQuestion('')
            setQStatus(t("questionSendSuccessfully"))
            setTimeout(() => {
                window.location.reload();
            }, 5000);
        }
    }, [props.newQuestionStatus])

    const handleQuestion=(e)=>{
        setNewQuestion(e.target.value)
        setQStatus('')
    }
    const handleRecaptcha=(value)=>{
        console.log(value);
        setButtonDisabled(false)
    }
    const handleExpire=(value)=>{
        console.log(value);
        setButtonDisabled(true)
    }
    return (
        <div style={{ backgroundColor: "rgb(243, 248, 255)" }}>
            <UserLayout subHeader={false} subFooter={false} myProfile={true} header={true}>
                <Container className={classes.containerHeading} >
                    <Typography> Frequently Asked Questions</Typography>
                </Container>
                <Container className={classes.containerClass} >
                    {faqData.length > 0 ? faqData.map((item) => <Accordion key={item} className={classes.accordianClass}>
                        <AccordionSummary
                            expandIcon={<ArrowDropDownOutlinedIcon />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                        >
                            <Typography>{item.question}</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography variant='p'>
                                {item.answer}
                            </Typography>
                        </AccordionDetails>
                    </Accordion>) : ''}
                    <Accordion className={classes.accordianClass}>
                        <AccordionSummary
                            expandIcon={<ArrowDropDownOutlinedIcon />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                        >
                            <Typography> {t("contactIseeju")} </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography variant='p'>
                               {t("weWillContactSoon")}
                            </Typography>
                            <List >
                                {[1].map((item) => {
                                    return (
                                        <div key={item}>
                                            <ListItem className={classes.newQuestion} key={item} divider={true} sx={{ padding: '10px 24px' }}>

                                                <ListItemAvatar>
                                                    <StyledBadge
                                                        overlap="circular"
                                                        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                                                        variant="dot"
                                                    >
                                                        <Avatar
                                                            alt="avatar"
                                                            src={`${props.headerData.pictures}`}
                                                            sx={{
                                                                height: '70px',
                                                                width: '70px',
                                                                marginRight: '10px',
                                                                border: '2px solid #F7B573',
                                                                cursor: 'pointer',
                                                            }}
                                                        />
                                                    </StyledBadge>
                                                </ListItemAvatar>
                                                <ListItemText >
                                                    <TextField
                                                    value={newQuestion}
                                                        placeholder={t("enterYourQuestions")}
                                                        multiline
                                                        rows={2}
                                                        rowsMax={4}
                                                        className={classes.textfield}
                                                        onChange={(e) => handleQuestion(e)}
                                                    />
                                                    <Grid item sx={{mt:1}}>
                                                        <ReCAPTCHA
                                                            sitekey="6LckUBohAAAAABQsvB7NDWXsMc7CfFQE_TlQp3f4"
                                                            onChange={handleRecaptcha}
                                                            onExpired={handleExpire}
                                                        />
                                                        </Grid>
                                                </ListItemText >
                                            </ListItem>
                                        </div>)
                                })}

                            </List>
                            <Grid>
                                {QStatus != '' ? <Typography className={classes.qStatus}>{QStatus}</Typography> : ''}
                                <Button
                                    right
                                    color={!submitButtonDisabled?"primary":"secondary"}
                                    variant="contained"
                                    sx={{ borderRadius: '10px', margin: '20px 0px ' }}
                                    type="submit"
                                    className={classes.continueButton}
                                    onClick={!submitButtonDisabled?() => postNewQuestions():''}
                                >
                                    {t("sendQuestion")}
                                </Button>
                            </Grid>
                        </AccordionDetails>
                    </Accordion>
                </Container>
            </UserLayout>
        </div>
    )
}
const mapStateToProps = (state) => {
    return {
        faqData: state.FAQReducer.faqData,
        headerData: state.HeaderReducer.headerData,
        newQuestionStatus: state.FAQReducer.newQuestionStatus
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        getFaqService: () =>
            dispatch(faqAction.getFaqService()),
        getHeaderData: (requestData) =>
            dispatch(headerAction.getHeaderData(requestData)),
        postNewQuestionService: (requestData, token) =>
            dispatch(faqAction.postNewQuestionService(requestData, token)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(FAQ);