import React, { useState, useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';
import {
  Grid,
  AppBar,
  Toolbar,
  IconButton,
  Drawer,
  List,
  ListItem,
  Badge,
  TextField,
  InputAdornment,
  Typography,
  Menu,
  MenuItem,
  ListItemAvatar,
  Avatar,
  ListItemText,
  LinearProgress,
  ListItemIcon,
  Divider,
  Stack,
  Autocomplete,
  Button,
  Chip,
  BottomNavigation,
  BottomNavigationAction,
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import SearchIcon from '@mui/icons-material/Search';
import useStyles from '../../styles/header';
import { getIsMobile, getTimeStamp } from '../../utils';
import { ReactComponent as FilterIcon } from '../icons/ico_filter.svg';
import { ReactComponent as FilterPinkIcon } from '../icons/ico filter pink.svg';
import { ReactComponent as NotificationIcon } from '../icons/notif_bell.svg';
import { ReactComponent as QuickAccessIcon } from '../icons/quick_access_menu.svg';
import { ReactComponent as LogoutIcon } from '../icons/ico-logout.svg';
import { ReactComponent as PackageIcon } from '../icons/ico-package.svg';
import { ReactComponent as ProfileIcon } from '../icons/ico-profile.svg';
import { ReactComponent as SecurityIcon } from '../icons/ico-security.svg';
import { ReactComponent as GalleryIcon } from '../icons/ico_gallery.svg';
import { ReactComponent as StickerIcon } from '../icons/ico_sticker.svg';
import { ReactComponent as TrophyIcon } from '../icons/trophy-cup.svg';
import { ReactComponent as ChatIcon } from '../icons/ico_message.svg';
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import { connect } from 'react-redux';
import * as loginAction from '../../redux/action/loginAction';
import * as profileTabAction from '../../redux/action/profileTabAction';
import * as headerAction from '../../redux/action/headerAction';
import * as searchAction from '../../redux/action/searchAction';
import * as stickersAction from '../../redux/action/stickersAction';
import * as commonAction from '../../redux/action/commonAction';
import * as chatAction from '../../redux/action/chatAction';
import * as userDetailsAction from '../../redux/action/userDetailsAction';
import { ReactComponent as LikeIcon2 } from '../icons/orange_like.svg';
import { ReactComponent as HeartIcon } from '../icons/ico_heart_active.svg';
import { ReactComponent as LikePurpleIcon } from '../icons/ico_like_purple.svg';
import PlusOneOutlinedIcon from '@mui/icons-material/PlusOneOutlined';
import CommentIcon from '@mui/icons-material/Comment';
import AssistantOutlinedIcon from '@mui/icons-material/AssistantOutlined';
import PersonPinIcon from '@mui/icons-material/PersonPin';
import { ReactComponent as CoinIcon } from '../icons/Coin_SVG_icon.svg';
// import Closetab from './closetab';
import { useTranslation } from 'react-i18next';
const UserHeader = (props) => {
  let history = useHistory();
  const { t } = useTranslation();
  const classes = useStyles();
  const isMobile = getIsMobile();
  const [pathname, setPathname] = useState('');
  const [drawer, setDrawer] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const [filterStatus, setfilterStatus] = React.useState(null);
  const filterCurrentStatus = Boolean(filterStatus);
  const [notificationStatus, setNotificationStatus] = React.useState(null);
  const notificationCurrentStatus = Boolean(notificationStatus);
  const [notificationData, setNotificationData] = useState([]);
  const [orientationData, setOrientationData] = useState([]);
  const [countryData, setCountryData] = useState([]);
  const [stateData, setStateData] = useState([]);
  const [cityData, setCityData] = useState([]);
  const [ageRange, setAgeRangeData] = useState([]);
  const [hairColors, setHairColorsData] = useState([]);
  const [eyeList, setEyeListData] = useState([]);
  const [piercingsList, setPiercingsData] = useState([]);
  const [tattoosList, setTattoosData] = useState([]);
  const [bodyTypes, setBodyTypesData] = useState([]);
  const [searchData, setSearchData] = useState({
    orientation: '',
    ethnicity: '',
    bodyType: '',
    hairColor: '',
    eyeColor: '',
    piercings: '',
    tattoos: '',
    ageRange: '',
    country: '',
    state: '',
    city: '',
    countryKey: '',
    stateKey: '',
    cityKey: '',
  });
  const [generalSearchKey, setGeneralSearchKey] = useState('');
  useEffect(() => {
    return history.listen((location) => {
      if (
        location.pathname === '/newest-members' ||
        location.pathname === '/in-your-area' ||
        location.pathname === '/search-result'
      ) {
        localStorage.removeItem('currentID');
        props.setCommonStatusNull();
      }

      if (location.pathname === '/') {

      } else if (location.pathname !== '/chat') {
        props.chatActiveInActive(localStorage.getItem('userToken'), 0)
      } else if (location.pathname === '/chat') {
        props.chatActiveInActive(localStorage.getItem('userToken'), 1)
      }
      if (location.pathname !== '/coin-package' && sessionStorage.getItem("OrderID") !== null) {
        sessionStorage.removeItem("OrderID")
      }
    });
  }, [history]);
  const handleInputChange = (event, newValue) => {
    setSearchData({
      ...searchData,
      [event.target.id]: newValue,
    });
  };
  const handleChange = (event, newValue) => {
    if (newValue != null) {
      const key = event.target.id;
      let finalKey = key.replace('-option-', '').replace(/\d+/g, '');
      let locationStatus = false;
      //replace country city state if respective values changed
      if (searchData.state !== '' && newValue.label !== searchData.country && finalKey === 'country') {
        locationStatus = true;
        setSearchData({
          ...searchData,
          ['stateKey']: '',
          ['state']: '',
          ['cityKey']: '',
          ['city']: '',
          [finalKey + 'Key']: newValue.id,
          country: newValue.label,
        })
        setStateData([])
        setCityData([])
      } else if (searchData.city !== '' && newValue.label !== searchData.state && finalKey === 'state') {
        locationStatus = true;
        setSearchData({
          ...searchData,
          ['cityKey']: '',
          ['city']: '',
          [finalKey + 'Key']: newValue.id,
          state: newValue.label,
        });

        setCityData([])
      }

      if (
        (finalKey === 'country' ||
          finalKey === 'state' ||
          finalKey === 'city') && !locationStatus
      ) {
        if (finalKey === 'country') {
          setSearchData({
            ...searchData,
            [finalKey + 'Key']: newValue.id,
            country: newValue.label,
          });
        } else if (finalKey === 'state') {
          setSearchData({
            ...searchData,
            [finalKey + 'Key']: newValue.id,
            state: newValue.label,
          });
        } else {
          setSearchData({
            ...searchData,
            [finalKey + 'Key']: newValue.id,
            city: newValue.label,
          });
        }
      } else if ((finalKey !== 'country' ||
        finalKey !== 'state' ||
        finalKey !== 'city') && !locationStatus) {
        setSearchData({
          ...searchData,
          [finalKey]: newValue.label === 'Any' ? '' : newValue.label,
        });
      }
    }
  };

  useEffect(() => {
    if (searchData.countryKey != '') {
      props.getStateService(searchData.countryKey);
    }
  }, [searchData.countryKey]);

  useEffect(() => {
    if (searchData.stateKey != '') {
      props.getCityService(searchData.stateKey);
    }
  }, [searchData.stateKey]);

  const doSearch = () => {
    localStorage.removeItem('generalSearchData');
    localStorage.removeItem('luckySearch');
    setfilterStatus(null);
    let unixTimeStamp = Date.now();
    let data = new FormData();
    data.append('term', '');
    data.append('orientation', searchData.orientation);
    data.append('body_type', searchData.bodyType);
    data.append('hair_color', searchData.hairColor);
    data.append('eye_color', searchData.eyeColor);
    data.append('piercings', searchData.piercings);
    data.append('tatoos', searchData.tattoos);
    data.append('age_range', searchData.ageRange);
    data.append('country', searchData.countryKey);
    data.append('state', searchData.stateKey);
    data.append('city', searchData.cityKey);
    data.append('time_stamp', unixTimeStamp);
    data.append('pincode', '');
    data.append('limit', '15');
    data.append('offset', '0');
    var advanceSearchObj = {};
    data.forEach(function (value, key) {
      advanceSearchObj[key] = value;
    });
    localStorage.setItem('advancedSearchData', JSON.stringify(advanceSearchObj));
    if (localStorage.getItem('userToken') !== null && localStorage.getItem('userToken') !== undefined) {
      // props.advancedSearchService(data, localStorage.getItem('userToken'));
      history.push('/search-result');
    }

  };

  const handleLuckySearch = () => {
    setfilterStatus(null);
    localStorage.removeItem('generalSearchData');
    localStorage.removeItem('advancedSearchData');
    localStorage.setItem('luckySearch', true);
    history.push('/search-result');
  };

  useEffect(() => {
    if (window.location.pathname) {
      setPathname(window.location.pathname);
    }
    if (window.location.pathname === '/stickers') {
      props.setSelectedTab(7)
    }
  }, [window.location.pathname]);

  useEffect(() => {
    if (localStorage.getItem('userToken') != null) {
      props.getNotificationData(localStorage.getItem('userToken'));
      props.getHeaderData(localStorage.getItem('userToken'));
      props.getStickersService(localStorage.getItem('userToken'));
    }
  }, []);

  useEffect(() => {
    if (props.notificationReadMessage != '') {
      props.getNotificationData(localStorage.getItem('userToken'));
    }
  }, [props.notificationReadMessage]);

  useEffect(() => {
    if (props.headerData != null) {
    }
  }, [props.headerData]);

  useEffect(() => {
    if (props.notificationData.length > 0) {
      setNotificationData(props.notificationData);
    }
  }, [props.notificationData]);

  useEffect(() => {
    if (props.logoutStatus) {
      history.push('/');
    }
  }, [props.logoutStatus]);

  const handleProfileClick = (event) => {
    props.getHeaderData(localStorage.getItem('userToken'));
    setAnchorEl(event.currentTarget);
  };

  const handleProfileClose = () => {
    setAnchorEl(null);
  };

  const handleFilterClick = (event) => {
    setfilterStatus(event.currentTarget);
    props.getOrientationService();
    props.getCountryService();
    props.getAgeRangeService();
    props.getBodyTypeService();
    props.getHairColorService();
    props.getEyeListService();
    props.getPiercingsService();
    props.getTattoosListService();
  };

  const handleFilterClose = () => {
    setfilterStatus(null);
  };

  const handleNotificationClick = (event) => {
    setNotificationStatus(event.currentTarget);
    props.readNotificationData(localStorage.getItem('userToken'));
  };

  const handleNotificationClose = () => {
    setNotificationStatus(null);
  };

  const Item = styled(Paper)(({ theme }) => ({
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
    boxShadow: 'none',
  }));

  const getIcon = (activityType) => {
    let icon;
    if (activityType === 1) {
      //Member Like
      icon = <LikePurpleIcon style={{ marginLeft: '5px' }} />;
    } else if (activityType === 2) {
      //Member Photo Like
      icon = <LikeIcon2 style={{ marginLeft: '5px' }} />;
    } else if (activityType === 3) {
      //Photo Comment
      icon = <CommentIcon style={{ marginLeft: '5px' }} />;
    } else if (activityType === 4) {
      icon = <HeartIcon style={{ marginLeft: '5px' }} />;
    } else if (activityType === 8) {
      //Bonus Point  Increased
      icon = <PlusOneOutlinedIcon style={{ marginLeft: '5px' }} />;
    } else if (activityType === 9) {
      //Level up
      icon = <TrophyIcon style={{ marginLeft: '0px' }} />;
    } else if (activityType === 10) {
      //Comment Liked
      icon = <AssistantOutlinedIcon style={{ marginLeft: '5px' }} />;
    } else if (activityType === 11) {
      //New Visitor
      icon = <PersonPinIcon style={{ marginLeft: '5px' }} />;
    } else if (activityType === 12) {
      //24hrs like point
      icon = <PersonPinIcon style={{ marginLeft: '5px' }} />;
    } else if (activityType === 13) {
      //New Message
      icon = <ChatIcon style={{ marginLeft: '5px' }} />;
    }

    return icon;
  };
  const getString = (activityType, message) => {
    let returnMessage;
    let words = message.split(" ");
    let Name = words[0];
    if (activityType === 1) {
      //Member Like  
      returnMessage = Name + " " + t("superLikedYourProfile");
    } else if (activityType === 2) {
      //Member Photo Like
      returnMessage = Name + " " + t("likedYourPhoto");
    } else if (activityType === 3) {
      //Photo Comment
      returnMessage = Name + " " + t("commentedYourPhoto");
    } else if (activityType === 4) {
      returnMessage = Name + " " + t("addedYouAsFavourite")
    } else if (activityType === 8) {
      //Bonus Point  Increased
      returnMessage = t("progressGrow");
    } else if (activityType === 9) {
      //Level up
      returnMessage = t("reachedNextLevel")
    } else if (activityType === 10) {
      //Comment Liked
      returnMessage = Name + " " + t("likedOnYourComment");
    } else if (activityType === 11) {
      //New Visitor
      returnMessage = Name + "" + t("visitedYourProfile");
    } else if (activityType === 12) {
      //24hrs like point
      returnMessage = t("24HrLikePoint")
    } else if (activityType === 13) {
      //New Message
      returnMessage = Name + " " + t("messagedYou")
    }

    return returnMessage;
  };

  useEffect(() => {
    let data = {},
      arr = [{ id: 'any', label: 'Irgendeine' }];
    if (props.orientationData.length > 0) {
      props.orientationData.forEach((item) => {
        data['label'] = item.name;
        data['id'] = item.id;
        arr.push(data);
        data = {};
      });
      setOrientationData(arr);
    }
  }, [props.orientationData]);

  useEffect(() => {
    let data = {},
      arr = [];
    if (props.countryData.length > 0) {
      props.countryData.forEach((item) => {
        data['label'] = item.name;
        data['id'] = item.id;
        arr.push(data);
        data = {};
      });
    }
    setCountryData(arr);
  }, [props.countryData]);

  useEffect(() => {
    let data = {},
      arr = [];
    if (props.stateData.length > 0) {
      props.stateData.forEach((item) => {
        data['label'] = item.name;
        data['id'] = item.id;
        arr.push(data);
        data = {};
      });
    }
    setStateData(arr);
  }, [props.stateData]);

  useEffect(() => {
    let data = {},
      arr = [];
    if (props.cityData.length > 0) {
      props.cityData.forEach((item) => {
        data['label'] = item.name;
        data['id'] = item.id;
        arr.push(data);
        data = {};
      });
    }
    setCityData(arr);
  }, [props.cityData]);

  useEffect(() => {
    let data = {},
      arr = [{ id: 'any', label: 'Irgendeine' }];
    if (props.ageRange.length > 0) {
      props.ageRange.forEach((item) => {
        data['label'] = item.name;
        data['id'] = item.id;
        arr.push(data);
        data = {};
      });
    }

    setAgeRangeData(arr);
  }, [props.ageRange]);

  useEffect(() => {
    let data = {},
      arr = [{ id: 'any', label: 'Irgendeine' }];
    if (props.bodyTypes.length > 0) {
      props.bodyTypes.forEach((item) => {
        data['label'] = item.name;
        data['id'] = item.id;
        arr.push(data);
        data = {};
      });
    }

    setBodyTypesData(arr);
  }, [props.bodyTypes]);

  useEffect(() => {
    let data = {},
      arr = [{ id: 'any', label: 'Irgendeine' }];
    if (props.hairColors.length > 0) {
      props.hairColors.forEach((item) => {
        data['label'] = item.name;
        data['id'] = item.id;
        arr.push(data);
        data = {};
      });
    }
    setHairColorsData(arr);
  }, [props.hairColors]);

  useEffect(() => {
    let data = {},
      arr = [{ id: 'any', label: 'Irgendeine' }];
    if (props.eyeList.length > 0) {
      props.eyeList.forEach((item) => {
        data['label'] = item.name;
        data['id'] = item.id;
        arr.push(data);
        data = {};
      });
    }
    setEyeListData(arr);
  }, [props.eyeList]);

  useEffect(() => {
    let data = {},
      arr = [{ id: 'any', label: 'Irgendeine' }];
    if (props.piercingsList.length > 0) {
      props.piercingsList.forEach((item) => {
        data['label'] = item.name;
        data['id'] = item.id;
        arr.push(data);
        data = {};
      });
    }
    setPiercingsData(arr);
  }, [props.piercingsList]);

  useEffect(() => {
    let data = {},
      arr = [{ id: 'any', label: 'Irgendeine' }];
    if (props.tattoosList.length > 0) {
      props.tattoosList.forEach((item) => {
        data['label'] = item.name;
        data['id'] = item.id;
        arr.push(data);
        data = {};
      });
    }
    setTattoosData(arr);
  }, [props.tattoosList]);
  const handleGeneralSearch = (e) => {
    setGeneralSearchKey(e.target.value);
  };

  const doGeneralSearch = () => {
    localStorage.removeItem('advancedSearchData');
    localStorage.removeItem('luckySearch');
    let data = new FormData();
    data.append('term', generalSearchKey);
    data.append('limit', '15');
    data.append('offset', '0');
    var generalSearchObj = {};
    data.forEach(function (value, key) {
      generalSearchObj[key] = value;
    });
    localStorage.setItem('generalSearchData', JSON.stringify(generalSearchObj));
    if (generalSearchKey.length !== '') {
      // props.generalSearchService(data, localStorage.getItem('userToken'));
      history.push('/search-result');
    }
  };

  const renderMobile = () => {
    return (
      <Grid
        container
        direction="row"
        alignItems="center"
      >
        <Grid item alignItems="center" xs={6} display="flex"  justifyContent="start">
          <IconButton
            aria-label="menu icon"
            color="primary"
            onClick={() => setDrawer(true)}
          >
            <MenuIcon style={{ fontSize: '32px' }} />
          </IconButton>

          <Link to="/">
            <img
              src="/assets/images/logo_white.png"
              className={`ml-5  ${classes.logoMobileUserHeader}`}
              alt="header logo"
            />
          </Link>
        </Grid>
        <Grid item display='flex' alignItems='center' xs={6} sx={{pl:0,ml:0}}  justifyContent="end">
          <Link
            to="/coin-package"
            className={classes.headerCoinIcon}
          >
            <Badge color="primary" badgeContent={'+'} sx={{ mr: 2 }}>
              <Chip icon={<CoinIcon />}
                classes={{
                  label: classes.chipLabel,
                }}
                label={`${props.headerData.tot_coins > 0 ? props.headerData.tot_coins : 0}`}
                sx={{ color: 'white', backgroundColor: ' rgb(61 58 58)', fontSize: '15px' }} />
            </Badge>
          </Link>
          <Link
            to="#"
            className={classes.headerIcons}
            aria-controls="basic-menu-filter"
            aria-haspopup="true"
            aria-expanded={filterCurrentStatus ? 'true' : undefined}
            onClick={handleFilterClick}
          >
            {filterStatus ? <FilterPinkIcon /> : <FilterIcon />}
          </Link>
          <Link
            to="#"
            className={classes.headerIcons}
            aria-controls="basic-menu-notification"
            aria-haspopup="true"
            aria-expanded={filterCurrentStatus ? 'true' : undefined}
            onClick={handleNotificationClick}
          >
            {!props.newNotificationStatus && notificationData.length > 0 ? (
              <Badge
                color="primary"
                badgeContent={''}
                variant="dot"
                overlap="circular"
              >
                <NotificationIcon />
              </Badge>
            ) : (
              <NotificationIcon />
            )}
          </Link>
          <Link to="#" className={classes.headerIcons}
            aria-controls="basic-menu"
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}
            onClick={handleProfileClick}>
            <QuickAccessIcon />
          </Link>
        </Grid>

      </Grid>
    );
  };
  const moveToChat = () => {
    props.getChatHistoryData(localStorage.getItem('userToken'));
  }
  const renderWeb = () => {
    return (
      <Grid
        container
        direction="row"
        justifyContent="space-between"
        alignItems="center"
      >
        <Grid item md={3}>
          <Link to="/my-profile">
            <img src="/assets/images/logo_white.png" alt="header logo" />
          </Link>
        </Grid>
        <Grid item md={4.5}>
          <Grid container sx={{ justifyContent: 'center' }}>
            <Link
              to="/newest-members"
              underline="none"
              color="#fffefe"
              variant="h6"
              className={
                pathname === '/newest-members'
                  ? classes.selectedHeaderLinks
                  : classes.headerLinks
              }
            >
              {t("newestMembers")}
            </Link>
            <Link
              to="/in-your-area"
              underline="none"
              color="#fffefe"
              variant="h6"
              className={
                pathname === '/in-your-area'
                  ? classes.selectedHeaderLinks
                  : classes.headerLinks
              }
            >
              {t("inYourarea")}
            </Link>
            <Link
              onClick={() => moveToChat()}
              to="/chat"
              underline="none"
              color="#fffefe"
              variant="h6"
              className={
                pathname === '/chat'
                  ? classes.selectedHeaderLinks
                  : classes.headerLinks
              }
            >
              {t("chat")}
            </Link>
          </Grid>
        </Grid>

        <Grid item md={4.5} style={{ textAlign: 'right' }}>
          <Grid container alignItems="center" sx={{ justifyContent: 'end' }}>
            <Link
              to="/coin-package"
              className={classes.headerCoinIcon}
            >
              <Badge color="primary" badgeContent={'+'} sx={{ mr: 2 }}>
                <Chip icon={<CoinIcon />}
                  classes={{
                    label: classes.chipLabel,
                  }}
                  label={`${props.headerData.tot_coins > 0 ? props.headerData.tot_coins : 0}`}
                  sx={{ color: 'white', backgroundColor: ' rgb(61 58 58)', fontSize: '15px' }} />
              </Badge>
            </Link>
            <TextField
              onChange={(e) => handleGeneralSearch(e)}
              autoComplete="off"
              variant="filled"
              name="search"
              placeholder={t("search")}
              InputProps={{
                classes: {
                  input: classes.inputFields,
                  root: classes.inputRoot,
                },
                disableUnderline: true,
                endAdornment: (
                  <InputAdornment
                    title="search"
                    position="end"
                    className={classes.startAdorn}
                  >
                    <SearchIcon
                      style={{ cursor: 'pointer' }}
                      onClick={() => doGeneralSearch()}
                      className={classes.mailIcon}
                    />
                  </InputAdornment>
                ),
              }}
            />
            <Link
              to="#"
              className={classes.headerIcons}
              aria-controls="basic-menu-filter"
              aria-haspopup="true"
              aria-expanded={filterCurrentStatus ? 'true' : undefined}
              onClick={handleFilterClick}
            >
              {filterStatus ? <FilterPinkIcon /> : <FilterIcon />}
            </Link>
            <Link
              to="#"
              className={classes.headerIcons}
              aria-controls="basic-menu-notification"
              aria-haspopup="true"
              aria-expanded={filterCurrentStatus ? 'true' : undefined}
              onClick={handleNotificationClick}
            >
              {!props.newNotificationStatus && notificationData.length > 0 ? (
                <Badge
                  color="primary"
                  badgeContent={''}
                  variant="dot"
                  overlap="circular"
                >
                  <NotificationIcon />
                </Badge>
              ) : (
                <NotificationIcon />
              )}
            </Link>

            <Link
              to="#"
              className={classes.headerIcons}
              aria-controls="basic-menu"
              aria-haspopup="true"
              aria-expanded={open ? 'true' : undefined}
              onClick={handleProfileClick}
            >
              <QuickAccessIcon />
            </Link>


          </Grid>
        </Grid>
      </Grid>
    );
  };
  const handleHeaderMenuEvents = (value, row) => {
    if (row === 'row1') {
      if (value === 0) {
        props.setSelectedTab(isMobile ? 3 : 2)
        history.push("/my-profile")
      } else if (value === 1) {
        props.setSelectedTab(7)
        history.push(isMobile ? "/my-profile" : "/stickers")
      } else if (value === 2) {
        props.setSelectedTab(8)
        history.push(isMobile ? "/my-profile" : "/coin-package")
      }

    } else if (row === 'row2') {
      if (value === 0) {
        history.push("/security")
      } else if (value === 1) {
        history.push("/my-profile")
        props.setSelectedTab(0)
      } else if (value === 2) {
        props.logoutService(localStorage.getItem('userToken'))
      }
    }
  }
  return (
    <>
      {/* <Closetab/> */}
      <AppBar position="static" className={classes.appBar} color="secondary">

        <Toolbar style={{ padding: isMobile ? '5px' : '' }}>
          {isMobile || window.innerWidth <= 1280 ? renderMobile() : renderWeb()}
        </Toolbar>
        <Drawer anchor="left" open={drawer} onClose={() => setDrawer(false)}>
          <List>
            <ListItem button key="logo">
              <Link to="/">
                <img src="/assets/images/logo_black.png" alt="header logo" />
              </Link>
            </ListItem>
            <ListItem button key="search">
              <TextField
                onChange={(e) => handleGeneralSearch(e)}
                autoComplete="off"
                variant="filled"
                name="search"
                placeholder="Search..."
                InputProps={{
                  classes: {
                    input: classes.inputFields,
                    root: classes.inputRoot,
                  },
                  disableUnderline: true,
                  endAdornment: (
                    <InputAdornment
                      title="search"
                      position="end"
                      className={classes.startAdorn}
                    >
                      <SearchIcon
                        style={{ cursor: 'pointer' }}
                        onClick={() => doGeneralSearch()}
                        className={classes.mailIcon}
                      />
                    </InputAdornment>
                  ),
                }}
              />
            </ListItem>
            <ListItem button key="newest-members">
              <Link
                to="/newest-members"
                underline="none"
                color="#0f0f0f"
                variant="h6"
                style={{ color: '#0f0f0f', textDecoration: 'none' }}
                onClick={() => setDrawer(false)}
              >
                {t("newestMembers")}
              </Link>
            </ListItem>
            <ListItem key="in-your-area">
              <Link
                to="/in-your-area"
                underline="none"
                color="#0f0f0f"
                variant="h6"
                style={{ color: '#0f0f0f', textDecoration: 'none' }}
                onClick={() => setDrawer(false)}
              >
                {t("inYourarea")}
              </Link>
            </ListItem>
            <ListItem key="chat">
              <Link
                to="/chat"
                underline="none"
                color="#0f0f0f"
                variant="h6"
                style={{ color: '#0f0f0f', textDecoration: 'none' }}
                onClick={() => setDrawer(false)}
              >
                {t("chat")}
              </Link>
            </ListItem>
            <ListItem key="Security">
              <Link
                to="/security"
                underline="none"
                color="#0f0f0f"
                variant="h6"
                style={{ color: '#0f0f0f', textDecoration: 'none' }}
                onClick={() => setDrawer(false)}
              >
                {t("security")}
              </Link>
            </ListItem>
            <ListItem key="logout">
              <Link
                to="#"
                underline="none"
                color="#0f0f0f"
                variant="h6"
                style={{ color: '#0f0f0f', textDecoration: 'none' }}
                onClick={(e) =>
                  handleHeaderMenuEvents(2, 'row2')
                }
              >
                Log out
              </Link>
            </ListItem>
          </List>
        </Drawer>
        <Menu
          id="basic-menu-notification"
          anchorEl={notificationStatus}
          open={notificationCurrentStatus}
          onClose={handleNotificationClose}
          MenuListProps={{
            'aria-labelledby': 'basic-button',
          }}
          transformOrigin={{ horizontal: 'right', vertical: 'top' }}
          anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
          PaperProps={{
            sx: {
              filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
              mt: 1.5,
            },
          }}
        >
          <List
            sx={{
              width: '100%',
              maxWidth: 500,
              bgcolor: 'background.paper',
            }}
          >
            {notificationData.length > 0 ? (
              notificationData.map((item) => (
                <>
                  <ListItem key={item.id}>
                    <ListItemIcon>{getIcon(item.activity_type)}</ListItemIcon>
                    <ListItemText
                      primary={getString(item.activity_type, item.description)}
                      secondary={getTimeStamp(item.created_at)}
                    />
                  </ListItem>
                  <Divider variant="inset" component="li" />
                </>
              ))
            ) : (
              <ListItem>
                <ListItemIcon>
                  <SearchIcon />
                </ListItemIcon>
                <ListItemText primary="No Data Available" />
              </ListItem>
            )}
          </List>
        </Menu>
        <Menu
          id="basic-menu-filter"
          anchorEl={filterStatus}
          open={filterCurrentStatus}
          onClose={handleFilterClose}
          MenuListProps={{
            'aria-labelledby': 'basic-button',
          }}
          transformOrigin={{ horizontal: 'right', vertical: 'top' }}
          anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
          PaperProps={{
            sx: {
              filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
              mt: 1.5,
            },
          }}
        >
          <MenuItem
            onClick={handleFilterClose}
            sx={{
              ml: 2,

              '&:hover': {
                backgroundColor: 'rgba(0, 0, 0, 0) !important',
              },
            }}
          >
            <Typography>{t("filterProfilejustForyou")}</Typography>
          </MenuItem>
          <MenuItem
            sx={{
              ml: 2,
              mt: 5,
              '&:hover': {
                backgroundColor: 'rgba(0, 0, 0, 0) !important',
              },
            }}
          >
            <Typography sx={{ color: '#0f0f0f8c' }}>
              {t("searchByAppearance")}
            </Typography>
          </MenuItem>
          <Grid container spacing={5}>
            <Grid
              item
              sx={{ textAlign: 'left', ml: 4 }}
              xs={12}
              sm={12}
              md={6}
              lg={2.7}
            >
              {' '}
              <Typography> {t("orientation")} </Typography>
              <Autocomplete
                onChange={(event, newValue) => handleChange(event, newValue)}
                disablePortal
                value={searchData.orientation}
                inputValue={searchData.orientation}
                onInputChange={(event, newInputValue) => {
                  handleInputChange(event, newInputValue);
                }}
                disableClearable={true}
                id="orientation"
                options={orientationData}
                sx={{ width: 300 }}
                renderInput={(params) => (
                  <TextField {...params} placeholder="Irgendeine" />
                )}
              />
            </Grid>
            {/* <Grid
              item
              sx={{ textAlign: 'left', ml: 4 }}
              xs={12}
              sm={12}
              md={6}
              lg={2.7}
            >
              {' '}
              <Typography>Ethnicity</Typography>
              <Autocomplete
                value={searchData.ethnicity}
                onChange={(event, newValue) => handleChange(event, newValue)}
                inputValue={searchData.ethnicity}
                onInputChange={(event, newInputValue) => {
                  handleInputChange(event, newInputValue);
                }}
                disableClearable={true}
                disablePortal
                id="ethnicity"
                options={ethnicities}
                sx={{ width: 300 }}
                renderInput={(params) => (
                  <TextField {...params} placeholder="Any" />
                )}
              />
            </Grid> */}
            <Grid
              item
              sx={{ textAlign: 'left', ml: 4 }}
              xs={12}
              sm={12}
              md={6}
              lg={2.7}
            >
              {' '}
              <Typography>{t("bodyType")}</Typography>
              <Autocomplete
                value={searchData.bodyType}
                disablePortal
                onChange={(event, newValue) => handleChange(event, newValue)}
                inputValue={searchData.bodyType}
                onInputChange={(event, newInputValue) => {
                  handleInputChange(event, newInputValue);
                }}
                disableClearable={true}
                id="bodyType"
                options={bodyTypes}
                sx={{ width: 300 }}
                renderInput={(params) => (
                  <TextField {...params} placeholder="Irgendeine" />
                )}
              />
            </Grid>
            <Grid
              item
              sx={{ textAlign: 'left', ml: 4 }}
              xs={12}
              sm={12}
              md={6}
              lg={2.7}
            >
              {' '}
              <Typography> {t("eyeColor")} </Typography>
              <Autocomplete
                value={searchData.eyeColor}
                disablePortal
                onChange={(event, newValue) => handleChange(event, newValue)}
                id="eyeColor"
                inputValue={searchData.eyeColor}
                onInputChange={(event, newInputValue) => {
                  handleInputChange(event, newInputValue);
                }}
                disableClearable={true}
                options={eyeList}
                sx={{ width: 300 }}
                renderInput={(params) => (
                  <TextField {...params} placeholder="Irgendeine" />
                )}
              />
            </Grid>
            <Grid
              item
              xs={12}
              sm={12}
              md={6}
              lg={2.7}
              sx={{
                textAlign: 'left',
                ml: 4,
                // marginLeft: '30px',
                // paddingRight: '20px',
              }}
            >
              {' '}
              <Typography>{t("hairColor")}</Typography>
              <Autocomplete
                value={searchData.hairColor}
                disablePortal
                onChange={(event, newValue) => handleChange(event, newValue)}
                inputValue={searchData.hairColor}
                onInputChange={(event, newInputValue) => {
                  handleInputChange(event, newInputValue);
                }}
                disableClearable={true}
                id="hairColor"
                options={hairColors}
                sx={{ width: 300 }}
                renderInput={(params) => (
                  <TextField {...params} placeholder="Irgendeine" />
                )}
              />
            </Grid>
          </Grid>
          <Grid container spacing={5} sx={{ mt: 1 }}>

            <Grid
              item
              sx={{ textAlign: 'left', ml: 4 }}
              xs={12}
              sm={12}
              md={6}
              lg={2.7}
            >
              {' '}
              <Typography>Piercings</Typography>
              <Autocomplete
                value={searchData.piercings}
                disablePortal
                onChange={(event, newValue) => handleChange(event, newValue)}
                inputValue={searchData.piercings}
                onInputChange={(event, newInputValue) => {
                  handleInputChange(event, newInputValue);
                }}
                disableClearable={true}
                id="piercings"
                options={piercingsList}
                sx={{ width: 300 }}
                renderInput={(params) => (
                  <TextField {...params} placeholder="Irgendeine" />
                )}
              />
            </Grid>
            <Grid
              item
              sx={{ textAlign: 'left', ml: 4 }}
              xs={12}
              sm={12}
              md={6}
              lg={2.7}
            >
              {' '}
              <Typography>Tattoos</Typography>
              <Autocomplete
                value={searchData.tattoos}
                disablePortal
                onChange={(event, newValue) => handleChange(event, newValue)}
                inputValue={searchData.tattoos}
                onInputChange={(event, newInputValue) => {
                  handleInputChange(event, newInputValue);
                }}
                disableClearable={true}
                id="tattoos"
                options={tattoosList}
                sx={{ width: 300 }}
                renderInput={(params) => (
                  <TextField {...params} placeholder="Irgendeine" />
                )}
              />
            </Grid>
            <Grid
              item
              sx={{
                textAlign: 'left',
                ml: 4,
              }}
              xs={12}
              sm={12}
              md={6}
              lg={2.7}
            >
              {' '}
              <Typography> {t("ageRange")} </Typography>
              <Autocomplete
                value={searchData.ageRange}
                disablePortal
                onChange={(event, newValue) => handleChange(event, newValue)}
                id="ageRange"
                inputValue={searchData.ageRange}
                onInputChange={(event, newInputValue) => {
                  handleInputChange(event, newInputValue);
                }}
                disableClearable={true}
                options={ageRange}
                sx={{ width: 300 }}
                renderInput={(params) => (
                  <TextField {...params} placeholder="Irgendeine" />
                )}
              />
            </Grid>
          </Grid>
          <MenuItem
            sx={{
              ml: 2,
              mt: 2,
              cursor: 'context-menu',
              '&:hover': {
                backgroundColor: 'rgba(0, 0, 0, 0) !important',
              },
            }}
          >
            <Typography sx={{ color: '#0f0f0f8c' }}> {t("searchByarea")} </Typography>
          </MenuItem>
          <Grid container spacing={5} sx={{ marginBottom: '30px' }}>
            <Grid item sx={{ textAlign: 'left', marginLeft: '30px' }}>
              {' '}
              <Typography> {t("country")} </Typography>
              <Autocomplete
                value={searchData.country}
                disablePortal
                onChange={(event, newValue) => handleChange(event, newValue)}
                inputValue={searchData.country}
                onInputChange={(event, newInputValue) => {
                  handleInputChange(event, newInputValue);
                }}
                disableClearable={true}
                id="country"
                options={countryData}
                sx={{ width: 300 }}
                renderInput={(params) => (
                  <TextField {...params} placeholder="Irgendeine" />
                )}
              />
            </Grid>
            {stateData.length > 0 ? (
              <Grid item sx={{ textAlign: 'left', marginLeft: '30px' }}>
                {' '}
                <Typography>{t("state")}</Typography>
                <Autocomplete
                  value={searchData.state}
                  disablePortal
                  onChange={(event, newValue) => handleChange(event, newValue)}
                  id="state"
                  inputValue={searchData.state}
                  onInputChange={(event, newInputValue) => {
                    handleInputChange(event, newInputValue);
                  }}
                  disableClearable={true}
                  options={stateData}
                  sx={{ width: 300 }}
                  renderInput={(params) => (
                    <TextField {...params} placeholder="Irgendeine" />
                  )}
                />
              </Grid>
            ) : (
              ''
            )}
            {cityData.length > 0 ? (
              <Grid item sx={{ textAlign: 'left', marginLeft: '30px' }}>
                {' '}
                <Typography> {t("city")} </Typography>
                <Autocomplete
                  value={searchData.city}
                  disablePortal
                  onChange={(event, newValue) => handleChange(event, newValue)}
                  id="city"
                  inputValue={searchData.city}
                  onInputChange={(event, newInputValue) => {
                    handleInputChange(event, newInputValue);
                  }}
                  disableClearable={true}
                  options={cityData}
                  sx={{ width: 300 }}
                  renderInput={(params) => (
                    <TextField {...params} placeholder="Irgendeine" />
                  )}
                />
              </Grid>
            ) : (
              ''
            )}
          </Grid>
          <Divider />
          <MenuItem>
            <Grid container>
              <Grid item xs={6} sm={6} md={6} lg={6}>
                <Button
                  onClick={() => handleLuckySearch()}
                  variant="contained"
                  sx={{
                    borderRadius: '10px',
                    margin: '20px 0px ',
                    backgroundColor: '#FD456D',
                  }}
                  type="submit"
                  endIcon={<SearchIcon />}
                >
                  {t("luckySearch")}
                </Button>
              </Grid>
              <Grid
                item
                xs={6}
                sm={6}
                md={6}
                lg={6}
                sx={{ float: 'right', display: 'flex', justifyContent: 'end' }}
              >
                <Button
                  onClick={() => doSearch()}
                  variant="contained"
                  sx={{
                    borderRadius: '10px',
                    margin: '20px 0px ',
                    backgroundColor: '#00a5b9',
                  }}
                  type="submit"
                  endIcon={<SearchIcon />}
                >
                  {t("search")}
                </Button>
              </Grid>
            </Grid>
          </MenuItem>
        </Menu>
        <Menu
          id="basic-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleProfileClose}
          MenuListProps={{
            'aria-labelledby': 'basic-button',
          }}
          transformOrigin={{ horizontal: 'right', vertical: 'top' }}
          anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
          PaperProps={{
            sx: {
              filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
              mt: 1.5,
            },
          }}
        >
          <List onClick={handleProfileClose}>
            {[1].map((item) => {
              return (
                <div key={item}>
                  <ListItem
                    className={classes.newQuestion}
                    key={item}
                    divider={true}
                    sx={{ padding: '10px ' }}
                  >
                    <Link to="my-profile">
                      {' '}
                      <ListItemAvatar>
                        <Avatar
                          alt="avatar"
                          src={`${props.headerData.pictures}`}
                          sx={{
                            height: '50px',
                            width: '50px',
                            marginRight: '10px',
                            border: '2px solid #F7B573',
                            cursor: 'pointer',
                          }}
                        />
                      </ListItemAvatar>
                    </Link>
                    <ListItemText>
                      <Link
                        to="my-profile"
                        style={{ textDecoration: 'none', color: 'black' }}
                      >
                        {' '}
                        <Typography
                          variant="h6"
                          component="h6"
                          sx={{
                            fontWeight: 'bold',
                            textDecoration: 'none !important',
                          }}
                        >
                          {props.headerData
                            ? props.headerData.username
                            : ''}
                        </Typography>
                      </Link>
                      <LinearProgress
                        value={props.headerData.progress}
                        variant="determinate"
                        size={100}
                        thickness={10}
                        color="success"
                        classes={{
                          determinate: classes.profileProgress,
                          root: classes.progressBar,
                        }}
                        sx={{ width: '130px' }}
                      />
                    </ListItemText>
                  </ListItem>
                </div>
              );
            })}
          </List>
          <BottomNavigation
            id="row1"
            showLabels
            // value={value}
            onChange={(event, newValue) => {
              handleHeaderMenuEvents(newValue, 'row1');
            }}
          >
            <BottomNavigationAction id="myGallery" label={t("myGallery")} sx={{color: "#000000bd !important",fontWeight: "900 !important",fontSize: '1.15rem !important'}} 
            icon={<GalleryIcon />} />
            <BottomNavigationAction sx={{ color: "#000000bd !important", fontWeight: "900 !important", fontSize: '1.15rem !important' }}
              id="Sticker" label="Sticker" icon={<StickerIcon />} />
            <BottomNavigationAction sx={{ color: "#000000bd !important", fontWeight: "900 !important", fontSize: '1.15rem !important' }} id="Packages" label="Packages" icon={<PackageIcon />} />
          </BottomNavigation>
          <BottomNavigation 
            id="row2"
            showLabels
            // value={value}
            onChange={(event, newValue) => {
              handleHeaderMenuEvents(newValue, 'row2');
            }}
          >
            <BottomNavigationAction sx={{ color: "#000000bd !important", fontWeight: "900 !important", fontSize: '1.15rem !important' }} id="security" label={t("security")} icon={<SecurityIcon />} />
            <BottomNavigationAction sx={{ color: "#000000bd !important", fontWeight: "900 !important", fontSize: '1.15rem !important' }} id="profile" label={t("profile")} icon={<ProfileIcon />} />
            <BottomNavigationAction sx={{ color: "#000000bd !important", fontWeight: "900 !important", fontSize: '1.15rem !important' }} id="logout" label="Log out " icon={<LogoutIcon />} />
          </BottomNavigation>
          <Divider sx={{ marginBottom: -'12px' }} />
          <MenuItem
            onClick={handleProfileClose}
            className={classes.menuhover}
          >
            <List
              sx={{
                width: '100%',
                maxWidth: 360,
                bgcolor: 'background.paper',
                padding: '0px',
              }}
            >
              <ListItem sx={{ paddingLeft: '0px', paddingRight: '0px' }}>
                <ListItemText
                  sx={{ textAlign: 'left' }}
                  primary={`Coins: ${props.headerData.tot_coins}`}
                />&nbsp;
                <ListItemText
                  primary={`Bonus Points: 10000`}
                  sx={{ textAlign: 'right', paddingRight: '0px' }}
                />
              </ListItem>
            </List>
          </MenuItem>
        </Menu>
      </AppBar>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    data: state.LoginReducer.userToken,
    headerData: state.HeaderReducer.headerData,
    notificationData: state.HeaderReducer.notificationData,
    notificationReadMessage: state.HeaderReducer.notificationReadMessage,
    countryData: state.SearchReducer.countryData,
    stateData: state.SearchReducer.stateData,
    cityData: state.SearchReducer.cityData,
    ageRange: state.SearchReducer.ageRange,
    bodyTypes: state.SearchReducer.bodyTypes,
    hairColors: state.SearchReducer.hairColors,
    eyeList: state.SearchReducer.eyeList,
    piercingsList: state.SearchReducer.piercingsList,
    tattoosList: state.SearchReducer.tattoosList,
    orientationData: state.SearchReducer.orientationList,
    logoutStatus: state.LoginReducer.logoutStatus,
    newNotificationStatus: state.HeaderReducer.newNotificationStatus,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    logoutService: (requestData) =>
      dispatch(loginAction.logoutService(requestData)),
    setSelectedTab: (requestData) =>
      dispatch(profileTabAction.setSelectedTab(requestData)),
    getHeaderData: (requestData) =>
      dispatch(headerAction.getHeaderData(requestData)),
    getNotificationData: (requestData) =>
      dispatch(headerAction.getNotificationData(requestData)),
    readNotificationData: (requestData) =>
      dispatch(headerAction.readNotificationData(requestData)),
    getOrientationService: () => dispatch(searchAction.getOrientationService()),
    getCountryService: () => dispatch(searchAction.getCountryService()),
    getAgeRangeService: () => dispatch(searchAction.getAgeRangeService()),
    getBodyTypeService: () => dispatch(searchAction.getBodyTypeService()),
    getHairColorService: () => dispatch(searchAction.getHairColorService()),
    getEyeListService: () => dispatch(searchAction.getEyeListService()),
    getPiercingsService: () => dispatch(searchAction.getPiercingsService()),
    getTattoosListService: () => dispatch(searchAction.getTattoosListService()),
    getStateService: (requestData) =>
      dispatch(searchAction.getStateService(requestData)),
    getCityService: (requestData) =>
      dispatch(searchAction.getCityService(requestData)),
    advancedSearchService: (requestData, token) =>
      dispatch(searchAction.advancedSearchService(requestData, token)),
    generalSearchService: (requestData, token) =>
      dispatch(searchAction.generalSearchService(requestData, token)),
    setCommonStatusNull: () => dispatch(commonAction.setCommonStatusNull()),
    getStickersService: (requestData) =>
      dispatch(stickersAction.getStickersService(requestData)),
    getChatHistoryData: (requestData) =>
      dispatch(chatAction.getChatHistoryData(requestData)),
    chatActiveInActive: (token, activeStatus) =>
      dispatch(chatAction.chatActiveInActive(token, activeStatus)),
    getFooterData: (token) => dispatch(commonAction.getFooterData(token)),
    userGeneralDetailsService: (requestData) =>
      dispatch(userDetailsAction.userGeneralDetailsService(requestData)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(UserHeader);
