import React, { useEffect, useState } from 'react';
import {
  Grid,
  Card,
  CardContent,
  Typography,
  ListItemAvatar,
  ListItem,
  List,
  Avatar,
  ListItemText,
  styled,
  Badge,
  Divider,
  Box,
  TextField,
  InputAdornment,
  Snackbar,
  Alert,
  Chip,
} from '@mui/material';
import { useHistory } from 'react-router';
import useStyles from '../../styles/myProfile';
import { ReactComponent as LikeIcon2 } from '../icons/orange_like.svg';
import { ReactComponent as HeartIcon } from '../icons/ico_heart_active.svg';
import { ReactComponent as CommentLikeIcon } from '../icons/ico_like.svg';
import { ReactComponent as LikePurpleIcon } from '../icons/ico_like_purple.svg';
import * as memberHistoryAction from '../../redux/action/memberHistoryAction';
import { ReactComponent as ChatIcon } from '../icons/ico_comment.svg';
import { connect } from 'react-redux';
import { getTimeStamp } from '../../utils';
import * as galleryAction from '../../redux/action/galleryAction';
import { ReactComponent as SubmitIcon } from '../icons/ico_btn_submit.svg';
import Loader from '../../utils/loader';
import { ReactComponent as LikeGreenIcon } from '../icons/gallery_liked_green.svg';
import { getIsMobile } from '../../utils';
import { useTranslation } from 'react-i18next';
const StyledBadge = styled(Badge)(({ theme }) => ({
  '& .MuiBadge-badge': {
    backgroundColor: '#44b700',
    color: '#44b700',
    boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
  },
}));

const MemberHistory = (props) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const history = useHistory();
  const isMobile = getIsMobile()
  const [replyCommentInput, setReplyComment] = useState({
    id: '',
    replyInputStatus: false,
  });
  const [commentData, setCommentData] = useState('');
  const [open, setOpen] = React.useState(false);
  const [responseData, setResponseData] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    setIsLoading(true);
    props.getMemberHistoryData(localStorage.getItem('userToken'));
    setTimeout(() => {
      setIsLoading(false);
    }, 5000);
  }, []);

  useEffect(() => {
    if (props.memberHistoryData.length > 0) {
      setIsLoading(false);
    }
  }, [props.memberHistoryData]);

  const getIcon = (activityType) => {
    let icon;
    if (activityType === 1) {
      icon = <LikePurpleIcon style={{ marginLeft: '5px' }} />;
    } else if (activityType === 2) {
      icon = <LikeIcon2 style={{ marginLeft: '5px' }} />;
    } else if (activityType === 3) {
    } else if (activityType === 4) {
      icon = <HeartIcon style={{ marginLeft: '5px' }} />;
    }
    return icon;
  };
  const getString = (activityType,username) => {
    let result = '';
    if (activityType===1) {
      result = <Typography component="p" variant="body2" className={classes.text} sx={{ mt: 1.5, ml: 0.5 }}>{t("superLikedYourProfile1")}{username} {t("superLikedYourProfile2")}</Typography>;
    }else if (activityType===2) {
      result = <Typography component="p" variant="body2" className={classes.text} sx={{ mt: 1.5, ml: 0.5 }}>{username}{t("likedYourPhoto")}</Typography>;
    }else if (activityType===3) {
      
    }else if (activityType===4) {
      result = <Typography component="p" variant="body2" className={classes.text} sx={{ mt: 1.5, ml: 0.5 }}>{username} {t("addedYouAsFavourite")}</Typography>;
    }else if (activityType===11) {
      result = <Typography component="p" variant="body2" className={classes.text} sx={{ mt: 1.5, ml: 0.5 }}>{username} {t("visitedYourProfile")}</Typography>;
    }else if (activityType===6) {

    }
    return result;
  };
  const likeGalleryComments = (item) => {
    let data = new FormData();
    data.append('comment_id', item.comment_id);
    data.append('action', item.comment_is_liked === 1 ? 0 : 1);
    props.LikeForComment(data, localStorage.getItem('userToken'));
  };
  const handleCommentReply = (item) => {
    if (item.comment_id != replyCommentInput.id) {
      setReplyComment({ id: item.comment_id, replyInputStatus: true });
    } else {
      setReplyComment({ id: '', replyInputStatus: false });
    }
  };
  const submitComment = (item) => {
    let datass = localStorage.getItem('userLevelData');
    let datas = JSON.parse(datass);
    let data = new FormData();
    data.append('member_id', datas.user_id);
    data.append('photo_id', item.photo_id);
    data.append('comment', commentData);
    data.append('parent_comment_id', item.comment_id);
    data.append('comment_level', 1);
    props.CommentOtherMemberPhotos(data, localStorage.getItem('userToken'));
  };
  const handleCommentChange = (e) => {
    if (e.target.name === 'comment') {
      setCommentData(e.target.value);
    }
  };
  useEffect(() => {
    if (props.likeForCommentStatus) {
      props.galleryActivity()
      setResponseData(t("likedSuccessfully"));
      props.getMemberHistoryData(localStorage.getItem('userToken'));
    }
  }, [props.likeForCommentStatus]);
  useEffect(() => {
    if (props.commentStatus) {
      props.galleryActivity()
      setOpen(true);
      setResponseData(props.commentStatus);
      setCommentData('');
      setReplyComment({ id: '', replyInputStatus: false });
    }
  }, [props.commentStatus]);
  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setResponseData('');
    setOpen(false);
  };
  return (
    <>
      <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
        <Alert onClose={handleClose} severity="success" sx={{ width: '100%' }}>
          {responseData}
        </Alert>
      </Snackbar>
      {isLoading ? <Loader /> : ''}
      <Card
        sx={{
          borderRadius: '10px',
          minHeight: '823px',
          maxHeight: '823px',
          overflow: 'auto',
        }}
      >
        <CardContent sx={{ pl: '0px !important', pr: '0px !important' }}>
          <Grid
            container
            justifyContent="space-between"
          // sx={{ margin: '20px 0px' }}
          >
            <Typography
              variant="body1"
              component="h6"
              sx={{ pl: '24px !important', pr: '24px !important', mb: 2 }}
            >
              {t("memberHistory")}
            </Typography>
          </Grid>
          <Divider sx={{ p: 0 }} />
          <List >
            {props.memberHistoryData.map((item) => (
              <>
                <ListItem sx={{ pb: 0 }} key={item}>
                  <ListItemAvatar>
                    {item.is_online ? (
                      <StyledBadge
                        overlap="circular"
                        anchorOrigin={{
                          vertical: 'bottom',
                          horizontal: 'right',
                        }}
                        variant="dot"
                      >
                        <Avatar
                          alt="avatar"
                          src={`${item.pictures}`}
                          sx={{
                            height: '70px',
                            width: '70px',
                            marginRight: '3px',
                            marginLeft: '10px',
                            border: '2px solid #F7B573',
                            cursor: 'pointer',
                          }}
                          onClick={() => history.push('/user-profile')}
                        />
                      </StyledBadge>
                    ) : (
                      <Avatar
                        alt="avatar"
                        src={`${item.pictures}`}
                        sx={{
                          height: '70px',
                          width: '70px',
                          marginRight: '3px',
                          marginLeft: '10px',
                          border: '2px solid #F7B573',
                          cursor: 'pointer',
                        }}
                        onClick={() => history.push('/user-profile')}
                      />
                    )}
                  </ListItemAvatar>
                  <ListItemText
                    sx={{ p: 0, width: "69%" }}
                    primary={
                      <Grid
                        container
                        alignItems="center"
                        justifyContent="space-between"
                      >
                        <Typography
                          variant="body1"
                          component="h6"
                          sx={{ p: 0 }}
                        >
                          {item.username}
                        </Typography>
                      </Grid>
                    }
                    secondary={
                      <Grid
                        container
                        alignItems="center"
                      // justifyContent="space-between"
                      >
                        {item.comment != '' ? (
                          ''
                        ) : (
                          <>{getIcon(item.activity_type)}</>
                        )}
                        {item.comment != '' ? (
                          <Box
                            sx={{
                              ml: 2,
                              width: 'auto',
                              height: 'auto',
                              backgroundColor: '#1d68944d',
                              borderRadius: '5px',
                              position: 'relative',
                              '&:after': {
                                content: '""',
                                position: 'absolute',
                                left: 0,
                                top: '50%',
                                width: 0,
                                height: 0,
                                border: '8px solid transparent',
                                borderRightColor: ' #1d68944d',
                                borderLeft: 0,
                                borderTop: 0,
                                marginTop: ' -5.5px',
                                marginLeft: '-7px',
                              },
                            }}
                          >
                            <Typography
                              component="p"
                              variant="body2"
                              color="#1b0121"
                              sx={{

                                color: '#1b0121 !important',
                                m: '12px',
                                fontWeight: 'bold'
                              }}
                            >
                              {item.comment}
                            </Typography>
                          </Box>
                        ) : (
                         getString(item.activity_type,item.username)
                        )}
                        <br />
                      </Grid>
                    }
                  />
                  <ListItemText
                    sx={{ display: 'inline-grid', justifyContent: 'end' }}
                    primary={
                      <Typography
                        variant="subtitle2"
                        component="p"
                        sx={{
                          fontWeight: 600,
                          color: '#7a797a',
                          marginLeft: '6px',
                          // pl: '24px !important',
                          // pr: '24px !important',
                          display: 'flex',
                          justifyContent: 'end'
                        }}
                      >
                        {getTimeStamp(item.created_at)}
                      </Typography>
                    }
                    secondary={
                      item.image != '' ? (
                        <Grid sx={{ display: 'flex', justifyContent: 'end' }}>
                          <img
                            src={`${item.image}`}
                            alt=""
                            style={{ borderRadius: '10px', width: '20%' }}
                          />
                        </Grid>
                      ) : (
                        ''
                      )
                    }
                  />
                </ListItem>
                <Grid container ><Grid item xs={8} sm={8} md={8} lg={8} sx={!isMobile ? { ml: 15, mr: 15 } : { ml: 15, mr: 5 }}>
                  {item.comment != '' ? (
                    <>
                      {' '}
                      <Chip sx={{
                        mb: 1, background: 'none', '&:hover': {
                          background: 'none'
                        },
                      }} icon={<ChatIcon />} label={t("reply")} onClick={() => handleCommentReply(item)} />
                      {/* <ChatIcon
                              onClick={() => handleCommentReply(item)}
                              className={classes.icons}
                              style={{ cursor: 'pointer',marginLeft:'5px' }}
                            />
                            <span
                              className={classes.counts}
                              onClick={() => handleCommentReply(item)}
                              style={{ cursor: 'pointer' }}
                            >
                              Reply
                            </span> */}
                    </>
                  ) : (
                    ''
                  )}
                  {item.comment != '' ? (
                    item.comment_is_liked ? (
                      <Chip sx={{
                        mb: 1, background: 'none',
                        '&:hover': {
                          background: 'none'
                        },
                      }} icon={<LikeGreenIcon />} label={t("liked")}
                        onClick={() => likeGalleryComments(item)} />

                      // <LikeGreenIcon
                      //   onClick={() => likeGalleryComments(item)}
                      //   className={classes.icons}
                      //   style={{
                      //     cursor: 'pointer',
                      //     marginLeft: '10px',
                      //   }}
                      // />
                    ) : (
                      // <CommentLikeIcon
                      //   onClick={() => likeGalleryComments(item)}
                      //   className={classes.icons}
                      //   style={{
                      //     cursor: 'pointer',
                      //     marginLeft: '10px',

                      //   }}
                      // />
                      <Chip sx={{
                        mb: 1, background: 'none', '&:hover': {
                          background: 'none'
                        },
                      }} icon={<CommentLikeIcon />} label="Like"
                        onClick={() => likeGalleryComments(item)} />
                    )
                  ) : (
                    ''
                  )}
                  {replyCommentInput.id === item.comment_id &&
                    replyCommentInput.replyInputStatus ? (
                    <TextField
                      variant="outlined"
                      name="comment"
                      placeholder={t("typeYourCommentHere")}
                      onChange={(e) => handleCommentChange(e)}
                      value={commentData}
                      InputProps={{
                        classes: {
                          input: classes.commentInputFields,
                          root: classes.inputRoot,
                        },
                        disableUnderline: true,
                        endAdornment: (
                          <InputAdornment position="end">
                            <SubmitIcon
                              style={{ cursor: 'pointer' }}
                              onClick={commentData.length > 0 ? () => submitComment(item) : ''}
                            />
                          </InputAdornment>
                        ),
                      }}
                      fullWidth
                      sx={{ marginTop: '20px' }}
                    />
                  ) : (
                    ''
                  )}
                </Grid></Grid>
                <Divider />
              </>
            ))}
          </List>
        </CardContent>
      </Card>
    </>
  );
};
const mapStateToProps = (state) => {
  return {
    memberHistoryData: state.MemberHistoryReducer.memberHistoryData,
    likeForCommentStatus: state.GalleryReducer.likeForCommentStatus,
    commentStatus: state.GalleryReducer.commentStatus,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getMemberHistoryData: (requestData) =>
      dispatch(memberHistoryAction.getMemberHistoryData(requestData)),
    LikeForComment: (data, token) =>
      dispatch(galleryAction.LikeForComment(data, token)),
    CommentOtherMemberPhotos: (data, token) =>
      dispatch(galleryAction.CommentOtherMemberPhotos(data, token)),
    galleryActivity: () => dispatch(galleryAction.profileChangeStatusNull()),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(MemberHistory);
