import { handleActions } from 'redux-actions';

const CookiePolicyReducer = {
    getCookieMessage: null,
    postCookieMessage: null,
};

const reducer = handleActions(
    {
        CHECK_COOKIES_INITIATE: (state) => {
            return {
                ...state,
            };
        },
        CHECK_COOKIES__SUCCESS: (state, action) => {
            return {
                ...state,
                postCookieMessage: action.payload,
            };
        },
        CHECK_COOKIES__FAILURE: (state) => {
            return {
                ...state,
                postCookieMessage: null,
            };
        },
        POST_COOKIES_INITIATE: (state) => {
            return {
                ...state,
            };
        },
        POST_COOKIES_SUCCESS: (state, action) => {
            return {
                ...state,
                getCookieMessage: action.payload,
            };
        },
        POST_COOKIES_FAILURE: (state) => {
            return {
                ...state,
                getCookieMessage: null,
            };
        },
    },
    CookiePolicyReducer
);
export default reducer;
