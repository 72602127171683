import { handleActions } from 'redux-actions';

const HeaderReducer = {
  headerData: {},
  notificationData:[],
  notificationReadMessage:'',
  newNotificationStatus:null,
};

const reducer = handleActions(
  {
    GET_HEADER_DATA_INITIATE: (state) => {
      return {
        ...state,
      };
    },
    GET_HEADER_DATA_SUCCESS: (state, action) => {
      return {
        ...state,
        headerData: action.payload,
       
      };
    },
    GET_HEADER_DATA_FAILURE: (state, action) => {
      return {
        ...state,
        headerData:{}
      };
    },
    GET_NOTIFICATION_DATA_INITIATE: (state) => {
      return {
        ...state,
      };
    },
    GET_NOTIFICATION_DATA_SUCCESS: (state, action) => {
      return {
        ...state,
        notificationData: action.payload.message,
        newNotificationStatus:action.payload.readed_status
      };
    },
    GET_NOTIFICATION_DATA_FAILURE: (state, action) => {
      return {
        ...state,
        notificationData:[]
      };
    },
    READ_NOTIFICATION_INITIATE: (state) => {
      return {
        ...state,
      };
    },
    READ_NOTIFICATION_SUCCESS: (state, action) => {
      return {
        ...state,
        notificationReadMessage: action.payload,
      };
    },
    READ_NOTIFICATION_FAILURE: (state, action) => {
      return {
        ...state,
        notificationReadMessage:''
      };
    }
  },
  HeaderReducer
);
export default reducer;
