import { makeStyles } from '@mui/styles';
const useStyles = makeStyles((theme) => ({
    containerClass: {
        marginTop: "30px",
        marginBottom: '60px',
        backgroundColor: '#0000ff05',
        padding: '0px !important',
        '@media(max-width: 1024px)': {
            paddingLeft: '10px !important',
            paddingRight: '10px !important',
        }
    },
    containerHeading: {
        marginTop: "30px",
        padding: '0px !important',
        '@media(max-width: 1024px)': {
            paddingLeft: '10px !important',
            paddingRight: '10px !important',
        }
    },
    accordianClass: {
        marginTop: "20px",
        borderRadius: "10px !important",
        borderTopLeftRadius: "10px !important",
        borderTopRightRadius: "10px !important",
        backgroundColor: '#fff',
        boxShadow: "0px 1px 3px 0px rgb(0 0 0 / 6%), 0px 1px 3px 0px rgb(0 0 0 / 6%) , 0px 1px 3px 0px rgb(0 0 0 / 6%) ,0px 1px 3px 0px rgb(0 0 0 / 6%) !important",
        '&::before': {
            backgroundColor: " rgba(0, 0, 0, 0) !important",
        }
    },
    newQuestion: {
        padding: '0px !important',
        margin: 'opx !important',
        borderBottom: "none !important",
    },
    textfield: {
        width: "100%",
        marginTop: "20px !important",
        background: '#dcdcdc4a',
        '@media(max-width: 320px)': {
            width: "9rem",
        },
        '@media(max-width: 414px)': {
            width: "13rem",
        },
        '@media(width: 768px)': {
            width: "20rem",
        },
        '&:hover': {
            borderColor: " none !important",
        }
    },

    continueButton: {
        float: 'right',
        marginTop: '0px !important',
        marginRight: "10px !important"
    },
    checkbox: {
        color: "rgb(43 41 41 / 25%) !important"
    },
    qStatus: {
        float: 'left',
        marginTop: '0px !important',
        marginLeft: "85px !important"
    },
}));

export default useStyles;
