import axios from 'axios';
import { createAction } from 'redux-actions';

export const getHeaderDataInitiate = createAction('GET_HEADER_DATA_INITIATE');
export const getHeaderDataSuccess = createAction('GET_HEADER_DATA_SUCCESS');
export const getHeaderDataFailure = createAction('GET_HEADER_DATA_FAILURE');

export const getNotificationDataInitiate = createAction('GET_NOTIFICATION_DATA_INITIATE');
export const getNotificationDataSuccess = createAction('GET_NOTIFICATION_DATA_SUCCESS');
export const getNotificationDataFailure = createAction('GET_NOTIFICATION_DATA_FAILURE');

export const readNotificationInitiate = createAction('READ_NOTIFICATION_INITIATE');
export const readNotificationSuccess = createAction('READ_NOTIFICATION_SUCCESS');
export const readNotificationFailure = createAction('READ_NOTIFICATION_FAILURE');

export const getHeaderData = (requestData) => {
    const config = {
      headers: {
        Authorization: `Bearer ${requestData}`,
      },
    };
    const bodyParameters = {
        key: "value"
      };
    return (dispatch) => {
      dispatch(getHeaderDataInitiate());
      axios
        .post(`${process.env.REACT_APP_BACKEND_URL }profile_drop_down`,bodyParameters,config)
        .then((response) => {
          if (response.data.STATUS) {                                                                                 
            dispatch(getHeaderDataSuccess(response.data.result));
          } else {
            dispatch(getHeaderDataFailure());
          }
        })
        .catch((error) => {
          if (error.response.status === 401) {
            localStorage.clear();
            window.location.replace('/session-expired');
          }
          else {
          dispatch(getHeaderDataFailure());
          }
        });
    };
  };
  
  export const getNotificationData = (requestData) => {
    const config = {
      headers: {
        Authorization: `Bearer ${requestData}`,
      },
    };
    // const bodyParameters = {
    //     key: "value"
    //   };
    return (dispatch) => {
      dispatch(getNotificationDataInitiate());
      axios
        .get(`${process.env.REACT_APP_BACKEND_URL }notifications-list`,config)
        .then((response) => {
          if (response.data.STATUS) {                                                                                 
            dispatch(getNotificationDataSuccess(response.data));
          } else {
            dispatch(getNotificationDataFailure());
          }
        })
        .catch((error) => {
          if (error.response.status === 401) {
            localStorage.clear();
            window.location.replace('/session-expired');
          }
          else {
          dispatch(getNotificationDataFailure());
          }
        });
    };
  };


  export const readNotificationData = (requestData) => {
    const config = {
      headers: {
        Authorization: `Bearer ${requestData}`,
      },
    };
    // const bodyParameters = {
    //     key: "value"
    //   };
    return (dispatch) => {
      dispatch(readNotificationInitiate());
      axios
        .get(`${process.env.REACT_APP_BACKEND_URL }all-mark-as-read`,config)
        .then((response) => {
          if (response.data.STATUS) {                                                                                 
            dispatch(readNotificationSuccess(response.data.message));
          } else {
            dispatch(readNotificationFailure());
          }
        })
        .catch((error) => {
          if (error.response.status === 401) {
            localStorage.clear();
            window.location.replace('/session-expired');
          }
          else {
          dispatch(readNotificationFailure());
          }
        });
    };
  };