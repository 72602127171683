import { handleActions } from "redux-actions"
import { API_STATUS } from "../../../constant"

const ForgotPasswordReducer ={
    apiStatus:-1,
    data:{},
    userToken:'',
}

const reducer = handleActions(
    {
        FORGOT_PASSWORD_INITIATE:(state)=>{
      return {
          ...state,
          apiStatus:API_STATUS.initiated,
      }
  },
  FORGOT_PASSWORD_SUCCESS:(state,action)=>{
    return {
        ...state,
        data:action.payload,
        userToken:action.payload.auth_token,
        apiStatus:API_STATUS.success,
    }
},
FORGOT_PASSWORD_FAILURE:(state)=>{
    return {
        ...state,
        data:{},
        apiStatus:API_STATUS.failed,
    }
},
FORGOT_PASSWORD_NULL:(state)=>{
    return {
        ...state,
        data:{},
        apiStatus:API_STATUS.failed,
    }
},
    },
    ForgotPasswordReducer
    );
    export default reducer