
import { createAction } from 'redux-actions';

export const tabSuccess = createAction('PROFILE_TAB_SUCCESS');

export const setSelectedTab = (tab) => {
    return (dispatch)=>{
  dispatch(tabSuccess( tab ));
    }
};
