import { handleActions } from 'redux-actions';


const ChestReducer = {
  chestData:[],
  unlockStatus:null,
  prize:null,
  chestUnlockReqCoins:0
};

const reducer = handleActions(
  {
    GET_CHEST_DATA_INITIATE: (state) => {
      return {
        ...state,
      };
    },
    GET_CHEST_DATA_SUCCESS: (state, action) => {
      return {
        ...state,
        chestData:action.payload.message,
        chestUnlockReqCoins:action.payload.required_coins
      };
    },
    GET_CHEST_DATA_FAILURE: (state) => {
      return {
        ...state,
        chestData:[],
        chestUnlockReqCoins:0
      };
    },
    UNLOCK_CHEST_DATA_INITIATE: (state) => {
        return {
          ...state,
        };
      },
      UNLOCK_CHEST_DATA_SUCCESS: (state, action) => {
        return {
          ...state,
          unlockStatus:action.payload.STATUS,
          prize:action.payload.gifts
        };
      },
      UNLOCK_CHEST_DATA_FAILURE: (state,action) => {
        return {
          ...state,
          unlockStatus:false,
          prize:''
        };
      },
      
      SET_UNLOCK_STATUS_SUCCESS: (state,action) => {
        return {
          ...state,
          unlockStatus:null,
          chestData:[]
        };
      },
  },
  ChestReducer
);
export default reducer;
