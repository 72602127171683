import axios from 'axios';
import { createAction } from 'redux-actions';

export const GetFAQDataInitiate = createAction('GET_FAQ_INITIATE');
export const GetFAQDataSuccess = createAction('GET_FAQ_SUCCESS');
export const GetFAQDataFailure = createAction('GET_FAQ_FAILURE');


export const postNewQuestionInitiate=createAction("POST_NEW_QUESTION_INITIATE");
export const postNewQuestionSuccess=createAction("POST_NEW_QUESTION_SUCCESS");
export const postNewQuestionFailure=createAction("POST_NEW_QUESTION_FAILURE");



export const getFaqService = () => {
  return (dispatch) => {
    dispatch(GetFAQDataInitiate());
    axios
      .get(`${process.env.REACT_APP_BACKEND_URL }faqs`)
      .then((response) => {
        if (response.data.STATUS) {
          dispatch(GetFAQDataSuccess(response.data.message));
        } else {
          dispatch(GetFAQDataFailure());
        }
      })
      .catch((error) => {
        dispatch(GetFAQDataFailure());
      });
  };
};



export const postNewQuestionService = (requestData,token)=>{
  const config = {
   headers: {
      Authorization: `Bearer ${token}`
   }
 };
   return (dispatch)=>{
     dispatch(postNewQuestionInitiate());
     axios.post( `${process.env.REACT_APP_BACKEND_URL }create_faq_ticket`,requestData,config)
       .then((response)=>{
           if(response.data.STATUS) { 
             dispatch(postNewQuestionSuccess(response.data.message));
          }else{
             dispatch(postNewQuestionFailure(response.data.message));
          }
       })
       .catch((error)=>{
        if (error.response.status === 401) {
          localStorage.clear();
          window.location.replace('/session-expired');
        }
        else {
         dispatch(postNewQuestionFailure());
        }
       })
 }
 }