import React, { useEffect,useState } from 'react';
import { useHistory } from 'react-router';
import {
  Grid,
  Card,
  Typography,
  Button,
  ListItemAvatar,
  ListItem,
  List,
  Avatar,
  ListItemText,
  styled,
  Badge,
  Divider,
} from '@mui/material';
import { ReactComponent as HeartIcon } from '../icons/ico_heart_active.svg';
import { ReactComponent as HeartInActiveIcon } from '../icons/ico heart inactive.svg';
import useStyles from '../../styles/myProfile';
import { connect } from 'react-redux';
import * as myFavoriteAction from '../../redux/action/myFavoriteAction';
import * as commonAction from '../../redux/action/commonAction';
import { getTimeStamp } from '../../utils';

import Loader from '../../utils/loader';
import { useTranslation } from 'react-i18next';

const StyledBadge = styled(Badge)(({ theme }) => ({
  '& .MuiBadge-badge': {
    backgroundColor: '#44b700',
    color: '#44b700',
    boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
    bottom: '18%',
    right: '18%',
  },
}));
const StyledBadgeGray = styled(Badge)(({ theme }) => ({
  '& .MuiBadge-badge': {
    backgroundColor: '#b1b5af',
    color: '#44b700',
    boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
    bottom: '18%',
    right: '18%',
  },
}));
const MyFavorites = (props) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);
  const [noData, setNodata] = useState(false);
  useEffect(() => {
    setIsLoading(true)
    props.favoriteData(localStorage.getItem('userToken'));
    setTimeout(() => {
      setIsLoading(false)
    }, 5000);
  }, []);

  useEffect(() => {
    // console.log(props.myFavoriteData);
    if(props.myFavoriteData.length>0){
      setIsLoading(false)
    }else{
      setTimeout(() => {
        setNodata(true)
      }, 7000);
    }
  }, [props.myFavoriteData]);

  const getUserData = (id) => {
    localStorage.setItem('currentID', id);
    props.getUserByID(localStorage.getItem('userToken'), id);
    history.push('/user-profile');
  };
  const handleFavorite=(item)=>{
    let data=new FormData();
    data.append('member_id',item.id)
    data.append('action',item.is_favourite===1?0:1)
  props.addorRemoveFavorite(data)
   }
   useEffect(() => {
    if(props.favoriteStatus){
      props.favoriteData(localStorage.getItem('userToken'));
    }
   }, [props.favoriteStatus])
  return (
    <Card sx={{ borderRadius: '10px',minHeight:'823px',maxHeight:'823px',overflow:'auto' }}>
  {isLoading ? <Loader /> : ''}
      <Grid container justifyContent="space-between" sx={{ padding: '24px' }}>
        <Typography variant="body1" component="h6">
         {t("myFavorites")}
        </Typography>
      </Grid>
      <Divider sx={{p:0}}/>
      <List sx={{ paddingBottom: '0px' }}>
        {props.myFavoriteData.length>0 ? (
          props.myFavoriteData.map((item) => {
            return (
              <div key={item}>
                <ListItem
                  key={item}
                  divider={true}
                  sx={{ padding: '10px 24px' }}
                >
                  {item.is_favourite===1?<HeartIcon className={classes.heartIcon} onClick={()=>handleFavorite(item)}/>:
                  <HeartInActiveIcon className={classes.heartIcon} onClick={()=>handleFavorite(item)}/>}
                  <ListItemAvatar>
                    {item.is_online === 1 ? (
                      <StyledBadge
                        overlap="circular"
                        anchorOrigin={{
                          vertical: 'bottom',
                          horizontal: 'right',
                        }}
                        variant="dot"
                      >
                        <Avatar
                          alt="avatar"
                          src={`${item.pictures}`}
                          sx={{
                            height: '70px',
                            width: '70px',
                            marginRight: '10px',
                            marginLeft: '10px',
                            border: '2px solid #F7B573',
                            cursor: 'pointer',
                          }}
                        />
                      </StyledBadge>
                    ) : (
                      
                      <StyledBadgeGray
                      overlap="circular"
                      anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'right',
                      }}
                      variant="dot"
                    >
                      <Avatar
                        alt="avatar"
                        src={`${item.pictures}`}
                        sx={{
                          height: '70px',
                          width: '70px',
                          marginRight: '10px',
                          marginLeft: '10px',
                          border: '2px solid #F7B573',
                          cursor: 'pointer',
                        }}
                      />
                      </StyledBadgeGray>
                    )}
                  </ListItemAvatar>
                  <ListItemText
                    primary={
                      <Grid
                        container
                        alignItems="center"
                        justifyContent="space-between"
                      >
                        <Typography variant="body1" component="h6">
                          {item.username}
                        </Typography>
                        <Typography
                          variant="subtitle2"
                          component="p"
                          sx={{
                            fontWeight: 600,
                            color: '#7a797a',
                            marginLeft: '6px',
                          }}
                        >
                        {getTimeStamp(item.created_at)}
                        </Typography>
                      </Grid>
                    }
                    secondary={
                      <Grid
                        container
                        alignItems="center"
                        justifyContent="space-between"
                      >
                        {item.is_favourite===1?<Typography
                          component="p"
                          variant="body2"
                          className={classes.text}
                          sx={{maxWidth:'70%'}}
                        >
                        {t("youAdded ")}{item.username}   {t("inToyourFavorite")}
                          <br />
                          <p style={{fontStyle: "italic",margin:'0px'}}>{t("toUnfavorite,pleaseClicktheHearticonAgain")}</p>
                        </Typography>:<Typography
                          component="p"
                          variant="body2"
                          className={classes.text}
                        >
                         {t("youRemoved")} {item.username} {t("fromYourFavourite")}
                          <br />
                          <p style={{fontStyle: "italic",margin:'0px'}}> {t("toFavoritePleaseClickTheHeartIconAgain")} </p>
                        </Typography>}
                        <Button
                          variant="contained"
                          className={classes.viewProfile}
                          sx={{
                            marginTop: '12px',
                            marginBottom: '10px',
                          }}
                          onClick={() => getUserData(item.id)}
                        >
                         {t("viewProfile")}
                        </Button>
                      </Grid>
                    }
                  />
                </ListItem>
              </div>
            );
          })
        ) : (
          <Typography variant="p" component="p" sx={{textAlign:'center',mb:5}}>
           {noData?t("noFavourites"):''}
          </Typography>
        )}
      </List>
      {/* </CardContent> */}
    </Card>
  );
};

const mapStateToProps = (state) => {
  return {
    myFavoriteData: state.MyFavoritesReducer.myFavoriteData,
    favoriteStatus:state.CommonReducer.favoriteStatus,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    favoriteData: (token) => dispatch(myFavoriteAction.myFavorite(token)),
    getUserByID: (token, requestData) =>
      dispatch(commonAction.getUserByID(token, requestData)),
      addorRemoveFavorite: (requestData) =>
      dispatch(commonAction.favoriteOtherMember(requestData)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(MyFavorites);
