import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
  chatContainer: {
    background: `url(/assets/images/pattern_bg.jpg) repeat`,
    backgroundSize: '100% 100%',
    // minHeight: '50vh',
    position: 'relative',
    // display: "flex",
  },
  voiceContainer: {
    background: `url(/assets/images/pattern_bg.jpg) repeat`,
    backgroundSize: '100% 100%',
    position: 'relative',
    display: 'flex',
    marginBottom: '50px',
  },
  messageContainer: {
    background: `url(/assets/images/pattern_bg.jpg) repeat`,
    backgroundSize: '100% 100%',
    position: 'relative',
    display: 'flex',
  },
  descmsg: {
    color: '#7a797a !important',
    fontSize: '12px !important',
    fontWeight: '600 !important',
  },
  receiverMsg: {
    backgroundColor: `#2887C9`,
    padding: '10px',
    float: 'right',
    borderRadius: '10px',
    marginTop: '20px',
    width: '300px',
    wordWrap:" break-word !important",
	position:'relative',
	"&:after": {
	content: '""',
	position: "absolute",
	right:"10px",
	top: "50%",
	width: 0,
	height: 0,
	border: "20px solid transparent",
	borderLeftColor: "#2887C9",
	borderRight: 0,
	borderTop: 0,
	marginTop: "-10px",
	marginRight:" -20px",
	}
  },
  senderMsg: {
    backgroundColor: `#ffffff`,
    padding: '10px',
    borderRadius: '5px',
    width: 'auto',
    marginTop: '20px',
	position: "relative",
  wordWrap:" break-word !important",
	"&:after": {
		content: '""',
	position: "absolute",
	left: 0,
	top: "50%",
	width: 0,
	height: 0,
	border: "20px solid transparent",
	borderRightColor:" #ffffff",
	borderLeft: 0,
	borderTop: 0,
	marginTop:" -10px",
	marginLeft: "-20px",
	}
  },
  inputFields: {
    padding: '10px !important',
  },
  inputRoot: {
    backgroundColor: '#ffffff !important',
    borderRadius: '4px !important',
    fontSize: '12px !important',
    lineHeight: '21px !important',
    color: `#7a797a !important`,
    fontWeight: `${600} !important`,
    marginLeft: '10px',
    marginRight: '10px',
  },
  startAdorn: {
    marginTop: '0px !important',
  },
  closeIcon: {
    position: 'absolute',
    right: ' 25px',
    top: '17px',
    color: '#9F9F9F',
    cursor: 'pointer',
  },
  arrowIcon: {
    position: 'relative',
    top: '7px',
    right: '5px',
  },
  uploadDragableUpload: {
    width: '125px',
    height: '150px',
    position: 'relative',
    margin: '20px auto',
  },
  uploadPlaceholderCt: {
    width: '125px',
    height: '150px',
    borderRadius: '10px',
    backgroundColor: '#DDE5E8',
    position: 'relative',
    overflow: 'hidden',
    '& img': {
      width: '100%',
      height: '150px',
      objectFit: 'cover',
    },
    '&:hover': {
      backgroundColor: '#000',
      '& img': {
        opacity: 0.5,
      },
      cursor: 'pointer',
      '& $makeProfilePicText': {
        display: 'block',
      },
    },
    '&:focus': {
      outline: 'none',
    },
  },
  addImage: {
    position: 'absolute',
    top: '45%',
    left: '40%',
    color: theme.palette.primary.main,
    cursor: 'pointer',
  },
  StickerList: {
    overflowY: 'auto',
    margin: 0,
    padding: 0,
    listStyle: 'none',
    height: '100%',
    '&::-webkit-scrollbar': {
      width: '3em',
      height: '3em',
    },
    '&::-webkit-scrollbar-track': {
      boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
      webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
    },
    '&::-webkit-scrollbar-thumb': {
      background: `url(/assets/images/pattern_bg.jpg) repeat`,
      outline: '1px solid slategrey',
    },
  },
  voiceMessangeSender: {
    backgroundColor: `#2887C9`,
    padding: '10px',
    float: 'right',
    borderRadius: '10px',
    marginTop: '20px',
    width: '500px',
	position:'relative',
	"&:after": {
	content: '""',
	position: "absolute",
	right:"10px",
	top: "50%",
	width: 0,
	height: 0,
	border: "20px solid transparent",
	borderLeftColor: "#2887C9",
	borderRight: 0,
	borderTop: 0,
	marginTop: "-10px",
	marginRight:" -20px",
	}
  },

triRightLeftInafter: {
	position:'relative',
	"&:after": {
	  content: '""',
	position: "absolute",
	left: 0,
	top: "50%",
	width: 0,
	height: 0,
	border: "20px solid transparent",
	borderRightColor:" #ffffff",
	borderLeft: 0,
	borderTop: 0,
	marginTop:" -10px",
	marginLeft: "-20px",
  }
},
heartIcon:{
  cursor:'pointer'
}

}));

export default useStyles;
