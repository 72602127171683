import React,{useEffect,useState}from 'react';
import { CircularProgress } from '@mui/material';
import Header from '../components/layout/header';
import UserHeader from '../components/layout/userHeader';
import Footer from '../components/layout/footer';
import LoadingOverlay from 'react-loading-overlay';

const AboutUsLayout = ({ loading, children }) => {

    const [layout,setLayout]=useState(false)
      useEffect(() => {
    if (localStorage.getItem('userToken') === null) {
      setLayout(false)
    }else{
        setLayout(true)

    }
  }, [localStorage.getItem('userToken')])

	return (
		<LoadingOverlay
			active={loading}
			spinner={<CircularProgress />}
			text="Please wait..."
			styles={{
				content: (base) => ({
					...base,
					color: '#919191',
					marginTop: '50vh',
				}),
				overlay: (base) => ({
					...base,
					zIndex: 9999,
					color: '#919191',
					backgroundColor: 'rgba(255,255,255,.5)',
				}),
			}}
		>
			{layout?<UserHeader />:<Header />}
			{children}
			<Footer />
		</LoadingOverlay>
	);
};

export default AboutUsLayout;
