import React from 'react';
import { Button, Grid, Typography } from '@mui/material';
import useStyles from '../../styles/landingPage';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
const Banner = () => {
	const classes = useStyles();
	const { t } = useTranslation();

	return (
		<div className={classes.bannerBgImage}>
			<Typography variant="h1" sx={{ textTransform: "none" }}>
				{t("findYourperfectMatch")}
			</Typography>
			{/* <Typography variant="h5" component="h5" sx={{ marginTop: '15px' }}>
				Consectetur adipiscing elit. In necum aliquet tristique.
			</Typography> */}
			<Grid container sx={{ marginTop: '30px' }}>
				<Button variant="contained" color="primary">
					<Link
						to="/register"
						style={{ textDecoration: 'none', color: '#fff',fontWeight:"bold" }}
					>
						{t("joinNow")}
					</Link>
				</Button>
				{/* <Link  to="#">
					<img
						src="/assets/images/cta_google_play.png"
						alt="google play"
						style={{ marginRight: '10px' }}
						className={classes.playstoreButton}
					/>
				</Link>
				<Link  to="#">
					<img
						src="/assets/images/cta_apple.png"
						alt="apple store"
						className={classes.playstoreButton}
					/>
				</Link> */}
			</Grid>
		</div>
	);
};
export default Banner;
