import axios from "axios";
import {createAction} from "redux-actions";

export const aboutusInitiate=createAction("ABOUT_US_INITIATE");
export const aboutusSuccess=createAction("ABOUT_US_SUCCESS");
export const aboutusFailure=createAction("ABOUT_US_FAILURE");

export const contactusInitiate=createAction("CONTACT_US_INITIATE");
export const contactusSuccess=createAction("CONTACT_US_SUCCESS");
export const contactusFailure=createAction("CONTACT_US_FAILURE");

export const privacyPolicyInitiate=createAction("PRIVACY_POLICY_INITIATE");
export const privacyPolicySuccess=createAction("PRIVACY_POLICY_SUCCESS");
export const privacyPolicyFailure=createAction("PRIVACY_POLICY_FAILURE");


export const termsConditionInitiate=createAction("TERMS_CONDITION_INITIATE");
export const termsConditionSuccess=createAction("TERMS_CONDITION_SUCCESS");
export const termsConditionFailure=createAction("TERMS_CONDITION_FAILURE");

export const imprintInitiate=createAction("IMPRINT_INITIATE");
export const imprintSuccess=createAction("IMPRINT_SUCCESS");
export const imprintFailure=createAction("IMPRINT_FAILURE");

export const aboutusService = ()=>{
    return (dispatch)=>{
        dispatch(aboutusInitiate());
        axios.get(`${process.env.REACT_APP_BACKEND_URL }about_us`,)
          .then((response)=>{
              if(response.data.STATUS===true||response.data.STATUS==="true") {
                dispatch(aboutusSuccess(response.data.result));
             }else{
                dispatch(aboutusFailure());
             }
          })
          .catch((error)=>{
            dispatch(aboutusFailure());
          })
    }
}

export const cookiePolicyService = ()=>{
    return (dispatch)=>{
        dispatch(contactusInitiate());
        axios.get(`${process.env.REACT_APP_BACKEND_URL }cookie_policy`,)
          .then((response)=>{
              if(response.data.STATUS===true||response.data.STATUS==="true") {
                dispatch(contactusSuccess(response.data.result));
             }else{
                dispatch(contactusFailure());
             }
          })
          .catch((error)=>{
            dispatch(contactusFailure());
          })
    }
}

export const privacyPolicyService = ()=>{
    return (dispatch)=>{
        dispatch(contactusInitiate());
        axios.get(`${process.env.REACT_APP_BACKEND_URL }privacy_policy`,)
          .then((response)=>{
              if(response.data.STATUS===true||response.data.STATUS==="true") {
                dispatch(privacyPolicySuccess(response.data.result));
             }else{
                dispatch(privacyPolicyFailure());
             }
          })
          .catch((error)=>{
            dispatch(privacyPolicyFailure());
          })
    }
}


export const termsOfService = ()=>{
    return (dispatch)=>{
        dispatch(termsConditionInitiate());
        axios.get(`${process.env.REACT_APP_BACKEND_URL }terms_conditions`,)
          .then((response)=>{
              if(response.data.STATUS===true||response.data.STATUS==="true") {
                dispatch(termsConditionSuccess(response.data.result));
             }else{
                dispatch(termsConditionFailure());
             }
          })
          .catch((error)=>{
            dispatch(termsConditionFailure());
          })
    }
}

export const imprintService = ()=>{
  return (dispatch)=>{
      dispatch(imprintInitiate());
      axios.get(`${process.env.REACT_APP_BACKEND_URL }imprint`,)
        .then((response)=>{
            if(response.data.STATUS===true||response.data.STATUS==="true") {
              dispatch(imprintSuccess(response.data.result));
           }else{
              dispatch(imprintFailure());
           }
        })
        .catch((error)=>{
          dispatch(imprintFailure());
        })
  }
}