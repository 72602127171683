import axios from "axios";
import {createAction} from "redux-actions";

export const GetMemberHistoryInitiate=createAction("GET_MEMBER_HISTORY_INITIATE");
export const GetMemberHistorySuccess=createAction("GET_MEMBER_HISTORY_SUCCESS");
export const GetMemberHistoryFailure=createAction("GET_MEMBER_HISTORY_FAILURE");

export const getMemberHistoryData = (requestData)=>{
    const config = {
     headers: {
        Authorization: `Bearer ${requestData}`
     }
   };
     return (dispatch)=>{
       dispatch(GetMemberHistoryInitiate());
       axios.get( `${process.env.REACT_APP_BACKEND_URL }member_history`,config)
         .then((response)=>{
             if(response.data.STATUS) { 
               dispatch(GetMemberHistorySuccess(response.data.message));
            }else{
               dispatch(GetMemberHistoryFailure());
            }
         })
         .catch((error)=>{
          if (error.response.status === 401) {
            localStorage.clear();
            window.location.replace('/session-expired');
          }
          else {
           dispatch(GetMemberHistoryFailure());
          }
         })
   }
   }
