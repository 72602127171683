import { handleActions } from 'redux-actions';

const SearchReducer = {
  countryData: [],
  stateData: [],
  cityData: [],
  ageRange: [],
  bodyTypes: [],
  ethnicities: [],
  hairColors: [],
  orientationList: [],
  eyeList: [],
  piercingsList: [],
  tattoosList: [],
  advancedSearchData:[],
  RecordTotal:0,
};

const reducer = handleActions(
  {
    GET_COUNTRY_INITIATE: (state) => {
      return {
        ...state,
      };
    },
    GET_COUNTRY_SUCCESS: (state, action) => {
      return {
        ...state,
        countryData: action.payload,
      };
    },
    GET_COUNTRY_FAILURE: (state) => {
      return {
        ...state,
        countryData: [],
      };
    },
    GET_STATE_INITIATE: (state) => {
      return {
        ...state,
      };
    },
    GET_STATE_SUCCESS: (state, action) => {
      return {
        ...state,
        stateData: action.payload,
      };
    },
    GET_STATE_FAILURE: (state) => {
      return {
        ...state,
        stateData: [],
      };
    },
    GET_CITY_INITIATE: (state) => {
      return {
        ...state,
      };
    },
    GET_CITY_SUCCESS: (state, action) => {
      return {
        ...state,
        cityData: action.payload,
      };
    },
    GET_CITY_FAILURE: (state) => {
      return {
        ...state,
        cityData: [],
      };
    },
    GET_AGERANGE_INITIATE: (state) => {
      return {
        ...state,
      };
    },
    GET_AGERANGE_SUCCESS: (state, action) => {
      return {
        ...state,
        ageRange: action.payload,
      };
    },
    GET_AGERANGE_FAILURE: (state) => {
      return {
        ...state,
        ageRange: [],
      };
    },
    GET_BODYTYPE_INITIATE: (state) => {
      return {
        ...state,
      };
    },
    GET_BODYTYPE_SUCCESS: (state, action) => {
      return {
        ...state,
        bodyTypes: action.payload,
      };
    },
    GET_BODYTYPE_FAILURE: (state) => {
      return {
        ...state,
        bodyTypes: [],
      };
    },
    GET_ETHENICITY_INITIATE: (state) => {
      return {
        ...state,
      };
    },
    GET_ETHENICITY_SUCCESS: (state, action) => {
      return {
        ...state,
        ethnicities: action.payload,
      };
    },
    GET_ETHENICITY_FAILURE: (state) => {
      return {
        ...state,
        ethnicities: [],
      };
    },
    GET_HAIRCOLOR_INITIATE: (state) => {
      return {
        ...state,
      };
    },
    GET_HAIRCOLOR_SUCCESS: (state, action) => {
      return {
        ...state,
        hairColors: action.payload,
      };
    },
    GET_HAIRCOLOR_FAILURE: (state) => {
      return {
        ...state,
        hairColors: [],
      };
    },
    GET_ORIENTATION_INITIATE: (state) => {
      return {
        ...state,
      };
    },
    GET_ORIENTATION_SUCCESS: (state, action) => {
      return {
        ...state,
        orientationList: action.payload,
      };
    },
    GET_ORIENTATION_FAILURE: (state) => {
      return {
        ...state,
        orientationList: [],
      };
    },
    GET_EYELIST_INITIATE: (state) => {
      return {
        ...state,
      };
    },
    GET_EYELIST_SUCCESS: (state, action) => {
      return {
        ...state,
        eyeList: action.payload,
      };
    },
    GET_EYELIST_FAILURE: (state) => {
      return {
        ...state,
        eyeList: [],
      };
    },
    GET_PIERCINGS_INITIATE: (state) => {
      return {
        ...state,
      };
    },
    GET_PIERCINGS_SUCCESS: (state, action) => {
      return {
        ...state,
        piercingsList: action.payload,
      };
    },
    GET_PIERCINGS_FAILURE: (state) => {
      return {
        ...state,
        piercingsList: [],
      };
    },
    GET_TATTOOS_LIST_INITIATE: (state) => {
      return {
        ...state,
      };
    },
    GET_TATTOOS_LIST_SUCCESS: (state, action) => {
      return {
        ...state,
        tattoosList: action.payload,
      };
    },
    GET_TATTOOS_LIST_FAILURE: (state) => {
      return {
        ...state,
        tattoosList: [],
      };
    },
    ADVANCED_SEARCH_INITIATE: (state) => {
      return {
        ...state,
      };
    },
    ADVANCED_SEARCH_SUCCESS: (state, action) => {
      return {
        ...state,
        advancedSearchData: action.payload.result,
        RecordTotal:action.payload.RecordTotal
      };
    },
    ADVANCED_SEARCH_FAILURE: (state) => {
      return {
        ...state,
        advancedSearchData: [],
      };
    },
    GENERAL_SEARCH_INITIATE: (state) => {
      return {
        ...state,
      };
    },
    GENERAL_SEARCH_SUCCESS: (state, action) => {
      return {
        ...state,
        advancedSearchData: action.payload.result,
        RecordTotal:action.payload.RecordTotal
      };
    },
    GENERAL_SEARCH_FAILURE: (state) => {
      return {
        ...state,
        advancedSearchData: [],
      };
    },
    LUCKY_SEARCH_INITIATE: (state) => {
      return {
        ...state,
      };
    },
    LUCKY_SEARCH_SUCCESS: (state, action) => {
      return {
        ...state,
        advancedSearchData: action.payload.result,
        RecordTotal:action.payload.RecordTotal
      };
    },
    LUCKY_SEARCH_FAILURE: (state) => {
      return {
        ...state,
        advancedSearchData: [],
      };
    },
  },
  SearchReducer
);
export default reducer;
