import { makeStyles } from '@mui/styles';
const useStyles = makeStyles((theme) => ({
notification: {
    maxWidth: '430px',
    maxHeight: '200px',
    overflow: 'hidden',
    padding: '12px 48px 12px 12px',
    zIndex: '99',
    fontWeight: 'bold',
    position: 'relative',
  },
  
notificationTimeInfo: {
    alignSelf: 'center !important',
    marginLeft:'30px',
    marginBottom:'5px !important',
     fontWeight:'550!important',
    fontSize:'15px !important',
    color:'#0000007d',
    marginRight:'0px !important'
  },
  card:{
    maxWidth: '400px', 
    position: "fixed",
     top: "65px", 
     right: "30px",
     zIndex:1,
     borderRadius:'5px !important',
  },
  cardHeader:{
// borderBottom:'1px solid #80808042',
marginLeft: '15px !important',
marginRight: '15px !important',
  },
  title:{
      fontWeight:'bold !important',
      marginBottom:'5px !important',
      fontSize:'17px !important'
  },
  avatar:{
    marginBottom:'5px !important',
    marginRight:'5px!important'
  },
  message:{
     fontSize:'15px',
     fontWeight:'450'
  },
  cardContent:{
    paddingTop:'15px !important',
    paddingBottom:'15px !important'
  },
  // bgimage: {
  //   //backgroundImage: "url(https://i.imgur.com/HeGEEbu.jpg) no-repeat",
  //   borderRadius: '10px',
  //   height: '400px',
  //   display: 'flex',
  //   justifyContent: 'center',
  //   alignItems: 'center',
  //   backgroundSize: "112% 115% !important",
  //   backgroundPosition: "center !important",
  // }
}));

export default useStyles;