import { handleActions } from 'redux-actions';
import { API_STATUS } from '../../../constant';

const RegisterReducer = {
  apiStatus: -1,
  data: {},
  userToken: '',
  emailStatus: null,
  verifyOtpStatus:null,
  resendOtpStatus:null,
  isMobileExist:null,
  isEmailExist:null,
  is_username_exist:null,
  registrationFailure:null,
};

const reducer = handleActions(
  {
    REGISTER_INITIATE: (state) => {
      return {
        ...state,
        apiStatus: API_STATUS.initiated,
      };
    },
    REGISTER_SUCCESS: (state, action) => {
      return {
        ...state,
        data: action.payload,
        userToken: action.payload.auth_token,
        apiStatus: API_STATUS.success,
        registrationFailure:null
      };
    },
    REGISTER_FAILURE: (state,action) => {
      return {
        ...state,
        data: {},
        apiStatus: API_STATUS.failed,
        registrationFailure:action.payload
      };
    },

    MAIL_VERIFY_INITIATE: (state) => {
      return {
        ...state,
        emailStatus: API_STATUS.initiated,
      };
    },
    MAIL_VERIFY_SUCCESS: (state, action) => {
      return {
        ...state,
        emailStatus: action.payload,
      };
    },
    MAIL_VERIFY_FAILURE: (state) => {
      return {
        ...state,
        data: {},
        emailStatus: API_STATUS.failed,
      };
    },

    VERIFY_OTP_INITIATE: (state) => {
      return {
        ...state,
        
      };
    },
    VERIFY_OTP_SUCCESS: (state, action) => {
      return {
        ...state,
        verifyOtpStatus:action.payload
      };
    },
    VERIFY_OTP_FAILURE: (state,action) => {
      return {
        ...state,
        verifyOtpStatus:action.payload
      };
    },
    RESEND_OTP_INITIATE: (state) => {
      return {
        ...state,
        
      };
    },
    RESEND_OTP_SUCCESS: (state, action) => {
      return {
        ...state,
        resendOtpStatus:action.payload
      };
    },
    RESEND_OTP_FAILURE: (state) => {
      return {
        ...state,
       
      };
    },
    VERIFY_UNIQUE_USER_INITIATE: (state) => {
      return {
        ...state,
        
      };
    },
    VERIFY_UNIQUE_USER_SUCCESS: (state, action) => {
      return {
        ...state,
        isMobileExist:action.payload.is_mobile_exist,
        isEmailExist:action.payload.is_email_exist,
        is_username_exist:action.payload.is_username_exist
      };
    },
    VERIFY_UNIQUE_USER_FAILURE: (state) => {
      return {
        ...state,
        isMobileExist:null,
        isEmailExist:null,
        is_username_exist:null
      };
    },
    SET_UNIQUE_STATUS_NULL: (state) => {
      return {
        ...state,
        data: {},
        userToken: '',
        emailStatus: null,
        verifyOtpStatus:null,
        resendOtpStatus:null,
        isMobileExist:null,
        isEmailExist:null,
        is_username_exist:null,
        registrationFailure:null,
      };
    },
  },
  RegisterReducer
);
export default reducer;
