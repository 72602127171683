import React, { useEffect, useState } from 'react';
import {
  Card,
  CardMedia,
  Grid,
  Typography,
  styled,
  Badge,
  Pagination,
} from '@mui/material';
import UserLayout from '../layouts/userLayout';
import useStyles from '../styles/newestMembers';
import * as searchAction from '../redux/action/searchAction';
import * as commonAction from '../redux/action/commonAction';
import * as lastVisitorAction from '../redux/action/lastVisitorAction';
import { connect } from 'react-redux';
import { useHistory } from 'react-router';
import { ReactComponent as HeartIcon } from '../components/icons/ico_heart_active.svg';
import { ReactComponent as HeartIconInActive } from '../components/icons/ico heart inactive.svg';
import { ReactComponent as Level1 } from '../components/icons/DarkBlue Like.svg';
import { ReactComponent as Level2 } from '../components/icons/litebluelike.svg';
import { ReactComponent as Level3 } from '../components/icons/ico like green.svg';
import { ReactComponent as Level4 } from '../components/icons/orange_like.svg';
import { ReactComponent as Level5 } from '../components/icons/like_purple.svg';
import { ReactComponent as GrayLike } from '../components/icons/ico like_gray.svg';
import { ReactComponent as HotIcon } from '../components/icons/hot.svg';
import { ReactComponent as ChatIcon } from '../components/icons/ico_message.svg';
import * as chatAction from '../redux/action/chatAction';
import ConfirmationAlert from '../components/common/confirmationAlert';
import Loader from '../utils/loader';
import { ReactComponent as TimerSmallIcon } from '../components/icons/ico_timer_small.svg';
import { useTranslation } from 'react-i18next';
const StyledBadge = styled(Badge)(({ theme }) => ({
  '& .MuiBadge-badge': {
    backgroundColor: '#44b700',
    color: '#44b700',
    boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
    bottom: '50%',
    right: '-30%',
  },
}));

const SearchData = (props) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const history = useHistory();
  const [searchResult, setSearchResult] = useState([]);
  const [newestMemberAlert, setNeweastMemberAlert] = useState(false);
  const [alertConfirmButton, setAlertConfirmButton] = useState(false);
  const [alertCloseButton, setAlertCloseButton] = useState(false);
  const [alertConfirmButtonText, setAlertConfirmButtonText] = useState('');
  const [alertCloseButtonText, setAlertCloseButtonText] = useState('');
  const [alertText, setAlertText] = useState('');
  const [alertTitle, setAlertTitle] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [initialLoading, setInitialLoading] = useState(true);
  const [page, setPage] = useState(1);
  const [buttonDisabled, setButtonDisabled] = useState(false)
  const [favoLikeActionStatus, setFavoLikeActionStatus] = useState('')
  const [userId, setUserId] = useState(0)
  const [firstPage, setFirstPage] = useState(false)
  const handleChange = (event, value) => {
    if(value===1){
      setFirstPage(true)
    }else{
      setFirstPage(false)
    }
    setPage(value);
    sessionStorage.setItem('searchPageNo',value)
  };
  useEffect(() => {
    if (props.advancedSearchData && props.advancedSearchData) {
      setSearchResult(props.advancedSearchData);
    }
  }, [props.advancedSearchData]);

  useEffect(() => {
    if (localStorage.getItem('advancedSearchData') !== null) {
      let pageNo=sessionStorage.getItem('searchPageNo')===null?1:parseInt(sessionStorage.getItem('searchPageNo'));
     
      if(pageNo===1){
        var form_data = new FormData();
        var searchData = JSON.parse(localStorage.getItem('advancedSearchData'));
        for (var key in searchData) {
          form_data.append(key, searchData[key]);
        }
        setTimeout(() => {
          props.advancedSearchService(
            form_data,
            localStorage.getItem('userToken')
          );
        }, 1000);
        setTimeout(() => {
          setInitialLoading(false)
        }, 2000);
      }else{
        setPage(pageNo)
        setTimeout(() => {
          setInitialLoading(false)
        }, 2000);
      }
    }
  }, [localStorage.getItem('advancedSearchData')]);

  useEffect(() => {
    if (localStorage.getItem('luckySearch') !== null) {
      setTimeout(() => {
        props.luckySearchService(localStorage.getItem('userToken'));
      }, 1000);
      setTimeout(() => {
        setInitialLoading(false)
      }, 2000);

    }
  }, [localStorage.getItem('luckySearch')]);

  useEffect(() => {
    if (localStorage.getItem('generalSearchData') !== null) {
      let pageNo=sessionStorage.getItem('searchPageNo')===null?1:parseInt(sessionStorage.getItem('searchPageNo'));
      if(pageNo===1){
      var form_data = new FormData();
      var searchData = JSON.parse(localStorage.getItem('generalSearchData'));
      for (var key in searchData) {
        form_data.append(key, searchData[key]);
      }
      setTimeout(() => {
        props.generalSearchService(form_data, localStorage.getItem('userToken'));
      }, 1000);
      setTimeout(() => {
        setInitialLoading(false)
      }, 2000);
    }else{
      setPage(pageNo)
      setTimeout(() => {
        setInitialLoading(false)
      }, 2000);
    }
  }
  }, [localStorage.getItem('generalSearchData')]);


  useEffect(() => {
    if (page > 1||firstPage) {
      var form_data = new FormData();
      var searchData, searchService;
      if (localStorage.getItem('advancedSearchData') !== null) {
        searchData = JSON.parse(localStorage.getItem('advancedSearchData'))
        searchService = 'advancedSearch'
      } else {
        searchData = JSON.parse(localStorage.getItem('generalSearchData'))
        searchService = 'generalSearch'
      }

      for (var key in searchData) {
        if (key === 'offset') {
          form_data.append(key, firstPage?0: page - 1);
        } else {
          form_data.append(key, searchData[key]);
        }

      }

      if (searchService === 'generalSearch') {
        props.generalSearchService(form_data, localStorage.getItem('userToken'));
      } else {
        props.advancedSearchService(form_data, localStorage.getItem('userToken'));
      }
    }
  }, [page])

  const getUserData = (e, id) => {
    e.stopPropagation();
    props.setCommonStatusNull();
    localStorage.setItem('currentID', id);
    props.getUserByID(localStorage.getItem('userToken'), id);
    props.addLastVisit(id, localStorage.getItem('userToken'));
    history.push('/user-profile');
  };
  const handleFavorite = (e, item) => {
    setIsLoading(true)
    e.stopPropagation();
    let data = new FormData();
    setUserId(item.id)
    setFavoLikeActionStatus('favourite')
    data.append('member_id', item.id);
    data.append('action', item.is_favourite === 1 ? 0 : 1);
    props.addorRemoveFavorite(data);
  };
  const handleLike = (e, item) => {

    e.stopPropagation();
    if (item.is_liked_level <= 4) {
      setIsLoading(true)
      let data = new FormData();
      setUserId(item.id)
      setFavoLikeActionStatus('like')
      data.append('member_id', item.id);
      data.append('action', 1);
      data.append(
        'level',
        item.is_liked_level <= 4 ? item.is_liked_level + 1 : ''
      );

      props.likeorDislike(data);
    } else {
      setNeweastMemberAlert(true);
      setAlertConfirmButton(false);
      setAlertCloseButton(true);
      setAlertConfirmButtonText('');
      setAlertCloseButtonText(t("Close"));
      setAlertTitle('');
      setAlertText(t("likeLevelReached"));
    }
  };

  useEffect(() => {
    if(props.favoriteStatus){
    if (localStorage.getItem('generalSearchData') !== null) {
      setIsLoading(false)
      var form_data = new FormData();
      var searchData = JSON.parse(localStorage.getItem('generalSearchData'));
      for (var key in searchData) {
        form_data.append(key, searchData[key]);
      }
      props.generalSearchService(form_data, localStorage.getItem('userToken'));
    }

    if (localStorage.getItem('advancedSearchData') !== null) {
      setIsLoading(false)
      var form_data = new FormData();
      var searchData = JSON.parse(localStorage.getItem('advancedSearchData'));
      for (var key in searchData) {
        form_data.append(key, searchData[key]);
      }
      props.advancedSearchService(
        form_data,
        localStorage.getItem('userToken')
      );
    }
    if (localStorage.getItem('luckySearch') === "true") {
      setIsLoading(false)
      prepareLuckyUserData()
    }
  }
  }, [props.favoriteStatus]);

  useEffect(() => {
    if (props.likeStatus) {
      if (localStorage.getItem('generalSearchData') !== null) {
        setIsLoading(false)
        props.setCommonStatusNull();
        var form_data = new FormData();
        var searchData = JSON.parse(localStorage.getItem('generalSearchData'));
        for (var key in searchData) {
          form_data.append(key, searchData[key]);
        }
        props.generalSearchService(form_data, localStorage.getItem('userToken'));
      }
      if (localStorage.getItem('advancedSearchData') !== null) {
        setIsLoading(false)
        var form_data = new FormData();
        var searchData = JSON.parse(localStorage.getItem('advancedSearchData'));
        for (var key in searchData) {
          form_data.append(key, searchData[key]);
        }

        props.advancedSearchService(
          form_data,
          localStorage.getItem('userToken')
        );
      }
      if (localStorage.getItem('luckySearch') === "true") {
        setIsLoading(false)
        prepareLuckyUserData()
      }
    } else if (props.likeStatus === false) {
      setIsLoading(false)
      props.setCommonStatusNull();
      setNeweastMemberAlert(true);
      setAlertConfirmButton(false);
      setAlertCloseButton(true);
      setAlertConfirmButtonText('');
      setAlertCloseButtonText(t("Close"));
      setAlertTitle(t("dontHaveLikePoint"));
      setAlertText(t("earnAndTryLater"));
    }
  }, [props.likeStatus]);

  const prepareLuckyUserData = (e,item) => {
    let newArr=[];
    newArr = searchResult.map(obj => {
      if (obj.id === userId) {
        if (favoLikeActionStatus === 'favourite') {
          return {...obj, is_favourite:obj.is_favourite===1?0:1};
        } else {
          return {...obj, is_liked_level:obj.is_liked_level+1,};
        }
       
      }
    
      return obj;
    });
   setSearchResult(newArr)
  }
  const getIcon = (item) => {
    let icon;
    if (item.is_liked_level === 0) {
      icon = (
        <GrayLike
          onClick={(e) => handleLike(e, item)}
          className={classes.likeIcon}
        />
      );
    } else if (item.is_liked_level === 1) {
      icon = (
        <Level1
          onClick={(e) => handleLike(e, item)}
          className={classes.likeIcon}
        />
      );
    } else if (item.is_liked_level === 2) {
      icon = (
        <Level2
          onClick={(e) => handleLike(e, item)}
          className={classes.likeIcon}
        />
      );
    } else if (item.is_liked_level === 3) {
      icon = (
        <Level3
          onClick={(e) => handleLike(e, item)}
          className={classes.likeIcon}
        />
      );
    } else if (item.is_liked_level === 4) {
      icon = (
        <Level4
          onClick={(e) => handleLike(e, item)}
          className={classes.likeIcon}
        />
      );
    } else if (item.is_liked_level === 5) {
      icon = <Level5 className={classes.likeIcon}
       //onClick={(e) => handleLike(e, item)}
        />;
    }
    return icon;
  };
  const movetoChat = (e, item) => {
    setIsLoading(true)
    e.stopPropagation();
    localStorage.setItem('currentChatId', item.id);
    let data = new FormData();
    data.append('to_user_id', item.id);
    props.InitiateNewChat(data, localStorage.getItem('userToken'));
  };

  useEffect(() => {
    if (props.newChatConnectionStatus) {
      setIsLoading(false)
      // props.getChatHistoryData(localStorage.getItem('userToken'))
      props.newChatStatusNull();
      // setTimeout(() => {
      history.push('/chat');
      // }, 3000);

    }
  }, [props.newChatConnectionStatus]);

  const CloseAlert = () => {
    setNeweastMemberAlert(false);
    setAlertConfirmButton(false);
    setAlertCloseButton(false);
    setAlertConfirmButtonText('');
    setAlertCloseButtonText('');
    setAlertTitle('');
    setAlertText('');
  };
  const confirmFunc = () => { };
  return (
    initialLoading ? <UserLayout subHeader={false} subFooter={false} header={true}><Loader /></UserLayout> : <UserLayout subHeader={false} subFooter={false} header={true}>
      {isLoading ? <Loader /> : ''}
      <ConfirmationAlert
        alert={newestMemberAlert}
        confirmButton={alertConfirmButton}
        closeButton={alertCloseButton}
        confirmButtonText={alertConfirmButtonText}
        closeButtonText={alertCloseButtonText}
        alertTitle={alertTitle}
        alertText={alertText}
        closeFunc={CloseAlert}
        confirmFunc={confirmFunc}
        buttonDisabled={buttonDisabled}
      />
      {searchResult.length === 0 ? (
        <Typography sx={{ ml: 1, mb: 3 }}>{t("noResults")}</Typography>
      ) : (
        ''
      )}
      {searchResult.length > 0 ? (
        <Grid
          container
          direction="row"
          justify="space-between"
          alignItems="center"
          spacing={4}
        >
          {searchResult.map((item) => {
            return (
              <Grid item xs={6} sm={4} md={3} lg={2.3} xl={2.3} key={item.id}>
                <Card className={classes.bannerCard} sx={{ cursor: 'pointer' }}>
                  {/* <CardActionArea> */}
                  <CardMedia
                    onClick={(e) => getUserData(e, item.id)}
                    className={classes.hotbidImg}
                    image={`${item.pictures}`}
                  >
                    {item.is_approved === 0 ? (
                      <TimerSmallIcon
                        className={classes.TimerIcon}
                        onClick={(e) => handleFavorite(e, item)}
                      />
                    ) : ''}
                    {item.is_favourite === 1 ? (
                      <HeartIcon
                        className={classes.heartIcon}
                        onClick={(e) => handleFavorite(e, item)}
                      />
                    ) : (
                      <HeartIconInActive
                        className={classes.heartIcon}
                        onClick={(e) => handleFavorite(e, item)}
                      />
                    )}
                    {getIcon(item)}
                    <div>
                      {item.is_popular === 1 ? <HotIcon className={classes.box1} /> : ''}

                      <div
                        className={classes.userDescription}
                        style={{ minHeight: '90px', maxHeight: '100px' }}
                      >
                        {item.is_online === 1 ? (
                          <Grid
                            sx={{
                              display: 'flex',
                              justifyContent: 'space-between',
                            }}
                          >
                            <Grid item>
                              <StyledBadge
                                overlap="circular"
                                anchorOrigin={{
                                  vertical: 'bottom',
                                  horizontal: 'right',
                                }}
                                variant="dot"
                              >
                                <Typography
                                  variant="body1"
                                  component="p"
                                  className={classes.userName}
                                >
                                  {item.username.length > 7 ? `${item.username.slice(0, 7)}...` : item.username},
                                  &nbsp;
                                  {item.age === null ? '' : item.age}
                                  &nbsp;
                                  {
                                    item.gender === 'Male'
                                      ? 'M'
                                      : item.gender === 'Female'
                                        ? 'F'
                                        : ''}
                                </Typography>
                              </StyledBadge>
                            </Grid>
                            <Grid item onClick={(e) => movetoChat(e, item)}>
                              <ChatIcon />
                            </Grid>
                          </Grid>
                        ) : (
                          <Grid
                            sx={{
                              display: 'flex',
                              justifyContent: 'space-between',
                            }}
                          >
                            <Grid item>
                              <Typography
                                variant="body1"
                                component="p"
                                className={classes.userName}
                              >
                                {item.username.length > 7 ? `${item.username.slice(0, 7)}...` : item.username},
                                &nbsp;
                                {item.age === null ? '' : item.age}
                                &nbsp;
                                {
                                  item.gender === 'Male'
                                    ? 'M'
                                    : item.gender === 'Female'
                                      ? 'F'
                                      : ''}
                              </Typography>
                            </Grid>
                            <Grid item onClick={(e) => movetoChat(e, item)}>
                              <ChatIcon />
                            </Grid>
                          </Grid>
                        )}
                        <Grid container>
                        <Grid item xs={8.5} display="flex" justifyContent={'start'}>
                          <Typography
                            variant="body2"
                            component="p"
                            className={classes.userName}
                          >
                            Status:
                          </Typography>
                          </Grid>
                          <Grid item xs={3.5} display="flex" justifyContent={'start'}>
                          <Typography
                            variant="body2"
                            component="p"
                            className={classes.userName}
                          >
                          {item.martial_status === ''||item.martial_status === null ? '' : item.martial_status}
                          </Typography>
                          </Grid>
                        </Grid>
                        <Grid container>
                        <Grid item xs={8.5} display="flex" justifyContent={'start'}>
                          <Typography
                            variant="body2"
                            component="p"
                            className={classes.userName}
                          >
                             {t("lookingFor")}:
                          </Typography>
                          </Grid>
                          <Grid item xs={3.5} display="flex" justifyContent={'start'}>
                          <Typography
                            variant="body2"
                            component="p"
                            className={classes.userName}
                          >
                            {item.looking_for === null || item.looking_for === '' ? '' :t(`${item.looking_for}`)}
                          </Typography>
                          </Grid>
                        </Grid>
                      </div>
                    </div>
                  </CardMedia>
                  {/* <CardContent className={classes.hotbidCard}>


                                              <Favorite className={classes.favoriteIcon} />
                                          </CardContent> */}
                  {/* </CardActionArea> */}
                  {/* <CardActions>
              <Button size="small" color="primary">
                Share
              </Button>
              <Button size="small" color="primary">
                Learn More
              </Button>
            </CardActions> */}
                </Card>
              </Grid>
            );
          })}
        </Grid>
      ) : (
        ''
      )}
      {props.RecordTotal > 15 ? <Grid item xs={12} display='flex' justifyContent='center' sx={{ mt: 5 }}>
        <Pagination count={Math.floor(props.RecordTotal / 15)} page={page} onChange={handleChange} variant="outlined" shape="rounded" />
      </Grid> : ''}
    </UserLayout>
  );
};
const mapStateToProps = (state) => {
  return {
    advancedSearchData: state.SearchReducer.advancedSearchData,
    favoriteStatus: state.CommonReducer.favoriteStatus,
    likeStatus: state.CommonReducer.likeStatus,
    newChatConnectionStatus: state.ChatReducer.newChatConnectionStatus,
    RecordTotal: state.SearchReducer.RecordTotal
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    advancedSearchService: (requestData, token) =>
      dispatch(searchAction.advancedSearchService(requestData, token)),
    generalSearchService: (requestData, token) =>
      dispatch(searchAction.generalSearchService(requestData, token)),
    getUserByID: (token, requestData) =>
      dispatch(commonAction.getUserByID(token, requestData)),
    addLastVisit: (userId, Token) =>
      dispatch(lastVisitorAction.addUserVisitor(userId, Token)),
    addorRemoveFavorite: (requestData) =>
      dispatch(commonAction.favoriteOtherMember(requestData)),
    likeorDislike: (requestData) =>
      dispatch(commonAction.likeOtherMember(requestData)),
    InitiateNewChat: (requestData, token) =>
      dispatch(chatAction.InitiateNewChat(requestData, token)),
    newChatStatusNull: () => dispatch(chatAction.newChatStatusNull()),
    setCommonStatusNull: () => dispatch(commonAction.setCommonStatusNull()),
    luckySearchService: (token) =>
      dispatch(searchAction.luckySearchService(token)),
    getChatHistoryData: (requestData) =>
      dispatch(chatAction.getChatHistoryData(requestData)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SearchData);
