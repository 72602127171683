import axios from 'axios';
import { createAction } from 'redux-actions';

export const registerInitiate = createAction('REGISTER_INITIATE');
export const registerSuccess = createAction('REGISTER_SUCCESS');
export const registerFailure = createAction('REGISTER_FAILURE');

export const mailVerifyInitiate = createAction('MAIL_VERIFY_INITIATE');
export const mailVerifySuccess = createAction('MAIL_VERIFY_SUCCESS');
export const mailVerifyFailure = createAction('MAIL_VERIFY_FAILURE');


export const verifyOTPInitiate = createAction('VERIFY_OTP_INITIATE');
export const verifyOTPSuccess = createAction('VERIFY_OTP_SUCCESS');
export const verifyOTPFailure = createAction('VERIFY_OTP_FAILURE');

export const resendOTPInitiate = createAction('RESEND_OTP_INITIATE');
export const resendOTPSuccess = createAction('RESEND_OTP_SUCCESS');
export const resendOTPFailure = createAction('RESEND_OTP_FAILURE');

export const verifyUniqueUserInitiate = createAction('VERIFY_UNIQUE_USER_INITIATE');
export const verifyUniqueUserSuccess = createAction('VERIFY_UNIQUE_USER_SUCCESS');
export const verifyUniqueUserFailure = createAction('VERIFY_UNIQUE_USER_FAILURE');

export const setUniqueStatusNull=createAction("SET_UNIQUE_STATUS_NULL");

export const registerService = (requestData) => {
  return (dispatch) => {
    dispatch(registerInitiate());
    axios({
      method: 'post',
      url: `${process.env.REACT_APP_BACKEND_URL}register1`,
      data: requestData,
      headers: { 'Content-Type': 'multipart/form-data' },
    })
      .then((response) => {
        if (response.data.STATUS === 1 || response.data.STATUS === '1') {
          dispatch(registerSuccess(response.data.STATUS));
        } else {
          dispatch(registerFailure(response.data.message));
        }
      })
      .catch((error) => {
        dispatch(registerFailure());
      });
  };
};



export const verifyUserByEmail = (requestData) => {
  return (dispatch) => {
    dispatch(mailVerifyInitiate());
    axios({
      method: 'post',
      url: `${process.env.REACT_APP_BACKEND_URL}is_email_exist`,
      data: requestData,
      headers: { 'Content-Type': 'multipart/form-data' },
    })
      .then((response) => {
        dispatch(mailVerifySuccess(response.data.message));
      })
      .catch((error) => {
        dispatch(mailVerifyFailure());
      });
  };
};



export const verifyOtp = (requestData) => {
  return (dispatch) => {
    dispatch(verifyOTPInitiate());
    axios({
      method: 'post',
      url: `${process.env.REACT_APP_BACKEND_URL}account_verification`,
      data: requestData,
      headers: { 'Content-Type': 'multipart/form-data' },
    })
      .then((response) => {
        if (response.data.STATUS===true||response.data.STATUS==="true") {
          dispatch(verifyOTPSuccess(response.data.STATUS))
        }else{
          dispatch(verifyOTPFailure(response.data.message));
       }
      })
      .catch((error) => {
        dispatch(verifyOTPFailure());
      });
  };
};

export const ResendOtp = (requestData) => {
  return (dispatch) => {
    dispatch(resendOTPInitiate());
    axios({
      method: 'post',
      url: `${process.env.REACT_APP_BACKEND_URL}resend-otp`,
      data: requestData,
      headers: { 'Content-Type': 'multipart/form-data' },
    })
      .then((response) => {
        if (response.data.STATUS) {
          dispatch(resendOTPSuccess(response.data.STATUS))
        }
      })
      .catch((error) => {
        dispatch(resendOTPFailure());
      });
  };
};


export const verifyUniqueUser = (requestData) => {
  return (dispatch) => {
    dispatch(verifyUniqueUserInitiate());
    axios({
      method: 'post',
      url: `${process.env.REACT_APP_BACKEND_URL}exists_checkup`,
      data: requestData,
      headers: { 'Content-Type': 'multipart/form-data' },
    })
      .then((response) => {
        dispatch(verifyUniqueUserSuccess(response.data));
      })
      .catch((error) => {
        dispatch(verifyUniqueUserFailure());
      });
  };
};

export const uniqueStatusNull = ()=>{ 
  return (dispatch)=>{
    dispatch(setUniqueStatusNull());
}
}