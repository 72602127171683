import React from 'react';
import { Grid, Typography } from '@mui/material';
import { getIsMobile } from '../../utils';
import { useTranslation } from 'react-i18next';

const HowItWorks = () => {
	const isMobile = getIsMobile();
	const { t } = useTranslation();
	return (
		<>
			<Typography variant="h2" component="h2">
				{t("howItWorks")}
			</Typography>
			{/* <Typography
				variant="body2"
				component="p"
				sx={{ marginTop: '10px', padding: isMobile ? '0px' : '0px 20vw' }}
			>
				Consectetur adipiscing elit. In nec aliquet arcu. Mauris tristique,
			</Typography> */}

			<Grid
				container
				spacing={4}
				justifyContent="space-between"
				alignItems="center"
				sx={{ marginTop: '0px' }}
			>
				<Grid item xs={12} md={4}>
					<img src="/assets/icons/ico_gender.png" alt="gender" />
					<Typography variant="body1" component="p" sx={{ marginTop: '15px' }}>
					{t("consecteturAdipiscingelit1")}
					</Typography>
					{/* <Typography variant="body2" component="p" sx={{ marginTop: '10px' }}>
						Consectetur adipiscing elit. In nec aliquet arcu.
					</Typography> */}
				</Grid>
				<Grid item xs={12} md={4}>
					<img src="/assets/icons/ico_heart.png" alt="heart" />
					<Typography variant="body1" component="p" sx={{ marginTop: '15px' }}>
					{t("consecteturAdipiscingelit2")}
					</Typography>
					{/* <Typography variant="body2" component="p" sx={{ marginTop: '10px' }}>
						Consectetur adipiscing elit. In nec aliquet arcu.
					</Typography> */}
				</Grid>
				<Grid item xs={12} md={4}>
					<img src="/assets/icons/ico_phone.png" alt="phone" />
					<Typography variant="body1" component="p" sx={{ marginTop: '15px' }}>
					{t("consecteturAdipiscingelit3")}
					</Typography>
					{/* <Typography variant="body2" component="p" sx={{ marginTop: '10px' }}>
						Consectetur adipiscing elit. In nec aliquet arcu.
					</Typography> */}
				</Grid>
			</Grid>
		</>
	);
};
export default HowItWorks;
