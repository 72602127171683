import React, { useState, useEffect } from 'react';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import {
  Typography,
  Grid,
  Button,
  FormControl,
  FormGroup,
  TextField,
  Dialog,
  DialogContent,
  Checkbox,
  InputAdornment,
  IconButton,
  Card,
  CardContent,
  CardHeader,
} from '@mui/material';
import CancelIcon from '@mui/icons-material/Cancel';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import useStyles from '../styles/auth';
import { RiLock2Fill } from 'react-icons/ri';
import { IoIosMail } from 'react-icons/io';
import { Link, useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import * as loginAction from '../redux/action/loginAction';
import * as forgotAction from '../redux/action/forgotAction';
import { ReactComponent as GmailIcon } from '../components/icons/Gmail.svg';
import Loader from '../utils/loader';
import GoogleLogin from 'react-google-login';
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props'
import { initializeApp } from 'firebase/app';
import { getAuth, signInWithPopup, TwitterAuthProvider } from "firebase/auth";
import { useTranslation } from 'react-i18next';
import { firebaseConfig } from '../configs';
// import RegisterConfirmation from '../utils/RegisterConfirmation';
const Login = (props) => {
  const app = initializeApp(firebaseConfig);
  const provider = new TwitterAuthProvider();
  const auth = getAuth();
  const classes = useStyles();
  const { t } = useTranslation();
  let history = useHistory();
  const [forgotModal, setForgotModal] = useState(false);
  const [remember, setRemember] = useState(false);
  const [passwordVisibility, showPassword] = React.useState(false);
  const [forgotEmail, setForgotEmail] = useState('');
  const [loginFailureMessage, setloginFailureMessage] = useState('');
  const [loginEmail, setLoginEmail] = useState('');
  const [errorMessages, setErrorMessages] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [forgotEmailError, setForgotEmailError] = useState('');
  // const [registerConfirmation, setRegisterConformation] = useState(false);
  const validationSchema = () => {
    return Yup.object().shape({
      email: Yup.string()
        .email('Invalid email format!')
        .required('Email is required!'),
      // password: Yup.string().required('Password incorrect!'),
      password: Yup.string()
        .required('Please Enter your password')
        .matches(
          '^(?=.{6,})',
          'Password Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and one special Character'
        ),
    });
  };
  const handleClose = () => {
    setForgotModal(false);
  };

  const handleResetPassword = (e) => {
    setForgotEmail(e.target.value);
  };

  const sendResetPasswordLink = () => {
    let validmail = false;
    if (
      forgotEmail.match(
        /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      )
    ) {
      validmail = true;
      setForgotEmailError('')
    } else if (forgotEmail.length > 0) {
      validmail = false;
      setForgotEmailError(t("invalidEmailType"))
    } else {
      validmail = false;
      setForgotEmailError(t("emailRequired"))
    }
    if (validmail) {

      var requestData = new FormData();
      requestData.append('email', forgotEmail);
      props.forgotPasswordService(requestData);
    }
  };

  const validate = (getValidationSchema) => {
    return (values) => {
      const validationSchema = getValidationSchema(values);
      try {
        validationSchema.validateSync(values, { abortEarly: false });
        setErrorMessages({});
        return {};
      } catch (error) {
        return console.log(error);
        //  getErrorsFromValidationError(error);
      }
    };
  };

  const getErrorsFromValidationError = (validationError) => {
    const FIRST_ERROR = 0;

    setErrorMessages(
      validationError.inner.reduce((errors, error) => {
        return {
          ...errors,
          [error.path]: error.errors[FIRST_ERROR],
        };
      }, {})
    );
    // return validationError.inner.reduce((errors, error) => {
    //   return {
    //     ...errors,
    //     [error.path]: error.errors[FIRST_ERROR],
    //   };
    // }, {});
  };

  // useEffect(() => {
  // if ((errorMessages.password != '' && errorMessages.email != '') && (Object.keys(errorMessages).length === 2)) {
  //   setloginFailureMessage(errorMessages.email + ' ' + errorMessages.password)
  // } else if (errorMessages.password != '' && Object.keys(errorMessages).length === 1) {
  //   setloginFailureMessage(errorMessages.password)
  // } else if (errorMessages.email != '' && Object.keys(errorMessages).length === 1) {
  //   setloginFailureMessage(errorMessages.email)
  // }
  // setloginFailureMessage(errorMessages)

  // }, [errorMessages])


  useEffect(() => {
    localStorage.setItem('rememberMe', remember)
  }, [remember])

  const getInitialValues = () => {
    const initialValues = {
      email: '',
      password: '',
    };
    return initialValues;
  };

  const handleSubmit = (values) => {

    if (values.email != '' && values.password != '') {
      setIsLoading(true);
      setLoginEmail(values.email);
      var requestData = new FormData();
      requestData.append('email', values.email);
      requestData.append('password', values.password);
      props.loginService(requestData);
    } else {
      setloginFailureMessage(t("userName/PasswordReq"));
    }
  };
  useEffect(() => {
    if (localStorage.getItem('userToken') != null) {
      setIsLoading(false);
      history.push('/newest-members');
    }
  }, [localStorage.getItem('userToken')]);

  useEffect(() => {
    if (
      props.resetPasswordStatus.STATUS ||
      props.resetPasswordStatus.STATUS === 'true'
    ) {
      setIsLoading(false)

      setTimeout(function () {
        setForgotModal(false);
        props.forgotPasswordServiceNull();
      }, 5000);
    } else if (
      props.resetPasswordStatus.STATUS === false
    ) {
      setIsLoading(false)

      setTimeout(function () {
        setForgotModal(false);
        props.forgotPasswordServiceNull();
      }, 5000);
    }
  }, [props.resetPasswordStatus]);

  useEffect(() => {

    if (props.loginStatus === 'Account not verified') {
      props.loginStatusNull()
      setIsLoading(false);
      localStorage.setItem('email', loginEmail);
      setTimeout(() => {
        history.push('/verify-otp');
      }, 5000);
    } else if (props.loginStatus === 'Username/Password invalid!') {
      setIsLoading(false);
      setloginFailureMessage(t("username/Passwordinvalid!"));
      props.loginStatusNull()
    } else if (props.loginStatus !== '') {
      setIsLoading(false);
      setloginFailureMessage(props.loginStatus);
      props.loginStatusNull()
    }
  }, [props.loginStatus]);

  const closeError = () => {
    setloginFailureMessage('');
    setErrorMessages({});
  };

  const responseGoogle = (response) => {
    setLoginEmail(response.profileObj?.email);
    let data = new FormData();
    data.append('unique_id', response.profileObj?.googleId)
    props.socialLoginService(data)
  };

  const responseError = (response) => {
    console.log(response);
  };

  const responseFacebook = (response) => {
    let data = new FormData();
    data.append('unique_id', response.id)
    props.socialLoginService(data)
  }

  const authHandler = () => {
    signInWithPopup(auth, provider)
    .then((result) => {
      // This gives you a the Twitter OAuth 1.0 Access Token and Secret.
      // You can use these server side with your app's credentials to access the Twitter API.
      const credential = TwitterAuthProvider.credentialFromResult(result);
      // The signed-in user info.
      const user = result.user;
      let data = new FormData();
      data.append('unique_id', user.uid)
      props.socialLoginService(data)
    }).catch((error) => {
      // Handle Errors here.
      const errorCode = error.code;
      const errorMessage = error.message;
      // The email of the user's account used.
      const email = error.customData.email;
      // The AuthCredential type that was used.
      const credential = TwitterAuthProvider.credentialFromError(error);
      console.log(error);
      // ...
    });
  };
  // const openRegisterConfirm = () => {
  //   setRegisterConformation(!registerConfirmation)
  // }

  return (
    <div className={classes.bannerBgImage}>
      {isLoading ? <Loader /> : ''}
      {/* <RegisterConfirmation dialogStatus={registerConfirmation} closeConfirmation={openRegisterConfirm} /> */}
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
      >
        <Grid item xs={12} sm={8} md={4} sx={{ marginTop: '-90px' }}>
          <Typography variant="h1" component="h1">
            {t("loginWith")}
          </Typography>
          <div>
            <Link to="#">
              <GoogleLogin
                clientId="564348909029-f0062k8qg8fe5u20lplb2dhguch636fu.apps.googleusercontent.com"
                render={(renderProps) => (
                  <GmailIcon
                    className={classes.instagram}
                    onClick={renderProps.onClick}
                    disabled={renderProps.disabled}
                  />
                )}
                buttonText="Login"
                onSuccess={responseGoogle}
                onFailure={responseError}
                cookiePolicy={'single_host_origin'}
              />
            </Link>
            <Link to="#">
              <FacebookLogin
                appId="699443281105756"
                autoLoad={false}
                callback={responseFacebook}
                render={(renderProps) => (
                  <img
                    src="/assets/icons/ico_facebook_1.png"
                    alt="facebook"
                    className={classes.instagram}
                    onClick={renderProps.onClick}
                  />
                )}
              />
            </Link>
            <Link to="#" >
              {/* <TwitterLogin
                className={classes.twiterlogin}
                authCallback={authHandler}
                consumerKey={'ibSoFonncr5z6Hxfo3DashLQD'}
                consumerSecret={'HnvQwkQnUfbqsMTTm7c2w3hETM0ss1hCyUHSGClV1iajjid0LD'}
                children={<img
                  src="/assets/icons/ico_twitter_1.png"
                  alt="Twitter"

                />}

              /> */}
              <img
                src="/assets/icons/ico_twitter_1.png"
                alt="Twitter"
                className={classes.instagram}
                onClick={() => authHandler()}
              />
            </Link>
          </div>
          <Typography variant="h5" component="h5" sx={{ marginBottom: '15px' }}>
            {t("orUseEmailAccount")}
          </Typography>
          <Formik
            initialValues={getInitialValues()}
            validate={validate(validationSchema)}
            onSubmit={handleSubmit}
            enableReinitialize={true}
            render={({
              errors,
              handleChange,
              handleSubmit,
              values,
              touched,
            }) => (
              <Form onSubmit={handleSubmit}>
                <FormControl style={{ width: '80%' }}>
                  <FormGroup aria-label="position">
                    {/* {error && (
								<div style={{ margin: '10px 0px' }}>
									<Alert severity="error">{error}</Alert>
								</div>
							)} */}
                    <TextField
                      variant="filled"
                      name="email"
                      placeholder={t("enterUsername")}
                      value={values.email}
                      onChange={handleChange}
                      autoComplete="off"
                      helperText={
                        errors.email &&
                        touched.email && (
                          <span className={classes.errorText}>
                            {errors.email}
                          </span>
                        )
                      }
                      InputProps={{
                        classes: {
                          input: classes.inputFields,
                          root: classes.inputRoot,
                        },
                        disableUnderline: true,
                        startAdornment: (
                          <InputAdornment
                            position="start"
                            className={classes.startAdorn}
                          >
                            <IoIosMail className={classes.mailIcon} />
                          </InputAdornment>
                        ),
                      }}
                      margin="normal"
                      fullWidth
                    />
                    <TextField
                      variant="filled"
                      name="password"
                      type={passwordVisibility ? 'text' : 'password'}
                      placeholder={t("enterYourpassword")}
                      value={values.password}
                      onChange={handleChange}
                      helperText={
                        errors.password &&
                        touched.password && (
                          <span className={classes.errorText}>
                            {errors.password}
                          </span>
                        )
                      }
                      InputProps={{
                        classes: {
                          input: classes.inputFields,
                          root: classes.inputRoot,
                        },
                        disableUnderline: true,
                        startAdornment: (
                          <InputAdornment
                            position="start"
                            className={classes.startAdorn}
                          >
                            <RiLock2Fill className={classes.lockIcon} />
                          </InputAdornment>
                        ),
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={() => showPassword(!passwordVisibility)}
                              edge="end"
                              sx={{ mr: 1 }}
                            >
                              {passwordVisibility ? (
                                <VisibilityIcon style={{ color: '#fff' }} />
                              ) : (
                                <VisibilityOffIcon style={{ color: '#fff' }} />
                              )}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                      inputProps={{
                        autoComplete: 'new-password',
                        form: {
                          autoComplete: 'off',
                        },
                      }}
                      margin="normal"
                      fullWidth
                    />
                    <Grid
                      container
                      justifyContent="space-between"
                      alignItems="center"
                      sx={{ margin: '25px 0px' }}
                    >
                      <Grid item xs={7}>
                        <Typography
                          variant="h6"
                          component="h6"
                          sx={{
                            color: '#fbfbe9',
                            fontWeight: 400,
                            textAlign: 'left',
                          }}
                        >
                          <Checkbox
                            checked={remember}
                            onChange={() => setRemember(!remember)}
                            color="secondary"
                            sx={{ padding: 0 }}
                          />{' '}
                          {t("rememberMe")}
                        </Typography>
                      </Grid>
                      <Grid item xs={5}>
                        <Typography
                          variant="h6"
                          component="h6"
                          sx={{
                            color: '#fbfbe9',
                            fontWeight: 400,
                            textAlign: 'right',
                            '&:hover': {
                              color: '#fbfbe9',
                              textDecoration: 'underline #fbfbe9',
                              cursor: 'pointer'
                            },
                          }}
                          onClick={() => setForgotModal(true)}
                        >
                          {t("forgetPassword")}
                        </Typography>
                      </Grid>
                    </Grid>
                    <Button
                      color="primary"
                      variant="contained"
                      sx={{ borderRadius: '10px', margin: '20px 0px ' }}
                      type="submit"
                    >
                      {t("login")}
                    </Button>
                    <Typography
                      variant="h6"
                      component="h6"
                      sx={{
                        color: '#fbfbe9',
                        fontWeight: 400,
                        marginTop: '20px',
                      }}
                    >
                      {t("dontHaveanAccountyet?")}
                      <Link
                        to="/register"
                        // onClick={() => openRegisterConfirm()}
                        style={{
                          color: '#fbfbe9',
                          textDecoration: 'underline',
                        }}
                      >
                        {t("Signup")}
                      </Link>
                    </Typography>
                    {loginFailureMessage != '' ||
                      Object.keys(errorMessages).length > 0 ? (
                      <Grid
                        sx={{
                          position: 'relative',
                          pt: 0,
                          pb: 0,
                        }}
                      >
                        <Card
                          sx={{
                            borderRadius: '5px',
                            backgroundColor: '#cc493a',
                            border: '#cc493a',
                            ml: 4,
                            mr: 4,
                            mt: 1,
                            color: '#edc9b8',
                            minHeight: '40px',
                            width: '350px',
                            position: 'absolute',
                            maxHeight: 'auto',
                          }}
                        >
                          <CardHeader
                          sx={{p:0,pt:1}}
                            action={
                              <IconButton aria-label="settings">
                                <CancelIcon
                                  onClick={() => closeError()}
                                  size={'lg'}
                                  style={{
                                    cursor: 'pointer',
                                    position: 'absolute',
                                    right: '7px',
                                    top:'-8px',
                                    color: 'white',
                                  }}
                                />
                              </IconButton>
                            }
                            title=""
                            subheader=""
                          />
                          <CardContent sx={{paddingTop:'0px !important'}}>
                            {loginFailureMessage != '' ? (
                              <Typography
                                sx={{
                                  color: '#edc9b8',
                                  fontSize: '12px',
                                  pt: ' 0 !important',
                                  pb: ' 0 !important',
                                }}
                              >
                                {loginFailureMessage}
                              </Typography>
                            ) : (
                              ''
                            )}
                            {errorMessages.email != '' ? (
                              <Typography
                                sx={{
                                  color: '#edc9b8',
                                  fontSize: '12px',
                                  pt: 0,
                                  pb: 0,
                                }}
                              >
                                {errorMessages.email}
                              </Typography>
                            ) : (
                              ''
                            )}
                            {errorMessages.password != '' ? (
                              <Typography
                                sx={{
                                  color: '#edc9b8',
                                  fontSize: '12px',
                                  pt: 0,
                                  pb: 0,
                                }}
                              >
                                {errorMessages.password}
                              </Typography>
                            ) : (
                              ''
                            )}
                          </CardContent>
                        </Card>
                        {' '}
                      </Grid>
                    ) : (
                      ''
                    )}
                  </FormGroup>
                </FormControl>
              </Form>
            )}
          />
          <Dialog
            open={forgotModal}
            onClose={handleClose}
            sx={{ backgroundColor: 'black' }}
          >
            <DialogContent sx={{ maxWidth: '430px' }}>
              <CancelIcon className={classes.closeIcon} onClick={handleClose} />
              <Grid>
                <Typography
                  variant="body1"
                  component="p"
                  sx={{ margin: '20px 20px', marginLeft: '0px !important' }}
                >
                  {t("forgetPassword")}
                </Typography>
                <Typography variant="h5" component="h5" sx={{ color: 'black' }}>
                 {t("forgetPasswordContent")}
                </Typography>
                <TextField
                  variant="filled"
                  name="email"
                  placeholder={t("enterEmail")}
                  autoComplete="off"
                  // value={values.email}
                  onChange={(e) => handleResetPassword(e)}
                  // helperText={
                  //   errors.email &&
                  //   touched.email && (
                  //     <span className={classes.errorText}>{errors.email}</span>
                  //   )
                  // }
                  InputProps={{
                    classes: {
                      input: classes.inputFields,
                      root: classes.inputRootModal,
                    },
                    disableUnderline: true,
                    startAdornment: (
                      <InputAdornment
                        position="start"
                        className={classes.forgotstartAdorn}
                      >
                        <IoIosMail className={classes.forgotmailIcon} />
                      </InputAdornment>
                    ),
                  }}
                  margin="normal"
                  fullWidth
                />
                {props.resetPasswordStatus.STATUS ||
                  props.resetPasswordStatus.STATUS === 'true' ? (
                  <Typography>{t("linkSendSuccess")}</Typography>
                ) : props.resetPasswordStatus.STATUS === false ? (
                  <Typography>Account Not Exist</Typography>
                ) : ""}
                {forgotEmailError !== '' ? <Typography>{forgotEmailError}</Typography> : ''}
                <Button
                  fullWidth
                  color="primary"
                  variant="contained"
                  sx={{
                    borderRadius: '10px',
                    margin: '20px 0px ',
                    fontSize: '17px',
                    height: '50px',
                  }}
                  type="submit"
                  onClick={() => sendResetPasswordLink()}
                >
              {t("send")}
                </Button>
              </Grid>
            </DialogContent>
          </Dialog>
        </Grid>
      </Grid>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    data: state.LoginReducer.userToken,
    resetPasswordStatus: state.ForgotPasswordReducer.data,
    loginStatus: state.LoginReducer.loginMessage,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    loginService: (requestData) =>
      dispatch(loginAction.loginService(requestData)),
    forgotPasswordService: (requestData) =>
      dispatch(forgotAction.forgotPasswordService(requestData)),
    loginStatusNull: () =>
      dispatch(loginAction.loginStatusNull()),
    forgotPasswordServiceNull: () =>
      dispatch(forgotAction.forgotPasswordServiceNull()),
    socialLoginService: (requestData) =>
      dispatch(loginAction.socialLoginService(requestData)),
  };

};

export default connect(mapStateToProps, mapDispatchToProps)(Login);
