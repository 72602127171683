import { Backdrop } from '@mui/material';
import React from 'react';
import { Hearts } from  'react-loader-spinner'
export default function Loader() {
  return (
  <Backdrop
  sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
  open={true}
//   onClick={handleClose}
> <Hearts
  heigth="100"
  width="100"
  color='#ff5e62'
  ariaLabel='loading'
/></Backdrop>
  )
}
