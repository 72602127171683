import axios from 'axios';
import { createAction } from 'redux-actions';

export const deleteUserInitiate = createAction('DELETE_USER_INITIATE');
export const deleteUserSuccess = createAction('DELETE_USER_SUCCESS');
export const deleteUserFailure = createAction('DELETE_USER_FAILURE');

export const DeleteUserByID = (UserID) => {
    const config = {
      headers: {
        // Authorization: `Bearer ${requestData}`,
        'Content-Type': 'multipart/form-data',
      },
    };
    //         const bodyParameters = {
    //      key: "value"
    //   };
    let data = new FormData();
    data.append('id', UserID);
    return (dispatch) => {
      dispatch(deleteUserInitiate());
      axios
        .post(
          `${process.env.REACT_APP_BACKEND_URL }member_remove`,
          data,
          config
        )
        .then((response) => {
          if (response.data.STATUS) {
            dispatch(deleteUserSuccess(response.data.STATUS));
          } else {
            dispatch(deleteUserFailure());
          }
        })
        .catch((error) => {
          dispatch(deleteUserFailure());
        });
    };
  };