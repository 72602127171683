import React, { useState, useEffect } from 'react';
import { Switch, Route, BrowserRouter as Router } from 'react-router-dom';
import LandingPage from './landingPage';
import Login from './login';
import Register from './register';
import OTP from './otp';
import CreateProfile from './createProfile';
import MyProfile from './myProfile';
import UserProfile from './userProfile';
import Newestmembers from './newestMembers';
import CoinPackage from './coinPackage';
import Chat from './chat';
import Stickers from './stickers';
import AboutUs from './aboutUs';
import FAQ from './faq';
import ResetPassword from './resetPassword';
import InYourArea from './inYourArea';
import SearchData from './search';
import Security from './security';
import Notify from '../layouts/notify';
import { connect } from 'react-redux';
import SessionExpired from './sessionExpired';
const AppRoutes = (props) => {
  const [initialLogin, setInitialLogin] = useState(null);
  if (performance.navigation.type == performance.navigation.TYPE_RELOAD) {
    localStorage.removeItem('activeHeader');
  }
  useEffect(() => {
    if (localStorage.getItem('userToken') != null) {
      setInitialLogin(true);
    }
  }, [localStorage.getItem('userToken')]);
  return (
    <>
      {/* {props.data != null || initialLogin != null ? */}
       <Notify subscribe={initialLogin}/> 
       {/* : ''} */}
      <Router>
        <Switch>
          <Route path="/login" component={Login} {...props} />
          <Route path="/register" component={Register} {...props} />
          <Route path="/verify-otp" component={OTP} {...props} />
          <Route path="/create-profile" component={CreateProfile} {...props} />
          <Route path="/my-profile" component={MyProfile} {...props} />
          <Route path="/user-profile" component={UserProfile} {...props} />
          <Route path="/newest-members" component={Newestmembers} {...props} />
          <Route path="/coin-package" component={CoinPackage} {...props} />
          <Route path="/chat" component={Chat} {...props} />
          <Route path="/stickers" component={Stickers} {...props} />
          <Route path="/about-us" component={AboutUs} {...props} />
          <Route path="/faq" component={FAQ} {...props} />
          <Route path="/reset-password" component={ResetPassword} {...props} />
          <Route path="/in-your-area" component={InYourArea} {...props} />
          <Route path="/search-result" component={SearchData} {...props} />
          <Route path="/security" component={Security} {...props} />
          <Route path="/session-expired" component={SessionExpired} {...props} />
          <Route path="/" component={LandingPage} {...props} />
        </Switch>
      </Router>
    </>
  );
};
const mapStateToProps = (state) => {
  return {
    data: state.LoginReducer.userToken,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(AppRoutes);
