import { applyMiddleware, combineReducers, compose, createStore } from "redux";
import thunk from "redux-thunk";
import combinedReducers from "../reducers/combinedReducers";



const composeEnhancers=window._REDUX_DEVTOOLS_EXTENSION_COMPOSE_ || compose

export const store = createStore(
    combineReducers({...combinedReducers}),
    composeEnhancers(applyMiddleware(thunk))
)