import { handleActions } from 'redux-actions';
import { API_STATUS } from '../../../constant';

const StaticPagesReducer = {
  apiStatus: -1,
  aboutUsData: {},
  contactUsData:{},
  privacyPolicyData:{},
  termsConditionsData:{},
  userToken: '',
  imprintData:{}
};

const reducer = handleActions(
  {
    ABOUT_US_INITIATE: (state) => {
      return {
        ...state,
        apiStatus: API_STATUS.initiated,
      };
    },
    ABOUT_US_SUCCESS: (state, action) => {
      return {
        ...state,
        aboutUsData: action.payload,
      };
    },
    ABOUT_US_FAILURE: (state) => {
      return {
        ...state,
        data: {},
        apiStatus: API_STATUS.failed,
      };
    },

    CONTACT_US_INITIATE: (state) => {
        return {
          ...state,
          apiStatus: API_STATUS.initiated,
        };
      },
      CONTACT_US_SUCCESS: (state, action) => {
        return {
          ...state,
          contactUsData: action.payload,
        };
      },
      CONTACT_US_FAILURE: (state) => {
        return {
          ...state,
          data: {},
          apiStatus: API_STATUS.failed,
        };
      },


      PRIVACY_POLICY_INITIATE: (state) => {
        return {
          ...state,
          apiStatus: API_STATUS.initiated,
        };
      },
      PRIVACY_POLICY_SUCCESS: (state, action) => {
        return {
          ...state,
          privacyPolicyData: action.payload,
        };
      },
      PRIVACY_POLICY_FAILURE: (state) => {
        return {
          ...state,
          data: {},
          apiStatus: API_STATUS.failed,
        };
      },


      TERMS_CONDITION_INITIATE: (state) => {
        return {
          ...state,
          apiStatus: API_STATUS.initiated,
        };
      },
      TERMS_CONDITION_SUCCESS: (state, action) => {
        return {
          ...state,
          termsConditionsData: action.payload,
        };
      },
      TERMS_CONDITION_FAILURE: (state) => {
        return {
          ...state,
          data: {},
          apiStatus: API_STATUS.failed,
        };
      },
      IMPRINT_INITIATE: (state) => {
        return {
          ...state,
          apiStatus: API_STATUS.initiated,
        };
      },
      IMPRINT_SUCCESS: (state, action) => {
        return {
          ...state,
          imprintData: action.payload,
        };
      },
      IMPRINT_FAILURE: (state) => {
        return {
          ...state,
          data: {},
          apiStatus: API_STATUS.failed,
        };
      },
  },
  StaticPagesReducer
);
export default reducer;
