import React, { useState, useEffect } from 'react';
import Dropzone from 'react-dropzone';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import {
  Typography,
  Grid,
  Button,
  ButtonGroup,
  FormControl,
  FormGroup,
  TextField,
  MenuItem,
  Card,
  CardContent,
  InputAdornment,
  IconButton,
} from '@mui/material';
import { DesktopDatePicker } from '@mui/x-date-pickers';
import { STATUS, GENDER, INTERESTED_IN } from '../configs';
import { getIsMobile } from '../utils';
import useStyles from '../styles/auth';
import * as registerAction from '../redux/action/registerAction';
import * as searchAction from '../redux/action/searchAction';
import { connect } from 'react-redux';
import { useHistory } from 'react-router';
import AddCircleOutlinedIcon from '@mui/icons-material/AddCircleOutlined';
import { ReactComponent as RedCloseIcon } from '../components/icons/ico_red_close.svg';
import { ReactComponent as CameraIcon } from '../components/icons/ico-camera.svg';
import { ReactComponent as GreenTickIcon } from '../components/icons/ico_green_tick.svg';
import Loader from '../utils/loader';
import CropImage from '../utils/cropImage';
import { useTranslation } from 'react-i18next';
import RegisterConfirmation from '../../src/utils/RegisterConfirmation';
const CreateProfile = (props) => {
  const { t } = useTranslation();
  const history = useHistory();
  const classes = useStyles();
  const isMobile = getIsMobile();
  const [dob, setdob] = useState(null);
  const [gender, setGender] = useState('');
  const [interestedIn, setInterestedIn] = useState('');
  const [selectedEnrollImage, setDragAndDropFiles] = useState({
    file: '',
    imagePreviewUrl: '',
    fileSize: false,
  });
  const [countryData, setCountryData] = useState([]);
  const [stateData, setStateData] = useState([]);
  const [cityData, setCityData] = useState([]);
  const [locationData, setLocationData] = useState({
    country: '',
    state: '',
    city: '',
    countryKey: '',
    stateKey: '',
    cityKey: '',
  });
  const [maxDob, setMaxDOB] = useState(new Date(''));
  const [userNameExist, setUsernameExist] = useState(null);
  const [genderError, setGenderError] = useState(false);
  const [dobError, setDOBError] = useState(false);
  const [IntrestInError, setIntrestInError] = useState(false);
  const [imageError, setImageError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [registerFailureMessage, setRegisterFailureMessage] = useState('');
  const [registerFailureStatus, setRegisterFailureStatus] = useState(false);
  const [openCropModal, setOpenCropModal] = useState(false);
  const [userNameLenReached, setUserNameLenReached] = useState(false);
  const [specialCharactesExist, setSpecialCharacterExist] = useState(false);
  const [registerConfirmation, setRegisterConformation] = useState(false);
  const [registerData, setRegisterData] = useState(null);
  useEffect(() => {
    var maxBirthdayDate = new Date();
    maxBirthdayDate.setFullYear(maxBirthdayDate.getFullYear() - 18);
    maxBirthdayDate.setMonth(
      maxBirthdayDate.getMonth(),
      maxBirthdayDate.getDate()
    );
    setdob(maxBirthdayDate);
    setMaxDOB(maxBirthdayDate);
  }, []);
  const onDrop = (files) => {
    let maxSize = 5242880;
    var mimeType = files[0].type;
    var fileType = mimeType.replace('image/', '');
    const isFileTooLarge = files[0].size > maxSize;
    if (!isFileTooLarge) {
      if (fileType === 'jpg' || fileType === 'jpeg' || fileType === 'png') {
        setRegisterFailureMessage('')
        setRegisterFailureStatus(false)
        setOpenCropModal(true)
        let reader = new FileReader();
        let file = files[0];
        reader.onloadend = () => {
          setDragAndDropFiles({
            file: file,
            imagePreviewUrl: reader.result,
            fileSize: false,
          });
        };
        reader.readAsDataURL(file);
      } else {
        setDragAndDropFiles({
          file: '',
          imagePreviewUrl: '',
          fileSize: true,
        });
        setRegisterFailureMessage('PNG/JPEG/JPG formats only supported!')
        setRegisterFailureStatus(true)
        setOpenCropModal(false)
      }

    } else {
      setDragAndDropFiles({
        file: '',
        imagePreviewUrl: '',
        fileSize: true,
      });
      setRegisterFailureMessage('File is too large')
      setRegisterFailureStatus(true)
      setOpenCropModal(false)
    }
  };
  const handleCropClose = (data) => {
    setOpenCropModal(data)
  };
  const validationSchema = () => {
    return Yup.object().shape({
      username: Yup.string().required(t("userNameRequired")),
      country: Yup.string().required(t("countryRequired")),
      state: Yup.string().required(t("stateRequired")),
      city: Yup.string().required(t("cityisRequired")),
      maritial_status: Yup.string().required(t("mariageStatusRequired")),
    });
  };

  const validate = (getValidationSchema) => {
    return (values) => {
      const validationSchema = getValidationSchema(values);
      try {
        validationSchema.validateSync(values, { abortEarly: false });
        return {};
      } catch (error) {
        return getErrorsFromValidationError(error);
      }
    };
  };

  useEffect(() => {
    if (gender != '') {
      setGenderError(false);
    }
  }, [gender]);
  useEffect(() => {
    if (dob === '') {
      setdob(maxDob);
    }
  }, [dob]);
  useEffect(() => {
    if (selectedEnrollImage.file != '') {
      setImageError(false);
    }
  }, [selectedEnrollImage.file]);
  useEffect(() => {
    if (interestedIn != '') {
      setIntrestInError(false);
    }
  }, [interestedIn]);
  const getErrorsFromValidationError = (validationError) => {
    const FIRST_ERROR = 0;
    let toFocus = false;
    return validationError.inner.reduce((errors, error) => {
      if((error.path==='username'&&error.errors[0]!=='')||userNameLenReached||specialCharactesExist){
        if (isMobile && !toFocus) {
          toFocus = true;
          focusToErrItem('username')
        }
      }
      if (gender === '') {
        setGenderError(true);
        if (isMobile && !toFocus) {
          toFocus = true;
          focusToErrItem('gender')
        }
      } else {
        setGenderError(false);
      }
      if(error.path==='country'&&error.errors[0]!==''){
        if (isMobile && !toFocus) {
          toFocus = true;
          focusToErrItem('country')
        }
      }
      if(error.path==='state'&&error.errors[0]!==''){
        if (isMobile && !toFocus) {
          toFocus = true;
          focusToErrItem('state')
        }
      }
      if(error.path==='city'&&error.errors[0]!==''){
        if (isMobile && !toFocus) {
          toFocus = true;
          focusToErrItem('city')
        }
      }
      if(error.path==='maritial_status'&&error.errors[0]!==''){
        if (isMobile && !toFocus) {
          toFocus = true;
          focusToErrItem('maritial_status')
        }
      }
      if (interestedIn === '') {
        setIntrestInError(true);
        if (isMobile && !toFocus) {
          toFocus = true;
          focusToErrItem('intrestedIn')
        }
      } else {
        setIntrestInError(false);
      }
      if (selectedEnrollImage.file === '') {
        setImageError(true);
        if (isMobile&& !toFocus) {
          toFocus = true;
          focusToErrItem('imageErr')
        }
      } else {
        setImageError(false);
      }
      return { 
        ...errors,
        [error.path]: error.errors[FIRST_ERROR],
      };
    }, {});
  };
  const focusToErrItem = (toBeFocus) => {
    var el = document.querySelector(
      "." + toBeFocus
    );
    if (el) {
      el.scrollIntoView({ behavior: "smooth" });
    }
   
  }
  const getInitialValues = () => {
    const initialValues = {
      username: '',
      country: '',
      state: '',
      city: '',
      maritial_status: '',
      gender: '',
    };
    return initialValues;
  };

  const handleSubmit = (values) => {
    setIsLoading(true)
    let toFocus=false;
    if (dob.getUTCFullYear() <= maxDob.getFullYear()) {


      if (!genderError && !IntrestInError && !imageError && !userNameLenReached && !specialCharactesExist) {
        var mm = dob.getMonth() + 1;
        var dd = dob.getDate();
        var yyyy = dob.getUTCFullYear();
        const requestData = new FormData();
        requestData.append('username', values.username);
        requestData.append('email', localStorage.getItem('email'));
        requestData.append('password', localStorage.getItem('password'));
        requestData.append('dob', `${yyyy}-${mm < 9 ? '0' + mm : mm}-${dd}`);
        requestData.append('gender', gender);
        requestData.append('country_code', values.country);
        requestData.append('city_code', values.city);
        requestData.append('state_code', values.state);
        requestData.append('looking_for', interestedIn);
        requestData.append('reference', localStorage.getItem('socialType') !== null ? localStorage.getItem('socialType') : 'None');
        requestData.append('profile_pic', selectedEnrollImage.file);
        requestData.append('martial_status', values.maritial_status);
        requestData.append('unique_id', localStorage.getItem('socialID'));
        requestData.append('access_token', '');
        requestData.append('tokenId', '');
        requestData.append('about_me', values.long_description);
        if (localStorage.getItem('email') != null) {
          setRegisterData(requestData)
          setRegisterConformation(true)
        } else if (localStorage.getItem('email') === null) {
          history.push('/register');
        }
      } else if(userNameLenReached||specialCharactesExist){
        setIsLoading(false)
        if (isMobile &&!toFocus) {
          toFocus = true;
          focusToErrItem('username')
        }
      }else if(genderError) {
        setIsLoading(false)
        if (isMobile) {
          toFocus = true;
          focusToErrItem('gender')
        }
      }else if(IntrestInError){
        setIsLoading(false)
        if (isMobile&&!toFocus) {
          toFocus = true;
          focusToErrItem('intrestedIn')
        }
      }else if(imageError){
        setIsLoading(false)
        if (isMobile &&!toFocus) {
          toFocus = true;
          focusToErrItem('imageErr')
        }
      }
    } else {
      setDOBError(true)
      setIsLoading(false)
      if (isMobile) {
        focusToErrItem("DOBField")
      }
    }
  };

  const registerFunc = () => {
    props.registerService(registerData);
    setRegisterConformation(false)
  }
  useEffect(() => {
    if (props.registerStatus === 1 || props.registerStatus === '1') {
      setIsLoading(false)
      //localStorage.setItem('email', '');
      // localStorage.setItem('password', '');
      setRegisterFailureMessage('')
      setRegisterFailureStatus(false)
      history.push('/verify-otp');
    }
  }, [props.registerStatus]);

  useEffect(() => {
    if (locationData.countryKey != '') {
      props.getStateService(locationData.countryKey);
    }
  }, [locationData.countryKey]);

  useEffect(() => {
    if (locationData.stateKey != '') {
      props.getCityService(locationData.stateKey);
    }
  }, [locationData.stateKey]);
  useEffect(() => {
    props.getCountryService();
  }, []);
  useEffect(() => {
    let data = {},
      arr = [];
    if (props.countryData.length > 0) {
      props.countryData.forEach((item) => {
        data['label'] = item.name;
        data['value'] = item.id;
        arr.push(data);
        data = {};
      });
    }
    setCountryData(arr);
  }, [props.countryData]);

  useEffect(() => {
    let data = {},
      arr = [];
    if (props.stateData.length > 0) {
      props.stateData.forEach((item) => {
        data['label'] = item.name;
        data['value'] = item.id;
        arr.push(data);
        data = {};
      });
    }
    setStateData(arr);
  }, [props.stateData]);

  useEffect(() => {
    let data = {},
      arr = [];
    if (props.cityData.length > 0) {
      props.cityData.forEach((item) => {
        data['label'] = item.name;
        data['value'] = item.id;
        arr.push(data);
        data = {};
      });
    }
    setCityData(arr);
  }, [props.cityData]);

  const getCountryCode = (e, values) => {
    const key = e.target.value;
    // let newKey = key.replace('-option', '');
    let finalKey = e.target.name;

    if (finalKey === 'country' || finalKey === 'state' || finalKey === 'city') {
      if (finalKey === 'country') {
        setLocationData({
          ...locationData,
          [finalKey + 'Key']: key,
          // country: newValue.label,
        });
      } else if (finalKey === 'state') {
        setLocationData({
          ...locationData,
          [finalKey + 'Key']: key,
          // state: newValue.label,
        });
      } else {
        setLocationData({
          ...locationData,
          [finalKey + 'Key']: key,
          // city: newValue.label,
        });
      }
      // } else {
      //   setLocationData({
      //     ...locationData,
      //     [finalKey]: newValue.label,
      //   });
    }
  };

  const verifyUserName = (e) => {
    let data = new FormData();
    data.append('username', e.target.value);
    var format = /[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;
    if (e.target.value.length >= 6 && !e.target.value.match(format)) {
      props.verifyUniqueUser(data);
      setUserNameLenReached(false)
      setSpecialCharacterExist(false)
    } else if (e.target.value.length >= 6 && e.target.value.match(format)) {
      setSpecialCharacterExist(true)
      setUserNameLenReached(false)
    } else {
      setUserNameLenReached(true)
      setSpecialCharacterExist(false)
    }
    setUsernameExist(null);
  };

  useEffect(() => {
    if (props.is_username_exist) {
      setUsernameExist(true);
      props.uniqueStatusNull()
    } else if (props.is_username_exist === false) {
      setUsernameExist(false);
      props.uniqueStatusNull()
    }
  }, [props.is_username_exist]);

  const IsUserNameExist = (e) => {
    setUsernameExist(null);
    setUserNameLenReached(false)
    setSpecialCharacterExist(false)
  }
  useEffect(() => {
    if (props.registrationFailure === 'Username already exists') {
      setUsernameExist(true);
      setIsLoading(false)
    } else if (props.registrationFailure === 'Email already exists') {
      setIsLoading(false)
      setRegisterFailureMessage('Email already exists')
      setRegisterFailureStatus(true)
      setTimeout(() => {
        history.push('/login');
      }, 5000);

    } else if (props.registrationFailure === 'Mobile number already exists') {
      setIsLoading(false)
      setRegisterFailureMessage('Mobile number already exists')
      setRegisterFailureStatus(true)
      setTimeout(() => {
        history.push('/login');
      }, 5000);
    }
  }, [props.registrationFailure]);

  const newImage = (newfile, url) => {
    setDragAndDropFiles({ imagePreviewUrl: url, file: newfile, fileSize: false, })
    setOpenCropModal(false)
  }
  const closeRegisterDialouge = () => {
    setRegisterConformation(false)
    setIsLoading(false)
  }
  return (
    <div className={classes.bannerBgProfileImage}>
      <RegisterConfirmation dialogStatus={registerConfirmation} closeConfirmation={closeRegisterDialouge} registerFunc={registerFunc} />
      {isLoading ? <Loader /> : ''}
      {openCropModal ? <CropImage srcImg={selectedEnrollImage.imagePreviewUrl} handleCropClose={handleCropClose} cropedImage={newImage} /> : ''}
      <Formik
        initialValues={getInitialValues()}
        validate={validate(validationSchema)}
        onSubmit={handleSubmit}
        enableReinitialize={true}
        render={({ errors, handleChange, handleSubmit, values, touched }) => (
          <Form onSubmit={handleSubmit}>
            <FormControl style={{ width: '100%' }}>
              <FormGroup aria-label="position">
                <Grid
                  container
                  direction="row"
                  justifyContent="space-around"
                  spacing={10}
                  sx={{ padding: '20px 10vw' }}
                >
                  <Grid item xs={12} md={6} lg={5}>
                    <Typography
                      variant="h1"
                      component="h1"
                      sx={{ margin: '40px 0px' }}
                    >
                      {t("createProfile")}
                    </Typography>
                    <Typography
                      variant="h5"
                      component="h5"
                      sx={{ marginTop: '15px' }}
                      className='username'
                    >
                      {t("username")} <span className={classes.asterik}>*</span>
                    </Typography>
                    <TextField
                      autoComplete="off"
                      variant="filled"
                      name="username"
                      placeholder={t("enterUserName")}
                      value={values.username}
                      onChange={(e) => {
                        handleChange(e);
                        IsUserNameExist(e);
                      }}
                      onBlur={(e) => verifyUserName(e)}
                      helperText={
                        <>
                          {errors.username &&
                            touched.username && (
                              <span className={classes.errorText}>
                                {errors.username}
                              </span>
                            )}
                          {userNameLenReached && (
                            <span className={classes.errorText}>
                              {userNameLenReached ? t("userNameAtleast6Char") : ""}
                            </span>
                          )}
                          {specialCharactesExist && (
                            <span className={classes.errorText}>
                              {specialCharactesExist ? t("specialCharNotAllowed") : ""}
                            </span>
                          )}
                        </>
                      }
                      InputProps={{
                        classes: {
                          input: classes.inputFields,
                          root: classes.inputRoot,
                        },
                        disableUnderline: true,
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              edge="end"
                            >
                              {userNameExist === false ? (
                                <GreenTickIcon title="Accepted" />
                              ) : userNameExist === true ? (
                                <RedCloseIcon title="User Name Already Exist" />
                              ) : (
                                ''
                              )}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                      margin="dense"
                      fullWidth
                    />
                    <Typography
                      variant="h5"
                      component="h5"
                      sx={{ marginTop: '15px' }}
                    >
                      {t("birthday")}<span className={classes.asterik}>*</span>
                    </Typography>
                    {/* {isMobile ? (
                      <MobileDatePicker
                        value={dob}
                        onChange={(newValue) => setdob(newValue)}
                        className={classes.inputRoot}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            variant="filled"
                            // helperText={
                            // 	errors.username &&
                            // 	touched.username && (
                            // 		<span className={classes.errorText}>
                            // 			{errors.username}
                            // 		</span>
                            // 	)
                            // }
                            InputProps={{
                              classes: {
                                input: classes.inputFields,
                                root: classes.inputRoot,
                              },
                              disableUnderline: true,
                            }}
                            margin="dense"
                            fullWidth
                          />
                        )}
                      />
                    ) : ( */}
                    {/* //<LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={deLocale}> */}
                    <DesktopDatePicker
                      className='DOBField'
                      value={dob}
                      maxDate={maxDob}
                      // mask="DD/MM/YYYY"
                      onChange={(newValue) => setdob(newValue)}
                      renderInput={(params) => (
                        <TextField
                          // variant="filled"
                          sx={{ backgroundColor: '#d4d4d3c7 !important', borderRadius: '10px !important', }}
                          helperText={
                            errors.dob &&
                            touched.dob && (
                              <span className={classes.errorText}>
                                {errors.dob}
                              </span>
                            )
                          }
                          InputProps={{
                            classes: {
                              input: classes.inputFields,
                              root: classes.inputRoot,
                            },
                            disableUnderline: true,
                          }}
                          margin="dense"
                          fullWidth
                          {...params}
                        />
                      )}
                    />
                    {/* // </LocalizationProvider>
                    )} */}
                    {dobError ? (
                      <Typography
                        variant="p"
                        component="p"
                        color="red"
                        fontSize="12px"
                      >
                        Invalid DOB
                      </Typography>
                    ) : (
                      ''
                    )}
                    <Typography
                      variant="h5"
                      component="h5"
                      sx={{ marginTop: '15px' }}
                      className="gender"
                    >
                      {t("gender")}<span className={classes.asterik}>*</span>
                    </Typography>
                    <ButtonGroup
                      variant="contained"
                      className={classes.buttonGrp}
                      sx={{ display: 'flex', justifyContent: 'space-between' }}
                    >
                      {GENDER.map((option) => (
                        <Button
                          sx={{ width: 300 }}
                          key={option.value}
                          className={
                            option.value === gender
                              ? classes.selectedGenderButton
                              : classes.genderButton
                          }
                          onClick={() => setGender(option.value)}
                        >
                          {t(`${option.label}`)}
                        </Button>
                      ))}
                    </ButtonGroup>
                    {genderError ? (
                      <Typography
                        variant="p"
                        component="p"
                        color="red"
                        fontSize="12px"
                      >
                        {t("selectGender")}
                      </Typography>
                    ) : (
                      ''
                    )}
                    <Typography
                      variant="h5"
                      component="h5"
                      sx={{ marginTop: '15px' }}
                      className='country'
                    >
                      {t("country")} <span className={classes.asterik}>*</span>
                    </Typography>
                    <TextField
                      variant="filled"
                      select
                      name="country"
                      placeholder="Please select your country"
                      value={values.country}
                      onChange={(e) => {
                        handleChange(e);
                        getCountryCode(e);
                      }}
                      helperText={
                        errors.country &&
                        touched.country && (
                          <span className={classes.errorText}>
                            {errors.country}
                          </span>
                        )
                      }
                      InputProps={{
                        classes: {
                          input: classes.inputFields,
                          root: classes.inputRoot,
                        },
                        disableUnderline: true,
                      }}
                      margin="dense"
                      fullWidth
                    >
                      {countryData.map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                          {option.label}
                        </MenuItem>
                      ))}
                    </TextField>
                    <Typography
                      variant="h5"
                      component="h5"
                      sx={{ marginTop: '15px' }}
                      className={`state`}
                    >
                      {t("state")} <span className={classes.asterik}>*</span>
                    </Typography>
                    <TextField
                      variant="filled"
                      select
                      name="state"
                      placeholder="Please select your State"
                      value={values.state}
                      onChange={(e) => {
                        handleChange(e);
                        getCountryCode(e);
                      }}
                      helperText={
                        errors.state &&
                        touched.state && (
                          <span className={classes.errorText}>
                            {errors.state}
                          </span>
                        )
                      }
                      InputProps={{
                        classes: {
                          input: classes.inputFields,
                          root: classes.inputRoot,
                        },
                        disableUnderline: true,
                      }}
                      margin="dense"
                      fullWidth
                    >
                      {stateData.map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                          {option.label}
                        </MenuItem>
                      ))}
                    </TextField>
                    <Typography
                      variant="h5"
                      component="h5"
                      sx={{ marginTop: '15px' }}
                      className={`city`}
                    >
                      {t("city")} <span className={classes.asterik}>*</span>
                    </Typography>
                    <TextField
                      variant="filled"
                      select
                      name="city"
                      placeholder="Please select your city"
                      value={values.city}
                      onChange={handleChange}
                      helperText={
                        errors.city &&
                        touched.city && (
                          <span className={classes.errorText}>
                            {errors.city}
                          </span>
                        )
                      }
                      InputProps={{
                        classes: {
                          input: classes.inputFields,
                          root: classes.inputRoot,
                        },
                        disableUnderline: true,
                      }}
                      margin="dense"
                      fullWidth
                    >
                      {cityData.map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                          {option.label}
                        </MenuItem>
                      ))}
                    </TextField>
                    <Typography
                      variant="h5"
                      component="h5"
                      sx={{ marginTop: '15px' }}
                      className="maritial_status"
                    >
                      {t("status")}<span className={classes.asterik}>*</span>
                    </Typography>
                    <TextField
                      variant="filled"
                      select
                      name="maritial_status"
                      placeholder="Please select your status"
                      value={values.maritial_status}
                      onChange={handleChange}
                      helperText={
                        errors.maritial_status &&
                        touched.maritial_status && (
                          <span className={classes.errorText}>
                            {errors.maritial_status}
                          </span>
                        )
                      }
                      InputProps={{
                        classes: {
                          input: classes.inputFields,
                          root: classes.inputRoot,
                        },
                        disableUnderline: true,
                      }}
                      margin="dense"
                      fullWidth
                    >
                      {STATUS.map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                          {option.label}
                        </MenuItem>
                      ))}
                    </TextField>
                    <Typography
                      variant="h5"
                      component="h5"
                      sx={isMobile ? '' : { marginTop: '15px' }}
                      className="intrestedIn"
                    >
                      {t("intrestedIn")} <span className={classes.asterik}>*</span>
                    </Typography>
                    <ButtonGroup
                      variant="contained"
                      className={classes.buttonGrp}
                      sx={{ display: 'flex', justifyContent: 'space-between' }}
                    >
                      {INTERESTED_IN.map((option) => (
                        <Button
                          sx={{ width: 170 }}
                          key={option.value}
                          className={
                            option.value === interestedIn
                              ? classes.selectedGenderButton
                              : classes.genderButton
                          }
                          onClick={() => setInterestedIn(option.value)}
                        >
                          {t(`${option.label}`)}
                        </Button>
                      ))}
                    </ButtonGroup>
                    {IntrestInError ? (
                      <Typography
                        variant="p"
                        component="p"
                        color="red"
                        fontSize="12px"
                      >
                        {t("showYouintrest")}
                      </Typography>
                    ) : (
                      ''
                    )}
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    md={6}
                    lg={5}
                    sx={isMobile ? { paddingTop: '15px !important' } : ''}
                  >
                    <Typography
                      variant="h5"
                      component="h5"
                      sx={isMobile ? '' : { marginTop: '102px' }}
                    >
                      {t("aLittleBitAboutYou")}
                      {/* <span className={classes.asterik}>*</span> */}
                    </Typography>
                    <TextField
                      multiline
                      autoComplete="off"
                      variant="filled"
                      name="long_description"
                      placeholder={t("descriptionPlaceHolder")}
                      value={values.long_description}
                      onChange={(e) => {
                        handleChange(e);
                      }}
                      // helperText={
                      //   errors.username &&
                      //   touched.username && (
                      //     <span className={classes.errorText}>
                      //       {errors.username}
                      //     </span>
                      //   )
                      // }
                      InputProps={{
                        classes: {
                          input: classes.inputFields,
                          root: classes.inputRoot,
                        },
                        disableUnderline: true,
                      }}
                      margin="dense"
                      sx={{ width: isMobile ? '99%' : '80%' }}
                    />
                    <Typography
                      variant="h5"
                      component="h5"
                      sx={{ marginTop: '15px' }}
                      className=""
                    >
                      {t('uploadPhoto')}
                      {/* <span className={classes.asterik}>*</span> */}
                    </Typography>
                    {/* <Typography
                      variant="body2"
                      component="p"
                      sx={{ marginTop: '15px', color: '#fff' }}
                    >
                      You can add more photos once your skill level up.
                    </Typography> */}
                    <Dropzone
                      // maxSize={5120000}
                      multiple={false}
                      onDrop={(acceptedFiles) => onDrop(acceptedFiles)}
                      accept="image/*"
                    >
                      {({ getRootProps, getInputProps }) => (
                        <Grid container>
                          {selectedEnrollImage.imagePreviewUrl != '' ? (
                            <section className={classes.uploadDragableUpload}>
                              <input {...getInputProps()} />
                              <div
                                {...getRootProps()}
                                className={classes.uploadPlaceholderCt}
                              >
                                {/* {files.map((file, index) => {
                                  return ( */}
                                <img
                                  src={selectedEnrollImage.imagePreviewUrl}
                                  alt=""
                                  style={{ width: '100%', height: '320px', objectFit: 'fill' }}
                                />
                                {/* ); */}
                                {/* })} */}
                              </div>
                            </section>
                          ) : (
                            <section className={classes.uploadDragableUpload}>
                              <input {...getInputProps()} />
                              <div
                                {...getRootProps()}
                                className={classes.uploadPlaceholderCt}
                              ></div>
                              <CameraIcon
                                {...getRootProps()}
                                className={classes.cameraImage}
                              />
                              <AddCircleOutlinedIcon
                                fontSize='large'
                                {...getRootProps()}
                                className={classes.addImage}
                              />
                            </section>
                          )}
                        </Grid>
                      )}
                    </Dropzone>
                    <Typography
                      variant="body2"
                      component="p"
                      sx={{ marginTop: '0px', color: '#fff', fontWeight: 'bold', fontSize: '20px', textAlign: 'center', mr: 10 }}
                    >
                      {t("photoRequired")} <span className={classes.asterik}>*</span>
                    </Typography>
                    {registerFailureStatus === true ? (
                      <Grid
                        sx={{
                          position: 'relative',
                          pt: 0,
                          pb: 0,
                        }}
                      >
                        <Card
                          sx={{
                            borderRadius: '5px',
                            backgroundColor: '#cc493a',
                            border: '#cc493a',
                            color: '#edc9b8',
                            minHeight: '20px',
                            width: '370px',
                            position: 'absolute',
                            maxHeight: 'auto',
                            textAlign: 'center',
                          }}
                        >
                          <CardContent>
                            <Typography
                              sx={{
                                color: '#edc9b8',
                                fontSize: '15px',
                                pt: ' 0 !important',
                                pb: ' 0 !important',
                              }}
                            >
                              {registerFailureMessage}
                            </Typography>
                          </CardContent>
                        </Card>
                      </Grid>
                    ) : (
                      ''
                    )}
                    {/* {imageError ? (
                      <Typography
                        variant="p"
                        component="p"
                        color="red"
                        fontSize="12px"
                      >
                        {t("uploadProfilepicture")}
                      </Typography>
                    ) : (
                      ''
                    )} */}
                    <Button
                      color="primary"
                      variant="contained"
                      sx={
                        !selectedEnrollImage.fileSize
                          ? {
                            borderRadius: '10px',
                            margin: '20px 0px ',
                            width: '80%',
                          }
                          : {
                            borderRadius: '10px',
                            margin: '20px 0px ',
                            width: '80%',
                            mt: 10,
                          }
                      }
                      type="submit"
                    >
                      {t("continue")}
                    </Button>
                  </Grid>
                </Grid>
              </FormGroup>
            </FormControl>
          </Form>
        )}
      />
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    registerStatus: state.RegisterReducer.data,
    countryData: state.SearchReducer.countryData,
    stateData: state.SearchReducer.stateData,
    cityData: state.SearchReducer.cityData,
    is_username_exist: state.RegisterReducer.is_username_exist,
    registrationFailure: state.RegisterReducer.registrationFailure,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    registerService: (requestData) =>
      dispatch(registerAction.registerService(requestData)),
    getCountryService: () => dispatch(searchAction.getCountryService()),
    getStateService: (requestData) =>
      dispatch(searchAction.getStateService(requestData)),
    getCityService: (requestData) =>
      dispatch(searchAction.getCityService(requestData)),
    verifyUniqueUser: (data) => dispatch(registerAction.verifyUniqueUser(data)),
    uniqueStatusNull: () => dispatch(registerAction.uniqueStatusNull()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CreateProfile);
