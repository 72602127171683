import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
	hotbidImg: {
		height: 300,
		position: 'relative',
	},
	userName: {
		fontWeight: `${600} !important`,
		color: '#fff !important',
	},
	location: {
		fontWeight: `${600} !important`,
		color: '#fff !important',
		fontSize: 12,
	},
	highestBid: {
		fontWeight: 700,
		color: theme.secondaryColor,
	},
	bidNumbers: {
		color: '#97a5ff',
		marginLeft: '10px',
		fontWeight: '400',
	},
	highBid: {
		fontWeight: '400',
	},
	hotbidCard: {
		backgroundColor: '#27167e',
		position: 'relative',
	},
	favoriteIcon: {
		position: 'absolute',
		bottom: '12px',
		right: '8px',
		color: '#4b33c5',
		fontSize: '24px',
	},
	userDescription: {
		position: 'absolute',
		bottom: '7px',
		background: '#00000080',
		width: '87%',
		borderRadius: '10px',
		marginLeft: '4px',
		padding: '10px',
	},
	heartIcon: {
		position: 'absolute',
		top: '8px',
		right: '8px',
		cursor:'pointer'
	},
	TimerIcon: {
		position: 'absolute',
		top: '45%',
		right: '45%',
		cursor:'pointer'
	},
	likeIcon: {
		position: 'absolute',
		top: '36px',
		right: '8px',
		cursor:'pointer'
	},
	box1: {
		backgroundColor: '#FF5B5B',
		borderRadius: '4px',
		position: 'absolute',
		top: '6px',
		left: '8px',
		zIndex: 5,
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'flex-end',
		padding: '3px',
	},
}));

export default useStyles;
