import axios from 'axios';
import { createAction } from 'redux-actions';

export const MyFavoriteInitiate = createAction('MYFAVORITE_DETAILS_INITIATE');
export const MyFavoriteSuccess = createAction('MYFAVORITE_DETAILS_SUCCESS');
export const MyFavoriteFailure = createAction('MYFAVORITEL_DETAILS_FAILURE');

export const myFavorite = (requestData) => {
  const config = {
    headers: {
      Authorization: `Bearer ${requestData}`,
    },
  };

  return (dispatch) => {
    dispatch(MyFavoriteInitiate());
    axios
      .get(
        `${process.env.REACT_APP_BACKEND_URL }my_activity`,
    
        config
      )
      .then((response) => {
        if (response.data.STATUS) {
          dispatch(MyFavoriteSuccess(response.data.result.my_favorites));
        } else {
          dispatch(MyFavoriteFailure());
        }
      })
      .catch((error) => {
        if (error.response.status === 401) {
          localStorage.clear();
          window.location.replace('/session-expired');
        }
        else {
        dispatch(MyFavoriteFailure());
        }
      });
  };
};
