import React, { useEffect, useState } from 'react';
import 'react-image-crop/dist/ReactCrop.css';
import ReactCrop from 'react-image-crop';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material';
export default function CropImage(props) {
  const [image, setImage] = useState(null);
  const [crop, setCrop] = useState({ aspect: 0 / 0 });
  const [result, setResult] = useState(null);
  const [blobFile, setResultBlob] = useState(null);
  const getCroppedImg = async () => {
    try {
      const canvas = document.createElement('canvas');
      const scaleX = image.naturalWidth / image.width;
      const scaleY = image.naturalHeight / image.height;
      canvas.width = crop.width;
      canvas.height = crop.height;
      const ctx = canvas.getContext('2d');
      ctx.drawImage(
        image,
        crop.x * scaleX,
        crop.y * scaleY,
        crop.width * scaleX,
        crop.height * scaleY,
        0,
        0,
        crop.width,
        crop.height
      );
      const base64Image = crop.width===0||crop.height===0?props.srcImg:canvas.toDataURL('image/jpeg', 1);
      setResult(base64Image);
    } catch (e) {
      console.log('crop the image');
    }
  };

  useEffect(() => {
      if(result !=null){
        fetch(result)
        .then((res) => res.blob())
        .then((blob) => {
          const file = new File([blob], 'ready.jpeg', { type: 'image/jpeg' });
          setResultBlob(URL.createObjectURL(file));
          props.cropedImage(file,URL.createObjectURL(file))
        });
      }
   
  }, [result]);

  return (
    <Dialog
      open={true}
      onClose={()=>props.handleCropClose(false)}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">Image Resize</DialogTitle>
      <DialogContent xs={12} sx={{ display: 'flex', justifyContent: 'center' }}>
        {result===null?<ReactCrop
          style={{ maxWidth: '50%' }}
          src={props.srcImg}
          onImageLoaded={setImage}
          crop={crop}
          onChange={setCrop}
        />:<ReactCrop
        style={{ maxWidth: '50%' }}
        src={result}
        onImageLoaded={setImage}
        // crop={crop}
        onChange={setCrop}
      />}
      </DialogContent>
      {result === null ? (
        <DialogContent
          xs={12}
          sx={{ display: 'flex', justifyContent: 'center' }}
        >
          <Button
            color="secondary"
            variant="contained"
            sx={{ mr: 2 }}
            onClick={()=>props.handleCropClose(false)}
          >
            CLOSE
          </Button>
          <Button onClick={()=>getCroppedImg()} color="primary" variant="contained">
            CROP
          </Button>
        </DialogContent>
      ) : (
        ''
      )}
      {result != null ? (
        <DialogActions>
          <Button
            onClick={()=>props.handleCropClose(false)}
            color="secondary"
            variant="contained"
          >
            CLOSE
          </Button>
          <Button
            onClick={()=>props.handleCropClose(false)}
            color="primary"
            variant="contained"
          >
            SAVE
          </Button>
        </DialogActions>
      ) : (
        ''
      )}
    </Dialog>
  );
}
