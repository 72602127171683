import axios from 'axios';
import { createAction } from 'redux-actions';

export const happyClientsInitiate = createAction('GET_HAPPY_CLIENTS_INITIATE');
export const happyClientsSuccess = createAction('GET_HAPPY_CLIENTS_SUCCESS');
export const happyClientsFailure = createAction('GET_HAPPY_CLIENTS_FAILURE');


export const getHappyClientsService = () => {

    return (dispatch) => {
      dispatch(happyClientsInitiate());
      axios
        .get(`${process.env.REACT_APP_BACKEND_URL }happy_clients`)
        .then((response) => {
          if (response.data.STATUS) {                                                                                 
            dispatch(happyClientsSuccess(response.data.result));
          } else {
            dispatch(happyClientsFailure());
          }
        })
        .catch((error) => {
          dispatch(happyClientsFailure());
        });
    };
  };

