import React from 'react';
import { CircularProgress } from '@mui/material';
import Header from '../components/layout/header';
import Footer from '../components/layout/footer';
import LoadingOverlay from 'react-loading-overlay';

const AppLayout = ({ loading, children }) => {
	return (
		<LoadingOverlay
			active={loading}
			spinner={<CircularProgress />}
			text="Please wait..."
			styles={{
				content: (base) => ({
					...base,
					color: '#919191',
					marginTop: '50vh',
				}),
				overlay: (base) => ({
					...base,
					zIndex: 9999,
					color: '#919191',
					backgroundColor: 'rgba(255,255,255,.5)',
				}),
			}}
		>
			<Header />
			{children}
			<Footer />
		</LoadingOverlay>
	);
};

export default AppLayout;
