import React from 'react';
import { Grid, Typography, Divider } from '@mui/material';
import { useTranslation } from 'react-i18next';

const WhyUsSecond = () => {
	const { t } = useTranslation();
	return (
		<Grid
			container
			columns={12}
			spacing={4}
			justifyContent="space-between"
			alignItems="center"
			sx={{ marginTop: '0px', textAlign: 'center' }}
		>
			<Grid item xs={12} md={1.4} sx={{ paddingLeft: '0px' }}>
				{/* <img src="/assets/icons/ico_mail.png" alt="mail" />
				<Typography variant="body1" component="p" sx={{ marginTop: '30px' }}>
					Consectetur adipiscing elit.
				</Typography>
				<Typography variant="body2" component="p" sx={{ marginTop: '10px' }}>
					Consectetur adipiscing elit. In nec aliquet arcu.
				</Typography> */}
			</Grid>
			<Divider orientation="vertical" flexItem={true} />
			<Grid item xs={12} md={2.8} sx={{ paddingLeft: '0px' }}>
				<img src="/assets/icons/ico_bag.png" alt="bag" />
				<Typography variant="body1" component="p" sx={{ marginTop: '30px' }}>
				{t("consecteturAdipiscingelit4")}
				</Typography>
				{/* <Typography variant="body2" component="p" sx={{ marginTop: '10px' }}>
					Consectetur adipiscing elit. In nec aliquet arcu.
				</Typography> */}
			</Grid>
			<Divider orientation="vertical" flexItem={true} />
			<Grid item xs={12} md={2.8} sx={{ paddingLeft: '0px' }}>
				<img src="/assets/icons/ico_message.png" alt="message" />
				<Typography variant="body1" component="p" sx={{ marginTop: '30px' }}>
				{t("consecteturAdipiscingelit5")}
				</Typography>
				{/* <Typography variant="body2" component="p" sx={{ marginTop: '10px' }}>
					Consectetur adipiscing elit. In nec aliquet arcu.
				</Typography> */}
			</Grid>
			<Divider orientation="vertical" flexItem={true} />
			<Grid item xs={12} md={2.8} sx={{ paddingLeft: '0px' }}>
				<img src="/assets/icons/ico_planet.png" alt="planet" />
				<Typography variant="body1" component="p" sx={{ marginTop: '30px' }}>
				{t("consecteturAdipiscingelit6")}
				</Typography>
				{/* <Typography variant="body2" component="p" sx={{ marginTop: '10px' }}>
					Consectetur adipiscing elit. In nec aliquet arcu.
				</Typography> */}
			</Grid>
			<Grid item xs={12} md={1.4} sx={{ paddingLeft: '0px' }}></Grid>
		</Grid>
	);
};
export default WhyUsSecond;
