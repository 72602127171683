import React from 'react';
import { connect } from 'react-redux';
import { Grid, Card, Tabs, Tab, Box, Typography } from '@mui/material';
import { TabContext } from '@mui/lab';
import PropTypes from 'prop-types';
import useStyles from '../../styles/header';
import { getIsMobile } from '../../utils';
import AccountCircleTwoToneIcon from '@mui/icons-material/AccountCircleTwoTone';
import SupportTwoToneIcon from '@mui/icons-material/SupportTwoTone';
import CollectionsTwoToneIcon from '@mui/icons-material/CollectionsTwoTone';
import FavoriteTwoToneIcon from '@mui/icons-material/FavoriteTwoTone';
import VisibilityTwoToneIcon from '@mui/icons-material/VisibilityTwoTone';
import AccessibilityNewTwoToneIcon from '@mui/icons-material/AccessibilityNewTwoTone';
import TrackChangesTwoToneIcon from '@mui/icons-material/TrackChangesTwoTone';
import SentimentVerySatisfiedIcon from '@mui/icons-material/SentimentVerySatisfied';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import * as profileTabAction from '../../redux/action/profileTabAction';
import { useHistory } from 'react-router';
import { useTranslation } from 'react-i18next';
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{props.children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const UserSubheader = (props) => {
  const history=useHistory();
  const classes = useStyles();
  const isMobile = getIsMobile();
  const { t } = useTranslation();
  const handleChange = (event, newValue) => {
    props.setSelectedTab(newValue);
    history.push('/my-profile')
  };

  const renderMobile = () => {
    return (
      <TabContext value={props.selectedTab}>
        <Box
          sx={{
            borderBottom: 1,
            borderColor: 'divider',
            bgcolor: 'background.paper',
            borderRadius: '10px',
          }}
        >
          <Tabs
            value={props.selectedTab}
            onChange={handleChange}
            aria-label="basic tabs example"
          >
            <Tab
              className={`${isMobile ? classes.tabsMobileView : ''}`}
              label={
                <Grid container direction="row" alignItems="center">
                  <Grid item>
                    <AccountCircleTwoToneIcon />
                  </Grid>
                  <Grid item>{props.selectedTab === 0 ? <>{t("profile")}</> : ''}</Grid>
                </Grid>
              }
              {...a11yProps(0)}
            />
              <Tab
                className={`${isMobile ? classes.tabsMobileView : ''}`}
                label={
                  <Grid container direction="row" alignItems="center">
                    <Grid item>
                      <TrackChangesTwoToneIcon />
                    </Grid>
                    <Grid item>{props.selectedTab === 1 ? <>{t("Tree")}</> : ''}</Grid>
                  </Grid>
                }
                {...a11yProps(5)}
              />
            <Tab
              className={`${isMobile ? classes.tabsMobileView : ''}`}
              label={
                <Grid container direction="row" alignItems="center">
                  <Grid item>
                    <SupportTwoToneIcon />
                  </Grid>
                  <Grid item>{props.selectedTab === 2 ? <>{t("Spin")}</> : ''}</Grid>
                </Grid>
              }
              {...a11yProps(1)}
            />
            <Tab
              className={`${isMobile ? classes.tabsMobileView : ''}`}
              label={
                <Grid container direction="row" alignItems="center">
                  <Grid item>
                    <CollectionsTwoToneIcon />
                  </Grid>
                  <Grid item>{props.selectedTab === 3 ? <>{t("Gallery")}</> : ''}</Grid>
                </Grid>
              }
              {...a11yProps(2)}
            />
            <Tab
              className={`${isMobile ? classes.tabsMobileView : ''}`}
              label={
                <Grid container direction="row" alignItems="center">
                  <Grid item>
                    <FavoriteTwoToneIcon />
                  </Grid>
                  <Grid item>{props.selectedTab === 4 ? <>{t("Favourites")}</> : ''}</Grid>
                </Grid>
              }
              {...a11yProps(3)}
            />
            <Tab
              className={`${isMobile ? classes.tabsMobileView : ''}`}
              label={
                <Grid container direction="row" alignItems="center">
                  <Grid item>
                    <AccessibilityNewTwoToneIcon />
                  </Grid>
                  <Grid item>{props.selectedTab === 5 ? <> {t("History")}</> : ''}</Grid>
                </Grid>
              }
              {...a11yProps(4)}
            />
            <Tab
              className={`${isMobile ? classes.tabsMobileView : ''}`}
              label={
                <Grid container direction="row" alignItems="center">
                  <Grid item>
                    <VisibilityTwoToneIcon />
                  </Grid>
                  <Grid item>{props.selectedTab === 6 ? <> {t("Visitors")}</> : ''}</Grid>
                </Grid>
              }
              {...a11yProps(5)}
            />
               <Tab
              className={`${isMobile ? classes.tabsMobileView : ''}`}
              label={
                <Grid container direction="row" alignItems="center">
                  <Grid item>
                    <SentimentVerySatisfiedIcon />
                  </Grid>
                  <Grid item>{props.selectedTab === 7 ? <> {t("Stickers")}</> : ''}</Grid>
                </Grid>
              }
              {...a11yProps(5)}
            />
               <Tab
              className={`${isMobile ? classes.tabsMobileView : ''}`}
              label={
                <Grid container direction="row" alignItems="center">
                  <Grid item>
                    <MonetizationOnIcon />
                  </Grid>
                  <Grid item>{props.selectedTab === 8 ? <> {t("Packages")}</> : ''}</Grid>
                </Grid>
              }
              {...a11yProps(5)}
            />
          </Tabs>
        </Box>
        <TabPanel value={props.selectedTab} index={props.selectedTab}>
          {props.children}
        </TabPanel>
      </TabContext>
    );
  };

  const renderWeb = () => {
    return (
      <TabContext value={props.selectedTab}>
        <Card
          sx={{
            borderRadius: '10px',
            margin: '20px 0px',
          }}
        >
          <Tabs
            value={props.selectedTab}
            onChange={handleChange}
            aria-label="basic tabs example"
          >
            <Tab
              label={
                <Typography
                  variant="h6"
                  component="h6"
                  sx={{
                    color: props.selectedTab === 0 ? '#fe456e' : '#7a797a',
                    textTransform: 'none',
                  }}
                >
                {t("profile")}
                  {/* {!isMobile ? "Profile" : <AccountCircleTwoToneIcon />} */}
                </Typography>
              }
              {...a11yProps(0)}
            />
            <Tab
              label={
                <Typography
                  variant="h6"
                  component="h6"
                  sx={{
                    color: props.selectedTab === 1 ? '#fe456e' : '#7a797a',
                    textTransform: 'none',
                  }}
                >
            {t("spinThewheel")}
                  {/* {!isMobile ? "Spin the Wheel" : <ImSpinner4 />} */}
                </Typography>
              }
              {...a11yProps(1)}
            />
            <Tab
              label={
                <Typography
                  variant="h6"
                  component="h6"
                  sx={{
                    color: props.selectedTab === 2 ? '#fe456e' : '#7a797a',
                    textTransform: 'none',
                  }}
                >
                    {t("myGallery")}
                </Typography>
              }
              {...a11yProps(2)}
            />
            <Tab
              label={
                <Typography
                  variant="h6"
                  component="h6"
                  sx={{
                    color: props.selectedTab === 3 ? '#fe456e' : '#7a797a',
                    textTransform: 'none',
                  }}
                >
                    {t("myFavorites")}
                </Typography>
              }
              {...a11yProps(3)}
            />
            <Tab
              label={
                <Typography
                  variant="h6"
                  component="h6"
                  sx={{
                    color: props.selectedTab === 4 ? '#fe456e' : '#7a797a',
                    textTransform: 'none',
                  }}
                >
                  {t("memberHistory")}
                </Typography>
              }
              {...a11yProps(4)}
            />
            <Tab
              label={
                <Typography
                  variant="h6"
                  component="h6"
                  sx={{
                    color: props.selectedTab === 5 ? '#fe456e' : '#7a797a',
                    textTransform: 'none',
                  }}
                >
                 {t("lastVisitors")}
                </Typography>
              }
              {...a11yProps(5)}
            />
          </Tabs>
        </Card>
        <TabPanel value={props.selectedTab} index={props.selectedTab}>
          {props.children}
        </TabPanel>
      </TabContext>
    );
  };

  return (
    <>
      {/* {renderWeb()} */}
      {isMobile ? renderMobile() : renderWeb()}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
	  selectedTab:state.ProfileTabReducer.selectedTab
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setSelectedTab: (requestData) =>
      dispatch(profileTabAction.setSelectedTab(requestData)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(UserSubheader);
