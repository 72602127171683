import axios from "axios";
import {createAction} from "redux-actions";

export const verifyResetPasswordTokenInitiate=createAction("VALIDATE_TOKEN_INITIATE");
export const verifyResetPasswordTokenSuccess=createAction("VALIDATE_TOKEN_SUCCESS");
export const verifyResetPasswordTokenFailure=createAction("VALIDATE_TOKEN_FAILURE");


export const resetPasswordInitiate=createAction("RESET_PASSWORD_INITIATE");
export const resetPasswordSuccess=createAction("RESET_PASSWORD_SUCCESS");
export const resetPasswordFailure=createAction("RESET_PASSWORD_FAILURE");

export const verifyToken = (token)=>{
    // const config ={
    //     params: {
    //       token:requestData
    //     }
    //   }
    return (dispatch)=>{
        dispatch(verifyResetPasswordTokenInitiate());
        axios.post(`${process.env.REACT_APP_BACKEND_URL }token_valid`, {token:token})
          .then((response)=>{
              if(response.data.STATUS) {
                dispatch(verifyResetPasswordTokenSuccess(response.data.result));
             }else{
                dispatch(verifyResetPasswordTokenFailure());
             }
          })
          .catch((error)=>{
            dispatch(verifyResetPasswordTokenFailure());
          })
    }
}


export const resetPassword = (token,password)=>{
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
    return (dispatch)=>{
        dispatch(resetPasswordInitiate());
        axios.post(`${process.env.REACT_APP_BACKEND_URL }reset_password`, {token:token,password:password},config)
          .then((response)=>{
              if(response.data.STATUS) {
                dispatch(resetPasswordSuccess(response.data.result));
             }else{
                dispatch(resetPasswordFailure());
             }
          })
          .catch((error)=>{
            dispatch(resetPasswordFailure());
          })
    }
}