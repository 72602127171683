import { handleActions } from 'redux-actions';

const PackageReducer = {
  packageList: [],
  paymentStatus:null
};

const reducer = handleActions(
  {
    GET_PACKAGE_LIST_INITIATE: (state) => {
      return {
        ...state,
      };
    },
    GET_PACKAGE_LIST_SUCCESS: (state, action) => {
      return {
        ...state,
        packageList: action.payload,
      };
    },
    GET_PACKAGE_LIST_FAILURE: (state, action) => {
      return {
        ...state,
        packageList:[]
      };
    },
    PURCHASE_INITIATE: (state, action) => {
      return {
        ...state,
      
      };
    },
    PURCHASE_SUCCESS: (state, action) => {
      return {
        ...state,
       
      };
    },
    PURCHASE_FAILURE: (state, action) => {
      return {
        ...state,
       
      };
    },
    TRANSACTION_COMPLETED_INITIATE: (state, action) => {
      return {
        ...state,
       
      };
    },
    TRANSACTION_COMPLETED_SUCCESS: (state, action) => {
      return {
        ...state,
        paymentStatus:true
      };
    },
    TRANSACTION_COMPLETED_FAILURE: (state, action) => {
      return {
        ...state,
        paymentStatus:false
      };
    },
    SET_PURCHASE_STATUSNULL: (state, action) => {
      return {
        ...state,
        paymentStatus:null
      };
    },
  },
  PackageReducer
);
export default reducer;
