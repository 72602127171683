import axios from 'axios';
import { createAction } from 'redux-actions';

export const postPhotoGalleryInitiate = createAction(
  'POST_PHOTO_GALLERY_INITIATE'
);
export const postPhotoGallerySuccess = createAction(
  'POST_PHOTO_GALLERY_SUCCESS'
);
export const postPhotoGalleryFailure = createAction(
  'POST_PHOTO_GALLERY_FAILURE'
);

export const getGalleryPhotosInitiate = createAction(
  'GET_GALLERY_PHOTOS_INITIATE'
);
export const getGalleryPhotosSuccess = createAction(
  'GET_GALLERY_PHOTOS_SUCCESS'
);
export const getGalleryPhotosFailure = createAction(
  'GET_GALLERY_PHOTOS_FAILURE'
);

export const getGalleryPhotosChatInitiate = createAction(
  'GET_GALLERY_PHOTOS_CHAT_INITIATE'
);
export const getGalleryPhotosChatSuccess = createAction(
  'GET_GALLERY_PHOTOS_CHAT_SUCCESS'
);
export const getGalleryPhotosChatFailure = createAction(
  'GET_GALLERY_PHOTOS_CHAT_FAILURE'
);
export const GalleryPhotoDetailsInitiate = createAction(
  'GALLERY_PHOTO_DETAILS_INITIATE'
);
export const GalleryPhotoDetailsSuccess = createAction(
  'GALLERY_PHOTO_DETAILS_SUCCESS'
);
export const GalleryPhotoDetailsFailure = createAction(
  'GALLERY_PHOTO_DETAILS_FAILURE'
);

export const LikeOthersPhotoIntiate = createAction(
  'LIKE_OTHERS_PHOTO_INITIATE'
);
export const LikeOthersPhotoSuccess = createAction('LIKE_OTHERS_PHOTO_SUCCESS');
export const LikeOthersPhotoFailure = createAction('LIKE_OTHERS_PHOTO_FAILURE');

export const CommentOthersPhotoIntiate = createAction(
  'COMMENT_OTHERS_PHOTO_INITIATE'
);
export const CommentOthersPhotoSuccess = createAction(
  'COMMENT_OTHERS_PHOTO_SUCCESS'
);
export const CommentOthersPhotoFailure = createAction(
  'COMMENT_OTHERS_PHOTO_FAILURE'
);

export const uploadGalleryPhotos = (requestData, files) => {
  const config = {
    headers: {
      Authorization: `Bearer ${requestData}`,
      'Content-Type': `multipart/form-data`,
    },
  };
  return (dispatch) => {
    dispatch(postPhotoGalleryInitiate());
    axios
      .post(
        `${process.env.REACT_APP_BACKEND_URL}update_user_gallery`,
        files,
        config
      )
      .then((response) => {
        if (response.data.STATUS) {
          dispatch(postPhotoGallerySuccess(response.data.result));
        } else {
          dispatch(postPhotoGalleryFailure());
        }
      })
      .catch((error) => {
        if (error.response.status === 401) {
          localStorage.clear();
          window.location.replace('/session-expired');
        }
        else {
        dispatch(postPhotoGalleryFailure());
        }
      });
  };
};

export const getGalleryPhotoList = (requestData) => {
  const config = {
    headers: {
      Authorization: `Bearer ${requestData}`,
    },
  };

  //  const bodyParameters = {
  //    key: "value"
  //  };
  return (dispatch) => {
    dispatch(getGalleryPhotosInitiate());
    axios
      .get(
        `${process.env.REACT_APP_BACKEND_URL}gallery_photo_list`,
        config
      )
      .then((response) => {
        if (response.data.STATUS) {
          dispatch(getGalleryPhotosSuccess(response.data));
        } else {
          dispatch(getGalleryPhotosFailure());
        }
      })
      .catch((error) => {
        if (error.response.status === 401) {
          localStorage.clear();
          window.location.replace('/session-expired');
        }
        else {
        dispatch(getGalleryPhotosFailure());
        }
      });
  };
};

export const getGalleryPhotoListChat = (requestData) => {
  const config = {
    headers: {
      Authorization: `Bearer ${requestData}`,
    },
  };

  //  const bodyParameters = {
  //    key: "value"
  //  };
  return (dispatch) => {
    dispatch(getGalleryPhotosChatInitiate());
    axios
      .get(
        `${process.env.REACT_APP_BACKEND_URL}chat_gallery`,
        config
      )
      .then((response) => {
        if (response.data.STATUS) {
          dispatch(getGalleryPhotosChatSuccess(response.data));
        } else {
          dispatch(getGalleryPhotosFailure());
        }
      })
      .catch((error) => {
        if (error.response.status === 401) {
          localStorage.clear();
          window.location.replace('/session-expired');
        }
        else {
        dispatch(getGalleryPhotosChatFailure());
        }
      });
  };
};

export const getGalleryPhotoDetails = (token, imageId) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const bodyParameters = {
    key: 'value',
  };
  let data = new FormData();
  data.append('gallery_id', imageId);

  return (dispatch) => {
    dispatch(GalleryPhotoDetailsInitiate());
    axios
      .post(
        `${process.env.REACT_APP_BACKEND_URL}gallery_single_photo`,
        data,
        config,
        bodyParameters
      )
      .then((response) => {
        if (response.data.STATUS) {
          dispatch(GalleryPhotoDetailsSuccess(response.data));
        } else {
          dispatch(GalleryPhotoDetailsFailure());
        }
      })
      .catch((error) => {
        if (error.response.status === 401) {
          localStorage.clear();
          window.location.replace('/session-expired');
        }
        else {
        dispatch(GalleryPhotoDetailsFailure());
        }
      });
  };
};

export const LikeOtherMemberPhotos = (data, token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  //  const bodyParameters = {
  //    key: "value"
  //  };
  return (dispatch) => {
    dispatch(LikeOthersPhotoIntiate());
    axios
      .post(
        `${process.env.REACT_APP_BACKEND_URL}like_photo`,
        data,
        config
      )
      .then((response) => {
        if (response.data.STATUS) {
          dispatch(LikeOthersPhotoSuccess(response.data.result));
        } else {
          dispatch(LikeOthersPhotoFailure());
        }
      })
      .catch((error) => {
        if (error.response.status === 401) {
          localStorage.clear();
          window.location.replace('/session-expired');
        }
        else {
        dispatch(LikeOthersPhotoFailure());
        }
      });
  };
};

export const CommentOtherMemberPhotos = (data, token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  //  const bodyParameters = {
  //    key: "value"
  //  };
  return (dispatch) => {
    dispatch(CommentOthersPhotoIntiate());
    axios
      .post(
        `${process.env.REACT_APP_BACKEND_URL}comment_other_member_gallery_photo`,
        data,
        config
      )
      .then((response) => {
        if (response.data.STATUS) {
          dispatch(CommentOthersPhotoSuccess(response.data.result));
        } else {
          dispatch(CommentOthersPhotoFailure(response.data));
        }
      })
      .catch((error) => {
        if (error.response.status === 401) {
          localStorage.clear();
          window.location.replace('/session-expired');
        }
        else {
        dispatch(CommentOthersPhotoFailure());
        }
      });
  };
};
export const GalleryToProfileIntiate = createAction(
  'SET_AS_PROFILE_PICTURE_INITIATE'
);
export const GalleryToProfileSuccess = createAction(
  'SET_AS_PROFILE_PICTURE_SUCCESS'
);
export const GalleryToProfileFailure = createAction(
  'SET_AS_PROFILE_PICTURE_FAILURE'
);

export const setAsProfilePic = (data, token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  //  const bodyParameters = {
  //    key: "value"
  //  };
  return (dispatch) => {
    dispatch(GalleryToProfileIntiate());
    axios
      .post(
        `${process.env.REACT_APP_BACKEND_URL}make_profile_photo`,
        data,
        config
      )
      .then((response) => {
        if (response.data.STATUS) {
          dispatch(GalleryToProfileSuccess(response.data));
        } else {
          dispatch(GalleryToProfileFailure());
        }
      })
      .catch((error) => {
        if (error.response.status === 401) {
          localStorage.clear();
          window.location.replace('/session-expired');
        }
        else {
        dispatch(GalleryToProfileFailure());
        }
      });
  };
};

export const setPhotoChangetatusNull = createAction(
  'SET_PHOTO_CHANGE_STATUS_NULL'
);
export const profileChangeStatusNull = () => {
  return (dispatch) => {
    dispatch(setPhotoChangetatusNull());
  };
};

export const pictureVisibilityChangeIntiate = createAction(
  'CHANGE_PICTURE_VISIBILITY_INITIATE'
);
export const pictureVisibilityChangeSuccess = createAction(
  'CHANGE_PICTURE_VISIBILITY_SUCCESS'
);
export const pictureVisibilityChangeFailure = createAction(
  'CHANGE_PICTURE_VISIBILITY_FAILURE'
);

export const setPicVisibility = (data, token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  //  const bodyParameters = {
  //    key: "value"
  //  };
  return (dispatch) => {
    dispatch(pictureVisibilityChangeIntiate());
    axios
      .post(
        `${process.env.REACT_APP_BACKEND_URL}visibility_change`,
        data,
        config
      )
      .then((response) => {
        if (response.data.STATUS) {
          dispatch(pictureVisibilityChangeSuccess(response.data));
        } else {
          dispatch(pictureVisibilityChangeFailure());
        }
      })
      .catch((error) => {
        if (error.response.status === 401) {
          localStorage.clear();
          window.location.replace('/session-expired');
        }
        else {
        dispatch(pictureVisibilityChangeFailure());
        }
      });
  };
};

export const deletePictureIntiate = createAction(
  'DELETE_GALLERY_PICTURE_INITIATE'
);
export const deletePictureSuccess = createAction(
  'DELETE_GALLERY_PICTURE_SUCCESS'
);
export const deletePictureFailure = createAction(
  'DELETE_GALLERY_PICTURE_FAILURE'
);

export const deleteGalleryPicture = (data, token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  //  const bodyParameters = {
  //    key: "value"
  //  };
  return (dispatch) => {
    dispatch(deletePictureIntiate());
    axios
      .post(
        `${process.env.REACT_APP_BACKEND_URL}delete_gallery_photo`,
        data,
        config
      )
      .then((response) => {
        if (response.data.STATUS) {
          dispatch(deletePictureSuccess(response.data));
        } else {
          dispatch(deletePictureFailure());
        }
      })
      .catch((error) => {
        if (error.response.status === 401) {
          localStorage.clear();
          window.location.replace('/session-expired');
        }
        else {
        dispatch(deletePictureFailure());
        }
      });
  };
};

export const LikeForCommentIntiate = createAction('LIKE_FOR_COMMENT_INITIATE');
export const LikeFoCommentSuccess = createAction('LIKE_FOR_COMMENT_SUCCESS');
export const LikeForCommentFailure = createAction('LIKE_FOR_COMMENT_FAILURE');

export const LikeForComment = (data, token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  //  const bodyParameters = {
  //    key: "value"
  //  };
  return (dispatch) => {
    dispatch(LikeForCommentIntiate());
    axios
      .post(
        `${process.env.REACT_APP_BACKEND_URL}like_comment`,
        data,
        config
      )
      .then((response) => {
        if (response.data.STATUS) {
          dispatch(LikeFoCommentSuccess(response.data));
        } else {
          dispatch(LikeForCommentFailure());
        }
      })
      .catch((error) => {
        if (error.response.status === 401) {
          localStorage.clear();
          window.location.replace('/session-expired');
        }
        else {
        dispatch(LikeForCommentFailure());
        }
      });
  };
};
