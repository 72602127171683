import React from 'react';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { create } from 'jss';
// import rtl from 'jss-rtl';
import {
	createGenerateClassName,
	jssPreset,
	StylesProvider,
} from '@mui/styles';
import customTheme from './theme';

const AppTheme = ({ direction, language, children }) => {
	// Configure JSS
	const plugins = [...jssPreset().plugins];
	// if (direction === 'rtl') {
	// 	plugins.push(rtl());
	// }
	const languageStyles = {};
	if (language !== 'en') {
		// languageStyles = import('@material-ui/core/locale');
	}
	const jss = create({ plugins: [...plugins] });
	const generateClassName = createGenerateClassName();
	const theme = createTheme(
		{
			...customTheme,
			direction,
		},
		languageStyles
	);

	return (
		<StylesProvider jss={jss} generateClassName={generateClassName}>
			<ThemeProvider theme={theme}>{children}</ThemeProvider>
		</StylesProvider>
	);
};

export default AppTheme;
