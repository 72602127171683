import { handleActions } from "redux-actions"

const FAQReducer = {
    faqData: [],
    newQuestionStatus:null
}

const reducer = handleActions(
    {
        GET_FAQ_INITIATE: (state) => {
            return {
                ...state,
            }
        },
        GET_FAQ_SUCCESS: (state, action) => {
            return {
                ...state,
                faqData: action.payload,
            }
        },
        GET_FAQ_FAILURE: (state) => {
            return {
                ...state,
                faqData: []
            }
        },
        POST_NEW_QUESTION_INITIATE: (state) => {
            return {
                ...state,
            }
        },
        POST_NEW_QUESTION_SUCCESS: (state, action) => {
            return {
                ...state,
                newQuestionStatus: action.payload,
            }
        },
        POST_NEW_QUESTION_FAILURE: (state,action) => {
            return {
                ...state,
                newQuestionStatus: action.payload,
            }
        },
    },
    FAQReducer
);
export default reducer