import React, { useState, useEffect } from 'react';
import { makeStyles } from '@mui/styles';
import {
  Card,
  CardContent,
  Typography
} from '@mui/material';
import { getRandomInt } from '../../utils/randomInit';
import { connect } from 'react-redux';
import * as spinwheelAction from '../../redux/action/spinwheelAction';
import Alert from '../common/alert';
import ConfirmationAlert from '../common/confirmationAlert';
import * as userDetailsAction from '../../redux/action/userDetailsAction';
import { useTranslation } from 'react-i18next';
const useStyles = makeStyles((theme) => ({
  button: {
    float: 'right',
    display: 'block',
    width: '5rem',
    cursor: 'pointer',
    marginTop: '-3rem',
    marginRight: '5rem',
    '@media(max-width: 1024px)': {
      marginTop: '-1rem',
      marginRight: '0rem',
      width: '3rem',
    },
  },
  currentMsg: {
    color: '#fff',
    padding: '0px 5px',
    backgroundColor: '#42ba96',
    borderRadius: 4,
    fontWeight: 400,
    '@media(max-width: 720px)': {
      padding: '0px',
    },
  },
  marker: {
    left: '44%',
    width: '10%',
    height: '18%',
    position: 'relative',
    zIndex: '2',
  },

  wheel: {
    width: '80%',
    height: '80%',

    // margin: '0 auto',
  },
  spintop: {
    position: 'relative',
    display: 'flex',
  },
  resultContent: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: '5rem !important',
    marginLeft: '2rem',
    '@media(max-width: 720px)': {
      marginLeft: '0rem',
      marginTop: '3rem !important',
    },
  },
}));
const SpinTheWheel = (props) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [couponNum, setCouponNum] = useState(0);
  const [postSpinData, setPostSpinData] = useState([]);
  const [spinWheelAlert, setSpinWheelAlert] = useState(false);
  const [alertConfirmButton, setAlertConfirmButton] = useState(false);
  const [alertCloseButton, setAlertCloseButton] = useState(false);
  const [alertConfirmButtonText, setAlertConfirmButtonText] = useState('');
  const [alertCloseButtonText, setAlertCloseButtonText] = useState('');
  const [alertText, setAlertText] = useState('');
  const [alertTitle, setAlertTitle] = useState('');
  const [spinWithBonus, setSpinWithBonus] = useState(false);
  const [commonAlertModal, setCommonAlertModal] = useState(false);
  const [commonAlertMessage, setCommonAlertMessage] = useState('');
  const [commonAlertButtonText, setCommonAlertButtonText] = useState('');
  const [IconName, setIconName] = useState('');
  const [commonTitle, setCommonAlertTitle] = useState('');
  const [containLink, setContainLink] = useState(false);
  const [navigationLink, setNavigateLink] = useState('');
  const [closeIcon, setCloseIcon] = useState(false);
  const [buttonDisabled,setButtonDisabled]=useState(false)
  useEffect(() => {
    if (localStorage.getItem('userToken') != null) {
      props.getSpinWheelData(localStorage.getItem('userToken'));
    }
  }, []);

  useEffect(() => {
    if (localStorage.getItem('userToken') != null) {
      props.spinWheelEligible(localStorage.getItem('userToken'));
    }
  }, []);

  useEffect(() => {
    if (couponNum > 0) {
      postSpinWheelData();
    }
  }, [couponNum]);

  const postSpinWheelData = () => {
    let data = postSpinData[couponNum===1?0:couponNum-1];
    if (data && spinWithBonus) {
      let formData = new FormData();
      formData.append('wheel_id', data['id']);
      formData.append('gift_type', data['gift_type']);
      formData.append('coins', 0);
      formData.append('bonus_points', props.requiredBonusPoints);
      props.postSpinWheelGiftData(formData, localStorage.getItem('userToken'));
    } else if (data) {
      let formData = new FormData();
      formData.append('wheel_id', data['id']);
      formData.append('gift_type', data['gift_type']);
      formData.append('coins', 0);
      formData.append('bonus_points', 0);
      props.postSpinWheelGiftData(formData, localStorage.getItem('userToken'));
    }
  };

  const generateRandomPrize = (actualDeg) => {
    let newCouponNum=0;
    if(actualDeg>=70&&actualDeg<=103){
      newCouponNum=1;
    }else{
      newCouponNum = getRandomInt(2,11);
    }
     setCouponNum(newCouponNum);
  };

  useEffect(() => {
    setPostSpinData(props.spinWheelData);
  }, [props.spinWheelData]);

  const spinWheelStatusChecking = () => {
    if (props.spinEligibility === 'Kindly Unlock the Chest Skill Tree.') {
      setSpinWheelAlert(true);
      setAlertTitle(t("chestNotUnlocked"));
      setAlertText(t("unlockTheChestInSkilltree"));
      setAlertConfirmButton(false);
      setAlertCloseButton(true);
      setAlertConfirmButtonText('');
      setAlertCloseButtonText(t("Close"));
    } else if (props.spinEligibility === 0) {
      setSpinWheelAlert(true);
      setAlertTitle(t("noFreeSpins"));
      setAlertText(
       t("confirmFreeSpinContent")
      );
      setAlertConfirmButton(true);
      setAlertCloseButton(true);
      setAlertConfirmButtonText(t("timerConfirmButton"));
      setAlertCloseButtonText(t("Close"));
    } else if (props.spinEligibility > 0) {
      spinWheel();
    }
  };

  const CloseAlert = () => {
    setSpinWheelAlert(false);
    setAlertConfirmButton(false);
    setAlertCloseButton(false);
    setAlertConfirmButtonText('');
    setAlertCloseButtonText('');
    setAlertTitle('');
    setAlertText('');
  };

  const spinWithBonuspoints = () => {
    if (props.userBonusPoints >= props.requiredBonusPoints) {
      CloseAlert();
      setSpinWithBonus(true);
      spinWheel();
    } else {
      setCloseIcon(false);
      setCommonAlertModal(true);
      setCommonAlertMessage(
t("dontHaveBonusForSpinWheel")
      );
      setCommonAlertButtonText(t("Close"));
      setIconName('BonusPointsNotEnough');
      setCommonAlertTitle(t("notEnoughBonusPoints"));
      setContainLink(false);
      setNavigateLink('');
      CloseAlert();
    }
  };

  const setCommonModalFalse = () => {
    setCommonAlertModal(false);
    setCommonAlertMessage('');
    setCommonAlertButtonText('');
    setIconName('');
    setCommonAlertTitle('');
    setContainLink(false);
    setNavigateLink('');
    setCloseIcon(false);
  };

  useEffect(() => {
 if(props.spinWheelChestAdd){
  setSpinWheelAlert(true);
  setAlertTitle(t("youWonSomething"));
  setAlertText(t("spinWheelContent"));
  setAlertConfirmButton(false);
  setAlertCloseButton(true);
  setAlertConfirmButtonText('');
  setAlertCloseButtonText(t("Close"));
  props.userGeneralDetailsService(localStorage.getItem('userToken'));
  props.spinWheelEligible(localStorage.getItem('userToken'));
  setCouponNum(0)
  props.spinWheelDataNull()
 }else if(props.spinWheelChestAdd===false){
  setSpinWheelAlert(true);
  setAlertTitle(t("noChest"));
  setAlertText(t("tryAgainLater"));
  setAlertConfirmButton(false);
  setAlertCloseButton(true);
  setAlertConfirmButtonText('');
  setAlertCloseButtonText(t("Close"));
  props.spinWheelDataNull()
  setCouponNum(0)
 }

  }, [props.spinWheelChestAdd]);
  
  const spinWheel = () => {
    const wheel = document.querySelector('.spinwheel');
    const startButton = document.querySelector('.button');
    let deg = 0,predictedDegree=0;

    deg =Math.floor(5000 + Math.random() * 5000);
    // Set the transition on the wheel
    wheel.style.transition = 'all 5s ease-out';
    // Rotate the wheel
    wheel.style.transform = `rotate(${deg}deg)`;
    // Apply the blur
    wheel.classList.add('blur');
    predictedDegree=props.isCoinSpin?getRandomInt(70,103):deg % 360>=70&&deg % 360<=103?getRandomInt(104,361):deg % 360;
    // console.log(predictedDegree,props.isCoinSpin);
    setTimeout(() => {
      generateRandomPrize(parseInt(predictedDegree));
    }, 5000);
    wheel.addEventListener('transitionend', () => {
      // Remove blur
      wheel.classList.remove('blur');
      // Enable button when spin is over
      // startButton.style.pointerEvents = 'auto';
      // Need to set transition to none as we want to rotate instantly
      wheel.style.transition = 'none';
      // Calculate degree on a 360 degree basis to get the "natural" real rotation
      // Important because we want to start the next spin from that one
      // Use modulus to get the rest value from 360
      //const actualDeg =deg % 360;
      // Set the real rotation instantly without animation
     
      wheel.style.transform = `rotate(${predictedDegree}deg)`;
    });
  };
  return (
    <div>
      {commonAlertModal ? (
        <Alert
          isContainLink={containLink}
          navigateLink={navigationLink}
          title={commonTitle}
          modal={true}
          message={commonAlertMessage}
          buttonText={commonAlertButtonText}
          icon={IconName}
          removeFunc={setCommonModalFalse}
          closeIcon={closeIcon}
        />
      ) : (
        ''
      )}
          <ConfirmationAlert
        alert={spinWheelAlert}
        confirmButton={alertConfirmButton}
        closeButton={alertCloseButton}
        confirmButtonText={alertConfirmButtonText}
        closeButtonText={alertCloseButtonText}
        alertTitle={alertTitle}
        alertText={alertText}
        closeFunc={CloseAlert}
        confirmFunc={spinWithBonuspoints}
        buttonDisabled={buttonDisabled}
      />

      <Card sx={{ borderRadius: '10px' }}>
        <CardContent sx={{ paddingBottom: '0px !important' }}>
          <div className={`spintop ${classes.spintop}`}>
            <img
              className={`marker ${classes.marker}`}
              src="/assets/images/pin.png"
              alt="marker"
            />
            <img
              className={`spinwheel ${classes.wheel}`}
              src="/assets/images/wheel.png"
              alt="wheel"
              style={{}}
            />
          </div>
        </CardContent>
        <CardContent
          sx={{
            display: 'flex',
            justifyContent: 'end',
            paddingTop: '0px !important',
            marginTop: '-2rem',
          }}
        >
          <img
            src="/assets/images/go.png"
            className={''}
            alt="button"
            title={props.spinEligibility ? '' : 'You are Not Eligible To Spin'}
            onClick={() => spinWheelStatusChecking()}
          />
        </CardContent>
        <CardContent
          sx={{
            marginTop: '5px',
            display: 'flex',
            justifyContent: 'center',
            marginLeft: '2rem',
          }}
        >
          <Typography variant="body1" component="h6">
          {t("bonusPoints")}: <span className={classes.currentMsg}>{props.userGeneralData.bonuspoints}</span>
          </Typography>
        </CardContent>
      </Card>
    </div>
  );
};
const mapStateToProps = (state) => {
  return {
    spinEligibility: state.SpinWheelReducer.spinWheelStatus,
    spinWheelData: state.SpinWheelReducer.spinWheelData,
    spinWheelEligibleFailureStatus:
      state.SpinWheelReducer.spinWheelEligibleFailureStatus,
    userBonusPoints: state.SpinWheelReducer.userBonusPoints,
    requiredBonusPoints: state.SpinWheelReducer.requiredBonusPoints,
    spinWheelChestAdd:state.SpinWheelReducer.spinWheelChestAdd,
    userGeneralData: state.UserDetailsReducer.userGeneralData,
    isCoinSpin:state.SpinWheelReducer.isCoinSpin
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getSpinWheelData: (requestData) =>
      dispatch(spinwheelAction.spinWheelData(requestData)),
    spinWheelEligible: (requestData) =>
      dispatch(spinwheelAction.spinWheelEligible(requestData)),
    postSpinWheelGiftData: (requestData, token) =>
      dispatch(spinwheelAction.postSpinWheelGiftData(requestData, token)),
      spinWheelDataNull:() =>
      dispatch(spinwheelAction.spinWheelDataNull()),
      userGeneralDetailsService: (requestData) =>
      dispatch(userDetailsAction.userGeneralDetailsService(requestData)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SpinTheWheel);
