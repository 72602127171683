import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
  appBar: {
    padding: '10px',
    [theme.breakpoints.between('xs', 'sm')]: {
      padding: '0px',
    },
  },
  headerLinks: {
    paddingRight: '20px',
    textTransform: 'uppercase',
    color: 'white',
    textDecoration: 'none',
    '&:hover': {
      color: 'white !important',
    }
  },
  selectedHeaderLinks: {
    paddingRight: '20px',
    textTransform: 'uppercase',
    color: '#fe456ede',
    textDecoration: 'none',
    '&::after': {
      height: '1px',
      content: '""',
      display: 'block',
      background:"none",
    },
    '&:hover': {
      color: '#fe456ede !important',
    }
  },
  logoMobile: {
    marginBottom: '-15px',

    [theme.breakpoints.between('xs', 'sm')]: {
      height: '30px',
      marginBottom: '-4px',
    },
  },
  logoMobileUserHeader: {
    marginBottom: '-15px',

    [theme.breakpoints.between('xs', 'sm')]: {
      height: '30px',
      marginBottom: '-10px',
    },
  },
  registerButton: {
    marginLeft: '15px',
  },
  loginButton: {
    '&:hover': {
      boxShadow:
        '0px 2px 4px -1px rgb(247 247 247 / 20%), 0px 4px 5px 0px rgb(242 237 237 / 14%), 0px 1px 10px 0px rgb(228 219 219 / 12%)',
    },
  },
  headerIcons: {
    marginRight: '12px !important',
  },
  inputFields: {
    padding: '5px 10px!important',
  },
  inputRoot: {
    backgroundColor: '#ffffff !important',
    border: 'none',
    borderRadius: '10px !important',
    borderTopLeftRadius: '10px !important',
    borderTopRightRadius: '10px !important',
    fontSize: '17px !important',
    lineHeight: '21px !important',
    fontWeight: `${400} !important`,
    marginRight: '20px',
  },
  tabsMobileView: {
    padding: '5px !important',
    minWidth: '0px !important',
  },
  profileProgress: {
    backgroundColor: '#cfdee9 !important',
    height: '6px',
    borderRadius: '3px',
  },
  progressBar: {
    height: '6px !important',
    borderRadius: '3px',
  },
  linkIcon: {
    marginRight: theme.spacing(-6),
    marginBottom: theme.spacing(7),
    width: 24,
    display: "flex",
    justifyContent: "center"
  },
  menuhover:{
    paddingTop:"0px !important",
    paddingBottom:"0px !important",
    minHeight:"0px !important",
    '&:hover': {
      backgroundColor: "rgba(0, 0, 0, 0) !important",
  },
  filter: {
    marginRight: theme.spacing(-6),
    marginBottom: theme.spacing(7),
    width: "auto",
    display: "flex",
    justifyContent: "center"
  },
  select: {
    '&:before': {
        borderColor: 'white !important',
    },
    '&:after': {
        borderColor: 'white !important',
    },
    '&:not(.Mui-disabled):hover::before': {
        borderColor: 'white !important',
    },
},
icon: {
    fill: 'white !important',
},
root: {
    color: 'white !important',
},
chipLabel:{
  textDecoration:'none !important',
  textUnderlinePosition: 'under !important',
  cursor:'pointer !important',
}

}
}));

export default useStyles;
