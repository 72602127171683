import axios from 'axios';
import { createAction } from 'redux-actions';

export const getStickersInitiate = createAction('GET_STICKERS_INITIATE');
export const getStickersSuccess = createAction('GET_STICKERS_SUCCESS');
export const getStickersFailure = createAction('GET_STICKERS_FAILURE');


export const getStickersService = (requestData) => {
    const config = {
        headers: {
          Authorization: `Bearer ${requestData}`,
        },
      };
    return (dispatch) => {
      dispatch(getStickersInitiate());
      axios
        .get(`${process.env.REACT_APP_BACKEND_URL }user_stickers`,config)
        .then((response) => {
          if (response.data.STATUS) {                                                                                 
            dispatch(getStickersSuccess(response.data));
          } else {
            dispatch(getStickersFailure());
          }
        })
        .catch((error) => {
          if (error.response.status === 401) {
            localStorage.clear();
            window.location.replace('/session-expired');
          }
          else {
          dispatch(getStickersFailure());
          }
        });
    };
  };

  export const buyStickersInitiate = createAction('BUY_STICKERS_INITIATE');
export const buyStickersSuccess = createAction('BUY_STICKERS_SUCCESS');
export const buyStickersFailure = createAction('BUY_STICKERS_FAILURE');


export const buyStickersService = (requestData,token) => {
    const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };
    return (dispatch) => {
      dispatch(buyStickersInitiate());
      axios
        .post(`${process.env.REACT_APP_BACKEND_URL }Buy_Stickers`,requestData,config)
        .then((response) => {
          if (response.data.STATUS) {                                                                                 
            dispatch(buyStickersSuccess(response.data));
          } else {
            dispatch(buyStickersFailure(response.data));
          }
        })
        .catch((error) => {
          if (error.response.status === 401) {
            localStorage.clear();
            window.location.replace('/session-expired');
          }
          else {
          dispatch(buyStickersFailure());
          }
        });
    };
  };

  export const setStickersStatusNull = createAction('SET_STICKERS_NULL');
  export const setPurchasedStatusNull = ()=>{ 
    return (dispatch)=>{
      dispatch(setStickersStatusNull());
  }
  }