import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router';
import OtpInput from 'react-otp-input';
import { Typography, Button, Grid, FormHelperText, Chip, CardContent, IconButton, CardHeader, Card } from '@mui/material';
import useStyles from '../styles/auth';
import { Link } from 'react-router-dom';
import { getIsMobile } from '../utils';
import { connect } from 'react-redux';
import * as registerAction from '../redux/action/registerAction';
import * as loginAction from '../redux/action/loginAction';
import Loader from '../utils/loader';
import { useTranslation } from 'react-i18next';
import CancelIcon from '@mui/icons-material/Cancel';
import ReactGA from 'react-ga';
ReactGA.initialize('AW-10947547771', { debug: true });
const Features = (props) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [otp, setOtp] = useState('');
  const [otpErr, setOtpErr] = useState(false);
  const [otpResponse, setOtpResponse] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const history = useHistory();
  const isMobile = getIsMobile();
  const handleOTP = (value) => {
    setOtp(value);
  };

  const validateOTP = () => {
    if (otp.length === 6) {
      setIsLoading(true)
      setOtpErr(false)
      let data = new FormData();
      data.append("email", localStorage.getItem('email'))
      data.append("otp", otp)
      props.verifyOtp(data)
    } else {
      setOtpErr(true)
    }
  }

  useEffect(() => {
    if (props.verifyOtpStatus === 'Verification code is incorrect. Kindly enter the correct code.') {
      setIsLoading(false)
      setOtpResponse(props.verifyOtpStatus)
      props.statusNull();
    } else if (props.verifyOtpStatus === true || props.verifyOtpStatus === "true") {
      setIsLoading(false)
      var requestData = new FormData();
      requestData.append('email', localStorage.getItem('email'));
      requestData.append('password', localStorage.getItem('password'));
      props.loginService(requestData);
    } else if (props.verifyOtpStatus !== null) {
      setIsLoading(false)
      setOtpResponse(props.verifyOtpStatus)
      props.statusNull();
    }
  }, [props.verifyOtpStatus])

  const resendOTP = () => {

    let data = new FormData();
    data.append("email", localStorage.getItem('email'))
    props.resendOtp(data)
  }

  const closeError = () => {
    setOtpResponse('')
  }
  useEffect(() => {
    if (props.userToken != '') {
      ReactGA.pageview(window.location.pathname);
      localStorage.removeItem('email')
      localStorage.removeItem('password')
      localStorage.removeItem('socialID')
      history.push('/newest-members');
    }
  }, [props.userToken]);
  return (
    <div className={classes.bannerBgImage}>
      {isLoading ? <Loader /> : ''}
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
      >
        <Grid item xs={10} sm={8} md={4}>
          <Typography variant="h1" component="h1">
            {t("enterYourcode")}
          </Typography>
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            sx={{ marginTop: '45px' }}
          >
            <Typography variant="h5" component="h5">
              {t("enterCodeOtp")}
            </Typography>
            <Link to="#" style={{ color: '#38ff1d' }} onClick={() => resendOTP()}>
              {t("resend")}
            </Link>
          </Grid>
          <OtpInput
            value={otp}
            onChange={(value) => handleOTP(value)}
            numInputs={6}
            containerStyle={classes.otpContainer}
            inputStyle={classes.inputContainer}
            sx={isMobile ? { width: '30px !important' } : ''}
            isInputNum={true}
            hasErrored={true}
          />
          {otpErr && (
            <FormHelperText classes={{ root: classes.errorText }}>
              {t("invalidOTP")}
            </FormHelperText>
          )}

          {otpResponse !== '' ?
            <Grid
              sx={{
                position: 'relative',
                pt: 0,
                pb: 0,
                mb: 12,
                display: 'flex',
                justifyContent: 'center'
              }}
            >
              <Card
                sx={{
                  borderRadius: '5px',
                  backgroundColor: '#cc493a',
                  border: '#cc493a',
                  ml: 4,
                  mr: 4,
                  color: '#edc9b8',
                  minHeight: '40px',
                  maxWidth: '520px',
                  position: 'absolute',
                  maxHeight: 'auto',
                }}
              >
                <CardHeader
                  sx={{ p: 0, pt: 1 }}
                  action={
                    <IconButton aria-label="settings">
                      <CancelIcon
                        onClick={() => closeError()}
                        size={'lg'}
                        style={{
                          cursor: 'pointer',
                          position: 'absolute',
                          right: '7px',
                          top: '-8px',
                          color: 'white',
                        }}
                      />
                    </IconButton>
                  }
                  title=""
                  subheader=""
                />
                <CardContent sx={{ paddingTop: '0px !important' }}>
                  {otpResponse != '' ? (
                    <Typography
                      sx={{
                        color: '#edc9b8',
                        fontSize: '12px',
                        pt: ' 0 !important',
                        pb: ' 0 !important',
                      }}
                    >
                      {otpResponse}
                    </Typography>
                  ) : (
                    ''
                  )}
                </CardContent>
              </Card>
            </Grid>
            : ''}
          <Button
            color="primary"
            variant="contained"
            sx={{
              borderRadius: '10px',
              margin: '20px 0px ',
              padding: '10px 80px',
            }}
            onClick={() => validateOTP()
            }
          >
            {t("continue")}
          </Button>
        </Grid>
      </Grid>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    verifyOtpStatus: state.RegisterReducer.verifyOtpStatus,
    resendOtpStatus: state.RegisterReducer.resendOtpStatus,
    userToken: state.LoginReducer.userToken,

  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    resendOtp: (requestData) =>
      dispatch(registerAction.ResendOtp(requestData)),
    verifyOtp: (requestData) =>
      dispatch(registerAction.verifyOtp(requestData)),
    loginService: (requestData) =>
      dispatch(loginAction.loginService(requestData)),
    statusNull: () => dispatch(registerAction.uniqueStatusNull()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Features);
