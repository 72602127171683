import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import {
  Grid,
  Card,
  CardContent,
  Typography,
  Button,
  ListItemAvatar,
  ListItem,
  List,
  Avatar,
  ListItemText,
  styled,
  Badge,
  Divider,
  ListItemIcon,
} from '@mui/material';
import { ReactComponent as HeartActiveIcon } from '../icons/ico_heart_active.svg';
import { ReactComponent as HeartIconInActive } from '../icons/ico heart inactive.svg';
import { ReactComponent as LikeIconActive } from '../icons/ico like_gray.svg';
import { ReactComponent as LikeIcon } from '../icons/orange_like.svg';
import useStyles from '../../styles/myProfile';
import { connect } from 'react-redux';
import * as lastVisitorAction from '../../redux/action/lastVisitorAction';
import * as commonAction from '../../redux/action/commonAction';
import {getTimeStamp}from '../../utils'
import { ReactComponent as Level1 } from '../icons/DarkBlue Like.svg';
import { ReactComponent as Level2 } from '../icons/litebluelike.svg';
import { ReactComponent as Level3 } from '../icons/ico like green.svg';
import { ReactComponent as Level4 } from '../icons/orange_like.svg';
import { ReactComponent as Level5 } from '../icons/like_purple.svg';
import { ReactComponent as GrayLike } from '../icons/ico like_gray.svg';
import Loader from '../../utils/loader';
import { useTranslation } from 'react-i18next';
const StyledBadge = styled(Badge)(({ theme }) => ({
  '& .MuiBadge-badge': {
    backgroundColor: '#44b700',
    color: '#44b700',
    boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
  },
}));

const UserVisitor = (props) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const history = useHistory();
  const [visitorData, setVisitorData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [noData, setNodata] = useState(false);
  useEffect(() => {
    if (localStorage.getItem('userToken') != null) {
      setIsLoading(true)
      props.getUserVisitorData(localStorage.getItem('userToken'));
      setTimeout(() => {
        setIsLoading(false)
      }, 5000);
    }
  }, []);

  useEffect(() => {
    if(props.lastVisitorData.length>0){
      setIsLoading(false)
      setVisitorData(props.lastVisitorData);
    }else{
      setTimeout(() => {
        setNodata(true)
      }, 7000);
    }

  }, [props.lastVisitorData]);

  const viewUserProfile = (id) => {
    localStorage.setItem('currentID', id);
    props.getUserByID(localStorage.getItem('userToken'), id);
    props.addLastVisit(id, localStorage.getItem('userToken'));
    history.push('/user-profile');
  };

  const handleFavorite = (item) => {
    let data = new FormData();
    data.append('member_id', item.user_id);
    data.append('action', item.is_favourite === 1 ? 0 : 1);
    props.addorRemoveFavorite(data);
  };

  const handleLike = (item) => {
    let data = new FormData();
    data.append('member_id', item.user_id);
    data.append('action', 1);
    data.append(
      'level',
      item.is_liked_level <= 4 ? item.is_liked_level + 1 : ''
    );
    if (item.is_liked_level <= 4) {
      props.likeorDislike(data);
    }
  };

  useEffect(() => {
    if (props.favoriteStatus) {
      props.getUserVisitorData(localStorage.getItem('userToken'));
    }
  }, [props.favoriteStatus]);

  useEffect(() => {
    if (props.likeStatus) {
      props.getUserVisitorData(localStorage.getItem('userToken'));
    }
  }, [props.likeStatus]);
  const getIcon = (item) => {
    let icon;
    if (item.is_liked_level === 0) {
      icon = (
        <GrayLike
          onClick={() => handleLike(item)}
          className={classes.likeIcon}
        />
      );
    } else if (item.is_liked_level === 1) {
      icon = (
        <Level1 onClick={() => handleLike(item)} className={classes.likeIcon} />
      );
    } else if (item.is_liked_level === 2) {
      icon = (
        <Level2 onClick={() => handleLike(item)} className={classes.likeIcon} />
      );
    } else if (item.is_liked_level === 3) {
      icon = (
        <Level3 onClick={() => handleLike(item)} className={classes.likeIcon} />
      );
    } else if (item.is_liked_level === 4) {
      icon = (
        <Level4 onClick={() => handleLike(item)} className={classes.likeIcon} />
      );
    } else if (item.is_liked_level === 5) {
      icon = <Level5 className={classes.likeIcon} />;
    }
    return icon;
  };
  return (
    <Card
      sx={{
        borderRadius: '10px',
        minHeight: '823px',
        maxHeight: '823px',
        overflow: 'auto',
      }}
    >
        {isLoading ? <Loader /> : ''}
      <CardContent sx={{ pl: '0px !important', pr: '0px !important' }}>
        <Grid
          container
          justifyContent="space-between"
          // sx={{ margin: '20px 0px' }}
        >
          <Typography
            variant="body1"
            component="h6"
            sx={{ pl: '24px !important', pr: '24px !important', mb: 2 }}
          >
            {t("lastVisitors")}
          </Typography>
        </Grid>
        <Divider sx={{ p: 0 }} />
        <List>
          {visitorData.length > 0 ? (
            visitorData.map((item) => {
              return (
                <div key={item}>
                  <ListItem key={item}>
                    <ListItemIcon sx={{minWidth:"30px"}}>
                      {item.is_favourite ? (
                        <HeartActiveIcon onClick={() => handleFavorite(item)} />
                      ) : (
                        <HeartIconInActive
                          onClick={() => handleFavorite(item)}
                        />
                      )}
                    </ListItemIcon>

                    <ListItemIcon sx={{minWidth:"30px"}}>
                      {' '}
                      {item.is_liked === 0 ? (
                        <LikeIconActive
                          onClick={() => handleLike(item)}
                          style={{ marginLeft: '15px !important' }}
                        />
                      ) : (
                        getIcon(item)
                      )}
                    </ListItemIcon>
                    <ListItemAvatar>
                      {item.is_online === 1 ? (
                        <StyledBadge
                          overlap="circular"
                          anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'right',
                          }}
                          variant="dot"
                        >
                          <Avatar
                            alt="avatar"
                            src={`${item.pictures}`}
                            sx={{
                              height: '70px',
                              width: '70px',
                              marginRight: '3px',
                              marginLeft: '10px',
                              border: '2px solid #F7B573',
                              cursor: 'pointer',
                            }}
                          />
                        </StyledBadge>
                      ) : (
                        <Avatar
                          alt="avatar"
                          src={`${item.pictures}`}
                          sx={{
                            height: '70px',
                            width: '70px',
                            marginRight: '3px',
                            marginLeft: '10px',
                            border: '2px solid #F7B573',
                            cursor: 'pointer',
                          }}
                          onClick={() => history.push('/user-profile')}
                        />
                      )}
                    </ListItemAvatar>
                    <ListItemText sx={{ml:1}}
                      primary={
                        <Grid
                          container
                          alignItems="center"
                          justifyContent="space-between"
                        >
                          <Typography variant="body1" component="h6">
                            {item.username}
                          </Typography>
                          <Typography
                            variant="subtitle2"
                            component="p"
                            sx={{
                              fontWeight: 600,
                              color: '#7a797a',
                              marginLeft: '6px',
                            }}
                          >
                            {getTimeStamp(item.last_visited)}
                          </Typography>
                        </Grid>
                      }
                      secondary={
                        <Grid
                          container
                          alignItems="center"
                          justifyContent="space-between"
                        >
                          <Typography
                            component="p"
                            variant="body2"
                            className={classes.text}
                            sx={{maxWidth:350}}
                          >
                           {t("viewedYourprofile")}
                            <br />
                            {item.long_description?.length>40?`${item.long_description.slice(0,40)}...`:item.long_description}
                          </Typography>
                          <Button
                            variant="contained"
                            className={classes.viewProfile}
                            sx={{
                              marginTop: '12px',
                              marginBottom: '10px',
                              textTransform: 'capitalize',
                            }}
                            onClick={() => viewUserProfile(item.user_id)}
                          >
                           {t("viewProfile")}
                          </Button>
                        </Grid>
                      }
                    />
                  </ListItem>
                  <Divider />
                </div>
              );
            })
          ) : (
            <Typography variant="p" component="p" sx={{ textAlign: 'center' }}>
             {noData?t("noVisitors"):''}
            </Typography>
          )}
        </List>
      </CardContent>
    </Card>
  );
};

const mapStateToProps = (state) => {
  return {
    lastVisitorData: state.LastVisitorReducer.lastVisitorData,
    favoriteStatus: state.CommonReducer.favoriteStatus,
    likeStatus: state.CommonReducer.likeStatus,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getUserVisitorData: (requestData) =>
      dispatch(lastVisitorAction.getUserVisitorData(requestData)),
    getUserByID: (token, requestData) =>
      dispatch(commonAction.getUserByID(token, requestData)),
    addLastVisit: (userId, Token) =>
      dispatch(lastVisitorAction.addUserVisitor(userId, Token)),
    addorRemoveFavorite: (requestData) =>
      dispatch(commonAction.favoriteOtherMember(requestData)),
    likeorDislike: (requestData) =>
      dispatch(commonAction.likeOtherMember(requestData)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(UserVisitor);
