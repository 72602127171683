import axios from "axios";
import {createAction} from "redux-actions";

export const GetSkillTreeInitiate=createAction("GET_SKILLTREE_INITIATE");
export const GetSkillTreesSuccess=createAction("GET_SKILLTREE_SUCCESS");
export const GetSkillTreeFailure=createAction("GET_SKILLTREE_FAILURE");

export const unLockSkillTreeInitiate=createAction("UNLOCK_SKILLTREE_INITIATE");
export const unLockSkillTreesSuccess=createAction("UNLOCK_SKILLTREE_SUCCESS");
export const unLockSkillTreeFailure=createAction("UNLOCK_SKILLTREE_FAILURE");

export const setUnlockStatusNull=createAction("SET_UNLOCK_STATUS_SUCCESS");
export const getSkillTreeService = (requestData)=>{
    const config = {
     headers: {
        Authorization: `Bearer ${requestData}`
     }
   };
     return (dispatch)=>{
       dispatch(GetSkillTreeInitiate());
       axios.get( `${process.env.REACT_APP_BACKEND_URL }my_skilltrees`,config)
         .then((response)=>{
             if(response.data.STATUS) { 
               dispatch(GetSkillTreesSuccess(response.data.result));
            }else{
               dispatch(GetSkillTreeFailure());
            }
         })
         .catch((error)=>{
          if (error.response.status === 401) {
            localStorage.clear();
            window.location.replace('/session-expired');
          }
          else {
           dispatch(GetSkillTreeFailure());
          }
         })
   }
   }

   export const unLockSkillTreeService = (data,token)=>{
    const config = {
     headers: {
        Authorization: `Bearer ${token}`
     }
   };
     return (dispatch)=>{
       dispatch(unLockSkillTreeInitiate());
       axios.post(`${process.env.REACT_APP_BACKEND_URL }unlock_skill`,data,config)
         .then((response)=>{
             if(response.data.STATUS) { 
               dispatch(unLockSkillTreesSuccess(response.data.message));
            }else{
               dispatch(unLockSkillTreeFailure(response.data.message));
            }
         })
         .catch((error)=>{
          if (error.response.status === 401) {
            localStorage.clear();
            window.location.replace('/session-expired');
          }
          else {
           dispatch(unLockSkillTreeFailure());
          }
         })
   }
   }

   export const UnlockStatusNull = ()=>{ 
     return (dispatch)=>{
       dispatch(setUnlockStatusNull());
   }
   }