import { Button, Grid, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import UserLayout from '../layouts/userLayout';
import * as securityAction from '../redux/action/securityAction';
import Switch from 'react-switch';
import { styled } from '@mui/material/styles';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import HelpOutlineIcon from '@mui/icons-material/Help';
import { useHistory } from 'react-router';
import ConfirmationAlert from '../components/common/confirmationAlert';
import * as deleteAccoutAction from '../redux/action/deleteAccountAction';
import * as loginAction from'../redux/action/loginAction'
import { useTranslation } from 'react-i18next';
const LightTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.white,
    color: 'rgba(0, 0, 0, 0.87)',
    boxShadow: theme.shadows[1],
    fontSize: 15,
    maxWidth: 700,
  },
}));
const Security = (props) => {
  const history = useHistory();
  const { t } = useTranslation();
  const [securityAlert, setSecurityAlert] = useState(false);
  const [alertConfirmButton, setAlertConfirmButton] = useState(false);
  const [alertCloseButton, setAlertCloseButton] = useState(false);
  const [alertConfirmButtonText, setAlertConfirmButtonText] = useState('');
  const [alertCloseButtonText, setAlertCloseButtonText] = useState('');
  const [alertText, setAlertText] = useState('');
  const [alertTitle, setAlertTitle] = useState('');
  const [securityData, setSecurityData] = useState([]);
  const [footerData, setFooterData] = useState([]);
  const [buttonDisabled,setButtonDisabled]=useState(false)
  useEffect(() => {
    if (props.securityData) {
      setSecurityData(props.securityData);
    }
  }, [props.securityData]);

  useEffect(() => {
    if (
      localStorage.getItem('userToken') != null &&
      window.location.pathname === '/security'


    ) {
      props.getSecurityDataService(localStorage.getItem('userToken'));
      let data = localStorage.getItem('userLevelData');
      let datas = JSON.parse(data);
      setFooterData(datas);
    } else if (localStorage.getItem('userToken') === null) {
      history.push('/');
    }
  }, []);

  const postSecurityData = (item) => {
    let data = new FormData();
    data.append('security_id', item.user_security_id);
    data.append('action', item.on_off === 0 ? 1 : 0);
    props.postSecurityDataService(data, localStorage.getItem('userToken'));
  };

  const deleteAccount = () => {
    props.DeleteUserByID(footerData.user_id);
  
  };

  useEffect(() => {
   if(props.AccountDeleteStatus===true){
    props.logoutService(localStorage.getItem('userToken'))
   }
  }, [props.AccountDeleteStatus])
  
useEffect(() => {
  if(props.securityPostResponse!=null){
    props.getSecurityDataService(localStorage.getItem('userToken'));
  }
}, [props.securityPostResponse])

  const CloseAlert = () => {
    setSecurityAlert(false);
    setAlertConfirmButton(false);
    setAlertCloseButton(false);
    setAlertConfirmButtonText('');
    setAlertCloseButtonText('');
    setAlertTitle('');
    setAlertText('');
  };
  return (
    <UserLayout subHeader={false} subFooter={false} header={true}>
      <ConfirmationAlert
        alert={securityAlert}
        confirmButton={true}
        closeButton={true}
        confirmButtonText={t("delete")}
        closeButtonText={t("cancel")}
        alertTitle={t("deleteAccountAlertTitle")}
        alertText={t("deleteAccountAlertContent")}
        closeFunc={CloseAlert}
        confirmFunc={ deleteAccount}
        width={400}
        buttonDisabled={buttonDisabled}
      />
      <Grid
        container
        direction="row"
        justifyContent="space-between"
        alignItems="center"
      >
        <Grid item md={12}>
          <Typography>{t("securitySettings")}</Typography>
        </Grid>
      </Grid>
      {securityData.length > 0 ? (
        securityData.map((item) => (
          <>
            {' '}
            <Grid
              container
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              sx={{ mt: 5 }}
              key={item}
            >
              <Grid item md={9}>
                <Typography>
                  {item.name}
                  <LightTooltip
                    title={item.long_description}
                    arrow
                    placement="top-end"
                  >
                    <IconButton>
                      <HelpOutlineIcon style={{ fill: 'chartreuse' }} />
                    </IconButton>
                  </LightTooltip>
                </Typography>
              </Grid>
              <Grid item md={3} sx={{ justifyContent: 'end', display: 'flex' }}>
                <Switch
                  checked={item.on_off === 1 ? true : false}
                  onChange={() => postSecurityData(item)}
                  handleDiameter={28}
                  offColor="#FF0000"
                  onColor="#00FF00"
                  offHandleColor="#FFFFFF"
                  onHandleColor="#FFFFFF"
                  height={30}
                  width={70}
                  borderRadius={25}
                  activeBoxShadow="0px 0px 1px 2px #fffc35"
                  uncheckedIcon={
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        height: '100%',
                        fontSize: 15,
                        color: 'white',
                        paddingRight: 2,
                      }}
                    >
                      {t("switchOff")}
                    </div>
                  }
                  checkedIcon={
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        height: '100%',
                        fontSize: 15,
                        color: 'white',
                        paddingRight: 2,
                      }}
                    >
                      {t("switchOn")}
                    </div>
                  }
                  className="react-switch"
                  id="small-radius-switch"
                />
              </Grid>
            </Grid>
            <Grid
              container
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              sx={{ mt: 1 }}
            >
              <Grid item md={9}>
                <Typography variant="p">{item.description}</Typography>
              </Grid>
            </Grid>
          </>
        ))
      ) : (
        <Grid container direction="row" sx={{ mt: 1 }}>
          <Grid item md={12} sx={{ justifyContent: 'center', display: 'flex' }}>
            <Typography variant="h3">{t("noSettingsData")}</Typography>
          </Grid>
        </Grid>
      )}
      <Grid container xs={12}>
        <Grid item xs={12}>
          <Button
            variant="contained"
            color="primary"
            sx={{ borderRadius: '5px', mt: 2 }}
            onClick={()=>setSecurityAlert(true)}
          >
           {t("deleteAccount")}
          </Button>
        </Grid>
      </Grid>
    </UserLayout>
  );
};
const mapStateToProps = (state) => {
  return {
    securityData: state.SecurityReducer.securityData,
    securityPostResponse: state.SecurityReducer.securityPostResponse,
    AccountDeleteStatus:state.DeleteAccountReducer.AccountDeleteStatus
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getSecurityDataService: (requestData) =>
      dispatch(securityAction.getSecurityDataService(requestData)),
    postSecurityDataService: (requestData, token) =>
      dispatch(securityAction.postSecurityDataService(requestData, token)),
      DeleteUserByID: (requestData) =>
      dispatch(deleteAccoutAction.DeleteUserByID(requestData)),
      logoutService: (requestData) =>
      dispatch(loginAction.logoutService(requestData)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Security);
