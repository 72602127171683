import axios from 'axios';
import { createAction } from 'redux-actions';

export const getPackageListInitiate = createAction('GET_PACKAGE_LIST_INITIATE');
export const getPackageListSuccess = createAction('GET_PACKAGE_LIST_SUCCESS');
export const getPackageListFailure = createAction('GET_PACKAGE_LIST_FAILURE');

export const purchaseInitiationInitiate = createAction('PURCHASE_INITIATE');
export const purchaseInitiationSuccess = createAction('PURCHASE_SUCCESS');
export const purchaseInitiationFailure = createAction('PURCHASE_FAILURE');

export const paymentCompletionInitiate = createAction('TRANSACTION_COMPLETED_INITIATE');
export const paymentCompletionSuccess = createAction('TRANSACTION_COMPLETED_SUCCESS');
export const paymentCompletionFailure = createAction('TRANSACTION_COMPLETED_FAILURE');

export const setPurchaseNull = createAction('SET_PURCHASE_STATUSNULL');
export const getPackageDetails = (requestData) => {
    const config = {
      headers: {
        Authorization: `Bearer ${requestData}`,
      },
    };
    // const bodyParameters = {
    //     key: "value"
    //   };
    return (dispatch) => {
      dispatch(getPackageListInitiate());
      axios
        .get(`${process.env.REACT_APP_BACKEND_URL }all_package_list`,config)
        .then((response) => {
          if (response.data.STATUS) {                                                                                 
            dispatch(getPackageListSuccess(response.data.result));
          } else {
            dispatch(getPackageListFailure());
          }
        })
        .catch((error) => {
          if (error.response.status === 401) {
            localStorage.clear();
            window.location.replace('/session-expired');
          }
          else {
          dispatch(getPackageListFailure());
          }
        });
    };
  };
  
  export const postPurchaseInitation = (requestData,token) => {
    const config = {
      headers: {
         Authorization: `Bearer ${token}`
      }
    };
    return (dispatch) => {
      dispatch(purchaseInitiationInitiate());
      axios.post(`${process.env.REACT_APP_BACKEND_URL}init_transaction`,requestData,config)
        .then((response) => {
          if (response.data.STATUS) {
            dispatch(purchaseInitiationSuccess());
          } else {
            dispatch(purchaseInitiationFailure());
          }
        })
        .catch((error) => {
          if (error.response.status === 401) {
            localStorage.clear();
            window.location.replace('/session-expired');
          }
          else {
          dispatch(purchaseInitiationFailure());
          }
        });
    };
  };

  export const postPurchaseSuccessFailure = (requestData,token) => {
    const config = {
      headers: {
         Authorization: `Bearer ${token}`
      }
    };
    return (dispatch) => {
      dispatch(paymentCompletionInitiate());
      axios.post(`${process.env.REACT_APP_BACKEND_URL}complete_pay`,requestData,config)
        .then((response) => {
          if (response.data.STATUS) {
            dispatch(paymentCompletionSuccess());
          } else {
            dispatch(paymentCompletionFailure());
          }
        })
        .catch((error) => {
          if (error.response.status === 401) {
            localStorage.clear();
            window.location.replace('/session-expired');
          }
          else {
          dispatch(paymentCompletionFailure());
          }
        });
    };
  };
  export const purchaseNull = (requestData,token) => {
    return (dispatch) => {
      dispatch(setPurchaseNull());
    }
  }