import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
 

    cls1: {
      stroke:" #2b3136",
      fill: "none",
      strokeWidth: "4px",
      fillRule: "evenodd",
    },

    cls2: {
      stroke: "#8400ff",
      fill: "none",
      strokeWidth: "4px",
      fillRule: "evenodd",
    },
    greenLine: {
      stroke:" #7de795",
      fill: "none",
      strokeWidth: "4px",
      fillRule: "evenodd",
    },
    orangeLine:{
      stroke:" #fb7318",
      fill: "none",
      strokeWidth: "4px",
      fillRule: "evenodd",
    },
    clss1: {
      stroke: "#c2ccd7"
    },
    grayline: {
      stroke: "#aeafb0",
      fill: "none",
      strokeWidth: "4px",
      fillRule: "evenodd",
    }
       
 
}));

export default useStyles;
