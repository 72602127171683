import axios from "axios";
import {createAction} from "redux-actions";

export const GeneralDetailsInitiate=createAction("GENERAL_DETAILS_INITIATE");
export const GeneralDetailsSuccess=createAction("GENERAL_DETAILS_SUCCESS");
export const GeneralDetailsFailure=createAction("GENERAL_DETAILS_FAILURE");

export const PersonalDetailsInitiate=createAction("PERSONAL_DETAILS_INITIATE");
export const PersonalDetailsSuccess=createAction("PERSONAL_DETAILS_SUCCESS");
export const PersonalDetailsFailure=createAction("PERSONAL_DETAILS_FAILURE");

export const UpdatePersonalDetailsInitiate=createAction("UPDATE_PERSONAL_DETAILS_INITIATE");
export const UpdatePersonalDetailsSuccess=createAction("UPDATE_PERSONAL_DETAILS_SUCCESS");
export const UpdatePersonalDetailsFailure=createAction("UPDATE_PERSONAL_DETAILS_FAILURE");

export const UpdateMobileInitiate=createAction("UPDATE_MOBILE_INITIATE");
export const UpdateMobileSuccess=createAction("UPDATE_MOBILE_SUCCESS");
export const UpdateMobileFailure=createAction("UPDATE_MOBILE_FAILURE");

export const VerifyMobileOTPInitiate=createAction("VERIFY_MOBILE_OTP_INITIATE");
export const VerifyMobileOTPSuccess=createAction("VERIFY_MOBILE_OTP_SUCCESS");
export const VerifyMobileOTPFailure=createAction("VERIFY_MOBILE_OTP_FAILURE");


export const stateDataNull=createAction("STATE_DATA_NULL");

export const userDetailsDataNull = ()=>{ 
  return (dispatch)=>{
    dispatch(stateDataNull());
}
}
export const userGeneralDetailsService = (requestData)=>{
    const config = {
     headers: {
        Authorization: `Bearer ${requestData}`
     }
   };
   
   const bodyParameters = {
     key: "value"
   };
     return (dispatch)=>{
       dispatch(GeneralDetailsInitiate());
       axios.post( `${process.env.REACT_APP_BACKEND_URL }get_user_general_details`, bodyParameters,config)
         .then((response)=>{
             if(response.data.STATUS) { 
               dispatch(GeneralDetailsSuccess(response.data));
            }else{
               dispatch(GeneralDetailsFailure());
            }
         })
         .catch((error)=>{
          if (error.response.status === 401) {
            localStorage.clear();
            window.location.replace('/session-expired');
          }
          else {
           dispatch(GeneralDetailsFailure());
          }
         })
   }
   }


   export const userPersonalDetailsService = (requestData)=>{
    const config = {
     headers: {
        Authorization: `Bearer ${requestData}`
     }
   };
   
   const bodyParameters = {
     key: "value"
   };
     return (dispatch)=>{
       dispatch(PersonalDetailsInitiate());
       axios.post( `${process.env.REACT_APP_BACKEND_URL }get_user_personal_details`, bodyParameters,config)
         .then((response)=>{
             if(response.data.STATUS) { 
               dispatch(PersonalDetailsSuccess(response.data.result));
            }else{
               dispatch(PersonalDetailsFailure());
            }
         })
         .catch((error)=>{
          if (error.response.status === 401) {
            localStorage.clear();
            window.location.replace('/session-expired');
          }
          else {
           dispatch(PersonalDetailsFailure());
          }
         })
   }
   }


   export const updateUserPersonalGeneralDetails = (token,requestData)=>{
    const config = {
     headers: {
        Authorization: `Bearer ${token}`
     }
   };
     return (dispatch)=>{
       dispatch(UpdatePersonalDetailsInitiate());
       axios.post( `${process.env.REACT_APP_BACKEND_URL }update_user_general_details`,requestData,config)
         .then((response)=>{
             if(response.data.STATUS) { 
               dispatch(UpdatePersonalDetailsSuccess(response.data.result));
            }else{
               dispatch(UpdatePersonalDetailsFailure());
            }
         })
         .catch((error)=>{
          if (error.response.status === 401) {
            localStorage.clear();
            window.location.replace('/session-expired');
          }
          else {
           dispatch(UpdatePersonalDetailsFailure());
          }
         })
   }
   }

   export const updateMobileNumber = (token,requestData)=>{
    const config = {
     headers: {
        Authorization: `Bearer ${token}`
     }
   };
     return (dispatch)=>{
       dispatch(UpdateMobileInitiate());
       axios.post( `${process.env.REACT_APP_BACKEND_URL }mobile_verification`,requestData,config)
         .then((response)=>{
             if(response.data.STATUS) { 
               dispatch(UpdateMobileSuccess(response.data));
            }else{
               dispatch(UpdateMobileFailure(response.data));
            }
         })
         .catch((error)=>{
          if (error.response.status === 401) {
            localStorage.clear();
            window.location.replace('/session-expired');
          }
          else {
           dispatch(UpdateMobileFailure());
          }
         })
   }
   }


   export const verifyMobileOtp = (token,requestData)=>{
    const config = {
     headers: {
        Authorization: `Bearer ${token}`
     }
   };
     return (dispatch)=>{
       dispatch(VerifyMobileOTPInitiate());
       axios.post( `${process.env.REACT_APP_BACKEND_URL }verification_otp_profile`,requestData,config)
         .then((response)=>{
             if(response.data.STATUS) { 
               dispatch( VerifyMobileOTPSuccess(response.data));
            }else{
               dispatch(VerifyMobileOTPFailure());
            }
         })
         .catch((error)=>{
          if (error.response.status === 401) {
            localStorage.clear();
            window.location.replace('/session-expired');
          }
          else {
           dispatch(VerifyMobileOTPFailure());
          }
         })
   }
   }
  