import { makeStyles } from '@mui/styles';
const useStyles = makeStyles((theme) => ({
	containerClass: {
marginTop:"30px",
marginBottom:'60px',
backgroundColor:'#0000ff05',
padding:'0px !important',
'@media(max-width: 1024px)' :{
    paddingLeft:'10px !important',
    paddingRight:'10px !important',
  }
	},

accordianClass:{
    marginTop:"20px", 
    borderRadius:"10px !important",
    borderTopLeftRadius:"10px !important",
    borderTopRightRadius:"10px !important",
    backgroundColor:'#fff',
    boxShadow: "0px 1px 3px 0px rgb(0 0 0 / 6%), 0px 1px 3px 0px rgb(0 0 0 / 6%) , 0px 1px 3px 0px rgb(0 0 0 / 6%) ,0px 1px 3px 0px rgb(0 0 0 / 6%) !important",
    '&::before': {
    backgroundColor:" rgba(0, 0, 0, 0) !important",
    }
},
}));

export default useStyles;
