import React, { useEffect, useState } from 'react';
import AppLayout from '../layouts/aboutusLayout';
import ArrowDropDownOutlinedIcon from '@mui/icons-material/ArrowDropDownOutlined';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
  Container,
} from '@mui/material';
import useStyles from '../styles/aboutus';
import { connect } from 'react-redux';
import * as staticPagesAction from '../redux/action/staticPagesAction';
import { useHistory } from 'react-router';
import CookiePolicy from '../utils/CookiePolicy';
import PrivacyPolicy from '../utils/PrivacyPolicy';
import TermsAndConditions from '../utils/TermsAndConditions';
import { useTranslation } from 'react-i18next';
import AboutUsContent from '../utils/aboutUs';
const AboutUs = (props) => {
  const classes = useStyles();
  const history = useHistory()
  const [isOpened, setIsopened] = useState('panel1')
  const { t } = useTranslation();
  useEffect(() => {
    props.aboutusService();
    props.cookiePolicyService();
    props.privacyPolicyService();
    props.termsOfService();
    props.imprintService();
  }, []);

  const aboutUs = () => {
    return (
      <AboutUsContent />
    );
  };

  const contactUs = () => {
    return (
      <CookiePolicy />

    );
  };

  const privacyPolicy = () => {
    return (

      <PrivacyPolicy />
    );
  };

  const termsandConditions = () => {
    return (
      <TermsAndConditions />
    );
  };
  const imprint = () => {
    return (
      <Typography variant="p">

        Verantwortlicher Seitenbetreiber:<br />
        KIA-Development Co.Ltd.<br />
        59/30 Moo 6 T.Ratsada,<br /> A.Muang Phuket, 83000
        <br /> Thailand<br /><br />
        Verantwortlich für die Payment-Abwicklung:<br />
        ZvW Valuetransfer 24 ApS <br />
        c/o Froeslev<br /> Hermesvej 15<br />
        DK-6330 Padborg<br /> CRV No 42801860
      </Typography>
    );
  };

  const handleChange = (value) => {
    if (value === isOpened) {
      setIsopened('')
      sessionStorage.setItem("accoPanel", '')
    } else {
      setIsopened(value)
      sessionStorage.setItem("accoPanel", value)
    }
  }

  useEffect(() => {
    if (sessionStorage.getItem("accoPanel") !== null) {
      setIsopened(sessionStorage.getItem("accoPanel"))
    }
  }, [sessionStorage.getItem("accoPanel")])


  return (
    <div style={{ backgroundColor: 'rgb(243, 248, 255)' }}>
      <AppLayout subFooter={false} subHeader={false} header={true}>
        <Container className={classes.containerClass}>
          <Accordion expanded={isOpened === 'panel1' ? true : false} onClick={() => handleChange('panel1')} className={classes.accordianClass}>
            <AccordionSummary
              expandIcon={<ArrowDropDownOutlinedIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography>	{t("aboutUs")}</Typography>
            </AccordionSummary>
            <AccordionDetails>{aboutUs()}</AccordionDetails>
          </Accordion>
          <Accordion className={classes.accordianClass} expanded={isOpened === 'panel2' ? true : false} onChange={() => handleChange('panel2')}>
            <AccordionSummary
              expandIcon={<ArrowDropDownOutlinedIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography>		{t("termsAndcondition")}</Typography>
            </AccordionSummary>
            <AccordionDetails>{termsandConditions()}</AccordionDetails>
          </Accordion>
          <Accordion className={classes.accordianClass} expanded={isOpened === 'panel3' ? true : false} onChange={() => handleChange('panel3')}>
            <AccordionSummary
              expandIcon={<ArrowDropDownOutlinedIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography>	{t("privacyPolicy")}</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography variant="p">{privacyPolicy()}</Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion className={classes.accordianClass} expanded={isOpened === 'panel4' ? true : false} onChange={() => handleChange('panel4')}>
            <AccordionSummary
              expandIcon={<ArrowDropDownOutlinedIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography>	{t("cookiesPolicy")}</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography variant="p">
                {/* Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                Suspendisse malesuada lacus ex, sit amet blandit leo lobortis
                eget. */}
                {contactUs()}
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion className={classes.accordianClass} expanded={isOpened === 'panel5' ? true : false} onChange={() => handleChange('panel5')}>
            <AccordionSummary
              expandIcon={<ArrowDropDownOutlinedIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography>	{t("impressum")}</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography variant="p">{imprint()}</Typography>
            </AccordionDetails>
          </Accordion>
        </Container>
      </AppLayout>
    </div>
  );
};
const mapStateToProps = (state) => {
  return {
    aboutUsData: state.StaticPagesReducer.aboutUsData,
    contactUsData: state.StaticPagesReducer.contactUsData,
    privacyPolicyData: state.StaticPagesReducer.privacyPolicyData,
    termsConditionsData: state.StaticPagesReducer.termsConditionsData,
    imprintData: state.StaticPagesReducer.imprintData
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    aboutusService: () => dispatch(staticPagesAction.aboutusService()),
    cookiePolicyService: () => dispatch(staticPagesAction.cookiePolicyService()),
    privacyPolicyService: () =>
      dispatch(staticPagesAction.privacyPolicyService()),
    termsOfService: () => dispatch(staticPagesAction.termsOfService()),
    imprintService: () => dispatch(staticPagesAction.imprintService()),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(AboutUs);
