export const GENDER = [
	{
		value: 'Male',
		label: 'Männlich',
	},
	{
		value: 'Female',
		label: 'Weiblich',
	},
	// {
	// 	value: 'ratherNotSay',
	// 	label: 'Rather Not Say',
	// },
];

export const INTERESTED_IN = [
	{
		value: 'Men',
		label: 'Männer',
	},
	{
		value: 'Women',
		label: 'Frauen',
	},
	{
		value: 'Both',
		label: 'Beides',
	},
];

export const COUNTRIES = [
	{
		value: 'Germany',
		label: 'Germany',
	},
	{
		value: 'Australia',
		label: 'Aus',
	},
];

export const CITIES = [
	{
		value: 'Delhi',
		label: 'Delhi',
	},
	{
		value: 'Mumbai',
		label: 'Mumbai',
	},
];

export const STATUS = [
	{
		value: 'Single',
		label: 'Single',
	},
	{
		value: 'Married',
		label: 'Verheiratet',
	},
	{
		value: 'Widow',
		label: 'Witwe',
	},
	{
		value: 'Divorced',
		label: 'Geschieden',
	},
];


export const firebaseConfig = {
	apiKey: "AIzaSyA0ZA4UUzpuZtaI5HMDIDTrluRslLWSeBU",
	authDomain: "iseejuweb.firebaseapp.com",
	projectId: "iseejuweb",
	storageBucket: "iseejuweb.appspot.com",
	messagingSenderId: "326688345459",
	appId: "1:326688345459:web:2423168ab0e7295a184906",
	measurementId: "G-C1JCW1GKYS"
};