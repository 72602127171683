import {
  Button,
  Dialog,
  DialogContent,
  Grid,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from '@mui/material';
import React, { useState, useEffect } from 'react';
import CancelIcon from '@mui/icons-material/Cancel';
import { RiLock2Fill } from 'react-icons/ri';
import useStyles from '../styles/auth';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { useHistory } from 'react-router';
import { connect } from 'react-redux';
import * as resetPasswordAction from '../redux/action/resetPasswordAction';
import { useTranslation } from 'react-i18next';

const ResetPassword = (props) => {
  const [resetModal, setResetModal] = React.useState(true);
  const {t}=useTranslation()
  const classes = useStyles();
  const [passwordVisibility, showPassword] = React.useState(false);
  const [token,setToken]=useState('')
  const[tokenStatus,setTokenStatus]=useState(false)
  const [newPassword,setNewPassword]=useState('')
  const[passwordError,setPasswordError]=useState('')
  const[passwordChangeStatus,sePasswordChangeStatus]=useState(false)
  const history = useHistory();
  const handleResetClose = () => {
    setResetModal(false);
    history.push('/login');
  };
  useEffect(() => {
    let val=window.location.search
    let token=val.replace('?token=','')
    setToken(token)
    props.verifyToken(token)
  }, []);

useEffect(() => {
  if(props.registerStatus===1){
    setTokenStatus(true)
  }
}, [props.registerStatus])

 const handleChange=(event)=>{
   setNewPassword(event.target.value)
 } 
 const SubmitPassword=()=>{
   let passwordRegex='^(?=.{6,})';
   let  passwordStatus=false
   if(newPassword.match(passwordRegex)){
    passwordStatus=true
    setPasswordError('')
   } else{
    setPasswordError(t("passwordCategory"))
    passwordStatus=false
   }

   if(passwordStatus){
     props.resetPassword(token,newPassword)
   }
 }

 useEffect(() => {
  if(props.passwordChangeStatus===1){
    sePasswordChangeStatus(true)
    history.push('/login');
  }
 }, [props.passwordChangeStatus])
  return (
    <>
      <Dialog
        open={resetModal}
        onClose={handleResetClose}
        sx={{ backgroundColor: 'black' }}
      >
        <DialogContent sx={{ maxWidth: '430px' }}>
          <CancelIcon
            className={classes.closeIcon}
            onClick={handleResetClose}
          />
          <Grid>
            <Typography
              variant="body1"
              component="p"
              sx={{ margin: '20px 20px', marginLeft: '0px !important' }}
            >
              {!passwordChangeStatus?t("resetPassword"):t("resetPasswordSuccess")}
             
            </Typography>
            <Typography variant="h5" component="h5" sx={{ color: 'black' }}>
             {!passwordChangeStatus?t("diffFromOldPassword"):'Lorem ipusum dollerr sit amet.'}
            </Typography>
            {!passwordChangeStatus?<><TextField
            type={passwordVisibility?'text':'password'}
              variant="filled"
              name="password"
              placeholder={t("enterNewPassword")}
              autoComplete="off"
              onChange={handleChange}
              // value={values.email}
              // onChange={handleChange}
              // helperText={
              //   errors.email &&
              //   touched.email && (
              //     <span className={classes.errorText}>{errors.email}</span>
              //   )
              // }
              InputProps={{
                classes: {
                  input: classes.inputFields,
                  root: classes.inputRootModal,
                },
                disableUnderline: true,
                startAdornment: (
                  <InputAdornment
                    position="start"
                    className={classes.forgotstartAdorn}
                  >
                    <RiLock2Fill className={classes.forgotmailIcon} />
                  </InputAdornment>
                ),
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={() => showPassword(!passwordVisibility)}
                      edge="right"
                    >
                      {passwordVisibility ? (
                        <VisibilityIcon style={{ color: '#1b1c1c59' }} />
                      ) : (
                        <VisibilityOffIcon style={{ color: '#1b1c1c59' }} />
                      )}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              margin="normal"
              fullWidth
            />
            <p>{passwordError}</p></>:''}
            {!passwordChangeStatus?<Button
              fullWidth
              color={tokenStatus?"primary":'secondary'}
              variant="contained"
              sx={{
                borderRadius: '10px',
                margin: '20px 0px ',
                fontSize: '17px',
                height: '50px',
              }}
              type="submit"
              onClick={tokenStatus?()=>SubmitPassword():""}
            >
            {t("sendPassword")}
            </Button>:<Button
              fullWidth
              color="primary"
              variant="contained"
              sx={{
                borderRadius: '10px',
                margin: '20px 0px ',
                fontSize: '17px',
                height: '50px',
              }}
              type="submit"
              onClick={()=>handleResetClose()}
            >
              {t("continue")}
            </Button>}
          </Grid>
        </DialogContent>
      </Dialog>
    </>
  );
};
const mapStateToProps = (state) => {
  return {
    registerStatus: state.ResetPasswordReducer.tokenStatus,
    passwordChangeStatus: state.ResetPasswordReducer.passwordChangeStatus,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    verifyToken: (requestData) =>
      dispatch(resetPasswordAction.verifyToken(requestData)),
      resetPassword: (token,password) =>
      dispatch(resetPasswordAction.resetPassword(token,password)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ResetPassword);
