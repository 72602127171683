import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import UserLayout from '../layouts/userLayout';
import { Grid, Card, CardContent } from '@mui/material';
import BasicInfoCard from '../components/profilePages/basicInfoCard';
import GalleryCard from '../components/profilePages/galleryCard';
import MyFavorites from '../components/profilePages/myFavorites';
import MemberHistory from '../components/profilePages/memberHistory';
import UserVisitor from '../components/profilePages/userVisitor';
import ProfileCard from '../components/profilePages/profileCard';
import useStyles from '../styles/myProfile';
import SpinTheWheel from '../components/profilePages/spinthewheel';
import { getIsMobile } from '../utils';
import { Link, useHistory } from 'react-router-dom';
import Stickers from './stickers';
import CoinPackage from './coinPackage';

const MyProfile = (props) => {
  const classes = useStyles();
  const isMobile = getIsMobile();
  let history = useHistory();
  useEffect(() => {
    if (localStorage.getItem('userToken') === null) {
      history.push('/');
    }
  }, [localStorage.getItem('userToken')]);

  const renderRightCard = () => {
    switch (props.selectedTab) {
      case 0: {
        return  <ProfileCard myProfile={true} />;
      }
      case 1: {
        return <SpinTheWheel />;
      }
      case 2: {
        return <GalleryCard myProfile={true} />;
      }
      case 3: {
        return <MyFavorites />;
      }
      case 4: {
        return <MemberHistory />;
      }
      case 5: {
        return <UserVisitor />;
      }
      case 6: {
        return <ProfileCard />;
      }
      case 7: {
        return <ProfileCard />;
      }
      case 8: {
        return <ProfileCard />;
      }
      default:
        return (
          <Card sx={{ minWidth: 275, borderRadius: '10px' }}>
            <CardContent>My profile</CardContent>
          </Card>
        );
    }
  };
  const renderRightMobileCard = () => {
    switch (props.selectedTab) {
      case 0: {
        return   <BasicInfoCard myProfile={true} />;
      }
      case 1: {
        return <ProfileCard />;
      }
      case 2: {
        return  <SpinTheWheel />;
      }
      case 3: {
        return <GalleryCard myProfile={true} />;
      }
      case 4: {
        return <MyFavorites />;
      }
      case 5: {
        return <MemberHistory />;
      }
      case 6: {
        return <UserVisitor />;
      }
      case 7: {
        return <Stickers />;
      }
      case 8: {
        return <CoinPackage />;
      }
      default:
        return (
          <Card sx={{ minWidth: 275, borderRadius: '10px' }}>
            <CardContent>My profile</CardContent>
          </Card>
        );
    }
  };
  return (
    <UserLayout subHeader={true} subFooter={true} myProfile={true} header={true}>
      <Grid
        container
        direction="row"
        justifyContent="space-around"
        spacing={4}
        sx={{ padding: '20px 0px' }}
      >
        {!isMobile ? (
          <Grid item xs={12} md={5} lg={5}>
            <BasicInfoCard myProfile={true} />
          </Grid>
        ) : (
          ''
        )}

        <Grid item xs={12} md={7} lg={7}>
          {!isMobile ? renderRightCard():renderRightMobileCard()}
        </Grid>
      </Grid>
    </UserLayout>
  );
};

const mapStateToProps = (state) => {
  return {
    userToken: state.LoginReducer.userToken,
    selectedTab: state.ProfileTabReducer.selectedTab,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(MyProfile);
