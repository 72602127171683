import axios from "axios";
import {createAction} from "redux-actions";

export const loginInitiate=createAction("LOGIN_INITIATE");
export const loginSuccess=createAction("LOGIN_SUCCESS");
export const loginFailure=createAction("LOGIN_FAILURE");

export const socialLoginInitiate=createAction("SOCIAL_LOGIN_INITIATE");
export const socialLoginSuccess=createAction("SOCIAL_LOGIN_SUCCESS");
export const socialLoginFailure=createAction("SOCIAL_LOGIN_FAILURE");

export const logoutInitiate=createAction("LOGOUT_INITIATE");
export const logoutSuccess=createAction("LOGOUT_SUCCESS");
export const logoutFailure=createAction("LOGOUT_FAILURE");
export const loginService = (requestData)=>{
    return (dispatch)=>{
        dispatch(loginInitiate());
        axios({
            method: "post",
            url: `${process.env.REACT_APP_BACKEND_URL }login`,
            data: requestData,
            headers: { "Content-Type": "multipart/form-data"},
          })
          .then((response)=>{
          
           
       
              if(response.data.STATUS===true||response.data.STATUS==="true") {
                localStorage.setItem('userData',JSON.stringify(response.data))
                localStorage.setItem("userToken",response.data.auth_token)
                 localStorage.setItem("userFavCount",response.data.user_fav_count)
                 localStorage.setItem("userLevelData",JSON.stringify(response.data.user_level))
                dispatch(loginSuccess(response.data));
             }else{
                dispatch(loginFailure(response.data.message));
             }
          })
          .catch((error)=>{
            dispatch(loginFailure());
          })
    }
}

export const socialLoginService = (requestData)=>{
  return (dispatch)=>{
      dispatch(loginInitiate());
      axios({
          method: "post",
          url: `${process.env.REACT_APP_BACKEND_URL }social_media_login`,
          data: requestData,
          headers: { "Content-Type": "multipart/form-data"},
        })
        .then((response)=>{
        
         
     
            if(response.data.STATUS===true||response.data.STATUS==="true") {
              localStorage.setItem('userData',JSON.stringify(response.data))
              localStorage.setItem("userToken",response.data.bearer_token)
               localStorage.setItem("userFavCount",response.data.user_fav_count)
               localStorage.setItem("userLevelData",JSON.stringify(response.data.user_level))
              dispatch(loginSuccess(response.data));
           }else{
              dispatch(loginFailure(response.data.message));
           }
        })
        .catch((error)=>{
          dispatch(loginFailure());
        })
  }
}

export const logoutService = (requestData)=>{
 const config = {
  headers: {
     Authorization: `Bearer ${requestData}`
  }
};

const bodyParameters = {
  key: "value"
};
  return (dispatch)=>{
    dispatch(logoutInitiate());
    axios.post( `${process.env.REACT_APP_BACKEND_URL }logout`, bodyParameters,config)
      .then((response)=>{
          if(response.data.STATUS) {
            localStorage.removeItem("userToken")
            localStorage.removeItem("userFavCount")
            localStorage.removeItem("userLevelData")
            localStorage.removeItem("currentChatId")
            localStorage.removeItem("userData")
            sessionStorage.clear()
            dispatch(logoutSuccess());
         }else{
            dispatch(logoutFailure());
         }
      })
      .catch((error)=>{
        dispatch(loginFailure());
      })
}
}

export const loginFailureStatusNull=createAction("LOGIN_NULL");

export const loginStatusNull = ()=>{ 
  return (dispatch)=>{
    dispatch(loginFailureStatusNull());
}
}