import { handleActions } from 'redux-actions';


const LastVisitorReducer = {
  lastVisitorData: [],

};

const reducer = handleActions(
  {
    GET_USER_VISITOR_INITIATE: (state) => {
      return {
        ...state,
      };
    },
    GET_USER_VISITOR_SUCCESS: (state, action) => {
      return {
        ...state,
        lastVisitorData: action.payload,
      };
    },
    GET_USER_VISITOR_FAILURE: (state) => {
      return {
        ...state,
      };
    },
    ADD_USER_VISITOR_INITIATE: (state) => {
        return {
          ...state,
        };
      },
      ADD_USER_VISITOR_SUCCESS: (state, action) => {
        return {
          ...state,
        };
      },
      ADD_USER_VISITOR_FAILURE: (state) => {
        return {
          ...state,
        };
      },
  },
  LastVisitorReducer
);
export default reducer;
