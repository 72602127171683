import React from 'react';
import { Grid, Typography, useMediaQuery } from '@mui/material';
import useStyles from '../../styles/landingPage';
import { getIsMobile } from '../../utils';
import { ReactComponent as PhoneMockupIcon } from '../icons/phone-mockup.svg';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const Download = () => {
	const classes = useStyles();
	const isMobile = getIsMobile();
	const { t } = useTranslation();
	return (
		<div className={classes.downloadBg}>
			<PhoneMockupIcon/>
			{/* <div className={classes.downloadText}>
				<Typography variant="h2" component="h2">
				{t("downloadTheapp")}
				</Typography>
				<Typography variant="body2" component="p" sx={{ marginTop: '15px' }}>
				{t("para1")}
				</Typography>
				<Grid
					container
					sx={{ marginTop: '30px' }}
					justifyContent={
						useMediaQuery('(max-width:600px)') ? 'center' : 'flex-start'
					}
				>
					<Link  to="#">
						<img
							src="/assets/images/cta_google_play_black.png"
							alt="google play"
							style={{ marginRight: '10px' }}
							width={isMobile ? '124px' : '155px'}
							className={classes.playstoreButton}
						/>
					</Link>
					<Link  to="#">
						<img
							src="/assets/images/cta_apple_black.png"
							alt="apple store"
							width={isMobile ? '124px' : '155px'}
							className={classes.playstoreButton}
						/>
					</Link>
				</Grid>
			</div> */}
		</div>
	);
};
export default Download;
