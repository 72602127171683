import { Box, Button, Grid, Modal, Typography } from '@mui/material';
import React from 'react';
import useStyles from '../../styles/myProfile';
export default function ConfirmationAlert(props) {
  const classes = useStyles();
  return (
    <div>
      <Modal
        backgroundColor="##000000c9"
        open={props.alert}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box className={classes.boxStyle} sx={props.width ? { width: "400px !important" } : {}}>
          <Grid>
            <Grid
              item
              sx={{ display: 'flex', justifyContent: 'center', mt: 3 }}
            >
              <Typography
                sx={{ textAlign: 'center' }}
                id="modal-modal-title"
                variant="h3"
                component="h3"
              >
                {props.alertTitle}
              </Typography>
            </Grid>
            <Grid
              item
              sx={{
                display: 'flex',
                justifyContent: 'center',
                mt: 3,
                ml: 3,
                mr: 3,
              }}
            >
              <Typography
                id="modal-modal-description"
                variant="p"
                sx={{ textAlign: 'center', cursor: 'text' }}
              >
                {props.alertText}
              </Typography>
            </Grid>
            <Grid
              item
              sx={{
                display: 'flex',
                justifyContent: `${props.confirmButton ? 'space-between' : 'center'
                  }`,
                mt: 1,
                ml: props.width ? 7 : 3,
                mr: props.width ? 7 : 3,
              }}
            >
              {props.confirmButton ? (
                <Button
                  disabled={props.buttonDisabled}
                  variant="contained"
                  className={classes.updateButton}
                  sx={{
                    marginTop: '12px',
                    marginBottom: '10px',
                  }}
                  onClick={props.buttonDisabled ? '' : () => props.confirmFunc()}
                >
                  {props.confirmButtonText}
                </Button>
              ) : (
                ''
              )}
              {props.closeButton ? (
                <Button
                  variant={props.confirmButton ? 'outlined' : 'contained'}
                  className={classes.updateButton}
                  sx={{
                    marginTop: '12px',
                    marginBottom: '10px',
                    marginLeft:props.alertText==='Möchten Sie wirklich kaufen?'?1:0
                  }}
                  onClick={() => props.closeFunc()}
                >
                  {props.closeButtonText}
                </Button>
              ) : (
                ''
              )}
            </Grid>
          </Grid>
        </Box>
      </Modal>
    </div>
  );
}
