import axios from 'axios';
import { createAction } from 'redux-actions';

export const getChestDataInitiate = createAction('GET_CHEST_DATA_INITIATE');
export const getChestDataSuccess = createAction('GET_CHEST_DATA_SUCCESS');
export const getChestDataFailure = createAction('GET_CHEST_DATA_FAILURE');

export const UnlockChestDataInitiate = createAction('UNLOCK_CHEST_DATA_INITIATE');
export const UnlockChestDataSuccess = createAction('UNLOCK_CHEST_DATA_SUCCESS');
export const UnlockChestDataFailure = createAction('UNLOCK_CHEST_DATA_FAILURE');

export const getChestData = (requestData) => {
  const config = {
    headers: {
      Authorization: `Bearer ${requestData}`,
    },
  };
  return (dispatch) => {
    dispatch(getChestDataInitiate());
    axios
      .get(`${process.env.REACT_APP_BACKEND_URL}Chest_list`, config)
      .then((response) => {

        if (response.data.STATUS) {
          dispatch(getChestDataSuccess(response.data));
        } else if (response.status === 401) {

        } else {
          dispatch(getChestDataFailure());
        }
      })
      .catch((error) => {
        if (error.response.status === 401) {
          localStorage.clear();
          window.location.replace('/session-expired');
        }
        else {
          dispatch(getChestDataFailure());
        }

      });
  };
};



export const unlockChestData = (requestData, token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  return (dispatch) => {
    dispatch(UnlockChestDataInitiate());
    axios
      .post(`${process.env.REACT_APP_BACKEND_URL}Unlock_chest`, requestData, config)
      .then((response) => {
        if (response.data.STATUS) {
          dispatch(UnlockChestDataSuccess(response.data));
        } else {
          dispatch(UnlockChestDataFailure(response.data));
        }
      })
      .catch((error) => {
        if (error.response.status === 401) {
          localStorage.clear();
          window.location.replace('/session-expired');
        }
        else {
        dispatch(UnlockChestDataFailure());
        }
      });
  };
};

export const setUnlockStatusNull = createAction("SET_UNLOCK_STATUS_SUCCESS");

export const UnlockStatusNull = () => {
  return (dispatch) => {
    dispatch(setUnlockStatusNull());
  }
}