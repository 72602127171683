import React from 'react'

export default function AboutUsContent() {
  return (
    <p>Unser Team aus Web-Professionals ist seit Jahrzehnten im Bereich der IT- & Social Media Industrie tätig. Wir haben unsere Erfahrungen bei einigen der größten Firmen dieses Bereiches gesammelt und aus einem langen Abend erwuchs eine Idee: wir wollten unsere Fähigkeiten nutzen, um ein komplett neues Projekt aufzustellen. 

    ISeeJu.com sollte alle Vorteile des modernen, mobilen und vernetzten Lebens in sich vereinen, dem Nutzer eine neue Erfahrung bieten ohne auf bewährte, bekannte und lieb gewonnene Funktionen verzichten oder den Gebrauch des Internets gar neu erlernen zu müssen. Gleichzeitig wollten wir aber auch Euch in den Vordergrund stellen. Dieses Projekt als Nutzer zu begleiten, soll Euch so viel Spaß machen wie uns die Entwicklung – ohne dabei kompliziert und schwer bedienbar zu werden. Die Idee einer völlig neuen Community mit vielen vernetzten Funktionen war geboren. 
    
    Auch wenn wir mit ISeeJu.com jetzt online sind und unsere Erfahrungen mit euch als unseren Mitgliedern teilen, so wollen wir aber nicht dabei stehen bleiben. Es ist uns ein Wunsch & Anliegen, dieses Projekt kontinuierlich weiterzuentwickeln und zu verbessern, wobei wir uns auf eure eingebrachten Ideen und Erfahrungen nicht nur sehr freuen, sondern auch immer unsere Gedanken darauf ausrichten wollen, wie wir ISeeJu.com für Euch besser, einfacher und umfassender machen können. 
    
    Besonders zu sein ohne zu einem Sonderling zu werden, das war unser Anspruch und diese Idee leitet uns jeden Tag, an dem wir für Euch weiter an diesem Projekt arbeiten. Unsere Ideen wachsen mit jeder Interaktion, unsere Gedanken kreisen um Eure Anregungen und unser Anspruch leitet uns in der Umsetzung. 
    
    Das ist der Weg. </p>
  )
}
