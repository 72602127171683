import { handleActions } from 'redux-actions';

const MyFavoritesReducer = {
  myFavoriteData: [],
};

const reducer = handleActions(
  {
    MYFAVORITE_DETAILS_INITIATE: (state) => {
      return {
        ...state,
      };
    },
    MYFAVORITE_DETAILS_SUCCESS: (state, action) => {
      return {
        ...state,
        myFavoriteData: action.payload,
      };
    },
    MYFAVORITEL_DETAILS_FAILURE: (state) => {
      return {
        ...state,
        myFavoriteData: [],
      };
    },
  },
  MyFavoritesReducer
);
export default reducer;
