import { handleActions } from 'redux-actions';

const SkillTreeReducer = {
  skillTreeData: [],
  unlockSkillTreeStatus: null,
};

const reducer = handleActions(
  {
    GET_SKILLTREE_INITIATE: (state) => {
      return {
        ...state,
      };
    },
    GET_SKILLTREE_SUCCESS: (state, action) => {
      return {
        ...state,
        skillTreeData: action.payload,
      };
    },
    GET_SKILLTREE_FAILURE: (state) => {
      return {
        ...state,
        skillTreeData: [],
      };
    },
    UNLOCK_SKILLTREE_INITIATE: (state) => {
      return {
        ...state,
      };
    },
    UNLOCK_SKILLTREE_SUCCESS: (state, action) => {
      return {
        ...state,
        unlockSkillTreeStatus: action.payload,
      };
    },
    UNLOCK_SKILLTREE_FAILURE: (state,action) => {
      return {
        ...state,
        unlockSkillTreeStatus: action.payload,
      };
    },
    SET_UNLOCK_STATUS_SUCCESS: (state,action) => {
      return {
        ...state,
        unlockSkillTreeStatus:null,
      };
    },
  },
  SkillTreeReducer
);
export default reducer;
